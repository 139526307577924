import React, { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { registerPatternFolder } from "../../../helpers/constant/registerPattern";
import { selectFolderState } from "../../../state/features/folder/folderSelector";
import { FolderInterface } from "../../../state/features/folder/folder.Interface";
import {
  addFolder,
  resetAddFolderSuccess,
  resetUpdateFolderSuccess,
  updateFolder,
} from "../../../state/features/folder/folderSlice";
import { FOLDER_DEFAULT_COLOR } from "../../../helpers/constant/folderConstant";

interface Props {
  onClose: () => void;
  isEditMode: boolean;
  folder?: FolderInterface;
}

const AddOrEditFolderName: React.FC<Props> = ({ onClose, isEditMode, folder }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isAdding, isAddSuccess, isUpdateSuccess, isUpdating } = useSelector(selectFolderState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FolderInterface>();

  const onSubmit: SubmitHandler<FolderInterface> = (data) => {
    if (isEditMode) {
      dispatch(updateFolder({ ...folder, name: data.name }));
    } else {
      dispatch(addFolder({ name: data.name, color: FOLDER_DEFAULT_COLOR }));
    }
  };

  useEffect(() => {
    if (folder) {
      setValue("name", folder.name);
    }
  }, [folder]);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      dispatch(resetAddFolderSuccess());
      dispatch(resetUpdateFolderSuccess());
      onClose();
    }
  }, [isAddSuccess, isUpdateSuccess]);

  const isLoading = isAdding || isUpdating;
  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
        <CoreTextField
          type='text'
          size='small'
          color='secondary'
          label={"Folder Name"}
          placeholder='Enter folder name here'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternFolder.name)}
          required
        />
      </FormControl>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={3}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isLoading}
          loadingText={isEditMode ? "Changing..." : "Creating..."}
          type='submit'
          size='large'
          color='success'
        >
          {isEditMode ? "Change" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditFolderName;
