import React from "react";
import { useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { Box, Divider, Stack, Typography } from "@mui/material";
import useBoolean from "../../../hooks/useBoolean";
import CoreButton from "../../../common/Button/CoreButton";
import CreateWebsiteModalAdmin from "./CreateWebsiteModalAdmin";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { selectPermissionState } from "../../../state/features/permission/permissionSelector";

const AdminListHeader: React.FC = () => {
  const { isAdmin, isSuperAdmin } = useSelector(selectPermissionState);

  const {
    value: isOpenCreateWebsiteModal,
    setTrue: onOpenCreateWebsiteModal,
    setFalse: onCloseCreateWebsiteModal,
  } = useBoolean(false);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} p={2}>
        <Box sx={{ flex: 1 }}>
          <Typography variant={"h6"} color={"text.secondary"}>
            {isSuperAdmin && "Super "} Admin Templates
          </Typography>
          <Typography variant={"body2"} color={"text.secondary"}>
            All of your website templates will be here.
          </Typography>
        </Box>

        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {/*<CoreButton color={"secondary"} variant={"outlined"} size={"large"} startIcon={<AutoGraphIcon />}>*/}
          {/*  Report*/}
          {/*</CoreButton>*/}

          {isAdmin && (
            <CoreButton
              color={"secondary"}
              size={"large"}
              variant='contained'
              startIcon={<Add />}
              onClick={onOpenCreateWebsiteModal}
            >
              New Website
            </CoreButton>
          )}
        </Stack>
      </Stack>
      <Divider light />

      <BasicModalWithHeader
        title='Create New Website Template'
        open={isOpenCreateWebsiteModal}
        onClose={onCloseCreateWebsiteModal}
        width={500}
      >
        <CreateWebsiteModalAdmin onClose={onCloseCreateWebsiteModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default AdminListHeader;
