import httpRequest from "./api/httpRequest";
import {
  FunnelStepInterface,
  IAddFunnelStepPayload,
  ICloneFunnelStepPayload,
  IDeleteFunnelStepPayload,
  IGetFunnelStepsPayload,
  IReorderFunnelStepPayload,
  IUpdateFunnelStepPayload,
} from "../state/features/funnelStep/funnelStep.interface";

const funnelAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelAPI) throw Error("FunnelStep API URL not found in env!");

class FunnelStepService {
  getFunnelSteps(body: IGetFunnelStepsPayload) {
    return httpRequest.get<FunnelStepInterface[]>(funnelAPI + "funnel/step/all/" + body.funnelId);
  }

  addFunnelStep(body: IAddFunnelStepPayload) {
    return httpRequest.post<FunnelStepInterface>(funnelAPI + "funnel/step/create", body);
  }

  updateFunnelStep(body: IUpdateFunnelStepPayload) {
    return httpRequest.put<FunnelStepInterface>(funnelAPI + "funnel/step/update", body);
  }
  reorderFunnelStep(body: IReorderFunnelStepPayload) {
    return httpRequest.post<IReorderFunnelStepPayload>(funnelAPI + "funnel/step/reorder", body);
  }

  deleteFunnelStep(params: IDeleteFunnelStepPayload) {
    return httpRequest.delete<number>(funnelAPI + "funnel/step/delete/" + params.id);
  }

  cloneFunnelStep(payload: ICloneFunnelStepPayload) {
    return httpRequest.post<FunnelStepInterface>(funnelAPI + "funnel/step/clone", payload);
  }
}

const funnelStepService = new FunnelStepService();
export default funnelStepService
