import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import {
  FunnelTemplateI,
  CloneFunnelTemplateI,
  DeleteFunnelTemplateI,
  FetchFunnelTemplatesPayloadI,
  InTrashFunnelTemplateI,
  RenameFunnelTemplateI,
  UpdateFunnelApproveStatusI,
  UpdateFunnelPublishStatusI,
} from "../../../interface/funnel-template.interface";

import {
  cloneTemplateFailed,
  cloneTemplateSuccess,
  createTemplateFailed,
  createTemplateSuccess,
  deleteTemplatesSuccess,
  resetCreateStatus,
  fetchTemplateSuccess,
  renameTemplateSuccess,
  resetDeleteTemplates,
  resetRenameTemplateSuccess,
  toggleApproveStatusByAdminFailed,
  toggleApproveStatusByAdminSuccess,
  togglePublishStatusFailed,
  togglePublishStatusSuccess,
  trashTemplatesFailed,
  trashTemplatesSuccess,
} from "./funnelTemplateSlice";
import funnelTemplateService from "../../../services/funnelTemplate";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import FunnelTemplateSettingService from "../../../services/funnelTemplateSetting";

function* templateWatcher() {
  yield takeEvery("funnelTemplate/fetchTemplate", fetchTemplateSaga);
  yield takeEvery("funnelTemplate/createTemplate", createTemplateSaga);
  yield takeEvery("funnelTemplate/renameTemplate", renameTemplateSaga);
  yield takeEvery("funnelTemplate/deleteTemplates", deleteTemplateSaga);
  yield takeEvery("funnelTemplate/trashTemplates", trashTemplateSaga);
  yield takeEvery("funnelTemplate/togglePublishStatus", toggleTemplatePublishSaga);
  yield takeEvery("funnelTemplate/toggleApproveStatusByAdmin", toggleTemplateApproveByAdminSaga);
  yield takeEvery("funnelTemplate/cloneTemplate", cloneTemplateSaga);
}

function* fetchTemplateSaga(action: PayloadAction<FetchFunnelTemplatesPayloadI>) {
  try {
    const response = yield* call(funnelTemplateService.fetchFunnelTemplate, action.payload);
    if (response.success) {
      yield put(fetchTemplateSuccess(response.data));
    } else {
      yield put(resetCreateStatus());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* createTemplateSaga(action: PayloadAction<FunnelTemplateI>) {
  try {
    const response = yield* call(funnelTemplateService.createFunnelTemplate, action.payload);
    if (response.success) {
      yield put(createTemplateSuccess(response.data));
    } else {
      yield put(createTemplateFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* renameTemplateSaga(action: PayloadAction<RenameFunnelTemplateI>) {
  try {
    const response = yield* call(funnelTemplateService.renameTemplate, action.payload);
    if (response.success) {
      yield put(renameTemplateSuccess(action.payload));
      showSuccessToaster("Renamed template successfully.");
    } else {
      yield put(resetRenameTemplateSuccess());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteTemplateSaga(action: PayloadAction<DeleteFunnelTemplateI>) {
  try {
    const response = yield* call(funnelTemplateService.deleteFunnelTemplate, action.payload);
    if (response.success) {
      yield put(deleteTemplatesSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetDeleteTemplates());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* trashTemplateSaga(action: PayloadAction<InTrashFunnelTemplateI>) {
  try {
    const response = yield* call(funnelTemplateService.trashFunnelTemplate, action.payload);
    if (response.success) {
      yield put(trashTemplatesSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(trashTemplatesFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* toggleTemplatePublishSaga(action: PayloadAction<UpdateFunnelPublishStatusI>) {
  try {
    const response = yield* call(funnelTemplateService.updatePublishTemplateStatus, action.payload);
    if (response.success) {
      yield put(togglePublishStatusSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(togglePublishStatusFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* toggleTemplateApproveByAdminSaga(action: PayloadAction<UpdateFunnelApproveStatusI>) {
  try {
    const response = yield* call(funnelTemplateService.updateTemplateApproveStatusByAdmin, action.payload);
    if (response.success) {
      yield put(toggleApproveStatusByAdminSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(toggleApproveStatusByAdminFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* cloneTemplateSaga(action: PayloadAction<CloneFunnelTemplateI>) {
  try {
    const response = yield* call(funnelTemplateService.cloneTemplate, action.payload);

    const funnelTemplateDetails = yield* call(
      FunnelTemplateSettingService.getFunnelTemplateSetting,
      action.payload.funnelId
    );

    if (response.success) {
      const template = {
        ...response.data,
      };
      if (funnelTemplateDetails.success) {
        if (funnelTemplateDetails.data.defaultStepId) template.defaultStepId = funnelTemplateDetails.data.defaultStepId;
      }
      template.createdAt = new Date().toJSON();
      yield put(cloneTemplateSuccess(template));
      showSuccessToaster(response.message);
    } else {
      yield put(cloneTemplateFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* funnelTemplateSaga() {
  yield all([templateWatcher()]);
}
