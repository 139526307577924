import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import SectionLibraryType from "./SectionLibraryType";
import { CustomList } from "../../../SitesList/SitesListStyles";
import CommonSidebarAccordion from "../CommonSidebarAccordion";
import AddOrEditCategoryName from "../modals/AddOrEditCategoryName";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import FolderListSkeleton from "../../../../common/LoadingView/FolderListSkeleton";
import useBoolean from "../../../../hooks/useBoolean";
import { fetchCategory } from "../../../../state/features/category/categorySlice";
import { selectPermissionState } from "../../../../state/features/permission/permissionSelector";
import { selectAdminTemplateState } from "../../../../state/features/adminTemplates/adminTemplateSelector";
import { selectCategoryState } from "../../../../state/features/category/categorySelector";

const SectionLibraryTypeList: React.FC = () => {
  const dispatch = useDispatch();

  const {
    value: openNewCategoryModal,
    setTrue: onOpenNewCategoryModal,
    setFalse: onCloseNewCategoryModal,
  } = useBoolean(false);

  const { isAdmin } = useSelector(selectPermissionState);
  const { data, isLoading } = useSelector(selectCategoryState);
  const {
    filters: { categoryId },
  } = useSelector(selectAdminTemplateState);

  let content = <></>;
  if (isLoading) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        <FolderListSkeleton count={10} />
      </CustomList>
    );
  } else if (data.length > 0) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        {data.map((category) => (
          <SectionLibraryType
            category={category}
            key={category.id}
            selected={category.id === categoryId}
            isAdmin={isAdmin}
          />
        ))}
      </CustomList>
    );
  } else {
    content = (
      <Typography variant={"caption"} fontStyle={"italic"}>
        No categories found.
      </Typography>
    );
  }

  useEffect(() => {
    dispatch(fetchCategory());
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onOpenNewCategoryModal();
  };

  return (
    <>
      <CommonSidebarAccordion
        title={"Categories"}
        tooltipText={"Add New Category"}
        onClick={handleClick}
        isAdmin={isAdmin}
      >
        {content}
      </CommonSidebarAccordion>
      <BasicModalWithHeader title={"Add New Category"} open={openNewCategoryModal} onClose={onCloseNewCategoryModal}>
        <AddOrEditCategoryName onClose={onCloseNewCategoryModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default SectionLibraryTypeList;
