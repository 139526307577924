import React from "react";
import { Tab, Tabs } from "@mui/material";
import { useHistory } from "react-router-dom";

interface IProps {
  firstPath: string;
  secondPath: string;
  firstTitle: string;
  secondTitle: string;
}

const WebsiteFunnelSwitchingTabs: React.FC<IProps> = ({ firstTitle, secondTitle, firstPath, secondPath }) => {
  const history = useHistory();
  const [value, setValue] = React.useState(window.location.pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: string | null) => {
    if (newValue !== null) {
      setValue(newValue);
      history.push(newValue);
    }
  };

  return (
    <Tabs value={value} onChange={handleChange} textColor={"secondary"} indicatorColor={"secondary"} sx={{ pb: 0.5 }}>
      <Tab value={firstPath} label={firstTitle} />
      <Tab value={secondPath} label={secondTitle} />
    </Tabs>
  );
};

export default WebsiteFunnelSwitchingTabs;
