import {call, put} from "typed-redux-saga";
import {PayloadAction} from "@reduxjs/toolkit";
import {all, takeEvery} from "redux-saga/effects";
import {getWebsiteReportsFailed, getWebsiteReportsSuccess} from "./websiteReportsSlice";
import websiteReportsService from "../../../services/websiteReports";

function* websiteReportsWatcher() {
  yield takeEvery("websiteReports/getWebsiteReports", getWebsiteReportsSaga);
}

function* getWebsiteReportsSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(websiteReportsService.getWebsiteReports, action.payload);

    if (response.success) {
      yield put(getWebsiteReportsSuccess(response.data));
    } else {
      yield put(getWebsiteReportsFailed(response.message));
    }
  } catch (err) {
    yield put(getWebsiteReportsFailed("Network or server error"));
  }
}

export default function* websiteReportsSaga() {
  yield all([websiteReportsWatcher()]);
}
