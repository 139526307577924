import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import FunnelTagService from "../../../services/funnel-tag.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  createTagFailed,
  createTagSuccess,
  deleteTagFailed,
  deleteTagSuccess,
  fetchTagFailed,
  fetchTagSuccess,
  updateTagFailed,
  updateTagSuccess,
} from "./funnelTagSlice";
import { CreateTagI, UpdateTagI } from "../../../interface/tag.interface";

function* funnelTagWatcher() {
  yield takeEvery("funnelTag/fetchTag", fetchTagSaga);
  yield takeEvery("funnelTag/fetchAllUserTag", fetchAllUserTagSaga);
  yield takeEvery("funnelTag/createTag", createTagSaga);
  yield takeEvery("funnelTag/updateTag", updateTagSaga);
  yield takeEvery("funnelTag/deleteTag", deleteTagSaga);
}

function* fetchTagSaga() {
  try {
    const response = yield* call(FunnelTagService.fetchTag);

    if (response.success) {
      yield put(fetchTagSuccess(response.data));
    } else {
      yield put(fetchTagFailed());
    }
  } catch (err) {
    yield put(fetchTagFailed());
  }
}

function* fetchAllUserTagSaga() {
  try {
    const response = yield* call(FunnelTagService.fetchAllUserTag);

    if (response.success) {
      yield put(fetchTagSuccess(response.data));
    } else {
      yield put(fetchTagFailed());
    }
  } catch (err) {
    yield put(fetchTagFailed());
  }
}


function* createTagSaga(action: PayloadAction<CreateTagI>) {
  try {
    const response = yield* call(FunnelTagService.createTag, action.payload);

    if (response.success) {
      yield put(createTagSuccess(response.data));
      showSuccessToaster("Tag created");
    } else {
      yield put(createTagFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createTagFailed());
  }
}

function* updateTagSaga(action: PayloadAction<UpdateTagI>) {
  try {
    const response = yield* call(FunnelTagService.updateTag, action.payload);

    if (response.success) {
      yield put(updateTagSuccess(response.data));
      showSuccessToaster("Tag Updated");
    } else {
      yield put(updateTagFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(updateTagFailed());
  }
}

function* deleteTagSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(FunnelTagService.deleteTag, action.payload);

    if (response.success) {
      yield put(deleteTagSuccess(action.payload));
      showSuccessToaster("Tag deleted");
    } else {
      yield put(deleteTagFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteTagFailed());
  }
}

export default function* funnelTagSaga() {
  yield all([funnelTagWatcher()]);
}
