import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, delay, put } from "typed-redux-saga";
import domainService from "../../../services/domain.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  createDomainFailed,
  createDomainSuccess,
  deleteDomainFailed,
  deleteDomainSuccess,
  fetchDomainCredentialFailed,
  fetchDomainCredentialSuccess,
  fetchDomainListFailed,
  fetchDomainListSuccess,
  updateDomainFailed,
  updateDomainSuccess,
} from "./domainSlice";
import { CreateDomainI, DeleteDomainI, FetchDomainPayloadI, UpdateDomainI } from "../../../interface/domain.interface";

function* domainWatcher() {
  yield takeEvery("domain/fetchDomainList", fetchDomainListSaga);
  yield takeEvery("domain/fetchDomainCredential", fetchDomainCredentialSaga);
  yield takeEvery("domain/createDomain", createDomainSaga);
  yield takeEvery("domain/updateDomain", updateDomainSaga);
  yield takeEvery("domain/deleteDomain", deleteDomainSaga);
}

function* fetchDomainListSaga(action: PayloadAction<FetchDomainPayloadI>) {
  try {
    const response = yield* call(domainService.fetchDomainList, action.payload);

    if (response.success) {
      yield put(fetchDomainListSuccess(response.data));
    } else {
      yield put(fetchDomainListFailed());
    }
  } catch (err) {
    yield put(fetchDomainListFailed());
  }
}

function* fetchDomainCredentialSaga() {
  try {
    const response = yield* call(domainService.fetchDomainCredential);

    if (response.success) {
      yield put(fetchDomainCredentialSuccess(response.data));
    } else {
      yield put(fetchDomainCredentialFailed());
    }
  } catch (err) {
    yield put(fetchDomainCredentialFailed());
  }
}

function* createDomainSaga(action: PayloadAction<CreateDomainI>) {
  try {
    const response = yield* call(domainService.createDomain, action.payload);
    yield delay(6000);
    if (response.success) {
      yield put(createDomainSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(createDomainFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createDomainFailed());
  }
}

function* updateDomainSaga(action: PayloadAction<UpdateDomainI>) {
  try {
    const response = yield* call(domainService.updateDomain, action.payload);

    yield delay(6000);

    if (response.success) {
      yield put(updateDomainSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(updateDomainFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(updateDomainFailed());
  }
}

function* deleteDomainSaga(action: PayloadAction<DeleteDomainI>) {
  try {
    const response = yield* call(domainService.deleteDomain, action.payload);

    if (response.success) {
      yield put(deleteDomainSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(deleteDomainFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteDomainFailed());
  }
}

export default function* domainSaga() {
  yield all([domainWatcher()]);
}
