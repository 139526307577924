import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CoreButton from "../../../../common/Button/CoreButton";
import { addTemplateCategory, getTemplateCategories } from "../../../../state/features/template/templateSlice";
import {
  selectSelectedCategoryIds,
  selectTemplateCategory,
} from "../../../../state/features/template/templateSelector";

const BrowseCategory: React.FC = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(5);
  const [expanded, setExpanded] = useState(true);
  const { data: categoryList, isSuccess } = useSelector(selectTemplateCategory);
  const selectedCategoryId = useSelector(selectSelectedCategoryIds);

  const handleSeeMore = () => {
    setLimit((prevState) => prevState + 5);
  };

  useEffect(() => {
    if (!isSuccess) dispatch(getTemplateCategories());
  }, [dispatch, isSuccess]);

  const handleSelectCategory = (categoryId: number | null) => {
    dispatch(addTemplateCategory(categoryId));
  };

  return (
    <>
      <ListItemButton
        selected={selectedCategoryId === null}
        onClick={() => handleSelectCategory(null)}
        sx={{ borderRadius: "4px", mb: 0.5 }}
      >
        <ListItemIcon sx={{ minWidth: 36 }}>
          <VerifiedOutlinedIcon color={"primary"} />
        </ListItemIcon>
        <ListItemText
          sx={{ my: 0 }}
          primary=' All Templates'
          primaryTypographyProps={{
            color: "primary",
            fontWeight: "medium",
            variant: "body2",
          }}
        />
      </ListItemButton>

      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ background: "transparent", boxShadow: "none" }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{
            p: 0,
            minHeight: "36px !important",
            "& .MuiAccordionSummary-content.Mui-expanded": { margin: "12px 0" },
          }}
        >
          <Typography variant={"body2SemiBold"} color={"text.secondary"}>
            Browse Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <List dense sx={{ pt: 0 }}>
            {categoryList.slice(0, limit).map((category, index) => (
              <ListItemButton
                key={index}
                selected={selectedCategoryId === category.id}
                onClick={() => handleSelectCategory(category.id as number)}
                sx={{ fontSize: "13px", borderRadius: "4px", mb: 0.25 }}
              >
                <span className={"text-truncate"} style={{fontWeight:300}} title={category.name}>{category.name}</span>
              </ListItemButton>
              ))}
            {categoryList.length > limit && (
              <CoreButton
                size={"small"}
                color={"secondary"}
                onClick={handleSeeMore}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ ml: "10px" }}
              >
                See More
              </CoreButton>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default BrowseCategory;
