import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Stack, styled, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CoreButton from "../Button/CoreButton";
import { LightCoreButton } from "../Button/LightCoreButton";
import CustomImageUploadWrapper from "../../components/CustomImageWrapper";

interface StyledBoxProps {
  width?: number | string;
  height?: number | string;
  radius?: number | string;
}

interface Props extends StyledBoxProps {
  maxFileInfoMessage?: string;
  infoText?: string;
  textWidth?: number | string;
  onChange?: (fileUrl: string) => void;
  fileUrl?: string;
  maxFileSizeMB?: number;
  isAdmin?: boolean;
  specialImageFileRegex?: RegExp;
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, width, height, radius }) => ({
  width: width ? width : 230,
  minWidth: width ? width : 230,
  height: height ? height : 120,
  borderRadius: radius ? radius : theme.spacing(1),
  border: `1px dashed ${theme.palette.other.outlinedBorder}`,
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  overflow: "hidden",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
}));

const FileUploadWithPreview: React.FC<Props> = ({
  maxFileInfoMessage = `Allowed PNG or JPEG or SVG. Max size of 2 MB. 
  We recommend use SVG for Best Results`,
  infoText,
  width = 230,
  height = 120,
  radius = "8px",
  maxFileSizeMB = 2,
  isAdmin = true,
  textWidth,
  fileUrl,
  onChange,
  specialImageFileRegex,
}) => {
  const [imgSrc, setImgSrc] = useState(fileUrl);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (fileUrl) setImgSrc(fileUrl);
  }, [fileUrl]);

  let content = <></>;
  if (isUploading) {
    content = <CircularProgress size={20} />;
  } else if (imgSrc) {
    content = <img src={imgSrc} alt='' />;
  } else {
    content = <AddIcon fontSize={"large"} color={"action"} />;
  }

  return (
    <Stack direction={"row"} alignItems={"center"} py={2} gap={2}>
      <StyledBox width={width} height={height} radius={radius}>
        {content}
      </StyledBox>
      <Stack spacing={2}>
        <Stack direction={"row"} gap={2} flexWrap={'wrap'}>
          <CustomImageUploadWrapper
            isAdmin={isAdmin}
            maxFileSizeMB={maxFileSizeMB}
            specialImageFileRegex={specialImageFileRegex}
            onUploading={(loading) => {
              setIsUploading(loading);
            }}
            onImageUpload={(data) => {
              setImgSrc(data);
              if (onChange) onChange(data);
            }}
          >
            <LightCoreButton color={"secondary"} size={"small"} fullWidth>
              Upload Photo
            </LightCoreButton>
          </CustomImageUploadWrapper>
          <CoreButton
            variant={"contained"}
            color={"inherit"}
            size={"small"}
            onClick={() => {
              setImgSrc("");
              if (onChange) onChange("");
            }}
          >
            Remove
          </CoreButton>
        </Stack>
        <Stack gap={0.5}>
        {infoText &&
        <Typography variant={"body2"} color={"text.primary"} fontWeight={400} width={textWidth}>
          {infoText}
        </Typography>
        }
        <Typography variant={"caption"} color={"text.tertiary"} width={textWidth}>
          {maxFileInfoMessage}
        </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FileUploadWithPreview;
