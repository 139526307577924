import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import categoryService from "../../../services/category.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  createCategoryFailed,
  createCategorySuccess,
  deleteCategoryFailed,
  deleteCategorySuccess,
  fetchCategoryFailed,
  fetchCategorySuccess,
  updateCategoryFailed,
  updateCategorySuccess,
} from "./categorySlice";
import { CreateCategoryI, UpdateCategoryI } from "../../../interface/category.interface";

function* categoryWatcher() {
  yield takeEvery("category/fetchCategory", fetchCategorySaga);
  yield takeEvery("category/createCategory", createCategorySaga);
  yield takeEvery("category/updateCategory", updateCategorySaga);
  yield takeEvery("category/deleteCategory", deleteCategorySaga);
}

function* fetchCategorySaga() {
  try {
    const response = yield* call(categoryService.fetchCategory);

    if (response.success) {
      yield put(fetchCategorySuccess(response.data));
    } else {
      yield put(fetchCategoryFailed());
    }
  } catch (err) {
    yield put(fetchCategoryFailed());
  }
}

function* createCategorySaga(action: PayloadAction<CreateCategoryI>) {
  try {
    const response = yield* call(categoryService.createCategory, action.payload);

    if (response.success) {
      yield put(createCategorySuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(createCategoryFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createCategoryFailed());
  }
}

function* updateCategorySaga(action: PayloadAction<UpdateCategoryI>) {
  try {
    const response = yield* call(categoryService.updateCategory, action.payload);

    if (response.success) {
      yield put(updateCategorySuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(updateCategoryFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(updateCategoryFailed());
  }
}

function* deleteCategorySaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(categoryService.deleteCategory, action.payload);

    if (response.success) {
      yield put(deleteCategorySuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(deleteCategoryFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteCategoryFailed());
  }
}

export default function* categorySaga() {
  yield all([categoryWatcher()]);
}
