import React from "react";
import {
  Checkbox,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AdminEachFunnel from "./AdminEachFunnel";
import WebsiteEmptyPage from "../SitesList/WebsiteEmptyPage";
import AdminSingleFunnelInTrash from "./AdminSingleFunnelInTrash";
import WebsiteListSkeleton from "../../common/LoadingView/WebsiteListSkeleton";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";

import useFunnelTemplates from "../../hooks/useFunnelTemplates";

const Container = styled("div")(() => ({
  height: "calc(100% - 50px)",
  overflow: "hidden",
}));

const AdminFunnelListTable: React.FC = () => {
  const {
    isLoading,
    data,
    selectedTemplateIds,
    inTrash,
    count,
    currentPage,
    limit,
    onChangePage,
    onChangePerPage,
    onClickCheckBox,
  } = useFunnelTemplates();

  // decide what to render
  let content = <></>;
  if (isLoading) {
    content = <WebsiteListSkeleton count={10} />;
  } else if (data.length === 0) {
    content = <WebsiteEmptyPage inTrash={false} isArchived={false} isFavorite={false} />;
  } else if (data.length > 0) {
    const selectAll = data.every((template) => selectedTemplateIds.includes(template.id));
    const indeterminate = !selectAll && selectedTemplateIds.length > 0;

    content = (
      <>
        <TableContainer sx={{ height: "calc(100% - 46px)" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Stack direction={"row"} alignItems={"center"} spacing={1} ml={-0.4}>
                    <Checkbox
                      edge='start'
                      color='secondary'
                      size={"small"}
                      tabIndex={-1}
                      checked={selectAll}
                      indeterminate={indeterminate}
                      onChange={onClickCheckBox}
                    />
                    <span>Template Name</span>
                  </Stack>
                </TableCell>
                {!inTrash && <TableCell>Created date</TableCell>}
                {!inTrash && <TableCell>Publish Status</TableCell>}
                {!inTrash && <TableCell>Approve Status</TableCell>}
                <TableCell align={"right"}>actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((template) => {
                if (inTrash) return <AdminSingleFunnelInTrash key={template.id} template={template} />;
                return <AdminEachFunnel key={template.id} template={template} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <GlobalTablePagination
          count={count}
          page={currentPage}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      </>
    );
  }

  return <Container>{content}</Container>;
};

export default AdminFunnelListTable;
