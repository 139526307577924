import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  AdminPageI,
  CloneAdminPagePayloadI,
  CreateAdminPagePayloadI,
  FetchAdminPagesParamsI,
  UpdateAdminPagePayloadI,
} from "../interface/adminPage.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class TemplatePageService {
  fetchTemplatePages(params: FetchAdminPagesParamsI) {
    return httpRequest.get<WithPagination<AdminPageI>>(websiteBuilderAPI + "admin/website/page/list", params);
  }

  createTemplatePage(payload: CreateAdminPagePayloadI) {
    return httpRequest.post<AdminPageI>(websiteBuilderAPI + "admin/website/page/create", payload);
  }

  updateTemplatePage(payload: UpdateAdminPagePayloadI) {
    return httpRequest.put<AdminPageI>(websiteBuilderAPI + "admin/website/page/update/" + payload.pageId, payload);
  }

  deleteTemplatePage(pageId: string) {
    return httpRequest.delete<string>(websiteBuilderAPI + "admin/website/page/delete/" + pageId);
  }

  cloneTemplatePage(payload: CloneAdminPagePayloadI) {
    return httpRequest.post<AdminPageI>(websiteBuilderAPI + "admin/website/page/clone", payload);
  }
}

const templatePageService = new TemplatePageService();
export default templatePageService;
