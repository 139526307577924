import React, { useCallback, useState } from "react";

import FunnelLists from "./FunnelLists";
import FunnelListLeftSide from "./FunnelListLeftSide";
import FunnelListHeader from "./TopBar/FunnelListHeader";
import { WebsiteListBody, WebsiteListWrapper } from "./FunnelListStyles";

const FunnelListPage = () => {
  const [isHideLeftSide, setIsHideLeftSide] = useState(false);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;

    if (width > 1200) {
      setIsHideLeftSide(!isHideLeftSide);
    } else {
      setIsHideLeftSide(false);
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  }, [isHideLeftSide, isShowLeftSideTab]);

  return (
    <WebsiteListWrapper>
      <FunnelListHeader />

      <WebsiteListBody>
        <FunnelListLeftSide
          toggleLeftSide={toggleLeftSidePanel}
          isHideLeftSide={isHideLeftSide}
          isShowLeftSideTab={isShowLeftSideTab}
        />
        <FunnelLists />
      </WebsiteListBody>
    </WebsiteListWrapper>
  );
};

export default FunnelListPage;
