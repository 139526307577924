import React from "react";
import { Link, LinkProps } from "@mui/material";

interface Props extends LinkProps {
  disabled?: boolean;
}

const HrefLink: React.FC<Props> = ({ href = "", target = "_blank", disabled = false, children, ...rest }) => {
  return (
    <Link
      href={href}
      target={target}
      sx={{
        color: "inherit",
        textDecoration: "none",
        pointerEvents: disabled ? "none" : "auto",
        cursor: disabled ? "default" : "auto",
        display: "inline-flex",
      }}
      {...rest}
    >
      {children || null}
    </Link>
  );
};

export default HrefLink;
