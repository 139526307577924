import React, { useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../state/store";
import { Box, Checkbox, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { Archive, ContentCopy, Delete, DeleteForever, MoreVert, RestoreFromTrash } from "@mui/icons-material";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import { FunnelInterface } from "../../state/features/funnel/funnel.interface";
import { selectFunnelSelectedIds } from "../../state/features/funnel/funnelSelector";
import {
  cloneFunnel,
  deleteFunnelForever,
  toggleActiveStatus,
  toggleArchiveStatus,
  toggleInTrashStatus,
  toggleSelectedSingleFunnel,
} from "../../state/features/funnel/funnelSlice";
import useBoolean from "../../hooks/useBoolean";
import { StyledSwitch } from "../../common/Switch/StyledSwitch";

interface Props {
  funnelInfo: FunnelInterface;
}

const SingleFunnel: React.FC<Props> = ({ funnelInfo }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  const { id, name, totalStep, activeStatus, isArchived, inTrash, updatedAt } = funnelInfo;

  const [isActive, setIsActive] = useState(false);
  const selectedFunnelIds = useSelector(selectFunnelSelectedIds);

  const handleClickActiveStatus = () => {
    dispatch(toggleActiveStatus({ id: id, activeStatus: !activeStatus }));
  };

  const onArchiveHandler = () => {
    dispatch(toggleArchiveStatus({ ids: [id as number], isArchived: !isArchived }));
  };

  const deleteFunnelHandler = () => {
    dispatch(toggleInTrashStatus({ ids: [id as number], inTrash: !inTrash }));
  };

  const handleCloneFunnel = () => {
    dispatch(cloneFunnel({ id: id as number }));
  };

  const onConfirmDeleteForever = () => {
    dispatch(deleteFunnelForever({ ids: [id as number] }));
  };

  const formattedUpdatedAt = updatedAt ? format(new Date(updatedAt), "MMM dd, yyyy") : "-";
  const isChecked = selectedFunnelIds.includes(id as number);

  return (
    <>
      <TableRow
        key={funnelInfo.id}
        selected={selectedFunnelIds.includes(id as number) || isActive}
        sx={{ "& td": { padding: "14px 12px" } }}
      >
        <TableCell>
          <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
            <Checkbox
              size={"small"}
              edge='start'
              color='secondary'
              checked={isChecked}
              onChange={() => dispatch(toggleSelectedSingleFunnel(id as number))}
              sx={{
                padding: "4px",
                marginLeft: 0,
                "& .PrivateSwitchBase-input": {
                  pointerEvents: "auto !important",
                },
              }}
            />
            {!inTrash ? (
              <Link to={"/wbuilder/funnel/" + id} style={{ textDecoration: "none", color: "inherit" }}>
                <Typography variant={"body1Medium"}>{name}</Typography>
              </Link>
            ) : (
              <Typography variant={"body1Medium"}>{name}</Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell align={"center"}>{totalStep}</TableCell>
        <TableCell>{formattedUpdatedAt}</TableCell>
        {!inTrash && (
          <TableCell>
            <StyledSwitch checked={!!activeStatus} onClick={handleClickActiveStatus} />
          </TableCell>
        )}

        <TableCell align={"right"}>
          <Box sx={{ width: "max-content", float: "right" }}>
            <CustomPopoverMenu position={"left"} callbackOnClose={() => setIsActive(false)}>
              <CustomPopoverMenuButton>
                <CustomTooltip title='More Options' arrow placement='top'>
                  <span>
                    <IconButton size='small' onClick={() => setIsActive(true)}>
                      <MoreVert color='action' />
                    </IconButton>
                  </span>
                </CustomTooltip>
              </CustomPopoverMenuButton>
              {!inTrash ? (
                <CustomPopoverMenuItem
                  title={isArchived ? "Unarchived" : "Archived"}
                  onClickHide={true}
                  icon={<Archive />}
                  onClick={onArchiveHandler}
                />
              ) : (
                <div></div>
              )}

              {!inTrash ? (
                <CustomPopoverMenuItem
                  title='Clone'
                  onClickHide={true}
                  icon={<ContentCopy color='action' />}
                  onClick={handleCloneFunnel}
                />
              ) : (
                <div></div>
              )}

              <CustomPopoverMenuItem
                title={!inTrash ? "Delete" : "Restore"}
                icon={!inTrash ? <Delete color={"action"} /> : <RestoreFromTrash color={"action"} />}
                onClick={deleteFunnelHandler}
                onClickHide={true}
              />

              {inTrash ? (
                <CustomPopoverMenuItem
                  title='Delete Forever'
                  icon={<DeleteForever color={"action"} />}
                  onClick={onOpenModal}
                  onClickHide={true}
                />
              ) : (
                <div></div>
              )}
            </CustomPopoverMenu>
          </Box>
        </TableCell>
      </TableRow>

      <DeleteWarningModal
        title={"Delete Forever!!"}
        warningSubtitle={"Are you sure, you want to delete this funnel?"}
        warningContent={"After delete, this operation cannot be undone."}
        buttonText={"Yes, Delete Forever"}
        cancelButtonText={"Cancel"}
        open={openModal}
        onClose={onCloseModal}
        onConfirm={onConfirmDeleteForever}
        isLoading={false}
      />
    </>
  );
};

export default SingleFunnel;
