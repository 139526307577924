import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { HeaderBoxWrapper } from "./SitesListStyles";
import WebsiteResetFilter from "./SiteListfilterHeader/WebsiteResetFilter";
import WebsiteListBulkActions from "./SiteListfilterHeader/WebsiteListBulkActions";
import WebsiteListFilterSearch from "./SiteListfilterHeader/WebsiteListFilterSearch";
import WebsiteListFilterStatus from "./SiteListfilterHeader/WebsiteListFilterStatus";
import { selectWebsiteSelectedIds } from "../../state/features/website/websiteSelector";

const WebsiteListFilterHeader: React.FC = () => {
  const selectedWebsiteIds = useSelector(selectWebsiteSelectedIds);

  return (
    <HeaderBoxWrapper>
      <>
        <Box sx={{ flex: 1 }}>
          {selectedWebsiteIds?.length === 0 ? <WebsiteListFilterSearch /> : <WebsiteListBulkActions />}
        </Box>
        <WebsiteListFilterStatus />
        <WebsiteResetFilter />
      </>
    </HeaderBoxWrapper>
  );
};

export default WebsiteListFilterHeader;
