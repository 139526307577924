import React from "react";
import { Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddOrEditRedirectModal from "./AddOrEditRedirectModal";
import CoreButton from "../../../common/Button/CoreButton";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";

const UrlRedirectHeader = () => {
  const {
    value: openAddRedirectModal,
    setTrue: onOpenAddRedirectModal,
    setFalse: onCloseAddRedirectModal,
  } = useBoolean(false);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} mb={3}>
        <CoreButton variant={"contained"} color={"secondary"} startIcon={<AddIcon />} onClick={onOpenAddRedirectModal}>
          Add a redirect
        </CoreButton>
      </Stack>

      <BasicModalWithHeader
        width={500}
        title={"Add Redirect"}
        open={openAddRedirectModal}
        onClose={onCloseAddRedirectModal}
      >
        <AddOrEditRedirectModal onClose={onCloseAddRedirectModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default UrlRedirectHeader;
