import React from "react";
import { Box, Chip, Divider, Paper, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CoreButton from "../../../../common/Button/CoreButton";
import LoadingButton from "../../../../common/Button/LoadingButton";
import { ITemplate } from "../../../../state/features/template/template.interface";
import useIframeHeight from "../../../../hooks/useIframeHeight";

interface IProps {
  onClose?: () => void;
  template: ITemplate;
  useTemplate: () => void;
  isLoading: boolean;
}

const TemplateDescriptionModal: React.FC<IProps> = ({ onClose, template, useTemplate, isLoading }) => {
  const { name, description, tagInfos, typeInfos, categoryName } = template || {};

  const src =
    process.env.REACT_APP_WEBSITE_EDITOR_URL +
    `/mf/admin/preview/${btoa(
      JSON.stringify({
        websiteId: template.id,
        pageId: template.defaultPageId,
      })
    )}`;

  const { iframeRef, iframeHeight } = useIframeHeight(src);

  return (
    <Box sx={{ height: "95vh" }}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
        <CoreButton variant={"outlined"} color={"secondary"} startIcon={<ArrowBackIcon />} onClick={onClose}>
          Back
        </CoreButton>
        <LoadingButton
          variant={"contained"}
          color={"secondary"}
          endIcon={<ArrowForwardIcon />}
          isLoading={isLoading}
          onClick={useTemplate}
        >
          Continue
        </LoadingButton>
      </Stack>
      <Divider light />
      <Box sx={{ height: "calc(100% - 72px)", overflowY: "auto" }}>
        <Stack spacing={2} alignItems={"baseline"} sx={{ width: "80%", margin: "auto", p: 3, pb: 0 }}>
          <Typography variant={"h5"} color={"other.darkNavy"}>
            {name}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography variant={"body2"} color={"text.secondary"}>
              {categoryName && `category -  ${categoryName}`}
            </Typography>
          </Stack>

          {tagInfos && (
            <>
              <Typography variant={"body1SemiBold"} color={"text.secondary"}>
                Tags
              </Typography>
              <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
                {tagInfos?.map((tagInfo, index) => {
                  return <Chip variant={"filled"} key={index} size={"small"} label={tagInfo.name} />;
                })}
              </Stack>
            </>
          )}
          {typeInfos && (
            <>
              <Typography variant={"body1SemiBold"} color={"text.secondary"}>
                Types
              </Typography>
              <Stack direction={"row"} spacing={1} flexWrap={"wrap"}>
                {typeInfos?.map((typeInfo, index) => {
                  return <Chip key={index} size={"small"} variant={"outlined"} label={typeInfo.name} />;
                })}
              </Stack>
            </>
          )}

          <Divider light />

          {description && (
            <>
              <Box sx={{ pb: 3 }}>
                <Typography variant={"body1SemiBold"} color={"text.secondary"} mb={1}>
                  Description
                </Typography>
                <Typography variant={"body2"} color={"text.secondary"}>
                  {description}
                </Typography>
              </Box>
            </>
          )}

          <Paper variant={"outlined"} sx={{ p: 2, width: "100%", height: "100%" }}>
            <iframe
              ref={iframeRef}
              src={src}
              name={template.name}
              width={"100%"}
              height={iframeHeight}
              style={{ border: "none" }}
            />
          </Paper>
        </Stack>
      </Box>
    </Box>
  );
};

export default TemplateDescriptionModal;
