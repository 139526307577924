import React from "react";
import { Grid, Skeleton } from "@mui/material";

const TemplateListSkeleton = ({ count = 1 }) => {
  return (
    <Grid container spacing={3} rowSpacing={4}>
      {Array.from({ length: count }).map((rowNum, index) => (
        <Grid key={index} item xs={12} sm={6} md={4} xl={3}>
          <Skeleton variant='rounded' sx={{ mt: 1 }} height={360} />
          <Skeleton width={200} variant='text' sx={{ mt: 1 }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TemplateListSkeleton;
