import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCookie } from "../../../helpers/Cookie";
import { generateEditorUrl } from "../../../helpers/utils/generateEditorUrl";
import { showErrorToasterRedux } from "../../../state/features/website/websiteSlice";

const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
if (!accessToken) throw Error("Access token not found in process env!");

interface PropsI {
  iframeElement: HTMLIFrameElement | undefined;
  websiteId?: string | undefined;
  isAdmin?: boolean;
  isSection?: boolean;
}

const RouteBlock: React.FC<PropsI> = ({ iframeElement, websiteId, isAdmin = false, isSection = false }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [saved, setSaved] = useState(true);

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      const sendCookieMessage = () => {
        iframeElement?.contentWindow?.postMessage(
          { isAdmin, token: getCookie(accessToken), message: "COOKIE_SET" },
          "*"
        );
      };

      const handleBackToPages = () => {
        document.title = "Website Builder";
        const backRoute = isSection
          ? "/wbuilder/admin?tab=section"
          : `/wbuilder${isAdmin ? "/admin" : ""}/site/${websiteId}`;

        if (!saved) {
          if (window.confirm("Changes you made may not be saved.")) {
            history.push(backRoute);
          }
        } else {
          history.push(backRoute);
        }
      };

      const handleMoveToPage = () => {
        if (!saved) {
          if (window.confirm("Changes you made may not be saved.")) {
            history.push(generateEditorUrl(event.data.data.pageId, event.data.data.websiteId, isAdmin));
          }
        } else {
          history.push(generateEditorUrl(event.data.data.pageId, event.data.data.websiteId, isAdmin));
        }
      };

      if (event.origin === process.env.REACT_APP_WEBSITE_EDITOR_URL) {
        if (event.data.message === "EDITOR_READY") {
          sendCookieMessage();
        } else if (event.data.message === "SAVE_TRIGGER") {
          setSaved(event.data.data);
        } else if (event.data.message === "BACK_TO_PAGES") {
          handleBackToPages();
        } else if (event.data.message === "MOVE_TO_PAGE") {
          handleMoveToPage();
        } else if (event.data.message === "SET_EDITOR_TITLE") {
          document.title = `${event.data.data}`.trim() + " | Website Builder";
        } else if (event.data.message === "SHOW_ERROR_TOASTER") {
          dispatch(showErrorToasterRedux(event.data.data));
        }
      }
    };

    window.addEventListener("message", handleMessageEvent);

    return () => {
      window.removeEventListener("message", handleMessageEvent);
    };
  }, [dispatch, history, saved, iframeElement?.contentWindow, websiteId, isAdmin, isSection]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (saved === false) {
        event.preventDefault();
        event.returnValue = "Changes you made may not be saved.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [saved]);

  return null;
};

export default RouteBlock;
