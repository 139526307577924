import React from "react";
import { green } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { Avatar, Paper, Stack, styled, Typography } from "@mui/material";
import { ContentCopy, Delete, Edit, MoreVert } from "@mui/icons-material";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";

import useBoolean from "../../../../hooks/useBoolean";
import AddOrEditStepContent from "../AddOrEditStepContent";
import {
  cloneFunnelTemplateStep,
  deleteFunnelTemplateStep,
  setSelectedFunnelTemplateStep,
} from "../../../../state/features/funnelTemplateStep/funnelTemplateStepSlice";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../../../common/PopoverMenu/CustomPopoverMenu";
import DeleteWarningModal from "../../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import { FunnelTemplateStepI } from "../../../../interface/funnelTemplateStep.interface";
import { selectFunnelTemplateStepState } from "../../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";

const StyledStepCard = styled(Paper, {
  shouldForwardProp: (props) => props !== "isDragging" && props !== "selected",
})<{ isDragging: boolean }>(({ theme, isDragging }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderWidth: isDragging ? 2 : 1.5,
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  width: "100%",
  "&.fnl-step-active": {
    borderColor: theme.palette.secondary.light,
    boxShadow: `0 0 2px ${theme.palette.secondary.main}`,
  },
}));

interface Props {
  funnelStepInfo: FunnelTemplateStepI;
  selected: boolean;
  isDragging: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
}

const FunnelStep: React.FC<Props> = ({ funnelStepInfo, selected, isDragging, dragHandleProps }) => {
  const dispatch = useDispatch();
  const { stepId, name, path } = funnelStepInfo || {};
  const { data: funnelStepList, isDeleting } = useSelector(selectFunnelTemplateStepState);

  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const { value: editStepModal, setTrue: onOpenEditStepModal, setFalse: onCloseEditStepModal } = useBoolean(false);

  const handleClickStep = () => {
    dispatch(setSelectedFunnelTemplateStep(funnelStepInfo));
  };

  const handleDuplicateStep = () => {
    dispatch(cloneFunnelTemplateStep({ stepId, index: funnelStepList.length }));
  };

  const onConfirmDelete = () => {
    dispatch(deleteFunnelTemplateStep({ stepId }));
  };

  return (
    <StyledStepCard
      variant={"outlined"}
      className={selected ? "fnl-step-active" : ""}
      isDragging={isDragging}
      {...dragHandleProps}
    >
      <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ width: "90%" }} onClick={handleClickStep}>
        <Avatar variant='rounded' sx={{ bgcolor: green[100] }}>
          <TextSnippetOutlinedIcon color={"success"} />
        </Avatar>
        <Stack sx={{ width: "85%" }}>
          <Typography variant={"body2"} noWrap title={name}>
            {name}
          </Typography>
          <Typography variant={"caption"} color={"text.secondary"} noWrap>
            {path}
          </Typography>
        </Stack>
      </Stack>
      <CustomPopoverMenu minWidth={"max-content"} position={"right"}>
        <CustomPopoverMenuButton>
          <IconButton color={"secondary"} size={"small"}>
            <MoreVert />
          </IconButton>
        </CustomPopoverMenuButton>
        <CustomPopoverMenuItem
          title='Duplicate'
          icon={<ContentCopy color={"action"} />}
          onClick={handleDuplicateStep}
          onClickHide={true}
        />
        <CustomPopoverMenuItem
          title='Edit'
          icon={<Edit color={"action"} />}
          onClick={onOpenEditStepModal}
          onClickHide={true}
        />
        <CustomPopoverMenuItem
          title='Delete'
          icon={<Delete color={"action"} />}
          onClick={onOpenDeleteModal}
          onClickHide={true}
        />
      </CustomPopoverMenu>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />

      <BasicModalWithHeader title={"Edit Step"} open={editStepModal} onClose={onCloseEditStepModal}>
        <AddOrEditStepContent onClose={onCloseEditStepModal} stepInfo={funnelStepInfo} isEdit={true} />
      </BasicModalWithHeader>
    </StyledStepCard>
  );
};

export default FunnelStep;
