const removeFalsyObjectProperty = <T extends Object>(obj: T): T => {
  if (typeof obj !== "object") throw Error("Need an object to remove falsy property");

  let newObject = { ...obj };
  for (let name in newObject) {
    if (!newObject[name]) delete newObject[name];
  }
  return newObject;
};

export default removeFalsyObjectProperty;
