const emailPattern = /^\w+([\\.-\\+]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

export function removeSpecialCharacters(str: string = "") {
  // Use a regular expression to match special characters and replace them with an empty string
  return str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, " ");
}

export function replaceSpaceToHyphen(str: string = "") {
  return str.replace(/[^A-Z0-9]/gi, "-");
}

export const registerPattern = {
  email: {
    required: "Email is required!",
    pattern: {
      value: emailPattern,
      message: "Email is invalid!",
    },
    maxLength: {
      value: 50,
      message: "Email must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  password: {
    required: "Password is required",
    minLength: {
      value: 6,
      message: "Password must have at least 6 characters",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },

  first_name: {
    required: "First name is required",
    maxLength: {
      value: 50,
      message: "First name must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  last_name: {
    required: "Last name is required",
    maxLength: {
      value: 50,
      message: "Last name must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  phone: {
    required: "Phone number is required",
    pattern: {
      value: /^[0-9]+$/,
      message: "Invalid phone number!",
    },
    minLength: {
      value: 11,
      message: "Phone number must have minimum 11 digits!",
    },
    maxLength: {
      value: 16,
      message: "Phone number must have less than 16 digits!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  web_site_url: {
    required: false,
    pattern: {
      value:
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/,
      message: "Invalid website url!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },

  client_secret: {
    required: "Client Secret key is required.",
    pattern: {
      value: /^\S*$/,
      message: "White space is not allow",
    },
  },
  charge_amount: {
    required: "Stripe Payment Charge is required.",
    pattern: {
      value: /^\d*[.]?\d*$/,
      message: "Invalid charge amount value !",
    },
    max: {
      value: 50,
      message: "Payment Charge must be less than 50",
    },
  },

  topUp_amount: {
    required: "Amount is required.",
    pattern: {
      value: /^\d*[.]?\d*$/,
      message: "Invalid amount value !",
    },
    min: {
      value: 10,
      message: "Minimum amount value is 10",
    },
  },

  sender_address: {
    required: "Sender address is required",
    pattern: {
      value: /^[0-9]+$/,
      message: "Invalid sender address!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  formTitle: {
    required: "Form title is required!",
    pattern: {
      value: /^(?!\s*$).+/,
      message: "Form title is empty",
    },
    maxLength: {
      value: 30,
      message: "Form title cannot be longer than 30 characters",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternFolder = {
  name: {
    required: "Folder name is required!",
    maxLength: {
      value: 30,
      message: "Folder name must have less than 30 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};
export const registerPatternCategory = {
  name: {
    required: "Category name is required!",
    maxLength: {
      value: 30,
      message: "Category name must have less than 30 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  description: {
    maxLength: { value: 255, message: "Description should be less than 255 characters" },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};
export const registerPatternType = {
  name: {
    required: "Type name is required!",
    maxLength: {
      value: 30,
      message: "Type name must have less than 30 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};
export const registerPatternTag = {
  name: {
    required: "Tag name is required!",
    maxLength: {
      value: 30,
      message: "Tag name must have less than 30 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternWebsite = {
  name: {
    required: "Website name is required!",
    maxLength: {
      value: 50,
      message: "Website name must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  defaultPageId: {
    required: "Default page is required!",
  },
  faviconURL: {
    required: false,
    // pattern: {
    //   value: /^https?:\/\/.*\/.*\.(png|jpeg|jpg|ico)\??.*$/gim,
    //   message: "faviconURL is invalid!",
    // },
  },
  headingTrackingCode: {
    required: false,
    maxLength: {
      value: 255,
      message: "Heading tracking code must have less than 255 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  bodyTrackingCode: {
    required: false,
    maxLength: {
      value: 255,
      message: "Body tracking code must have less than 255 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  pixel: {
    required: false,
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  analytics: {
    required: false,
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternPage = {
  name: {
    required: "Website name is required!",
    maxLength: {
      value: 50,
      message: "Website name must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  path: {
    required: "Path name is required!",
    maxLength: {
      value: 50,
      message: "Path name must have less than 50 characters!",
    },
    setValueAs: (v: string) => {
      let path = v ? v.trim() : "";
      path = removeSpecialCharacters(path);
      path = path.trimStart();
      path = replaceSpaceToHyphen(path);
      if (!path.startsWith("/")) path = "/" + path;
      return path;
    },
  },
  author: {
    // required: "Website name is required!",
    maxLength: {
      value: 50,
      message: "Author name must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  title: {
    // required: "Website name is required!",
    maxLength: {
      value: 50,
      message: "Page title must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  description: {
    // required: "Website name is required!",
    maxLength: {
      value: 200,
      message: "Page description must have less than 200 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternTemplateSettings = {
  name: {
    required: "Website name is required!",
    maxLength: {
      value: 50,
      message: "Website name must have less than 50 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  defaultPageId: {
    required: "Default Page is required!",
  },
  categoryId: {
    required: "Category is required!",
  },
  description: {
    maxLength: { value: 255, message: "Description should be less than 255 characters" },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};
export const registerPatterUrlRedirect = {
  domain: {
    required: "Domain name is required!",
  },
  path: {
    required: "Path is required!",
    maxLength: {
      value: 50,
      message: "Path must have less than 50 characters!",
    },
    setValueAs: (v: string) => {
      let path = v ? v.trim() : "";
      path = removeSpecialCharacters(path);
      path = path.trimStart();
      path = replaceSpaceToHyphen(path);
      if (!path.startsWith("/")) path = "/" + path;
      return path;
    },
  },
  redirectType: {
    required: "redirect Type is required!",
  },
  targetUrl: {
    required: "Target URL is required!",
    maxLength: {
      value: 75,
      message: "Target URL must have less than 75 characters!",
    },
    pattern: {
      value:
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/,
      message: "Invalid Target url!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternDomain = {
  domain: {
    required: "Custom domain is required!",
    maxLength: {
      value: 61,
      message: "Custom domain must have less than 61 characters!",
    },
    pattern: {
      // value: /^[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/,
      value:
        /^(?!www|http)[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.([a-zA-Z]{1,6}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,3})$/,
      message: "Invalid custom domain!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternFunnel = {
  name: {
    required: "Funnel name is required!",
    maxLength: {
      value: 50,
      message: "Funnel name cannot be longer than 30 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  defaultStepId: {
    required: "Default step is required!",
  },
  description: {
    maxLength: { value: 255, message: "Description should be less than 255 characters" },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  faviconURL: {
    required: false,
  },
  headingTrackingCode: {
    required: false,
    maxLength: {
      value: 255,
      message: "Heading tracking code must have less than 255 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  bodyTrackingCode: {
    required: false,
    maxLength: {
      value: 255,
      message: "Body tracking code must have less than 255 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
};

export const registerPatternFunnelStep = {
  name: {
    required: "Step name is required!",
    maxLength: {
      value: 50,
      message: "Step name cannot be longer than 30 characters!",
    },
    setValueAs: (v: string) => (v ? v.trim() : ""),
  },
  path: {
    required: "Slug is required!",
    maxLength: {
      value: 50,
      message: "Slug must have less than 50 characters!",
    },
    setValueAs: (v: string) => {
      let path = v ? v.trim() : "";
      path = removeSpecialCharacters(path);
      path = path.trimStart();
      path = replaceSpaceToHyphen(path);
      if (!path.startsWith("/")) path = "/" + path;
      return path;
    },
  },
};
