import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Refresh } from "@mui/icons-material";
import ButtonForIcon from "../../../common/Button/ButtonForIcon";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import { websiteOrderByList } from "../../../helpers/constant/websiteConstant";
import { selectFunnelFilters } from "../../../state/features/funnel/funnelSelector";
import { resetOnlyFunnelFilter } from "../../../state/features/funnel/funnelSlice";

const FunnelResetFilter = () => {
  const dispatch = useDispatch();
  const { searchText, orderBy } = useSelector(selectFunnelFilters);

  const handleReset = () => {
    dispatch(resetOnlyFunnelFilter());
  };

  const isShowResetButton = searchText || orderBy !== websiteOrderByList[2].value;
  if (!isShowResetButton) return null;

  return (
    <CustomTooltip arrow placement='bottom' title='Reset Filter'>
      <ButtonForIcon variant={"outlined"} color={"secondary"} onClick={handleReset}>
        <Refresh />
      </ButtonForIcon>
    </CustomTooltip>
  );
};

export default FunnelResetFilter;
