import React from "react";
import { Box, Paper, Stack, Tab, Tabs } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import DomainSettings from "./domain/DomainSettings";
import CoreButton from "../../common/Button/CoreButton";
import UrlRedirects from "../SingleWebsiteProperty/urlRedirects/UrlRedirects";
import { useHistory } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const SettingsPage = () => {
  const history = useHistory();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Box>
      <Paper elevation={0}>
        <Stack direction={"row"} alignItems={"center"} spacing={1} px={1}>
          <CoreButton size={"small"} color={"secondary"} startIcon={<ArrowBackIcon />} onClick={handleBack}>
            Back
          </CoreButton>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor={"secondary"}
            indicatorColor={"secondary"}
            sx={{ "& .MuiTab-root": { fontSize: 18 } }}
          >
            <Tab label='Domain Settings' />
            <Tab label='Url Redirect Settings' />
          </Tabs>
        </Stack>

        <Divider light />
        <TabPanel index={0} value={value}>
          <DomainSettings />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <UrlRedirects />
        </TabPanel>
      </Paper>
    </Box>
  );
};

export default SettingsPage;
