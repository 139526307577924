import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import sectionLibraryService from "../../../services/sectionLibrary";
import {
  fetchSectionLibrariesSuccess,
  fetchSectionLibraryFailed,
  createSectionLibrarySuccess,
  createSectionLibraryFailed,
  resetSectionLibraryState,
  resetCreateStatus,
  updateSectionLibrarySuccess,
  deleteSectionLibrarySuccess,
  cloneSectionLibrarySuccess,
  toggleSectionLibraryApproveStatusSuccess,
  toggleSectionLibraryPublishStatusSuccess,
} from "./adminSectionLibrarySlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  FetchSectionLibraryPayloadI,
  CreateSectionLibraryI,
  UpdateSectionLibraryI,
  DeleteSectionLibraryI,
  CloneSectionLibraryI,
  PublishSectionLibraryI,
  ApproveSectionLibraryI,
} from "../../../interface/websiteSectionLibrary.interface";

function* adminSectionLibraryWatcher() {
  yield takeEvery("adminSectionLibrary/fetchSectionLibraries", fetchSectionLibrariesSaga);
  yield takeEvery("adminSectionLibrary/createSectionLibrary", createTemplatePageSaga);
  yield takeEvery("adminSectionLibrary/updateSectionLibrary", updateTemplatePageSaga);
  yield takeEvery("adminSectionLibrary/deleteSectionLibrary", deleteTemplatePageSaga);
  yield takeEvery("adminSectionLibrary/cloneSectionLibrary", cloneTemplatePageSaga);
  yield takeEvery("adminSectionLibrary/renameSectionLibrary", updateTemplatePageSaga);
  yield takeEvery("adminSectionLibrary/toggleSectionLibraryPublishStatus", toggleSectionLibraryPublishStatusSaga);
  yield takeEvery("adminSectionLibrary/toggleSectionLibraryApproveStatus", toggleSectionLibraryApproveStatusSaga);
}

function* fetchSectionLibrariesSaga(action: PayloadAction<FetchSectionLibraryPayloadI>) {
  try {
    const response = yield* call(sectionLibraryService.getSectionLibraries, action.payload);

    if (response.success) {
      yield put(fetchSectionLibrariesSuccess(response.data));
    } else {
      yield put(resetSectionLibraryState());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(fetchSectionLibraryFailed());
    console.error(err);
  }
}

function* createTemplatePageSaga(action: PayloadAction<CreateSectionLibraryI>) {
  try {
    const response = yield* call(sectionLibraryService.createSectionLibrary, action.payload);

    if (response.success) {
      yield put(createSectionLibrarySuccess(response.data));
    } else {
      yield put(resetCreateStatus());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createSectionLibraryFailed());
    console.error(err);
  }
}

function* updateTemplatePageSaga(action: PayloadAction<UpdateSectionLibraryI>) {
  try {
    const response = yield* call(sectionLibraryService.updateSectionLibrary, action.payload);

    if (response.success) {
      yield put(updateSectionLibrarySuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.error(err);
  }
}

function* deleteTemplatePageSaga(action: PayloadAction<DeleteSectionLibraryI>) {
  try {
    const response = yield* call(sectionLibraryService.deleteSectionLibrary, action.payload);

    if (response.success) {
      yield put(deleteSectionLibrarySuccess({ sectionId: action.payload.sectionId }));
      showSuccessToaster(response.message);
    } else {
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.error(err);
  }
}

function* cloneTemplatePageSaga(action: PayloadAction<CloneSectionLibraryI>) {
  try {
    const response = yield* call(sectionLibraryService.cloneSectionLibrary, action.payload);

    if (response.success) {
      yield put(cloneSectionLibrarySuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      // yield put(resetCloneTemplatePage());
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.error(err);
  }
}

function* toggleSectionLibraryPublishStatusSaga(action: PayloadAction<PublishSectionLibraryI>) {
  try {
    const response = yield* call(sectionLibraryService.publishStatusUpdateSectionLibrary, action.payload);

    if (response.success) {
      yield put(toggleSectionLibraryPublishStatusSuccess({ id: action.payload.id, status: response.data.status }));
      showSuccessToaster(response.message);
    } else {
      // yield put(resetCloneTemplatePage());
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.error(err);
  }
}

function* toggleSectionLibraryApproveStatusSaga(action: PayloadAction<ApproveSectionLibraryI>) {
  try {
    const response = yield* call(sectionLibraryService.approveStatusUpdateSectionLibrary, action.payload);

    if (response.success) {
      yield put(
        toggleSectionLibraryApproveStatusSuccess({ id: action.payload.id, approveStatus: response.data.approveStatus })
      );
      showSuccessToaster(response.message);
    } else {
      // yield put(resetCloneTemplatePage());
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* adminSectionLibrarySaga() {
  yield all([adminSectionLibraryWatcher()]);
}
