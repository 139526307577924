import React from "react";
import { CircularProgress, CircularProgressProps, Stack } from "@mui/material";

interface IProps extends CircularProgressProps{
  height?: string;
}

const CircleLoader: React.FC<IProps> = ({ height = "60vh", color = "secondary" }) => {
  return (
    <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} sx={{ height: height }}>
      <CircularProgress color={color} />
    </Stack>
  );
};

export default CircleLoader;
