import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { selectFunnelTemplateStepList } from "../../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";
import AddStepFunnel from "../AddStepFunnel";

const FunnelStepsHeader = () => {
  const funnelSteps = useSelector(selectFunnelTemplateStepList);

  const totalStep = funnelSteps?.length || 0;
  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pb={1.5}>
      <Stack>
        <Typography variant={"subtitle1"}>Funnel Steps</Typography>
        <Typography variant={"body2"} color={"text.secondary"} sx={{lineHeight:'14px'}}>
          {totalStep} {totalStep > 1 ? "Steps" : "Step"}
        </Typography>
      </Stack>
      <AddStepFunnel />
    </Stack>
  );
};

export default FunnelStepsHeader;
