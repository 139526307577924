import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import CreateWebsiteOptionCard from "./CreateWebsiteOptionCard";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import InputWithLabel from "../../../common/TextField/InputWithLabel";
import { CREATE_WEBSITE_FROM_BLANK, CREATE_WEBSITE_FROM_TEMPLATE } from "../../../helpers/constant/coreConstants";
import { addWebsite, resetAddWebsiteSuccess } from "../../../state/features/website/websiteSlice";
import { selectWebsiteState } from "../../../state/features/website/websiteSelector";
import { IAddWebsitePayload } from "../../../state/features/website/website.interface";

interface IProps {
  onClose: () => void;
  onOpenTemplateModal: () => void;
}

const getError = (name: string) => {
  if (name.trim() === "") {
    return "Website name is empty!";
  } else if (name.length > 50) {
    return "Website name cannot be longer than 50 characters";
  } else {
    return "";
  }
};

const makeDefaultSubdomain = (websiteName: string) => {
  return websiteName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase() + ".crmconnection.io";
};

const CreateWebsiteModal: React.FC<IProps> = ({ onClose, onOpenTemplateModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isAdding, isAddSuccess, addedWebsiteData, isNewWebsiteInsideFolder, newWebsiteFolderId } =
    useSelector(selectWebsiteState);
  const [selectedOption, setSelectedOption] = useState(CREATE_WEBSITE_FROM_BLANK);
  const [websiteName, setWebsiteName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (isAddSuccess && addedWebsiteData?.id) {
      history.push("/wbuilder/site/" + addedWebsiteData.id);
      dispatch(resetAddWebsiteSuccess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddSuccess]);

  const handleSelectOption = (option: number) => {
    setSelectedOption(option);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(getError(e.target.value));
    setWebsiteName(e.target.value);
  };

  const handleContinue = () => {
    if (selectedOption === CREATE_WEBSITE_FROM_TEMPLATE) {
      onOpenTemplateModal();
      onClose();
    } else {
      const errorMessage = getError(websiteName);
      setError(errorMessage);
      if (!errorMessage) {
        const payload: IAddWebsitePayload = {
          name: websiteName,
          subDomain: makeDefaultSubdomain(websiteName),
        };
        if (isNewWebsiteInsideFolder && newWebsiteFolderId) payload.folderId = Number(newWebsiteFolderId);
        dispatch(addWebsite(payload));
      }
    }
  };

  return (
    <form>
      <Stack direction={"row"} spacing={3} p={3}>
        <CreateWebsiteOptionCard
          title={"Start from Scratch"}
          subtitle={"Start from scratch using our page builder."}
          checked={selectedOption === CREATE_WEBSITE_FROM_BLANK}
          onClick={() => handleSelectOption(CREATE_WEBSITE_FROM_BLANK)}
        >
          <InputWithLabel
            required={true}
            fullWidth={true}
            autoFocus={true}
            color={"secondary"}
            label={"Website Name"}
            placeholder={"Give your new website a name"}
            onChange={handleChangeName}
            error={!!error}
            helperText={error}
            sx={{ "& .MuiInputBase-root": { bgcolor: "common.white" } }}
          />

          {websiteName !== "" && (
            <Typography variant={"caption"}>https://{makeDefaultSubdomain(websiteName)}</Typography>
          )}
        </CreateWebsiteOptionCard>

        <CreateWebsiteOptionCard
          title={"Use a Template"}
          subtitle={"Save time by using one of our pre-built designs."}
          checked={selectedOption === CREATE_WEBSITE_FROM_TEMPLATE}
          onClick={() => handleSelectOption(CREATE_WEBSITE_FROM_TEMPLATE)}
        >
          <Paper variant={"outlined"} sx={{ borderRadius: "8px", height: 120, overflow: "hidden" }}>
            <img
              src={"https://crm-sites-media.s3.amazonaws.com/123/file/webpage-1703748635414.jpeg"}
              alt={"img"}
              style={{ height: "100%", width: "101%", marginLeft: "-1px", objectFit: "cover" }}
            />
          </Paper>
        </CreateWebsiteOptionCard>
      </Stack>
      <Divider light />

      <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} p={3}>
        <CoreButton color={"inherit"} size={"small"} type={"button"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          variant={"contained"}
          color={"secondary"}
          size={"small"}
          isLoading={isAdding}
          onClick={handleContinue}
          sx={{ minWidth: 88 }}
          type={CREATE_WEBSITE_FROM_BLANK === selectedOption ? "submit" : "button"}
        >
          {CREATE_WEBSITE_FROM_BLANK === selectedOption ? "Get Started" : "Continue"}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default CreateWebsiteModal;
