import httpRequest from "./api/httpRequest";
import {
  IGetTemplatePayload,
  ITemplateCategory,
  ITemplate,
  ITemplateTag,
  ITemplateType,
} from "../state/features/template/template.interface";
import { WithPagination } from "../interface/response.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class TemplateService {
  getTemplates(params: IGetTemplatePayload) {
    return httpRequest.get<WithPagination<ITemplate>>(websiteBuilderAPI + "website/template/list", params);
  }
  getTemplateCategories() {
    return httpRequest.get<ITemplateCategory[]>(websiteBuilderAPI + "website/category/all");
  }
  getTemplateTags() {
    return httpRequest.get<ITemplateTag[]>(websiteBuilderAPI + "website/tag/all");
  }
  getTemplateTypes() {
    return httpRequest.get<ITemplateType[]>(websiteBuilderAPI + "website/type/all");
  }
}

const templateService = new TemplateService();
export default templateService;
