import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import {
  AdminPageI,
  CloneAdminPagePayloadI,
  CreateAdminPagePayloadI,
  DeleteAdminPagePayloadI,
  FetchAdminPagesParamsI,
  UpdateAdminPagePayloadI,
} from "../../../interface/adminPage.interface";

const initialState = {
  data: [] as AdminPageI[],
  isLoading: false,

  isUpdating: false,
  isUpdateSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isCloning: false,
  isClonedSuccess: false,

  isCreating: false,
  isCreateSuccess: false,
  createdPageId: "",
};

const adminPageSlice = createSlice({
  name: "adminTemplatePage",
  initialState,
  reducers: {
    fetchTemplatePages(state, action: PayloadAction<FetchAdminPagesParamsI>) {
      state.isLoading = true;
    },
    fetchTemplatePagesSuccess(state, action: PayloadAction<WithPagination<AdminPageI>>) {
      state.data = action.payload.content;
      state.isLoading = false;
    },
    fetchTemplatePagesFailed(state) {
      state.isLoading = false;
    },

    createTemplatePage(state, action: PayloadAction<CreateAdminPagePayloadI>) {
      state.isCreating = true;
    },
    createTemplatePageSuccess(state, action: PayloadAction<AdminPageI>) {
      state.isCreating = false;
      state.isCreateSuccess = true;
      state.data.push(action.payload);
      state.createdPageId = action.payload.pageId;
    },
    resetCreateTemplatePage(state) {
      state.isCreating = false;
      state.isCreateSuccess = false;
      state.createdPageId = "";
    },

    cloneTemplatePage(state, action: PayloadAction<CloneAdminPagePayloadI>) {
      state.isCloning = true;
    },
    cloneTemplatePageSuccess(state, action: PayloadAction<AdminPageI>) {
      state.isCloning = false;
      state.isClonedSuccess = true;
      state.data.push(action.payload);
    },
    resetCloneTemplatePage(state) {
      state.isCloning = false;
      state.isClonedSuccess = false;
    },

    updateTemplatePage(state, action: PayloadAction<UpdateAdminPagePayloadI>) {
      state.isUpdating = true;
    },
    updateTemplatePageSuccess(state, action: PayloadAction<UpdateAdminPagePayloadI>) {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.data = state.data.map((page) => {
        if (page.pageId === action.payload.pageId) {
          return { ...page, ...action.payload };
        }
        return page;
      });
    },
    resetUpdateTemplatePage(state) {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    deleteTemplatePage(state, action: PayloadAction<DeleteAdminPagePayloadI>) {
      state.isDeleting = true;
    },
    deleteTemplatePageSuccess(state, action: PayloadAction<DeleteAdminPagePayloadI>) {
      state.isDeleting = false;
      state.data = state.data.filter(({ pageId }) => {
        return parseInt(pageId) !== parseInt(action.payload.pageId);
      });
    },
    deleteTemplatePageFailed(state) {
      state.isDeleting = false;
    },
  },
});

export const {
  createTemplatePage,
  createTemplatePageSuccess,
  resetCreateTemplatePage,

  deleteTemplatePage,
  deleteTemplatePageSuccess,
  deleteTemplatePageFailed,

  fetchTemplatePages,
  fetchTemplatePagesFailed,
  fetchTemplatePagesSuccess,

  cloneTemplatePage,
  cloneTemplatePageSuccess,
  resetCloneTemplatePage,

  updateTemplatePage,
  updateTemplatePageSuccess,
  resetUpdateTemplatePage,
} = adminPageSlice.actions;
export default adminPageSlice.reducer;
