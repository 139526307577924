import React from "react";
import { LinearProgress, Stack, Typography } from "@mui/material";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import VerifiedUserTwoToneIcon from "@mui/icons-material/VerifiedUserTwoTone";
import DownloadForOfflineTwoToneIcon from "@mui/icons-material/DownloadForOfflineTwoTone";

interface SettingUpDomainLoadingInterface {
  progress?: number;
}

const SettingUpDomainLoading: React.FC<SettingUpDomainLoadingInterface> = ({ progress = 0 }) => {
  let content = <SaveTwoToneIcon color={"secondary"} sx={{ fontSize: 70 }} />;
  let subText = "Finding provider details...";

  if (progress > 30 && progress < 60) {
    content = <DownloadForOfflineTwoToneIcon color={"warning"} sx={{ fontSize: 70 }} />;
    subText = "Retrieving DNS records...";
  } else if (progress >= 60) {
    content = <VerifiedUserTwoToneIcon color={"success"} sx={{ fontSize: 70 }} />;
    subText = "Finalizing the setup...";
  }

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={2} p={3}>
      {content}
      <Stack spacing={1} flex={1}>
        <Typography variant={"body2"}>Setting up your domain...</Typography>
        <LinearProgress variant='determinate' value={progress} />
        <Typography variant={"caption"}>{subText}</Typography>
      </Stack>
    </Stack>
  );
};

export default SettingUpDomainLoading;
