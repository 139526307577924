import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import { AppDispatch } from "../../../../state/store";
import TagI from "../../../../interface/tag.interface";
import CoreButton from "../../../../common/Button/CoreButton";
import LoadingButton from "../../../../common/Button/LoadingButton";
import CoreTextField from "../../../../common/TextField/CoreTextField";
import { registerPatternTag } from "../../../../helpers/constant/registerPattern";
import { selectFunnelTagState } from "../../../../state/features/funnel-tag/funnelTagSelector";
import { createTag, updateTag, resetCreateSuccess, resetUpdateSuccess } from "../../../../state/features/funnel-tag/funnelTagSlice";

interface Props {
  onClose: () => void;
  isEditMode?: boolean;
  tag?: TagI;
  label?: string;
}

const AddOrEditTagsName: React.FC<Props> = ({ onClose, isEditMode, tag, label }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isCreating, isCreatedSuccessfully, isUpdatedSuccessfully, isUpdating } = useSelector(selectFunnelTagState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TagI>();

  const onSubmit: SubmitHandler<TagI> = (data) => {
    if (isEditMode) {
      dispatch(updateTag(data));
    } else {
      dispatch(createTag(data));
    }
  };

  useEffect(() => {
    if (tag) {
      setValue("id", tag.id);
      setValue("name", tag.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    if (isCreatedSuccessfully || isUpdatedSuccessfully) {
      dispatch(resetCreateSuccess());
      dispatch(resetUpdateSuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedSuccessfully, isUpdatedSuccessfully]);

  const isLoading = isCreating || isUpdating;
  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
        <CoreTextField
          type='text'
          size='small'
          color='secondary'
          label={label}
          placeholder='Enter a Tag name here'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternTag.name)}
          required
        />
      </FormControl>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isLoading}
          loadingText={isEditMode ? "Changing..." : "Creating..."}
          type='submit'
          size='large'
          color='success'
        >
          {isEditMode ? "Change" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditTagsName;
