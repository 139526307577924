import React from "react";
import { ListsWrapper } from "../SitesList/SitesListStyles";
import AdminWebsiteListTable from "./AdminWebsiteListTable";
import AdminWebsiteListFilterHeader from "./AdminWebsiteListFilterHeader";

const AdminWebsiteLists: React.FC = () => {
  return (
    <ListsWrapper p={2}>
      <AdminWebsiteListFilterHeader />
      <AdminWebsiteListTable />
    </ListsWrapper>
  );
};

export default AdminWebsiteLists;
