import { all } from "redux-saga/effects";
import tagSaga from "./features/tag/tagSaga";
import typeSaga from "./features/type/typeSaga";
import folderSaga from "./features/folder/folderSaga";
import websiteSaga from "./features/website/websiteSaga";
import templateSaga from "./features/template/templateSaga";
import categorySaga from "./features/category/categorySaga";
import websiteSettingSaga from "./features/website-settings/websiteSettingSaga";
import websiteReportsSaga from "./features/websiteReports/websiteReportsSaga";
import adminTemplateSaga from "./features/adminTemplates/adminTemplateSaga";
import adminWebsiteSettingSaga from "./features/adminWebsiteSetting/adminWebsiteSettingSaga";
import permissionSaga from "./features/permission/permissionSaga";
import adminTemplatePageSaga from "./features/adminTemplatePage/adminTemplatePageSaga";
import domainSaga from "./features/domain/domainSaga";
import adminSectionLibrarySaga from "./features/adminSectionLibrary/adminSectionLibrarySaga";
import urlRedirectSaga from "./features/urlRirects/redirectSaga";
import funnelSaga from "./features/funnel/funnelSaga";
import funnelStepSaga from "./features/funnelStep/funnelStepSaga";
import funnelInfoSaga from "./features/funnelInfo/funnelInfoSaga";
import funnelStepPagesSaga from "./features/funnelStepPages/funnelStepPagesSaga";
import funnelReportsSaga from "./features/funnelReports/funnelReportsSaga";
import funnelTemplateSaga from "./features/funnelTemplate/funnelTemplateSaga";
import funnelTemplateSettingSaga from "./features/funnelTemplateSetting/funnelTemplateSettingSaga";
import funnelCategorySaga from "./features/funnel-category/funnelCategorySaga";
import funnelTagSaga from "./features/funnel-tag/funnelTagSaga";
import funnelTypeSaga from "./features/funnel-type/funnelTypeSaga";
import funnelTemplateStepSaga from "./features/funnelTemplateStep/funnelTemplateStepSaga";
import funnelTemplateLibrarySaga from "./features/funnel-template-library/funnelTemplateLibrarySaga";

export default function* rootSaga() {
  yield all([
    folderSaga(),
    templateSaga(),
    websiteSaga(),
    websiteSettingSaga(),
    websiteReportsSaga(),
    categorySaga(),
    typeSaga(),
    tagSaga(),
    adminTemplateSaga(),
    adminWebsiteSettingSaga(),
    permissionSaga(),
    adminTemplatePageSaga(),
    domainSaga(),
    adminSectionLibrarySaga(),
    urlRedirectSaga(),
    funnelSaga(),
    funnelStepSaga(),
    funnelInfoSaga(),
    funnelStepPagesSaga(),
    funnelReportsSaga(),
    funnelTemplateSaga(),
    funnelTemplateStepSaga(),
    funnelTemplateSettingSaga(),
    funnelCategorySaga(),
    funnelTagSaga(),
    funnelTypeSaga(),
    funnelTemplateLibrarySaga()
  ]);
}
