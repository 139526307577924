import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import FunnelTemplateSettingService from "../../../services/funnelTemplateSetting";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import { FunnelTemplateSettingI } from "../../../interface/funnelTemplateSetting.interface";
import {
  getFunnelTemplateSettingSuccess,
  resetFunnelTemplateSetting,
  updateFunnelTemplateSettingSuccess,
  resetUpdateSettingStatus,
} from "./funnelTemplateSettingSlice";

function* funnelTemplateSettingWatcher() {
  yield takeEvery("funnelTemplateSetting/getFunnelTemplateSetting", getFunnelSettingSaga);
  yield takeEvery("funnelTemplateSetting/updateFunnelTemplateSetting", updateFunnelSettingSaga);
}

function* getFunnelSettingSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(FunnelTemplateSettingService.getFunnelTemplateSetting, action.payload);

    if (response.success) {
      yield put(getFunnelTemplateSettingSuccess(response.data));
    } else {
      yield put(resetFunnelTemplateSetting(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetFunnelTemplateSetting("Network or server error"));
    console.error(err);
  }
}

function* updateFunnelSettingSaga(action: PayloadAction<FunnelTemplateSettingI>) {
  try {
    const response = yield* call(FunnelTemplateSettingService.updateFunnelTemplateSetting, action.payload);

    if (response.success) {
      yield put(updateFunnelTemplateSettingSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetUpdateSettingStatus());
      showErrorToaster(response.message);
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(resetUpdateSettingStatus());
    }
  }
}

export default function* funnelTemplateSettingSaga() {
  yield all([funnelTemplateSettingWatcher()]);
}
