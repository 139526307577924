import httpRequest from "./api/httpRequest";
import CategoryI, { CreateCategoryI, UpdateCategoryI } from "../interface/category.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class CategoryService {
  fetchCategory() {
    return httpRequest.get<CategoryI[]>(websiteBuilderAPI + "admin/website/category/list");
  }

  createCategory(payload: CreateCategoryI) {
    return httpRequest.post<CategoryI>(websiteBuilderAPI + "admin/website/category/create", payload);
  }

  updateCategory(payload: UpdateCategoryI) {
    return httpRequest.put<CategoryI>(websiteBuilderAPI + "admin/website/category/update", payload);
  }

  deleteCategory(categoryId: string) {
    return httpRequest.delete<CategoryI>(websiteBuilderAPI + "admin/website/category/delete/" + categoryId);
  }
}

const categoryService = new CategoryService();
export default categoryService;
