import React from "react";
import { useSelector } from "react-redux";
import Popper from "@mui/material/Popper";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Stack } from "@mui/material";
import HrefLink from "../../../common/Link/HrefLink";
import CoreButton from "../../../common/Button/CoreButton";
import useBoolean from "../../../hooks/useBoolean";
import { generateFunnelEditorUrl } from "../../../helpers/utils/generateEditorUrl";
import generateFunnelPreviewUrl from "../../../helpers/utils/generateFunnelPreviewUrl";
import { selectFunnelInfoData } from "../../../state/features/funnelInfo/funnelInfoSelector";
import { selectSelectedFunnelStep } from "../../../state/features/funnelStep/funnelStepSelector";
import { selectFunnelStepPageOriginal } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";
import { useHistory } from "react-router-dom";

const EditPageButtonGroup = () => {
  const history = useHistory();
  const { id: stepId, path } = useSelector(selectSelectedFunnelStep);
  const original = useSelector(selectFunnelStepPageOriginal);
  const { id: funnelId, subDomain, domainInfo } = useSelector(selectFunnelInfoData);
  const { value: open, setFalse: onClose, toggle: onToggle } = useBoolean(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;
    onClose();
  };

  const editorUrl = generateFunnelEditorUrl(stepId, Number(funnelId), Number(original?.id), false);
  const previewUrl = generateFunnelPreviewUrl(subDomain, path ?? "", domainInfo);

  const handleGoToEditPage = (newTab: boolean) => {
    if (newTab) {
      window.open(editorUrl, "_blank");
    } else {
      history.push(editorUrl);
    }
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={0.5}
    >
      <ButtonGroup variant={"contained"} color={"secondary"} size={"small"} ref={anchorRef} sx={{ boxShadow: "none" }}>
        <CoreButton onClick={onToggle}>Edit Page</CoreButton>
        <CoreButton onClick={onToggle}>
          <ArrowDropDownIcon />
        </CoreButton>
      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 9,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "right top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  <MenuItem onClick={() => handleGoToEditPage(false)} sx={{ fontSize: 14 }}>
                    Edit Page Details
                  </MenuItem>
                  <MenuItem onClick={() => handleGoToEditPage(true)} sx={{ fontSize: 14 }}>
                    Edit in a New Tab
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <HrefLink href={previewUrl} target={"_blank"}>
        <CoreButton size={"small"} variant={"outlined"} color={"secondary"} startIcon={<ArrowOutwardIcon />} sx={{display:{xs:'none',lg:'inline-flex'}}}>
          Open URL
        </CoreButton>
        <CoreButton size={"small"} variant={"outlined"} color={"secondary"} sx={{padding:'5px !important',display:{xs:'inline-flex',lg:'none'}}}>
          <ArrowOutwardIcon />
        </CoreButton>
      </HrefLink>
    </Stack>
  );
};

export default EditPageButtonGroup;
