import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { AppDispatch } from "../../state/store";
import CreateFolder from "./CreateFolder";
import FolderListItem from "./FolderListItem";
import FolderAllSites from "./FolderAllSites";
import SpecialFolderList from "./SpecialFolderList";
import ShowSideBarIcon from "../../common/Icons/ShowSideBarIcon";
import HideSideBarIcon from "../../common/Icons/HideSideBarIcon";
import FolderListSkeleton from "../../common/LoadingView/FolderListSkeleton";
import { CustomList, LeftSideBar, SidebarListWrapper, SideTopBarTab } from "./SitesListStyles";
import { selectFolderState } from "../../state/features/folder/folderSelector";
import { getFolders, getMoreFolders } from "../../state/features/folder/folderSlice";
import { selectWebsiteFilters } from "../../state/features/website/websiteSelector";

interface Props {
  toggleLeftSide?: React.MouseEventHandler<HTMLButtonElement>;
  isHideLeftSide: boolean;
  isShowLeftSideTab: boolean;
}

const WebsiteListLeftSide: React.FC<Props> = ({ toggleLeftSide, isHideLeftSide, isShowLeftSideTab }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { data: folderList, isLoading, currentPage, limit, hasMore } = useSelector(selectFolderState);
  const { folderId } = useSelector(selectWebsiteFilters);

  useEffect(() => {
    dispatch(getFolders({ currentPage: 1, limit: limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMore = () => {
    const payload = {
      currentPage: currentPage + 1,
      limit: limit,
    };
    dispatch(getMoreFolders(payload));
  };

  // ## decide what to render ##
  let content = null;
  if (isLoading) {
    content = (
      <CustomList className='pb_scroll_bar'>
        <FolderListSkeleton count={7} />
      </CustomList>
    );
  } else if (folderList.length > 0) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"}>
        <InfiniteScroll
          dataLength={folderList.length}
          next={fetchMore}
          hasMore={hasMore}
          loader={<FolderListSkeleton count={3} />}
          scrollableTarget='wb_folder_scroll_bar'
        >
          {folderList.map((folder) => {
            return <FolderListItem key={folder.id} folder={folder} selected={folder.id === folderId} />;
          })}
        </InfiniteScroll>
      </CustomList>
    );
  }

  return (
    <LeftSideBar className={isHideLeftSide ? "collapseLeftSide" : ""}>
      <Stack direction='row' alignItems='center' className={"iSidebarTitleBar"}>
        <Typography variant='body1' color='text.secondary' className={"iSidebarTitle"}>
          My Folders
        </Typography>
        <IconButton size={"large"} onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
          {isHideLeftSide || isShowLeftSideTab ? <ShowSideBarIcon /> : <HideSideBarIcon />}
        </IconButton>
      </Stack>
      <SidebarListWrapper
        px={1}
        className={`${isHideLeftSide ? "slw-hide-sidebar" : ""} ${isShowLeftSideTab ? "slw-show-sidebar-tab" : ""}`}
      >
        <SideTopBarTab>
          <Typography variant='body1' color='text.secondary'>
            My Folders
          </Typography>
          <IconButton size='large' onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
            <HideSideBarIcon />
          </IconButton>
        </SideTopBarTab>

        <FolderAllSites />

        {content}
        <CreateFolder />

        <Divider light />
        <SpecialFolderList />
      </SidebarListWrapper>
    </LeftSideBar>
  );
};

export default WebsiteListLeftSide;
