import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  FetchSectionLibraryPayloadI,
  SectionLibraryI,
  CreateSectionLibraryI,
  UpdateSectionLibraryI,
  DeleteSectionLibraryI,
  CloneSectionLibraryI,
  PublishSectionLibraryI,
  ApproveSectionLibraryI,
} from "../interface/websiteSectionLibrary.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class SectionLibraryService {
  getSectionLibraries(params: FetchSectionLibraryPayloadI) {
    return httpRequest.get<WithPagination<SectionLibraryI>>(
      websiteBuilderAPI + "admin/website/section/library/list",
      params
    );
  }
  createSectionLibrary(payload: CreateSectionLibraryI) {
    return httpRequest.post<SectionLibraryI>(websiteBuilderAPI + "admin/website/section/library/create", {
      ...payload,
      section: {},
    });
  }
  updateSectionLibrary(payload: UpdateSectionLibraryI) {
    return httpRequest.put<SectionLibraryI>(websiteBuilderAPI + "admin/website/section/library/update", payload);
  }
  deleteSectionLibrary(payload: DeleteSectionLibraryI) {
    return httpRequest.delete<DeleteSectionLibraryI>(
      websiteBuilderAPI + "admin/website/section/library/delete/" + payload.sectionId
    );
  }
  cloneSectionLibrary(payload: CloneSectionLibraryI) {
    return httpRequest.post<SectionLibraryI>(
      websiteBuilderAPI + "admin/website/section/library/clone?id=" + payload.sectionId
    );
  }
  publishStatusUpdateSectionLibrary(payload: PublishSectionLibraryI) {
    return httpRequest.put<PublishSectionLibraryI>(
      websiteBuilderAPI + "admin/website/section/library/publish",
      payload
    );
  }
  approveStatusUpdateSectionLibrary(payload: ApproveSectionLibraryI) {
    return httpRequest.put<ApproveSectionLibraryI>(
      websiteBuilderAPI + "admin/website/section/library/approve",
      payload
    );
  }
}

const templateService = new SectionLibraryService();
export default templateService;
