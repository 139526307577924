import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, FormControl, FormHelperText, Grid, MenuItem, Select, Stack, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InputLabel from "@mui/material/InputLabel";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import StyledReactSelect from "../../../common/Select/StyledReactSelect";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { REDIRECT_TYPE_LIST } from "../../../helpers/constant/urlRedirectConstant";
import { registerPatterUrlRedirect } from "../../../helpers/constant/registerPattern";
import { useGetAllDomainsQuery } from "../../../state/features/domain/domainApiSlice";
import { useGetWebsiteListQuery } from "../../../state/features/website/websitApiSlice";
import { useGetFunnelListQuery } from "../../../state/features/funnel/funnelListApiSlice";
import { useGetAllFunnelStepQuery } from "../../../state/features/funnelStep/funnelStepApiSlice";
import { useGetAllWebsitePagesQuery } from "../../../state/features/website-pages/websitePageApiSlice";
import {
  AddEditRedirectUrlRequestType,
  RedirectUrlInterface,
} from "../../../state/features/redirectUrl/redirectUrl.interface";
import {
  useCreateRedirectUrlMutation,
  useUpdateRedirectUrlMutation,
} from "../../../state/features/redirectUrl/redirectUrlApiSlice";
import CircleLoader from "../../../common/LoadingView/CircleLoader";

interface addRedirectModalInterface {
  onClose: () => void;
  redirectInfo?: RedirectUrlInterface;
  isEdit?: boolean;
}

const AddOrEditRedirectModal: React.FC<addRedirectModalInterface> = ({ redirectInfo, onClose, isEdit }) => {
  const muiTheme = useTheme();

  const { data: domainState, isSuccess: isGetAllDomain } = useGetAllDomainsQuery({});
  const { data: domainList = [] } = domainState || {};

  const [createRedirectUrl, { isLoading: isCreating, isSuccess: isCreateSuccess }] = useCreateRedirectUrlMutation();
  const [updateRedirectUrl, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] = useUpdateRedirectUrlMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const redirectType = watch("redirectType");
  const websiteInfo = watch("websiteInfo");
  const funnelInfo = watch("funnelInfo");

  const [websiteSearchText, setWebsiteSearchText] = useState("");

  const { data: websiteState, isLoading: websiteLoading } = useGetWebsiteListQuery(
    {
      currentPage: 1,
      limit: 10,
      searchText: websiteSearchText,
    },
    { skip: redirectType !== "WEBSITE" }
  );

  const { data: websitePageState } = useGetAllWebsitePagesQuery(
    { websiteId: websiteInfo?.value },
    { skip: !websiteInfo?.value }
  );
  const { data: websitePageList = [] } = websitePageState || {};
  let { content = [] } = websiteState?.data || {};

  const websiteList: { value: number; label: string }[] = content.map(({ id, name }) => ({
    value: Number(id),
    label: name,
  }));
  const [funnelSearchText, setFunnelSearchText] = useState("");
  const { data: funnelState, isLoading: funnelLoading } = useGetFunnelListQuery(
    {
      currentPage: 1,
      limit: 10,
      searchText: funnelSearchText,
    },
    { skip: redirectType !== "FUNNEL" }
  );
  let { content: funnelContent = [] } = funnelState?.data || {};

  const funnelList: { value: number; label: string }[] = funnelContent.map(({ id, name }) => ({
    value: Number(id),
    label: name,
  }));
  const { data: funnelStepState } = useGetAllFunnelStepQuery(
    { funnelId: funnelInfo?.value },
    { skip: !funnelInfo?.value }
  );

  const { data: funnelStepList = [] } = funnelStepState || {};
  const doSearchWebsite = (value: string) => {
    setWebsiteSearchText(value);
  };
  // debounce implement for search input change for website

  const handleWebsiteSearch = React.useMemo(() => {
    return debounceHandler(doSearchWebsite, 500);
  }, []);
  const doSearchFunnel = (value: string) => {
    setFunnelSearchText(value);
  };
  // debounce implement for search input change for website

  const handleFunnelSearch = React.useMemo(() => {
    return debounceHandler(doSearchFunnel, 500);
  }, []);

  const onSubmitHandler = (values: any) => {
    const domainInfo = domainList.find((domain) => domain.domain === values.domain);

    const redirectValues = {
      domainId: Number(domainInfo?.id),
      domain: values.domain,
      path: values.path,
    };

    let payload = {} as AddEditRedirectUrlRequestType;
    if (values.redirectType === "URL") {
      payload = {
        ...redirectValues,
        redirectType: "URL",
        targetUrl: values.targetUrl,
      };
    } else if (values.redirectType === "WEBSITE") {
      payload = {
        ...redirectValues,
        redirectType: "WEBSITE",
        websiteId: websiteInfo?.value,
        pageId: values.pageId,
      };
    } else if (values.redirectType === "FUNNEL") {
      payload = {
        ...redirectValues,
        redirectType: "FUNNEL",
        funnelId: funnelInfo?.value,
        stepId: values.stepId,
      };
    } else if (values.redirectType === "ALL") {
      const targetDomainInfo = domainList.find((domain) => domain.domain === values.targetDomain);

      payload = {
        ...redirectValues,
        path: "*",
        redirectType: "ALL",
        targetDomainId: Number(targetDomainInfo?.id),
        targetDomain: values.targetDomain,
      };
    }

    if (isEdit && redirectInfo?.id) {
      payload.id = redirectInfo.id;
      updateRedirectUrl(payload);
    } else {
      createRedirectUrl(payload);
    }
  };

  useEffect(() => {
    if (isEdit && redirectInfo?.id) {
      setValue("domain", redirectInfo.domain);
      setValue("path", redirectInfo.path);
      setValue("redirectType", redirectInfo.redirectType);

      if (redirectInfo.redirectType === "URL") {
        setValue("targetUrl", redirectInfo.targetUrl);
      } else if (redirectInfo.redirectType === "WEBSITE") {
        setValue("websiteInfo", { label: redirectInfo.siteInfo?.name ?? "", value: redirectInfo.websiteId });
        setValue("pageId", redirectInfo.pageId);
      } else if (redirectInfo.redirectType === "FUNNEL") {
        setValue("funnelInfo", { label: redirectInfo.funnelInfo?.name ?? "", value: redirectInfo.funnelId });
        setValue("stepId", redirectInfo.stepId);
        setValue("stepId", redirectInfo.stepId);
      } else if (redirectInfo.redirectType === "ALL") {
        setValue("targetDomain", redirectInfo.targetDomain);
      }
    }
  }, [isEdit, redirectInfo]);

  useEffect(() => {
    if (redirectType === "ALL") {
      setValue("path", "*");
    }
  }, [redirectType]);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) onClose();
  }, [isCreateSuccess, isUpdateSuccess]);

  const isLoading = isCreating || isUpdating;

  if (!isGetAllDomain) return <CircleLoader height={'350px'} />;

  return (
    <Box p={3} component={"form"} noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='domain'
            defaultValue={""}
            rules={registerPatterUrlRedirect.domain}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
              return (
                <FormControl disabled={isEdit} fullWidth required error={invalid}>
                  <InputLabel color='secondary'>Domain</InputLabel>
                  <Select color='secondary' label='Domain' displayEmpty fullWidth value={value} onChange={onChange}>
                    {domainList.map((eachDomain) => {
                      const { id, domain } = eachDomain;
                      return (
                        <MenuItem key={id} value={domain}>
                          {domain}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CoreTextField
            required
            fullWidth
            type='text'
            size='medium'
            color='secondary'
            label='Path'
            placeholder='/path'
            disabled={isEdit || redirectType === "ALL"}
            error={!!(errors.path && errors.path.message)}
            helperText={typeof errors?.path?.message === "string" ? errors.path.message : ""}
            {...register("path", registerPatterUrlRedirect.path)}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='redirectType'
            defaultValue={""}
            rules={registerPatterUrlRedirect.redirectType}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
              return (
                <FormControl
                  disabled={isEdit && redirectInfo?.redirectType === "ALL"}
                  fullWidth
                  required
                  error={invalid}
                >
                  <InputLabel color='secondary'>Redirect Type</InputLabel>
                  <Select
                    color='secondary'
                    label='Redirect Type'
                    displayEmpty
                    fullWidth
                    value={value}
                    onChange={onChange}
                  >
                    {REDIRECT_TYPE_LIST.map(({ label, value }) => {
                      return (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              );
            }}
          />
        </Grid>
        {redirectType === "URL" && (
          <Grid item xs={12}>
            <CoreTextField
              required
              fullWidth
              type='text'
              size='medium'
              color='secondary'
              label={"Target URL"}
              placeholder={"www.exp.com"}
              error={!!(errors.targetUrl && errors.targetUrl.message)}
              helperText={typeof errors?.targetUrl?.message === "string" ? errors.targetUrl.message : ""}
              {...register("targetUrl", registerPatterUrlRedirect.targetUrl)}
            />
          </Grid>
        )}

        {redirectType === "WEBSITE" && (
          <>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='websiteInfo'
                defaultValue={""}
                rules={{ required: "need to choose website" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel color='secondary'>Select Website</InputLabel>
                      <StyledReactSelect
                        isLoading={websiteLoading}
                        classNamePrefix={"st-select"}
                        options={websiteList}
                        value={value}
                        onChange={(newOption) => {
                          onChange(newOption);
                          setValue("pageId", null);
                        }}
                        onInputChange={(newValue) => {
                          return handleWebsiteSearch(newValue);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: invalid ? muiTheme.palette.error.main : muiTheme.palette.secondary.main,
                            ...(invalid ? { borderColor: muiTheme.palette.error.main } : {}),
                          },
                        })}
                      />
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='pageId'
                defaultValue={""}
                rules={{ required: "need to select website page" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel>Select Page</InputLabel>
                      <Select
                        fullWidth
                        displayEmpty
                        color='secondary'
                        label='Select Website'
                        value={value}
                        onChange={onChange}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value=''>None</MenuItem>
                        {websitePageList.map((pageInfo: any) => (
                          <MenuItem key={pageInfo.pageId} value={pageInfo.pageId}>
                            {pageInfo.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </>
        )}

        {redirectType === "FUNNEL" && (
          <>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='funnelInfo'
                defaultValue={""}
                rules={{ required: "need to choose funnel" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel color='secondary'>Select Funnel</InputLabel>
                      <StyledReactSelect
                        isLoading={funnelLoading}
                        classNamePrefix={"st-select"}
                        options={funnelList}
                        value={value}
                        onChange={(newOption) => {
                          onChange(newOption);
                          setValue("stepId", null);
                        }}
                        onInputChange={(newValue) => {
                          return handleFunnelSearch(newValue);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: invalid ? muiTheme.palette.error.main : muiTheme.palette.secondary.main,
                            ...(invalid ? { borderColor: muiTheme.palette.error.main } : {}),
                          },
                        })}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: invalid ? muiTheme.palette.error.main : base.borderColor,
                          }),
                        }}
                      />
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='stepId'
                defaultValue={""}
                rules={{ required: "need to select a step" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel>Select Step</InputLabel>
                      <Select
                        fullWidth
                        displayEmpty
                        color='secondary'
                        label='Select Step'
                        value={value}
                        onChange={onChange}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value=''>None</MenuItem>
                        {funnelStepList.map((stepInfo: any) => (
                          <MenuItem key={stepInfo.id} value={stepInfo.id}>
                            {stepInfo.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </>
        )}

        {redirectType === "ALL" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name='targetDomain'
              defaultValue={""}
              rules={registerPatterUrlRedirect.domain}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                return (
                  <FormControl fullWidth required error={invalid}>
                    <InputLabel color='secondary'>Target Domain</InputLabel>
                    <Select
                      color='secondary'
                      label='Target Domain'
                      displayEmpty
                      fullWidth
                      value={value}
                      onChange={onChange}
                    >
                      {domainList.map((eachDomain) => {
                        const { id, domain } = eachDomain;
                        return (
                          <MenuItem key={id} value={domain}>
                            {domain}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                );
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Stack direction={"row"} alignItems={"center"} spacing={2} justifyContent={"flex-end"}>
            <CoreButton size='large' color='inherit' onClick={onClose} type='button'>
              Cancel
            </CoreButton>
            <LoadingButton
              type='submit'
              size='large'
              color='secondary'
              isLoading={isLoading}
              loadingText={isEdit ? "Updating..." : "Adding..."}
            >
              {isEdit ? "Update Redirect" : "Add Redirect"}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddOrEditRedirectModal;
