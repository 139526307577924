import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddStepPageRequestType,
  FunnelStepPageInterface,
  IDeleteStepPagePayload,
  IFunnelStepPagesRequest,
  PageCreateType,
  PageType,
} from "./funnelStepPagesInterfaces";

const initialState = {
  original: undefined as FunnelStepPageInterface | undefined,
  variant: undefined as FunnelStepPageInterface | undefined,
  isLoading: true,
  isError: false,
  error: "",

  isAdding: false,
  isAddSuccess: false,
  addPagePayload: null as null | { pageCreateType: PageCreateType; pageType: PageType; pageId?: number },

  isDeleting: false,
  isDeleteSuccess: false,
};

const funnelStepPagesSlice = createSlice({
  name: "funnelStepPages",
  initialState,
  reducers: {
    getFunnelStepPages(state, _action: PayloadAction<IFunnelStepPagesRequest>) {
      state.isLoading = true;
    },
    getFunnelStepPagesSuccess(state, action: PayloadAction<FunnelStepPageInterface[]>) {
      state.original = action.payload.filter((data) => data.pageType === "ORIGINAL")[0];
      state.variant = action.payload.filter((data) => data.pageType === "VARIANT")[0];
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getFunnelStepPagesFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addStepPage(state, action: PayloadAction<AddStepPageRequestType>) {
      state.isAdding = true;
      state.addPagePayload = {
        pageCreateType: action.payload.pageCreateType,
        pageType: action.payload.pageType,
      };
    },
    addStepPageSuccess(state, action: PayloadAction<FunnelStepPageInterface>) {
      state.isAddSuccess = true;
      state.isAdding = false;
      if (state.addPagePayload) {
        state.addPagePayload.pageId = action.payload.id;
      }
    },
    resetAddStepPageSuccess(state) {
      state.isAddSuccess = false;
      state.isAdding = false;
      state.addPagePayload = null;
    },

    deleteStepPage(state, action: PayloadAction<IDeleteStepPagePayload>) {
      state.isDeleting = true;
    },
    deleteStepPageSuccess(state, action: PayloadAction<IDeleteStepPagePayload>) {
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      if (action.payload.pageType === "VARIANT") {
        state.variant = undefined;
      }
    },
    resetDeleteStepPageSuccess(state) {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },
  },
});

export const {
  getFunnelStepPages,
  getFunnelStepPagesSuccess,
  getFunnelStepPagesFailed,

  addStepPage,
  addStepPageSuccess,
  resetAddStepPageSuccess,

  deleteStepPage,
  deleteStepPageSuccess,
  resetDeleteStepPageSuccess,
} = funnelStepPagesSlice.actions;
export default funnelStepPagesSlice.reducer;
