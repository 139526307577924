import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import { ICloneWebsiteByTemplatePayload } from "../../state/features/website/website.interface";
import WebsiteService from "../../services/website.service";
import { showErrorToaster } from "../../helpers/utils/toaster";
import { selectWebsiteState } from "../../state/features/website/websiteSelector";
import InputWithLabel from "../../common/TextField/InputWithLabel";
import { cloneWebsite } from "../../state/features/website/websiteSlice";


const makeDefaultSubdomain = (websiteName: string) => {
  return websiteName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase() + ".crmconnection.io";
};
const getError = (name: string) => {
  if (name.trim() === "") {
    return "Website name is empty!";
  } else if (name.length > 50) {
    return "Website name cannot be longer than 50 characters";
  } else {
    return "";
  }
};

interface IProps {
  onClose: ()=>void;
  clone?: boolean;
  websiteId: number;
}

const CloneWebsiteModal:React.FC<IProps> = ({onClose,websiteId,clone}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isNewWebsiteInsideFolder, newWebsiteFolderId,isUpdating } = useSelector(selectWebsiteState);
  const [isLoading, setLoading] = useState(false);
  const [websiteName, setWebsiteName] = useState("");
  const [error, setError] = useState("");

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError(getError(e.target.value));
    setWebsiteName(e.target.value);
  };

  const handleTemplateSelect = () => {
    const errorMessage = getError(websiteName);
    setError(errorMessage);
    if (!errorMessage) {
      let payload: ICloneWebsiteByTemplatePayload = {
        websiteId,
        name: websiteName,
        subDomain: makeDefaultSubdomain(websiteName)
      };
      if (isNewWebsiteInsideFolder) payload.folderId = newWebsiteFolderId;
      if (clone) {
        dispatch(cloneWebsite(payload))
        onClose();
      } else {
        setLoading(true);
        WebsiteService.createWebsiteByTemplate(payload)
          .then((res) => {
            if (res.success) {
              history.push("/wbuilder/site/" + res.data.id);
            } else {
              showErrorToaster(res.message);
            }
          })
          .finally(() => setLoading(false));
      }
    }
  };

  return (
    <Stack justifyContent={"space-between"} p={3} width={500} minHeight={200}>
      <InputWithLabel
        required={true}
        fullWidth={true}
        color={"secondary"}
        label={"Website Name"}
        value={websiteName}
        placeholder={"Give your new website a name"}
        onChange={handleChangeName}
        error={!!error}
        helperText={error}
        sx={{ "& .MuiInputBase-root": { bgcolor: "common.white" } }}
      />

      {websiteName !== "" && (
        <Typography variant={"caption"} noWrap component={'p'} pt={0.5}>https://{makeDefaultSubdomain(websiteName)}</Typography>
      )}
      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} pt={3}>
        <CoreButton color={"inherit"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          size={"medium"}
          variant={"contained"}
          color={"secondary"}
          isLoading={isLoading || isUpdating}
          onClick={handleTemplateSelect}
        >
          {isLoading || isUpdating ? 'Cloning...' : 'Clone'}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default CloneWebsiteModal;