import React from "react";
import { Typography, Box, styled } from "@mui/material";

const BoxStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "50%",
  marginTop: "16px",
}));

interface Props {
  title: string;
  description?: string;
  height?: string;
}

const NoDataFoundPage: React.FC<Props> = ({ title, description, height = "100%" }) => {
  return (
    <BoxStyled sx={{ height: height }}>
      <Typography variant={"h5"} color={"text.secondary"}>
        {title}
      </Typography>
      <Typography variant={"body2"} color={"text.primary"}>
        {description}
      </Typography>
    </BoxStyled>
  );
};

export default NoDataFoundPage;
