import React from "react";
import { ListsWrapper } from "../SitesList/SitesListStyles";
import AdminFunnelListTable from "./AdminFunnelListTable";
import AdminFunnelListFilterHeader from "./AdminFunnelListFilterHeader";

const AdminFunnelLists: React.FC = () => {
  return (
    <ListsWrapper p={2}>
      <AdminFunnelListFilterHeader />
      <AdminFunnelListTable />
    </ListsWrapper>
  );
};

export default AdminFunnelLists;
