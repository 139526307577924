import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Divider, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import StepPages from "./StepPages";
import StepHeader from "./StepHeader";
import AddStepFunnel from "./AddStepFunnel";
import useQuery from "../../../hooks/useQuery";
import CircleLoader from "../../../common/LoadingView/CircleLoader";
import NoContentFoundIcon from "../../../common/Icons/NoContentFoundIcon";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import { generateFunnelEditorUrl } from "../../../helpers/utils/generateEditorUrl";
import { resetAddFunnelTemplateStep } from "../../../state/features/funnelTemplateStep/funnelTemplateStepSlice";
import {
  selectFunnelTemplateStepState,
  selectSelectedFunnelTemplateStep,
} from "../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";

const ContentBox = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.other.outlinedBorder}`,
  height: "100%",
  flex: 1,
  backgroundColor: theme.palette.common.white,
  overflowY: "auto",
}));

const BuilderMainContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParams = useQuery();
  const { funnelId } = useParams<IFunnelDetailsParams>();

  const { stepId } = useSelector(selectSelectedFunnelTemplateStep) || {};

  const { isAddSuccess, createdPageId, createdStepId, isLoading } = useSelector(selectFunnelTemplateStepState);

  useEffect(() => {
    if (stepId) {
      searchParams.set("step", String(stepId));
      history.replace({ search: searchParams.toString() });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [stepId]);

  // after add page from scratch
  useEffect(() => {
    if (isAddSuccess && createdPageId && createdStepId) {
      const editorUrl = generateFunnelEditorUrl(+createdStepId, Number(funnelId), +createdPageId, true);
      window.open(editorUrl, "_blank");

      dispatch(resetAddFunnelTemplateStep());
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isAddSuccess]);

  let content: null | React.ReactNode = null;
  if (isLoading) {
    content = <CircleLoader />;
  } else if (stepId) {
    content = (
      <>
        <StepHeader />
        <Divider light />
        <StepPages />
      </>
    );
  } else {
    content = (
      <GlobalEmptyPage
        icon={<NoContentFoundIcon />}
        title={"Looks like the funnel is empty!"}
        description={"Get started by adding a new step."}
      >
        <Box mt={1}>
          <AddStepFunnel size={"large"} />
        </Box>
      </GlobalEmptyPage>
    );
  }

  return <ContentBox>{content}</ContentBox>;
};

export default BuilderMainContent;
