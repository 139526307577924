import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FolderInterface, IDeleteFolderPayload, IGetFoldersPayload, IGetFoldersResponse } from "./folder.Interface";

const initialState = {
  data: [] as FolderInterface[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  limit: 10,
  currentPage: 1,
  hasMore: true,

  isFetching: false,

  isAdding: false,
  isAddSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,
};

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    getFolders: (state, _action: PayloadAction<IGetFoldersPayload>) => {
      state.isLoading = true;
    },
    getFoldersSuccess: (state, action: PayloadAction<IGetFoldersResponse>) => {
      state.data = action.payload.content;
      state.currentPage = +action.payload.currentPage;
      state.count = +action.payload.count;
      state.isLoading = false;
      state.hasMore = action.payload.content?.length >= action.payload.limit;
    },
    getFoldersFailed: (state, action: PayloadAction<string>) => {
      state.data = [];
      state.isLoading = false;
      state.error = action.payload;
    },
    getMoreFolders: (state, _action: PayloadAction<IGetFoldersPayload>) => {
      state.isFetching = true;
    },
    getMoreFoldersSuccess: (state, action: PayloadAction<IGetFoldersResponse>) => {
      state.data = [...state.data, ...action.payload.content];
      state.currentPage = +action.payload.currentPage;
      state.count = +action.payload.count;
      state.isFetching = false;
      state.hasMore = action.payload.content?.length >= action.payload.limit;
    },
    getMoreFoldersFailed: (state, action: PayloadAction<string>) => {
      state.isFetching = false;
    },

    addFolder: (state, _action: PayloadAction<FolderInterface>) => {
      state.isAdding = true;
      state.isError = false;
      state.error = "";
    },
    addFolderSuccess: (state, action: PayloadAction<FolderInterface>) => {
      if (!state.hasMore) {
        state.data.push(action.payload);
      }
      state.count += 1;
      state.isAdding = false;
      state.isAddSuccess = true;
    },
    addFolderFailed: (state, action: PayloadAction<string>) => {
      state.isAdding = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetAddFolderSuccess: (state) => {
      state.isAddSuccess = false;
    },
    deleteFolder: (state, _action: PayloadAction<IDeleteFolderPayload>) => {
      state.isDeleting = true;
    },
    deleteFolderSuccess: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.data = state.data.filter((folder) => folder.id !== action.payload);
      }
      state.isDeleting = false;
      state.isDeleteSuccess = true;
    },
    deleteFolderFailed: (state, action: PayloadAction<string>) => {
      state.isDeleting = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetDeleteFolderSuccess: (state) => {
      state.isDeleteSuccess = false;
    },
    updateFolder: (state, action: PayloadAction<FolderInterface>) => {
      state.isUpdating = true;
    },
    updateFolderSuccess: (state, action: PayloadAction<FolderInterface>) => {
      if (action.payload.id) {
        state.data = state.data.map((folder) => {
          if (folder.id === action.payload.id) {
            if (folder.color === action.payload.color) {
              state.isUpdateSuccess = true;
            }
            folder = { ...folder, ...action.payload };
          }
          return folder;
        });
      }
      state.isUpdating = false;
    },
    updateFolderFailed: (state, action: PayloadAction<string>) => {
      state.isUpdating = false;
      state.isError = true;
      state.error = action.payload;
    },
    resetUpdateFolderSuccess: (state) => {
      state.isUpdateSuccess = false;
    },
  },
});

export const {
  getFolders,
  getFoldersFailed,
  getFoldersSuccess,

  getMoreFolders,
  getMoreFoldersFailed,
  getMoreFoldersSuccess,

  addFolder,
  addFolderFailed,
  addFolderSuccess,
  resetAddFolderSuccess,

  updateFolder,
  updateFolderFailed,
  updateFolderSuccess,
  resetUpdateFolderSuccess,

  deleteFolder,
  deleteFolderFailed,
  deleteFolderSuccess,
  resetDeleteFolderSuccess,
} = folderSlice.actions;

export default folderSlice.reducer;
