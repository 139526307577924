import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { lightModePalette, myBreakpoints, typographyProperties } from "../../helpers/constant/themeConstant";
import { components } from "../../component";

interface Props {
  children: React.ReactNode;
}

const ThemeWrapper: React.FC<Props> = ({ children }) => {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: lightModePalette,
        typography: typographyProperties,
        breakpoints: myBreakpoints,
        components: components,
      }),
    []
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeWrapper;
