import React from "react";
import { Grow, Modal, ModalProps, Paper,Stack, styled } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";

const PaperStyle = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: "none",
  border: 0,
  outline: 'none',
  borderRadius: 12,
  pointerEvents:'all'
}));

interface Props extends ModalProps {
  width?: string | number;
  disableOutSideClick?: boolean;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hideCloseIcon?: boolean;
}

const BasicModal: React.FC<Props> = ({
  open,
  onClose,
  width = 500,
  disableOutSideClick = false,
  hideCloseIcon = false,
  children,
  ...rest
}) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        {...rest}
      >
        <Grow in={open}>
          <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%", pointerEvents: "none" }}>
            <PaperStyle sx={{ minWidth: width }}>
              {!hideCloseIcon && (
                <IconButton color='error' onClick={onClose} sx={{ position: "absolute", top: "10px", right: "10px",zIndex:99 }}>
                  <Close />
                </IconButton>
              )}

              <Box>{children}</Box>
            </PaperStyle>
          </Stack>
        </Grow>
      </Modal>
    </React.Fragment>
  );
};

export default BasicModal;
