import React from "react";
import { styled } from "@mui/material";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";

interface CustomTooltipProps {
  backgroundColor?: string;
}

interface Props extends TooltipProps {
  className?: string;
}

const CustomTooltip = styled(({ className, ...props }: Props) => (
  <Tooltip {...props} classes={{ popper: className }} />
))<CustomTooltipProps>(({ backgroundColor = "#15008A",theme, title }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: title === "Delete" ? theme.palette.error.dark : backgroundColor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: title === "Delete" ? theme.palette.error.dark : backgroundColor,
    fontSize: 11,
  },
}));

export default CustomTooltip;
