import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminWebsiteSettingI, UpdateAdminWebsiteSettingI } from "../../../interface/adminWebsiteSetting.interface";

const initialState = {
  data: {} as AdminWebsiteSettingI,
  isLoading: false,
  isError: false,
  error: "",

  isUpdating: false,

  isUpdated: false,
};

const adminWebsiteSetting = createSlice({
  name: "adminWebsiteSetting",
  initialState,
  reducers: {
    getAdminWebsiteSetting(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },

    getAdminWebsiteSettingSuccess(state, action: PayloadAction<AdminWebsiteSettingI>) {
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.data = action.payload;
    },

    getAdminWebsiteSettingFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateAdminWebsiteSetting(state, action: PayloadAction<UpdateAdminWebsiteSettingI>) {
      state.isUpdating = true;
    },
    updateAdminWebsiteSettingSuccess(state, action: PayloadAction<AdminWebsiteSettingI>) {
      state.isUpdating = false;
      state.isUpdated = true;
      state.data = action.payload;
    },
    resetAdminWebsiteSetting(state) {
      state.isUpdating = false;
      state.isUpdated = false;
    },

    resetUpdateSettingStatus(state) {
      state.isUpdated = false;
    },
  },
});

export const {
  getAdminWebsiteSetting,
  getAdminWebsiteSettingFailed,
  getAdminWebsiteSettingSuccess,

  updateAdminWebsiteSetting,
  resetAdminWebsiteSetting,
  updateAdminWebsiteSettingSuccess,
  resetUpdateSettingStatus
} = adminWebsiteSetting.actions;

export default adminWebsiteSetting.reducer;
