import React from "react";
import {ListsWrapper} from "../../SitesList/SitesListStyles";
import AdminSectionListFilterHeader from "./AdminSectionListFilterHeader";
import AdminSectionLibraryLists from "./AdminSectionLibraryLists";

const AdminSectionLibrary: React.FC = () => {
  return (
    <ListsWrapper p={2}>
      <AdminSectionListFilterHeader />
      <AdminSectionLibraryLists />
    </ListsWrapper>
  );
};

export default AdminSectionLibrary;
