import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select } from "typed-redux-saga";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  addFunnelStepSuccess,
  cloneFunnelStepFailed,
  cloneFunnelStepSuccess,
  deleteFunnelStepSuccess,
  resetGetFunnelSteps,
  getFunnelStepsSuccess,
  reorderFunnelStepSuccess,
  resetAddFunnelStepSuccess,
  resetDeleteFunnelStepSuccess,
  resetReorderFunnelStepSuccess,
  resetUpdateFunnelStepSuccess,
  updateFunnelStepSuccess,
} from "./funnelStepSlice";
import {
  FunnelStepInterface,
  ICloneFunnelStepPayload,
  IDeleteFunnelStepPayload,
  IGetFunnelStepsPayload,
  IReorderFunnelStepPayload,
  IUpdateFunnelStepPayload,
} from "./funnelStep.interface";
import FunnelStepService from "../../../services/funnelStepService";
import { getFunnelInfo } from "../funnelInfo/funnelInfoSlice";

function* funnelStepWatcher() {
  yield takeEvery("funnelStep/getFunnelSteps", getFunnelStepsSaga);
  yield takeEvery("funnelStep/addFunnelStep", addFunnelStepSaga);
  yield takeEvery("funnelStep/updateFunnelStep", updateFunnelStepSaga);
  yield takeEvery("funnelStep/reorderFunnelStep", reorderFunnelStepSaga);
  yield takeEvery("funnelStep/cloneFunnelStep", cloneFunnelStepSaga);
  yield takeEvery("funnelStep/deleteFunnelStep", deleteFunnelStepSaga);
}

function* getFunnelStepsSaga(action: PayloadAction<IGetFunnelStepsPayload>) {
  try {
    const response = yield* call(FunnelStepService.getFunnelSteps, action.payload);

    if (response.success) {
      yield put(getFunnelStepsSuccess(response.data));

      // if (response.data?.length > 0) {
      //   yield put(setSelectedFunnelStep(response.data[0]));
      // } else {
      //   yield put(setSelectedFunnelStep({} as FunnelStepInterface));
      // }
    } else {
      yield put(resetGetFunnelSteps());
    }
  } catch (err) {
    yield put(resetGetFunnelSteps());
  }
}

function* addFunnelStepSaga(action: PayloadAction<FunnelStepInterface>) {
  try {
    const response = yield* call(FunnelStepService.addFunnelStep, action.payload);

    if (response.success) {
      const { data: stepList } = yield select((state) => state.funnelStep);

      if (stepList?.length === 0) {
        yield put(getFunnelInfo({ id: action.payload.funnelId, loadingDisabled: true }));
      }

      yield put(addFunnelStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetAddFunnelStepSuccess());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetAddFunnelStepSuccess());
    showErrorToaster("Network or server error");
  }
}

function* updateFunnelStepSaga(action: PayloadAction<IUpdateFunnelStepPayload>) {
  try {
    const response = yield* call(FunnelStepService.updateFunnelStep, action.payload);

    if (response.success) {
      yield put(updateFunnelStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetUpdateFunnelStepSuccess());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetUpdateFunnelStepSuccess());
    showErrorToaster("Network or server error");
  }
}

function* reorderFunnelStepSaga(action: PayloadAction<IReorderFunnelStepPayload>) {
  try {
    const response = yield* call(FunnelStepService.reorderFunnelStep, action.payload);

    if (response.success) {
      yield put(reorderFunnelStepSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(resetReorderFunnelStepSuccess(action.payload));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetReorderFunnelStepSuccess(action.payload));
    showErrorToaster("Network or server error");
  }
}

function* deleteFunnelStepSaga(action: PayloadAction<IDeleteFunnelStepPayload>) {
  try {
    const response = yield* call(FunnelStepService.deleteFunnelStep, action.payload);

    if (response.success) {
      yield put(deleteFunnelStepSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetDeleteFunnelStepSuccess());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetDeleteFunnelStepSuccess());
    showErrorToaster("Network or server error");
  }
}

function* cloneFunnelStepSaga(action: PayloadAction<ICloneFunnelStepPayload>) {
  try {
    const response = yield* call(FunnelStepService.cloneFunnelStep, action.payload);

    if (response.success) {
      yield put(cloneFunnelStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(cloneFunnelStepFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(cloneFunnelStepFailed());
    showErrorToaster("Network or server error");
  }
}

export default function* funnelStepSaga() {
  yield all([funnelStepWatcher()]);
}
