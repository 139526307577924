import funnelApiSlice from "../../funnelApiSlice";
import { SuccessResponseIn } from "../../../interface/response.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  IDeclareWinPayload,
  SplitTestPageReportInterface,
  SplitTestSettingsInterface,
} from "./funnelStepPagesInterfaces";
import { getFunnelStepPages } from "./funnelStepPagesSlice";

export const funnelStepApiSlice = funnelApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSplitTestSettings: builder.query<SuccessResponseIn<SplitTestSettingsInterface>, { stepId: number }>({
      query: (params) => ({
        url: "funnel/step/split-test/settings/" + params.stepId,
        method: "GET",
      }),
      providesTags: ["SPLIT_TEST_SETTINGS"],
    }),

    updateSplitTestSettings: builder.mutation<
      SuccessResponseIn<SplitTestSettingsInterface>,
      SplitTestSettingsInterface
    >({
      query: (body) => ({
        url: "funnel/step/split-test/settings",
        method: "POST",
        body,
      }),
      async onQueryStarted({ stepId, hideToaster }, { dispatch, queryFulfilled }) {
        try {
          const { data: updateSettings } = await queryFulfilled;

          dispatch(
            funnelStepApiSlice.util.updateQueryData("getSplitTestSettings", { stepId: stepId }, (draft): any => {
              return Object.assign(draft, updateSettings);
            })
          );

          if(hideToaster) return;
          if (updateSettings.success) showSuccessToaster(updateSettings.message);
          else showErrorToaster(updateSettings.message);
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
    }),

    getSplitTestReport: builder.query<SuccessResponseIn<SplitTestPageReportInterface[]>, { stepId: number }>({
      query: (params) => ({
        url: "funnel/step/page/split-test/report/" + params.stepId,
        method: "GET",
      }),
      providesTags: ["SPLIT_TEST_REPORTS"],
    }),

    declareWinPage: builder.mutation<SuccessResponseIn<boolean>, IDeclareWinPayload>({
      query: (body) => ({
        url: "funnel/step/page/win",
        method: "POST",
        body: body,
      }),
      async onQueryStarted({ stepId, funnelId, ...rest }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedData } = await queryFulfilled;

          if (updatedData.success) {
            dispatch(getFunnelStepPages({ stepId: stepId, funnelId }));
          }
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
      invalidatesTags: ["SPLIT_TEST_SETTINGS", "SPLIT_TEST_REPORTS"],
    }),
  }),
});

export const {
  useGetSplitTestSettingsQuery,
  useUpdateSplitTestSettingsMutation,
  useGetSplitTestReportQuery,
  useDeclareWinPageMutation,
} = funnelStepApiSlice;
