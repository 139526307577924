import React from "react";
import { useDispatch } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Add } from "@mui/icons-material";
import CreateWebsiteModal from "./CreateWebsiteModal";
import TemplateLibrary from "../templateLibrary/TemplateLibrary";
import CoreButton from "../../../common/Button/CoreButton";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { addNewWebsiteInsideFolder } from "../../../state/features/website/websiteSlice";
import useBoolean from "../../../hooks/useBoolean";
import WebsiteFunnelSwitchingTabs from "../../WebsiteFunnelSwitchingTabs";
import { SiteListHeaderWrp } from "../SitesListStyles";

const WebsiteListHeader = () => {
  const dispatch = useDispatch();
  const {
    value: isOpenCreateWebsiteModal,
    setTrue: onOpenCreateWebsiteModal,
    setFalse: onCloseCreateWebsiteModal,
  } = useBoolean(false);
  const { value: openTemplateModal, setTrue: onOpenTemplateModal, setFalse: onCloseTemplateModal } = useBoolean(false);

  const handleOpenNewWebsite = () => {
    onOpenCreateWebsiteModal();
    dispatch(addNewWebsiteInsideFolder({ status: false, folderId: 0 }));
  };

  return (
    <>
      <SiteListHeaderWrp>
        <Stack direction={"row"} alignItems={"center"} gap={2.5}>
          <Box width={268}>
            <Typography variant={"h5"} color={"text.primary"}>
              My Sites
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              Manage all your websites in one spot.
            </Typography>
          </Box>
          <WebsiteFunnelSwitchingTabs
            firstPath={"/wbuilder/sites"}
            secondPath={"/wbuilder/funnels"}
            firstTitle={"Websites"}
            secondTitle={"Funnels"}
          />
        </Stack>

        <Stack direction={"row"} spacing={1} alignSelf={"flex-end"}>
          <CoreButton
            color={"secondary"}
            variant={"outlined"}
            size={"large"}
            startIcon={<SettingsIcon />}
            href={"/wbuilder/settings"}
          >
            Settings
          </CoreButton>

          <CoreButton
            color={"secondary"}
            size={"large"}
            variant='contained'
            startIcon={<Add />}
            onClick={handleOpenNewWebsite}
            sx={{width:200}}
          >
            Create Website
          </CoreButton>
        </Stack>
      </SiteListHeaderWrp>

      <BasicModalWithHeader
        title='Create a New Website'
        open={isOpenCreateWebsiteModal}
        onClose={onCloseCreateWebsiteModal}
        width={850}
      >
        <CreateWebsiteModal onClose={onCloseCreateWebsiteModal} onOpenTemplateModal={onOpenTemplateModal} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        width={"98%"}
        title={"Template Library"}
        disableOutSideClick={true}
        open={openTemplateModal}
        onClose={onCloseTemplateModal}
      >
        <TemplateLibrary />
      </BasicModalWithHeader>
    </>
  );
};

export default WebsiteListHeader;
