import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const AddPageIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 100 100'>
      <path
        d='M87.5938 18.1875V29.1875H6.25V18.1875C6.25 13.3438 10.1875 9.375 15.0625 9.375H78.7812C83.6562 9.375 87.5938 13.3438 87.5938 18.1875Z'
        fill='#E5EBFF'
      />
      <path
        d='M87.5938 29.1875V70.25C87.5938 75.125 83.6562 79.0625 78.7812 79.0625H15.0938C10.2188 79.0625 6.25 75.125 6.25 70.2187V29.1875H87.5938Z'
        fill='white'
      />
      <path
        d='M50.5 76.2532H15.0937C11.7687 76.2532 9.0625 73.55 9.0625 70.2219V32H84.7812V49.0032C84.7812 50.5563 86.0406 51.8157 87.5937 51.8157C89.1469 51.8157 90.4062 50.5563 90.4062 49.0032V18.1907C90.4062 11.7813 85.1937 6.56567 78.7812 6.56567H15.0625C8.65313 6.56567 3.4375 11.7782 3.4375 18.1907V70.2219C3.4375 76.6501 8.66563 81.8782 15.0937 81.8782H50.5C52.0531 81.8782 53.3125 80.6188 53.3125 79.0657C53.3125 77.5126 52.0531 76.2532 50.5 76.2532ZM15.0625 12.1907H78.7812C82.0906 12.1907 84.7812 14.8813 84.7812 18.1907V26.375H9.0625V18.1907C9.0625 14.8844 11.7531 12.1907 15.0625 12.1907Z'
        fill='#E5EBFF'
      />
      <path
        d='M18.7186 21.775C20.1062 21.775 21.2311 20.6501 21.2311 19.2625C21.2311 17.8749 20.1062 16.75 18.7186 16.75C17.3309 16.75 16.2061 17.8749 16.2061 19.2625C16.2061 20.6501 17.3309 21.775 18.7186 21.775Z'
        fill='#346FEF'
      />
      <path
        d='M27.5687 21.775C28.9563 21.775 30.0812 20.6501 30.0812 19.2625C30.0812 17.8749 28.9563 16.75 27.5687 16.75C26.181 16.75 25.0562 17.8749 25.0562 19.2625C25.0562 20.6501 26.181 21.775 27.5687 21.775Z'
        fill='#346FEF'
      />
      <path
        d='M36.4187 21.775C37.8064 21.775 38.9312 20.6501 38.9312 19.2625C38.9312 17.8749 37.8064 16.75 36.4187 16.75C35.0311 16.75 33.9062 17.8749 33.9062 19.2625C33.9062 20.6501 35.0311 21.775 36.4187 21.775Z'
        fill='#346FEF'
      />
      <path
        d='M76.562 53.1252C72.8536 53.1252 69.2285 54.2249 66.1451 56.2852C63.0617 58.3455 60.6584 61.2738 59.2393 64.6999C57.8201 68.126 57.4488 71.896 58.1723 75.5332C58.8958 79.1703 60.6815 82.5113 63.3038 85.1335C65.926 87.7557 69.2669 89.5415 72.9041 90.265C76.5412 90.9884 80.3112 90.6171 83.7373 89.198C87.1634 87.7788 90.0918 85.3756 92.1521 82.2922C94.2123 79.2088 95.312 75.5836 95.312 71.8752C95.3061 66.9042 93.3288 62.1385 89.8138 58.6235C86.2988 55.1084 81.533 53.1311 76.562 53.1252ZM85.0847 73.5798H78.2666V80.398C78.2666 80.85 78.087 81.2836 77.7673 81.6033C77.4476 81.9229 77.0141 82.1025 76.562 82.1025C76.1099 82.1025 75.6764 81.9229 75.3567 81.6033C75.0371 81.2836 74.8575 80.85 74.8575 80.398V73.5798H68.0393C67.5872 73.5798 67.1537 73.4002 66.834 73.0805C66.5143 72.7609 66.3347 72.3273 66.3347 71.8752C66.3347 71.4232 66.5143 70.9896 66.834 70.6699C67.1537 70.3503 67.5872 70.1707 68.0393 70.1707H74.8575V63.3525C74.8575 62.9004 75.0371 62.4669 75.3567 62.1472C75.6764 61.8276 76.1099 61.648 76.562 61.648C77.0141 61.648 77.4476 61.8276 77.7673 62.1472C78.087 62.4669 78.2666 62.9004 78.2666 63.3525V70.1707H85.0847C85.5368 70.1707 85.9704 70.3503 86.29 70.6699C86.6097 70.9896 86.7893 71.4232 86.7893 71.8752C86.7893 72.3273 86.6097 72.7609 86.29 73.0805C85.9704 73.4002 85.5368 73.5798 85.0847 73.5798Z'
        fill='#346FEF'
      />
    </SvgIcon>
  );
};

export default AddPageIcon;
