import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetUrlRedirectPayload,
  IRemoveUrlRedirectPayload,
  IUpdateUrlRedirect,
  IUrlRedirect,
} from "./redirect.interface";
import { WithPagination } from "../../../interface/response.interface";
import eachRedirect from "../../../components/SingleWebsiteProperty/urlRedirects/EachRedirect";

const initialState = {
  data: [] as IUrlRedirect[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  currentPage: 1,
  limit: 10,
  nextPage: 1,
  totalPages: 1,

  renderId: "",

  isAdding: false,
  isAddSuccess: false,
  addedRedirectedData: {} as IUrlRedirect,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,
}

const redirectSlice = createSlice({
  name: "urlRedirect",
  initialState,
  reducers: {
    getUrlRedirect: (state, _action: PayloadAction<IGetUrlRedirectPayload>) => {
      state.isLoading = true;
    },
    getUrlRedirectSuccess: (state, action: PayloadAction<WithPagination<IUrlRedirect>>) => {
      const { content, count, limit, currentPage } = action.payload;
      state.data = content;
      state.count = count;
      state.limit = +limit;
      state.currentPage = currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getUrlRedirectFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    addUrlRedirect: (state, _action: PayloadAction<IUrlRedirect>) => {
      state.isAdding = true;
    },
    addUrlRedirectSuccess: (state, action: PayloadAction<IUrlRedirect>) => {
      state.isAdding = true;
      state.data.push(action.payload);
      state.isAdding = false;
      state.isAddSuccess = true;
    },
    addUrlRedirectFailed: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },
    deleteUrlRedirect: (state, action: PayloadAction<IRemoveUrlRedirectPayload>) => {
      state.isDeleting = true;
    },
    deleteUrlRedirectSuccess: (state, action: PayloadAction<IRemoveUrlRedirectPayload>) => {
      if (action.payload) {
        state.data = state.data.filter((url) => !action.payload.id.includes(url.id as string) );
      }
      state.isDeleting = false;
      // state.renderId = nanoid(5);
      console.log(action.payload.id+"-action");

    },
    deleteUrlRedirectFailed: (state) => {
      state.isDeleting = false;
    },
    updateUrlRedirect:(state,action:PayloadAction<IUpdateUrlRedirect>)=>{
      state.isUpdating = true;
    },
    updateUrlRedirectSuccess:(state,action:PayloadAction<IUpdateUrlRedirect>)=>{
      state.isAdding = false;
      state.isUpdateSuccess = true;
      // if (action.payload){
        state.data = state.data.map((redirect)=>{
          console.log(redirect.id);
          console.log(action.payload.id);
          if(redirect.id === action.payload.id){
            console.log("kjfhksfjk");
            return { ...redirect,...action.payload}
          }
          return redirect;
        })
      // }

    }
  }
})

export const {getUrlRedirect,
  getUrlRedirectSuccess,
  getUrlRedirectFailed,
  addUrlRedirect,
  addUrlRedirectSuccess,
  deleteUrlRedirect,
  deleteUrlRedirectSuccess,
  updateUrlRedirectSuccess
} = redirectSlice.actions;
export default redirectSlice.reducer;