import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import WebsiteSettings from "./website-setting.interface";

const initialState = {
  data: {} as WebsiteSettings,
  isLoading: false,
  isError: false,
  error: "",

  isUpdating: false,
  isUpdateSuccess: false,
};

const websiteSlice = createSlice({
  name: "webSiteSetting",
  initialState,
  reducers: {
    getWebsiteSetting(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },

    getWebsiteSettingSuccess(state, action: PayloadAction<WebsiteSettings>) {
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.data = action.payload;
    },

    getWebsiteSettingFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateWebsiteSetting(state, action: PayloadAction<WebsiteSettings>) {
      state.isUpdating = true;
    },

    updateWebsiteSettingSuccess(state, action: PayloadAction<WebsiteSettings>) {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.data = { ...state.data, ...action.payload };
    },

    resetUpdateWebsiteSetting(state) {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },
  },
});

export const {
  getWebsiteSetting,
  getWebsiteSettingFailed,
  getWebsiteSettingSuccess,

  updateWebsiteSetting,
  resetUpdateWebsiteSetting,
  updateWebsiteSettingSuccess,
} = websiteSlice.actions;

export default websiteSlice.reducer;
