import React from "react";
import { AppDispatch } from "../../state/store";
import { useDispatch } from "react-redux";
import { Box, Tab } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import { FolderChipListTabs, StyledChip } from "./SitesListStyles";
import { removeFolderFromWebsite } from "../../state/features/website/websiteSlice";

interface IProps {
  websiteId: number;
  websiteFolderList: {
    folderId: number;
    name: string;
    color: string;
  }[];
}

const FolderNameChipList: React.FC<IProps> = ({ websiteId, websiteFolderList = [] }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };

  const handleDelete = (folderId: number) => {
    dispatch(
      removeFolderFromWebsite({
        folderId,
        websiteId,
      })
    );
  };

  return (
    <Box
      sx={{
        flex: 1,
        width: 0,
        position: "relative",
      }}
    >
      <FolderChipListTabs
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
        aria-label='scrollable force tabs example'
        onChange={handleChange}
        value={value}
      >
        {websiteFolderList.map((singleFolder, index) => (
          <Tab
            key={index}
            component={"div"}
            sx={{ padding: 0, borderRadius: "16px", minWidth: "max-content" }}
            label={
              <StyledChip
                size={"small"}
                icon={<FolderIcon />}
                label={singleFolder.name}
                chipColor={singleFolder.color || "#676767"}
                onDelete={() => handleDelete(singleFolder.folderId)}
              />
            }
          />
        ))}
      </FolderChipListTabs>
    </Box>
  );
};

export default FolderNameChipList;
