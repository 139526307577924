import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import FunnelRouteBlock from "./FunnelRouteBlock";
import { extractFunnelPageToken } from "../../../helpers/utils/extractFunnelPageToken";

const FunnelEditorPage: React.FC<{ isAdmin?: boolean }> = ({ isAdmin = false }) => {
  const [iframeElement, setIframeElement] = useState<HTMLIFrameElement>();
  const { token } = useParams<{ token: string }>();
  const funnelId = extractFunnelPageToken(token)?.funnelId;
  const stepId = extractFunnelPageToken(token)?.stepId;

  return (
    <>
      <FunnelRouteBlock iframeElement={iframeElement} funnelId={funnelId} stepId={stepId} isAdmin={isAdmin} />
      <Box
        ref={(el) => setIframeElement(el as HTMLIFrameElement)}
        component='iframe'
        sx={{ width: "100%", height: "100vh", border: "none", position: "absolute" }}
        allow='clipboard-read; clipboard-write'
        src={`${process.env.REACT_APP_WEBSITE_EDITOR_URL}/builder/${token}`}
      />
    </>
  );
};

export default FunnelEditorPage;
