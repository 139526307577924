import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreTextField from "../../common/TextField/CoreTextField";
import { registerPatternTemplateSettings } from "../../helpers/constant/registerPattern";
import { renameTemplate, resetRenameTemplateSuccess } from "../../state/features/funnelTemplate/funnelTemplateSlice";
import { selectFunnelTemplateState } from "../../state/features/funnelTemplate/funnelTemplateSelector";

interface UpdateTemplateName {
  name: string;
  id: string;
}

interface Props {
  onClose: () => void;
  template: UpdateTemplateName;
}

const AdminFunnelTemplateRename: React.FC<Props> = ({ onClose, template }) => {
  const dispatch = useDispatch();
  const { isRenaming, isRenameSuccess } = useSelector(selectFunnelTemplateState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateTemplateName>();

  useEffect(() => {
    if (template && template.id) {
      setValue("name", template.name);
      setValue("id", template.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRenameSuccess) {
      dispatch(resetRenameTemplateSuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRenameSuccess]);

  const onSubmit = (data: UpdateTemplateName) => {
    dispatch(renameTemplate({ funnelId: data.id, name: data.name }));
  };

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
        <CoreTextField
          label='Template Name'
          required
          sx={{ mt: 0 }}
          type='text'
          size='small'
          color='secondary'
          placeholder='Enter name here'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternTemplateSettings.name)}
        />
      </FormControl>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton isLoading={isRenaming} loadingText={"Renaming..."} type='submit' size='large' color='success'>
          Rename
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AdminFunnelTemplateRename;
