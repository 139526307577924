import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  IAddWebsitePayload,
  IAddWebsitesToFoldersPayload,
  IArchiveStatusPayload,
  ICloneWebsitePayload,
  ICreateWebsiteByTemplatePayload,
  ICreateWebsiteByTemplateResponse,
  IDeleteForeverPayload,
  IFavoriteStatusPayload,
  IGetWebsitesPayload,
  IRemoveFolderFromWebsitePayload,
  IRenameWebsitePayload,
  IWebsite,
  IWebsiteRemovePayload,
} from "../state/features/website/website.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!"); //TODO - error message showing invoice instead of website builder

class WebsiteService {
  getWebsites(params: IGetWebsitesPayload) {
    return httpRequest.get<WithPagination<IWebsite>>(websiteBuilderAPI + "website/list", params);
  }

  addWebsite(params: IAddWebsitePayload) {
    return httpRequest.post<IWebsite>(websiteBuilderAPI + "website/create", params);
  }

  createWebsiteByTemplate(params: ICreateWebsiteByTemplatePayload) {
    return httpRequest.post<ICreateWebsiteByTemplateResponse>(websiteBuilderAPI + "website/create/by/template", params);
  }

  renamedWebsite(params: IRenameWebsitePayload) {
    return httpRequest.put<IRenameWebsitePayload>(websiteBuilderAPI + "website/rename", params);
  }

  cloneWebsite(body: ICloneWebsitePayload) {
    return httpRequest.post<ICloneWebsitePayload>(websiteBuilderAPI + "website/clone", body);
  }

  addWebsitesToFolders(params: IAddWebsitesToFoldersPayload) {
    return httpRequest.post<IAddWebsitesToFoldersPayload>(websiteBuilderAPI + "website/folder/add", params);
  }

  removeFolderFromWebsite(params: IRemoveFolderFromWebsitePayload) {
    return httpRequest.delete<IRemoveFolderFromWebsitePayload>(
      websiteBuilderAPI + "website/folder/remove",
      undefined,
      params
    );
  }

  toggleWebsiteFavStatus(params: IFavoriteStatusPayload) {
    return httpRequest.put<IFavoriteStatusPayload>(websiteBuilderAPI + "website/favorite", params);
  }

  toggleArchiveStatus(params: IArchiveStatusPayload) {
    return httpRequest.put<IArchiveStatusPayload>(websiteBuilderAPI + "website/archive", params);
  }

  deleteWebsite(params: IWebsiteRemovePayload) {
    return httpRequest.put<IWebsiteRemovePayload>(websiteBuilderAPI + "website/trash", params);
  }

  deleteWebsiteForever(params: IDeleteForeverPayload) {
    return httpRequest.delete<IDeleteForeverPayload>(websiteBuilderAPI + "website/delete", undefined, params);
  }
}

const webSiteService = new WebsiteService();
export default webSiteService;
