import httpRequest from "./api/httpRequest";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class FileUploadService {
  uploadFileByUser(params: FormData) {
    return httpRequest.post<{ url: string }>(websiteBuilderAPI + "website/file/upload", params);
  }

  uploadFileByAdmin(params: FormData) {
    return httpRequest.post<{ url: string }>(websiteBuilderAPI + "admin/website/file/upload", params);
  }
}

const fileUploadService = new FileUploadService();

export default fileUploadService;
