import React from "react";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

const RedirectSkeleton = ({ count = 1 }: { count: number }) => {
  return (
    <TableBody>
      {Array.from({ length: count }).map((_rowNum, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton animation={"wave"} width={200} />
          </TableCell>
          <TableCell>
            <Skeleton animation={"wave"} width={80} />
          </TableCell>
          <TableCell>
            <Skeleton animation={"wave"} width={200} />
          </TableCell>
          <TableCell>
            <Skeleton animation={"wave"} width={100} />
          </TableCell>
          <TableCell align={"right"}>
            <Skeleton variant={"circular"} animation={"wave"} width={40} height={40} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default RedirectSkeleton;
