import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import typeService from "../../../services/type.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  createTypeFailed,
  createTypeSuccess,
  deleteTypeFailed,
  deleteTypeSuccess,
  fetchTypeFailed,
  fetchTypeSuccess,
  updateTypeFailed,
  updateTypeSuccess,
} from "./typeSlice";
import { CreateTypeI, UpdateTypeI } from "../../../interface/type.interface";

function* typeWatcher() {
  yield takeEvery("type/fetchType", fetchTypeSaga);
  yield takeEvery("type/createType", createTypeSaga);
  yield takeEvery("type/updateType", updateTypeSaga);
  yield takeEvery("type/deleteType", deleteTypeSaga);
}

function* fetchTypeSaga() {
  try {
    const response = yield* call(typeService.fetchType);

    if (response.success) {
      yield put(fetchTypeSuccess(response.data));
    } else {
      yield put(fetchTypeFailed());
    }
  } catch (err) {
    yield put(fetchTypeFailed());
  }
}

function* createTypeSaga(action: PayloadAction<CreateTypeI>) {
  try {
    const response = yield* call(typeService.createType, action.payload);

    if (response.success) {
      yield put(createTypeSuccess(response.data));
      showSuccessToaster("Type created");
    } else {
      yield put(createTypeFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createTypeFailed());
  }
}

function* updateTypeSaga(action: PayloadAction<UpdateTypeI>) {
  try {
    const response = yield* call(typeService.updateType, action.payload);

    if (response.success) {
      yield put(updateTypeSuccess(response.data));
      showSuccessToaster("Type Updated");
    } else {
      yield put(updateTypeFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(updateTypeFailed());
  }
}

function* deleteTypeSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(typeService.deleteType, action.payload);

    if (response.success) {
      yield put(deleteTypeSuccess(action.payload));
      showSuccessToaster("Type deleted");
    } else {
      yield put(deleteTypeFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteTypeFailed());
  }
}

export default function* typeSaga() {
  yield all([typeWatcher()]);
}
