import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  CloneFunnelTemplateI,
  CreateFunnelTemplateI,
  DeleteFunnelTemplateI,
  FetchFunnelTemplatesPayloadI,
  FunnelTemplateI,
  InTrashFunnelTemplateI,
  UpdateFunnelApproveStatusI,
  RenameFunnelTemplateI,
  UpdateFunnelPublishStatusI,
  CreateFunnelByTemplatePayloadI
} from "../interface/funnel-template.interface";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Invoice API URL not found in env!");

class FunnelTemplateService {
  fetchFunnelTemplate(params: FetchFunnelTemplatesPayloadI) {
    return httpRequest.get<WithPagination<FunnelTemplateI>>(funnelBuilderAPI + "admin/funnel/list", params);
  }

  createFunnelTemplate(payload: CreateFunnelTemplateI) {
    return httpRequest.post<FunnelTemplateI>(funnelBuilderAPI + "admin/funnel/create", payload);
  }

  renameTemplate(payload: RenameFunnelTemplateI) {
    return httpRequest.put(funnelBuilderAPI + "admin/funnel/setting/update/" + payload.funnelId, payload);
  }

  deleteFunnelTemplate(payload: DeleteFunnelTemplateI) {
    return httpRequest.delete<FunnelTemplateI>(funnelBuilderAPI + "admin/funnel/delete", "", payload);
  }

  trashFunnelTemplate(payload: InTrashFunnelTemplateI) {
    return httpRequest.put<InTrashFunnelTemplateI>(funnelBuilderAPI + "admin/funnel/trash", payload);
  }

  updatePublishTemplateStatus(payload: UpdateFunnelPublishStatusI) {
    return httpRequest.put<UpdateFunnelPublishStatusI>(funnelBuilderAPI + "admin/funnel/publish", payload);
  }

  updateTemplateApproveStatusByAdmin(payload: UpdateFunnelApproveStatusI) {
    return httpRequest.put<UpdateFunnelApproveStatusI>(funnelBuilderAPI + "admin/funnel/approve", payload);
  }

  cloneTemplate(payload: CloneFunnelTemplateI) {
    return httpRequest.post<FunnelTemplateI>(funnelBuilderAPI + "admin/funnel/clone", payload);
  }

  createFunnelByTemplate(payload: CreateFunnelByTemplatePayloadI) {
    return httpRequest.post<FunnelTemplateI>(funnelBuilderAPI + "/funnel/create/by/template", payload);
  }
}

const funnelTemplateService = new FunnelTemplateService();
export default funnelTemplateService;
