import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import {
  AdminTemplateI,
  CloneAdminTemplateI,
  DeleteAdminTemplateI,
  FetchTemplatesPayloadI,
  InTrashAdminTemplateI,
  RenameAdminTemplateI,
  UpdateApproveStatusI,
  UpdatePublishStatusI,
} from "../../../interface/website-template.interface";

import {
  cloneTemplateFailed,
  cloneTemplateSuccess,
  createTemplateFailed,
  createTemplateSuccess,
  deleteTemplatesSuccess,
  fetchTemplateFailed,
  fetchTemplateSuccess,
  renameTemplateSuccess,
  resetDeleteTemplates,
  resetRenameTemplateSuccess,
  toggleApproveStatusByAdminFailed,
  toggleApproveStatusByAdminSuccess,
  togglePublishStatusFailed,
  togglePublishStatusSuccess,
  trashTemplatesFailed,
  trashTemplatesSuccess,
} from "./adminTemplateSlice";
import adminTemplateService from "../../../services/adminTemplate";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import AdminWebsiteSettingService from "../../../services/adminWebsiteSetting.service";

function* templateWatcher() {
  yield takeEvery("adminTemplate/fetchTemplate", fetchTemplateSaga);
  yield takeEvery("adminTemplate/createTemplate", createTemplateSaga);
  yield takeEvery("adminTemplate/renameTemplate", renameTemplateSaga);
  yield takeEvery("adminTemplate/deleteTemplates", deleteTemplateSaga);
  yield takeEvery("adminTemplate/trashTemplates", trashTemplateSaga);
  yield takeEvery("adminTemplate/togglePublishStatus", toggleTemplatePublishSaga);
  yield takeEvery("adminTemplate/toggleApproveStatusByAdmin", toggleTemplateApproveByAdminSaga);
  yield takeEvery("adminTemplate/cloneTemplate", cloneTemplateSaga);
}

function* fetchTemplateSaga(action: PayloadAction<FetchTemplatesPayloadI>) {
  try {
    const response = yield* call(adminTemplateService.fetchWebSiteTemplate, action.payload);
    if (response.success) {
      yield put(fetchTemplateSuccess(response.data));
    } else {
      yield put(fetchTemplateFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* createTemplateSaga(action: PayloadAction<AdminTemplateI>) {
  try {
    const response = yield* call(adminTemplateService.createWebsiteTemplate, action.payload);
    if (response.success) {
      yield put(createTemplateSuccess(response.data));
    } else {
      yield put(createTemplateFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* renameTemplateSaga(action: PayloadAction<RenameAdminTemplateI>) {
  try {
    const response = yield* call(adminTemplateService.renameTemplate, action.payload);
    if (response.success) {
      yield put(renameTemplateSuccess(action.payload));
      showSuccessToaster("Renamed template successfully.");
    } else {
      yield put(resetRenameTemplateSuccess());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* deleteTemplateSaga(action: PayloadAction<DeleteAdminTemplateI>) {
  try {
    const response = yield* call(adminTemplateService.deleteWebSiteTemplate, action.payload);
    if (response.success) {
      yield put(deleteTemplatesSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetDeleteTemplates());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* trashTemplateSaga(action: PayloadAction<InTrashAdminTemplateI>) {
  try {
    const response = yield* call(adminTemplateService.trashWebSiteTemplate, action.payload);
    if (response.success) {
      yield put(trashTemplatesSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(trashTemplatesFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* toggleTemplatePublishSaga(action: PayloadAction<UpdatePublishStatusI>) {
  try {
    const response = yield* call(adminTemplateService.updatePublishTemplateStatus, action.payload);
    if (response.success) {
      yield put(togglePublishStatusSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(togglePublishStatusFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* toggleTemplateApproveByAdminSaga(action: PayloadAction<UpdateApproveStatusI>) {
  try {
    const response = yield* call(adminTemplateService.updateTemplateApproveStatusByAdmin, action.payload);
    if (response.success) {
      yield put(toggleApproveStatusByAdminSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(toggleApproveStatusByAdminFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

function* cloneTemplateSaga(action: PayloadAction<CloneAdminTemplateI>) {
  try {
    const response = yield* call(adminTemplateService.cloneTemplate, action.payload);

    const websiteDetails = yield* call(AdminWebsiteSettingService.getAdminWebsiteSetting, action.payload.websiteId);

    if (response.success) {
      const template = {
        ...response.data,
      };
      if (websiteDetails.success) template.defaultPageId = websiteDetails.data.defaultPageId;
      template.createdAt = new Date().toJSON();
      yield put(cloneTemplateSuccess(template));
      showSuccessToaster(response.message);
    } else {
      yield put(cloneTemplateFailed());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* adminTemplateSaga() {
  yield all([templateWatcher()]);
}
