import React from "react";
import { Box, Divider, Paper, Radio, Stack, styled, Typography } from "@mui/material";

interface CardWrapperInterface {
  selected?: boolean;
}

const CardWrapper = styled(Paper)<CardWrapperInterface>(({ theme, selected }) => ({
  width: "50%",
  cursor: "pointer",
  borderRadius: "8px",
  border: "2px solid",
  borderColor: selected ? theme.palette.secondary.main : "transparent",
  background: selected ? theme.palette.other.secondaryHover : theme.palette.background.paper,
  transition: "all 0.3s ease",
  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },
}));

interface OptionCardInterface {
  checked: boolean;
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  onClick: () => void;
}

const CreateWebsiteOptionCard: React.FC<OptionCardInterface> = ({ checked, onClick, title, subtitle, children }) => {
  return (
    <CardWrapper elevation={1} selected={checked} onClick={onClick}>
      <Stack
        direction={"row"}
        alignItems={"baseline"}
        justifyContent={"space-between"}
        px={3}
        py={2}
        sx={{ position: "relative" }}
      >
        <Box>
          <Typography variant={"subtitle1"} color={checked ? "secondary.main" : "text.primary"} fontWeight={500}>
            {title}
          </Typography>
          <Typography variant={"body2"} color={"text.secondary"} fontSize={12} fontWeight={400}>
            {subtitle}
          </Typography>
        </Box>
        <Radio size={"small"} color={"secondary"} checked={checked} sx={{ position: "absolute", top: 10, right: 10 }} />
      </Stack>
      <Divider light />
      <Box p={3} pb={4}>
        {children}
      </Box>
    </CardWrapper>
  );
};

export default CreateWebsiteOptionCard;
