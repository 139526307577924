import React from "react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

interface customLabelInterface {
  icon: React.ReactNode;
  title: string;
}

const CustomLabel: React.FC<customLabelInterface> = ({ icon, title }) => {
  return (
    <Stack direction={"row"} alignItems={"center"} alignSelf={"flex-start"} spacing={1}>
      {icon}
      <Typography>{title}</Typography>
    </Stack>
  );
};

interface Props {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const WebsitePageTabs: React.FC<Props> = ({ value, setValue }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor={"secondary"}
        indicatorColor={"secondary"}
        orientation='vertical'
        variant='scrollable'
        sx={{
          width: "280px",
          borderRight: 1,
          borderColor: "divider",
          height: "100%",
          "& .MuiTab-root": {
            fontSize: 18,
            "&.Mui-selected": { backgroundColor: "other.secondaryHover" },
            "&:focus": { backgroundColor: "other.secondaryHover !important" },
          },
        }}
      >
        <Tab label={<CustomLabel icon={<AutoStoriesOutlinedIcon />} title={"Pages"} />} />
        <Tab label={<CustomLabel icon={<SettingsOutlinedIcon />} title={"Settings"} />} />
        {/*<Tab label={<CustomLabel icon={<AssessmentOutlinedIcon />} title={"Analytics"} />} />*/}
      </Tabs>
    </Box>
  );
};

export default WebsitePageTabs;
