import React from "react";
import AdminPrivateLayout from "../Layouts/AdminPrivateLayout";
import { Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import Templates from "./Templates";
import Sections from "./sections/Sections";
import WebsiteFunnelSwitchingTabs from "../WebsiteFunnelSwitchingTabs";

const AdminIndex = () => {
  const [value, setValue] = React.useState(0);

  React.useLayoutEffect(() => {
    const tab = new URLSearchParams(window.location.search).get("tab");
    if (tab === "section") setValue(1);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AdminPrivateLayout>
      <>
        <Paper elevation={0}>
          <Stack direction={"row-reverse"} alignItems={"center"} justifyContent={"space-between"} px={2}>
            <Typography variant={"h5"} color={"secondary.main"}>
              Admin Panel
            </Typography>
            <WebsiteFunnelSwitchingTabs
              firstPath={"/wbuilder/admin"}
              secondPath={"/wbuilder/funnels/admin"}
              firstTitle={'Websites'}
              secondTitle={'Funnels'}
            />
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant={"body2"}>Templates:</Typography>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor={"secondary"}
                indicatorColor={"secondary"}
                sx={{
                  "& .MuiTab-root": {
                    fontSize: 18,
                  },
                }}
              >
                <Tab label='Pages' />
                <Tab label='Sections' />
              </Tabs>
            </Stack>
          </Stack>
        </Paper>
        {value === 0 && <Templates />}
        {value === 1 && <Sections />}
      </>
    </AdminPrivateLayout>
  );
};

export default AdminIndex;
