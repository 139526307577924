import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DomainI, {
  CreateDomainI,
  DeleteDomainI,
  DomainCredentialI,
  FetchDomainPayloadI,
  UpdateDomainI,
} from "../../../interface/domain.interface";
import { WithPagination } from "../../../interface/response.interface";

const initialState = {
  data: [] as DomainI[],
  count: 0,
  limit: 10,
  currentPage: 1,
  isLoading: false,
  isReady: false,

  domainCredential: {
    data: null as DomainCredentialI | null,
    isLoading: false,
    isSuccess: false,
  },

  isUpdating: false,
  isUpdatedSuccess: false,

  isCreating: false,
  isCreatedSuccess: false,

  isDeleting: false,
  isDeletedSuccess: false,
};

const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    fetchDomainList(state, action: PayloadAction<FetchDomainPayloadI>) {
      state.isLoading = true;
      state.isReady = true;
    },
    fetchDomainListSuccess(state, action: PayloadAction<WithPagination<DomainI>>) {
      state.data = action.payload.content;
      state.count = +action.payload.count;
      state.limit = +action.payload.limit;
      state.currentPage = action.payload.currentPage;
      state.isLoading = false;
    },
    fetchDomainListFailed(state) {
      state.data = [] as DomainI[];
      state.count = 0;
      state.limit = 10;
      state.currentPage = 1;
      state.isLoading = false;
    },

    fetchDomainCredential(state) {
      state.domainCredential.isLoading = true;
    },
    fetchDomainCredentialSuccess(state, action: PayloadAction<DomainCredentialI>) {
      state.domainCredential.data = action.payload;
      state.domainCredential.isLoading = false;
      state.domainCredential.isSuccess = true;
    },
    fetchDomainCredentialFailed(state) {
      state.domainCredential.data = null;
      state.domainCredential.isLoading = false;
      state.domainCredential.isSuccess = false;
    },

    createDomain(state, action: PayloadAction<CreateDomainI>) {
      state.isCreating = true;
    },
    createDomainSuccess(state, action: PayloadAction<DomainI>) {
      state.isCreating = false;
      state.data.push({ ...action.payload, updatedAt: new Date() });
      state.count += 1;
      state.isCreatedSuccess = true;
    },
    createDomainFailed(state) {
      state.isCreating = false;
    },

    updateDomain(state, action: PayloadAction<UpdateDomainI>) {
      state.isUpdating = true;
    },
    updateDomainSuccess(state, action: PayloadAction<UpdateDomainI>) {
      state.data = state.data.map((tag) => {
        if (tag.id === action.payload.id) {
          tag = { ...tag, ...action.payload, updatedAt: new Date() };
        }
        return tag;
      });
      state.isUpdating = false;
      state.isUpdatedSuccess = true;
    },
    updateDomainFailed(state) {
      state.isUpdating = false;
    },

    deleteDomain(state, action: PayloadAction<DeleteDomainI>) {
      state.isDeleting = true;
    },
    deleteDomainSuccess(state, action: PayloadAction<DeleteDomainI>) {
      state.data = state.data.filter((domain) => {
        return domain.id !== action.payload.id;
      });
      state.count -= 1;
      state.isDeleting = false;
      state.isDeletedSuccess = true;
    },
    deleteDomainFailed(state) {
      state.isDeleting = false;
    },

    resetCreateDomainSuccess(state) {
      state.isCreatedSuccess = false;
    },
    resetUpdateDomainSuccess(state) {
      state.isUpdatedSuccess = false;
    },
    showDomainCredential(state) {
      state.isUpdatedSuccess = true;
    },
  },
});

export const {
  fetchDomainList,
  fetchDomainListFailed,
  fetchDomainListSuccess,

  fetchDomainCredential,
  fetchDomainCredentialFailed,
  fetchDomainCredentialSuccess,

  createDomain,
  createDomainFailed,
  createDomainSuccess,

  updateDomain,
  updateDomainFailed,
  updateDomainSuccess,

  deleteDomain,
  deleteDomainFailed,
  deleteDomainSuccess,

  resetCreateDomainSuccess,
  resetUpdateDomainSuccess,
  showDomainCredential,
} = domainSlice.actions;
export default domainSlice.reducer;
