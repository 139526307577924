import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Container, FormHelperText, Grid, InputAdornment, Paper, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DomainI from "../../interface/domain.interface";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreTextField from "../../common/TextField/CoreTextField";
import FileUploadWithPreview from "../../common/FileUpload/FileUploadWithPreview";
import { showSuccessToaster } from "../../helpers/utils/toaster";
import copyFromClipBoard from "../../helpers/utils/copyFromClipBoard";
import { registerPatternFunnel } from "../../helpers/constant/registerPattern";
import { useGetAllDomainsQuery } from "../../state/features/domain/domainApiSlice";
import { FunnelInfoInterface, IUpdateFunnelInfo } from "../../state/features/funnelInfo/funnelInfoInterfaces";
import { selectFunnelInfoState } from "../../state/features/funnelInfo/funnelInfoSelector";
import { selectFunnelStepList } from "../../state/features/funnelStep/funnelStepSelector";
import { resetUpdateFunnelInfo, updateFunnelInfo } from "../../state/features/funnelInfo/funnelInfoSlice";

const FunnelSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { funnelId } = useParams<{ funnelId: string }>();
  const [copied, setCopied] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<FunnelInfoInterface>();

  const { data: funnelDetails, isUpdating, isUpdateSuccess } = useSelector(selectFunnelInfoState);
  const { data: domainState } = useGetAllDomainsQuery({});
  const { data: domainList = [] } = domainState || {};

  const funnelStepList = useSelector(selectFunnelStepList);

  useEffect(() => {
    if (funnelDetails?.id) {
      reset({ ...funnelDetails, domainId: funnelDetails?.domainId || "" });
    }
  }, [funnelDetails?.id]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(resetUpdateFunnelInfo());
    }
  }, [isUpdateSuccess]);

  const onSubmitHandler = (values: IUpdateFunnelInfo) => {
    const updatedValues: IUpdateFunnelInfo = {
      id: Number(funnelId),
      name: values.name,
      defaultStepId: values.defaultStepId,
      domainId: values.domainId ? values.domainId : null,
      faviconURL: values.faviconURL,
      headingTrackingCode: values.headingTrackingCode,
      bodyTrackingCode: values.bodyTrackingCode,
    };
    updatedValues.domainInfo = domainList?.find((domainInfo) => domainInfo.id === Number(values.domainId));

    dispatch(updateFunnelInfo(updatedValues));
  };

  const { subDomain, domainInfo } = funnelDetails || {};
  const sitemapUrl = `${domainInfo ? domainInfo.domain : subDomain}/sitemap.xml`;
  const handleCopy = () => {
    copyFromClipBoard(sitemapUrl);
    showSuccessToaster("Successfully copied sitemap");

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onSubmitHandler)} sx={{ p: 3 }}>
      <Container maxWidth='xl'>
        <Grid container spacing={3} columnSpacing={5} mb={3}>
          <Grid item xs={12} md={4}>
            <CoreTextField
              fullWidth
              required
              label='Funnel Name'
              color='secondary'
              error={!!(errors.name && errors.name.message)}
              helperText={errors.name && errors.name.message}
              {...register("name", registerPatternFunnel.name)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name='defaultStepId'
              defaultValue={""}
              rules={registerPatternFunnel.defaultStepId}
              render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                return (
                  <FormControl fullWidth required error={invalid}>
                    <InputLabel color='secondary' id='demo-simple-select-label'>
                      Default Step
                    </InputLabel>
                    <Select color='secondary' label='Domain' fullWidth value={value} onChange={onChange}>
                      <MenuItem disabled value=''>
                        Choose your default Home Page
                      </MenuItem>
                      {funnelStepList?.map((pageInfo) => (
                        <MenuItem key={pageInfo.id} value={pageInfo.id}>
                          {pageInfo.path}
                        </MenuItem>
                      ))}
                    </Select>
                    {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Controller
              control={control}
              name='domainId'
              defaultValue={""}
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControl fullWidth>
                    <InputLabel color='secondary' id='demo-simple-select-label'>
                      Domain
                    </InputLabel>
                    <Select
                      color='secondary'
                      label='Domain'
                      fullWidth
                      displayEmpty
                      value={value}
                      onChange={onChange}
                      MenuProps={{ style: { maxHeight: 250 } }}
                    >
                      <MenuItem value=''>None</MenuItem>
                      {domainList?.map((domainInfo) => (
                        <MenuItem key={domainInfo.id} value={domainInfo.id}>
                          {domainInfo.domain}
                        </MenuItem>
                      ))}
                    </Select>
                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                      <FormHelperText>
                        The domain for the Funnel - <Link to={"/wbuilder/settings"}>Add/Edit Domains</Link>
                      </FormHelperText>
                    </Stack>
                  </FormControl>
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <CoreTextField
              fullWidth
              multiline
              label='Head tracking code'
              color='secondary'
              minRows={6}
              maxRows={10}
              error={!!(errors.headingTrackingCode && errors.headingTrackingCode.message)}
              helperText={errors.headingTrackingCode && errors.headingTrackingCode.message}
              {...register("headingTrackingCode", registerPatternFunnel.headingTrackingCode)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CoreTextField
              fullWidth
              multiline
              label='Body tracking code'
              color='secondary'
              minRows={6}
              maxRows={10}
              error={!!(errors.bodyTrackingCode && errors.bodyTrackingCode.message)}
              helperText={errors.bodyTrackingCode && errors.bodyTrackingCode.message}
              {...register("bodyTrackingCode", registerPatternFunnel.bodyTrackingCode)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel error={!!(errors.faviconURL && errors.faviconURL.message)}>Favicon</InputLabel>
            <Paper variant={"outlined"} sx={{ borderColor: "other.outlinedBorderDarker", pl: 2 }}>
              <Controller
                control={control}
                name='faviconURL'
                rules={registerPatternFunnel.faviconURL}
                render={({ field: { value, onChange } }) => {
                  return (
                    <FileUploadWithPreview
                      fileUrl={value}
                      onChange={(fileUrl) => {
                        onChange(fileUrl);
                      }}
                      maxFileSizeMB={0.2}
                      infoText={`Click here to upload your favicon`}
                      maxFileInfoMessage={`File format must be .png or .ico. We recommend using .ico for best results.
Max size of 200kb`}
                      isAdmin={false}
                      specialImageFileRegex={/image\/png|image\/PNG|image\/vnd.microsoft.icon|image\/x-icon/g}
                    />
                  );
                }}
              />
            </Paper>
            <FormHelperText>{errors.faviconURL && errors.faviconURL.message}</FormHelperText>
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: "none" }}>
            <InputLabel color='secondary' id='demo-simple-select-label'>
              Sitemap
            </InputLabel>
            <TextField
              fullWidth
              disabled
              color={"secondary"}
              InputProps={{
                endAdornment: (
                  <InputAdornment sx={{ mr: 0 }} position='start'>
                    <CoreButton
                      variant={"contained"}
                      size={"small"}
                      sx={{ width: 100 }}
                      startIcon={copied ? <CheckCircleOutlineIcon /> : <ContentCopyIcon />}
                      onClick={handleCopy}
                    >
                      {copied ? "Copied" : "Copy"}
                    </CoreButton>
                  </InputAdornment>
                ),
              }}
              value={sitemapUrl}
            />
          </Grid>
        </Grid>
        <LoadingButton
          isLoading={isUpdating}
          loadingText={"Saving..."}
          type='submit'
          variant='contained'
          color='secondary'
        >
          Save
        </LoadingButton>
      </Container>
    </Box>
  );
};

export default FunnelSettings;
