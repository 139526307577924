import React from "react";
import { Skeleton } from "@mui/material";

const FunnelStepSkeleton: React.FC<{count?: number}> = ({ count = 1 }) => {
  return (
    <>
      {Array.from({ length: count }).map((rowNum, index) => (
        <Skeleton key={index} variant='rounded' sx={{ mt: 1 }} height={55} />
      ))}
    </>
  );
};

export default FunnelStepSkeleton;
