import httpRequest from "./api/httpRequest";
import {
  FunnelTemplateStepI,
  CreateFunnelTemplateStepPayloadI,
  UpdateFunnelTemplateStepPayloadI,
  DeleteFunnelTemplateStepPayloadI,
  CloneFunnelTemplateStepPayloadI,
  ReorderFunnelTemplateStepPayloadI
} from "../interface/funnelTemplateStep.interface";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Funnel API URL not found in env!");

class FunnelTemplateStepService {
  fetchFunnelTemplateSteps(params: string) {
    return httpRequest.get<FunnelTemplateStepI[]>(funnelBuilderAPI + "admin/funnel/step/all/" + params);
  }

  addFunnelTemplateStep(payload: CreateFunnelTemplateStepPayloadI) {
    return httpRequest.post<FunnelTemplateStepI>(funnelBuilderAPI + "admin/funnel/step/create", payload);
  }

  updateFunnelTemplateStep(payload: UpdateFunnelTemplateStepPayloadI) {
    return httpRequest.put<FunnelTemplateStepI>(funnelBuilderAPI + "admin/funnel/step/update", payload);
  }

  deleteFunnelTemplateStep(payload: DeleteFunnelTemplateStepPayloadI) {
    return httpRequest.delete<FunnelTemplateStepI>(funnelBuilderAPI + "admin/funnel/step/delete/" + payload.stepId);
  }

  cloneFunnelTemplateStep(payload: CloneFunnelTemplateStepPayloadI) {
    return httpRequest.post<FunnelTemplateStepI>(funnelBuilderAPI + "admin/funnel/step/clone", payload);
  }

  reorderFunnelTemplateStep(payload: ReorderFunnelTemplateStepPayloadI) {
    return httpRequest.post<FunnelTemplateStepI>(funnelBuilderAPI + "admin/funnel/step/reorder", payload);
  }
}

const funnelTemplateStepService = new FunnelTemplateStepService();
export default funnelTemplateStepService;
