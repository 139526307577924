import React, { useEffect } from "react";
import { format, isDate } from "date-fns";
import { TableCell, TableRow, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Delete, Edit } from "@mui/icons-material";
import AddOrEditRedirectModal from "./AddOrEditRedirectModal";
import useBoolean from "../../../hooks/useBoolean";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../../common/PopoverMenu/CustomPopoverMenu";
import IconButton from "../../../common/IconButton";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { RedirectUrlInterface } from "../../../state/features/redirectUrl/redirectUrl.interface";
import { useDeleteRedirectUrlMutation } from "../../../state/features/redirectUrl/redirectUrlApiSlice";

interface IProps {
  eachRedirect: RedirectUrlInterface;
}

const DestinationPreview = ({ eachRedirect }: IProps) => {
  const { redirectType, targetUrl, siteInfo, pageInfo, funnelInfo, stepInfo, targetDomain } = eachRedirect || {};

  let content: React.ReactNode = <>{"-"}</>;
  if (redirectType === "URL") {
    content = <>{targetUrl || "-"}</>;
  } else if (redirectType === "WEBSITE") {
    content = (
      <>
        <Typography variant={"body2"}>Website: {siteInfo?.name || "-"}</Typography>
        <Typography variant={"body2"}>Page: {pageInfo?.name || "-"}</Typography>
      </>
    );
  } else if (redirectType === "FUNNEL") {
    content = (
      <>
        <Typography variant={"body2"}>Funnel: {funnelInfo?.name || "-"}</Typography>
        <Typography variant={"body2"}>Step: {stepInfo?.name || "-"}</Typography>
      </>
    );
  } else if (redirectType === "ALL") {
    content = <>{targetDomain || "-"}</>;
  }

  return content;
};

const EachRedirect: React.FC<IProps> = ({ eachRedirect }) => {
  const { id, domain, path, createdAt } = eachRedirect;
  const formattedCreatedAt = isDate(new Date(createdAt)) ? format(new Date(createdAt), "MMM dd, yyyy") : "-";
  const [deleteRedirectUrl, { isLoading: isDeleting, isSuccess: isDeleteSuccess }] = useDeleteRedirectUrlMutation();

  const { value: openEditModal, setTrue: onOpenEditModal, setFalse: onCloseEditModal } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);

  useEffect(() => {
    if (isDeleteSuccess) {
      onCloseDeleteModal();
    }
  }, [isDeleteSuccess]);

  const handleDelete = () => {
    deleteRedirectUrl({ ids: [id] });
  };

  return (
    <>
      <TableRow key={id} sx={{ "& td": { wordBreak: "break-all" } }}>
        <TableCell>{domain}</TableCell>
        <TableCell>{path}</TableCell>
        <TableCell>
          <DestinationPreview eachRedirect={eachRedirect} />
        </TableCell>
        <TableCell>{formattedCreatedAt}</TableCell>
        <TableCell align={"right"}>
          <CustomPopoverMenu position={"bottom"}>
            <CustomPopoverMenuButton>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </CustomPopoverMenuButton>
            <CustomPopoverMenuItem
              title='Edit'
              icon={<Edit fontSize={"small"} color={"action"} />}
              onClickHide={true}
              onClick={onOpenEditModal}
            />
            <CustomPopoverMenuItem
              title='Delete'
              icon={<Delete fontSize={"small"} color={"action"} />}
              onClickHide={true}
              onClick={onOpenDeleteModal}
            />
          </CustomPopoverMenu>
        </TableCell>
      </TableRow>

      <BasicModalWithHeader width={500} title={"Edit Redirect"} open={openEditModal} onClose={onCloseEditModal}>
        <AddOrEditRedirectModal isEdit={true} onClose={onCloseEditModal} redirectInfo={eachRedirect} />
      </BasicModalWithHeader>

      <DeleteWarningModal
        title={"Delete!!"}
        warningSubtitle={"Are you sure, you want to delete this Redirect?"}
        warningContent={"After delete, this operation cannot be undone."}
        buttonText={"Yes, Delete"}
        cancelButtonText={"Cancel"}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default EachRedirect;
