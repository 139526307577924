import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import RouteBlock from "./RouteBlock";

const EditorPageSection: React.FC = () => {
  const [iframeElement, setIframeElement] = useState<HTMLIFrameElement>();
  const { sectionId } = useParams<{ sectionId: string }>();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSuperAdmin = queryParams.get("superadmin");

  return (
    <>
      <RouteBlock iframeElement={iframeElement} isSection />
      <Box
        ref={(el) => setIframeElement(el as HTMLIFrameElement)}
        component='iframe'
        sx={{ width: "100%", height: "100vh", border: "none", position: "absolute" }}
        allow='clipboard-read; clipboard-write'
        src={`${process.env.REACT_APP_WEBSITE_EDITOR_URL}/builder/section/${sectionId}${
          isSuperAdmin ? "?superadmin=true" : ""
        }`}
      />
    </>
  );
};

export default EditorPageSection;
