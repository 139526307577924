import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, FormControl, Link, List, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DomainI from "../../../interface/domain.interface";
import { AppDispatch } from "../../../state/store";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import CustomDomainSnippets from "../modals/CustomDomainSnippets";
import SettingUpDomainLoading from "../modals/SettingUpDomainLoading";
import { registerPatternDomain } from "../../../helpers/constant/registerPattern";
import {
  createDomain,
  resetCreateDomainSuccess,
  resetUpdateDomainSuccess,
  updateDomain,
} from "../../../state/features/domain/domainSlice";
import { selectDomainState } from "../../../state/features/domain/domainSelector";

interface IProps {
  onClose: () => void;
  domainInfo?: DomainI;
  isEdit?: boolean;
}

const AddOrEditDomain: React.FC<IProps> = ({ onClose, domainInfo, isEdit = false }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isCreating, isUpdating, isCreatedSuccess, isUpdatedSuccess } = useSelector(selectDomainState);
  const [progress, setProgress] = React.useState(0);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<DomainI>();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        // if (oldProgress === 100) {
        //   // setManuallyDomain(true);
        //   // setIsLoading(false);
        //   return 0;
        // }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 300);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (domainInfo) {
      setValue("domain", domainInfo.domain);
    }
  }, [domainInfo]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateDomainSuccess());
      dispatch(resetUpdateDomainSuccess());
    };
  }, []);

  const onSubmit: SubmitHandler<DomainI> = (data) => {
    setProgress(0);
    if (isEdit) {
      data.id = domainInfo?.id as string;
      dispatch(updateDomain(data));
    } else {
      dispatch(createDomain(data));
    }
  };

  const isLoading = isUpdating || isCreating;
  const showCredential = isCreatedSuccess || isUpdatedSuccess;

  // decide what to load
  if (isLoading) {
    return <SettingUpDomainLoading progress={progress} />;
  } else if (!isLoading && showCredential) {
    return (
      <Box sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}>
        <CustomDomainSnippets domain={domainInfo?.domain ?? getValues("domain")} />
      </Box>
    );
  }

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ px: 3, py: 2, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <List sx={{ "& li": { listStyle: "disc !important", fontWeight: 300, fontSize: 12 }, ml: 2, mb: 1 }}>
        <li>Do not include here http/https/www</li>
        <li>
          Top-level domains like (".com", ".org", ".net") is more preferable, others domains may take more time to
          propagate
        </li>
        <li>Example sub domain: subdomain.test.com, info.example.com, xyz.example.com, abc.example.com etc</li>
      </List>
      <FormControl fullWidth error={!!(errors.domain && errors.domain.message)} required>
        <CoreTextField
          required
          type='text'
          size='small'
          color='secondary'
          label={"Add Your Custom Domain"}
          placeholder='test.com'
          error={!!(errors.domain && errors.domain.message)}
          helperText={errors.domain && errors.domain.message}
          {...register("domain", registerPatternDomain.domain)}
        />
      </FormControl>

      <Stack direction='row' alignItems='flex-end' justifyContent={"space-between"} gap={2} mt={3}>
        <Link
          href={
            "https://s3.us-east-1.amazonaws.com/pypepro/contact/36807830/lefEhFzVpyeLg4FKqeHvUocWikDvh068uMGUiNlp.pdf"
          }
          target={"_blank"}
        >
          <Typography
            variant={"caption"}
            color={"secondary.main"}
            sx={{ display: "inline-flex", alignItems: "center", gap: 0.5 }}
          >
            <InfoOutlinedIcon fontSize={"small"} /> Domain setup instructions.
          </Typography>
        </Link>

        <CoreButton size='large' color='error' onClick={onClose} type='button' sx={{ ml: "auto" }}>
          Cancel
        </CoreButton>
        <LoadingButton
          type='submit'
          size='large'
          color='success'
          isLoading={isLoading}
          loadingText={isEdit ? "Updating..." : "Adding..."}
        >
          {isEdit ? "Update" : "Continue"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditDomain;
