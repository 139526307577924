import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import DomainList from "./DomainList";
import DomainHeader from "./DomainHeader";
import { fetchDomainCredential } from "../../../state/features/domain/domainSlice";
import { selectDomainState } from "../../../state/features/domain/domainSelector";

const DomainSettings = () => {
  const dispatch = useDispatch();
  const { data: domainList } = useSelector(selectDomainState);

  useEffect(() => {
    dispatch(fetchDomainCredential());
  }, []);

  return (
    <Box sx={{ width: { xs: "100%", lg: "80%" }, margin: "auto" }}>
      {domainList.length > 0 && <DomainHeader />}
      <DomainList />
    </Box>
  );
};

export default DomainSettings;
