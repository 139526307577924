import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../state/store";
import { Box, Divider, Paper, Stack } from "@mui/material";
import BuilderTopBar from "./BuilderTopBar";
import ReportPage from "../reports/ReportPage";
import FunnelSteps from "../funnelSteps/FunnelSteps";
import BuilderMainContent from "./BuilderMainContent";
import FunnelSettingsPage from "../../Settings/FunnelSettingsPage";
import CircleLoader from "../../../common/LoadingView/CircleLoader";
import NoContentFoundIcon from "../../../common/Icons/NoContentFoundIcon";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import { FUNNEL_PAGE_ID } from "../../../helpers/constant/coreConstants";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import { getFunnelSteps, resetGetFunnelSteps } from "../../../state/features/funnelStep/funnelStepSlice";
import { selectFunnelInfoState } from "../../../state/features/funnelInfo/funnelInfoSelector";
import { getFunnelInfo } from "../../../state/features/funnelInfo/funnelInfoSlice";

const FunnelBuilderIndex: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const [selectedScreen, setSelectedScreen] = useState(FUNNEL_PAGE_ID.FUNNEL);
  const { isLoading, data: funnelInfo } = useSelector(selectFunnelInfoState);
  const [initialLoader, setInitialLoader] = useState(true);

  useEffect(() => {
    setInitialLoader(false);
    dispatch(getFunnelInfo({ id: +funnelId }));
    dispatch(getFunnelSteps({ funnelId: +funnelId }));

    return () => {
      dispatch(resetGetFunnelSteps());
    };
  }, []);

  const onBackToFunnel = () => {
    setSelectedScreen(FUNNEL_PAGE_ID.FUNNEL);
  };

  let content;
  if (isLoading || initialLoader) {
    content = <CircleLoader />;
  } else if (!funnelInfo?.id) {
    content = (
      <GlobalEmptyPage
        icon={<NoContentFoundIcon />}
        title={"Page Not Found!"}
        description={"We wouldn't find the page you were looking for."}
      />
    );
  } else {
    content = (
      <Box>
        {selectedScreen === FUNNEL_PAGE_ID.FUNNEL && (
          <>
            <BuilderTopBar setSelectedScreen={setSelectedScreen} />
            <Divider light />
            <Paper elevation={0} sx={{ height: "calc(100vh - 51px)", background: "transparent" }}>
              <Stack direction={"row"} sx={{ height: "100%" }}>
                <FunnelSteps />
                <BuilderMainContent />
              </Stack>
            </Paper>
          </>
        )}
        {selectedScreen === FUNNEL_PAGE_ID.STATS && <ReportPage onBackToFunnel={onBackToFunnel} />}
        {selectedScreen === FUNNEL_PAGE_ID.SETTINGS && <FunnelSettingsPage onBackToFunnel={onBackToFunnel} />}
      </Box>
    );
  }

  return content;
};

export default FunnelBuilderIndex;
