import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import { websiteOrderByList } from "../../../helpers/constant/websiteConstant";
import {
  SectionLibraryI,
  CloneSectionLibraryI,
  CreateSectionLibraryI,
  DeleteSectionLibraryI,
  UpdateSectionLibraryI,
  PublishSectionLibraryI,
  ApproveSectionLibraryI,
  FetchSectionLibraryPayloadI,
} from "../../../interface/websiteSectionLibrary.interface";

const initialState = {
  data: [] as SectionLibraryI[],
  currentPage: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  renderId: "",

  isCreating: false,
  isCreated: false,

  isUpdating: false,
  isUpdateSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,
  isCloning: false,

  isRenaming: false,
  isRenameSuccess: false,

  selectedTemplateIds: [] as string[],
  createdSectionLibraryId: null as null | string,

  filters: {
    type: "",
    searchText: "",
    inTrash: false,
    orderBy: websiteOrderByList[2].value,
  },
};

const adminSectionLibrarySlice = createSlice({
  name: "adminSectionLibrary",
  initialState,
  reducers: {
    fetchSectionLibraries(state, action: PayloadAction<FetchSectionLibraryPayloadI>) {
      if (!state.renderId) state.isLoading = true;
    },
    fetchSectionLibrariesSuccess(state, action: PayloadAction<WithPagination<SectionLibraryI>>) {
      state.data = action.payload.content;
      state.currentPage = action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = +action.payload.count;
      state.isLoading = false;
      state.selectedTemplateIds = [];
    },
    fetchSectionLibraryFailed(state) {
      state.data = [];
      state.currentPage = 1;
      state.limit = 10;
      state.count = 0;
      state.isLoading = false;
    },
    resetSectionLibraryState(state) {
      state.data = [];
      state.currentPage = 1;
      state.limit = 10;
      state.count = 0;
      state.isLoading = false;
      state.selectedTemplateIds = [];
      state.renderId = "";
      state.filters = {
        type: "",
        searchText: "",
        inTrash: false,
        orderBy: websiteOrderByList[2].value,
      };
    },
    forceTemplateRender: (state) => {
      state.renderId = nanoid(5);
    },

    createSectionLibrary(state, action: PayloadAction<CreateSectionLibraryI>) {
      state.isCreating = true;
    },
    createSectionLibrarySuccess(state, action: PayloadAction<SectionLibraryI>) {
      state.isCreating = false;
      state.isCreated = true;
      state.createdSectionLibraryId = action.payload.sectionId;
    },
    createSectionLibraryFailed(state) {
      state.isCreating = false;
    },

    updateSectionLibrary(state, action: PayloadAction<UpdateSectionLibraryI>) {
      state.isUpdating = true;
    },
    updateSectionLibrarySuccess(state, action: PayloadAction<UpdateSectionLibraryI>) {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.data = state.data.map((page) => {
        if (page.sectionId === action.payload.sectionId) {
          return { ...page, ...action.payload };
        }
        return page;
      });
    },
    resetUpdateSectionLibrarySuccess(state) {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    deleteSectionLibrary(state, action: PayloadAction<DeleteSectionLibraryI>) {
      state.isDeleting = true;
    },
    deleteSectionLibrarySuccess(state, action: PayloadAction<DeleteSectionLibraryI>) {
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.data = state.data.filter((template) => template.sectionId !== action.payload.sectionId);
      state.selectedTemplateIds = [];
    },
    resetSectionLibrary(state) {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    cloneSectionLibrary(state, action: PayloadAction<CloneSectionLibraryI>) {
      state.isCloning = true;
    },
    cloneSectionLibrarySuccess(state, action: PayloadAction<SectionLibraryI>) {
      state.isCloning = false;
      state.data = [action.payload, ...state.data.slice(0, 9)];
      state.count += 1;
    },
    cloneSectionLibraryFailed(state) {
      state.isCloning = false;
    },

    toggleSectionLibraryPublishStatus(state, action: PayloadAction<PublishSectionLibraryI>) {},
    toggleSectionLibraryPublishStatusSuccess(state, action: PayloadAction<PublishSectionLibraryI>) {
      state.data = state.data.map((library) => {
        if (library.sectionId === action.payload.id) {
          return { ...library, status: action.payload.status };
        }
        return library;
      });
    },
    toggleSectionLibraryPublishStatusFailed(state) {},
    toggleSectionLibraryApproveStatus(state, action: PayloadAction<ApproveSectionLibraryI>) {},
    toggleSectionLibraryApproveStatusSuccess(state, action: PayloadAction<ApproveSectionLibraryI>) {
      state.data = state.data.map((library) => {
        if (library.sectionId === action.payload.id) {
          return { ...library, approveStatus: action.payload.approveStatus };
        }
        return library;
      });
    },
    toggleSectionLibraryApproveStatusFailed(state) {},

    toggleSingleSectionLibrary(state, action: PayloadAction<string>) {
      const currentFormIndex = state.selectedTemplateIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentFormIndex !== -1) {
        state.selectedTemplateIds.splice(currentFormIndex, 1);
      } else {
        state.selectedTemplateIds.push(action.payload);
      }
    },
    selectedAllSectionLibraries: (state) => {
      state.selectedTemplateIds = state.data.map((data) => data.sectionId);
    },
    deselectAllSectionLibraries: (state) => {
      state.selectedTemplateIds = [];
    },

    resetCreateStatus(state) {
      state.isCreated = false;
      state.createdSectionLibraryId = null;
    },

    toggleType(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        state.filters.type = action.payload;
        state.filters.inTrash = false;
      } else {
        state.filters.type = "";
      }
    },
    removeType(state) {
      state.filters.type = "";
    },
    templateSearchTextFilter(state, action: PayloadAction<string>) {
      state.filters.searchText = action.payload;
    },

    selectTrashFolder(state) {
      state.currentPage = 1;
      state.filters.type = "";
      state.filters.inTrash = true;
      state.selectedTemplateIds = [];
    },

    resetTemplateFilter(state) {
      state.filters.type = "";
      state.filters.inTrash = false;
      state.selectedTemplateIds = [];
      state.currentPage = 1;
    },
  },
});

export const {
  fetchSectionLibraries,
  fetchSectionLibrariesSuccess,
  fetchSectionLibraryFailed,
  resetSectionLibraryState,
  forceTemplateRender,

  createSectionLibrary,
  createSectionLibrarySuccess,
  createSectionLibraryFailed,

  updateSectionLibrary,
  updateSectionLibrarySuccess,
  resetUpdateSectionLibrarySuccess,

  deleteSectionLibrary,
  deleteSectionLibrarySuccess,
  resetSectionLibrary,

  cloneSectionLibrary,
  cloneSectionLibrarySuccess,
  cloneSectionLibraryFailed,

  toggleSectionLibraryPublishStatus,
  toggleSectionLibraryPublishStatusSuccess,
  toggleSectionLibraryPublishStatusFailed,
  toggleSectionLibraryApproveStatus,
  toggleSectionLibraryApproveStatusSuccess,
  toggleSectionLibraryApproveStatusFailed,

  toggleSingleSectionLibrary,
  selectedAllSectionLibraries,
  deselectAllSectionLibraries,

  selectTrashFolder,
  resetCreateStatus,

  toggleType,
  removeType,
  resetTemplateFilter,
  templateSearchTextFilter,
} = adminSectionLibrarySlice.actions;

export default adminSectionLibrarySlice.reducer;
