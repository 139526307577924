import React, { ReactElement } from "react";
import {
  EmptyArchiveIcon,
  EmptyFavouriteIcon,
  EmptyFormListIcon,
  EmptyTrashIcon,
} from "../../common/Icons/EmptyDataIcons";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";

const getEmptyPageContent = (
  favourite: boolean,
  archive: boolean,
  trash: boolean
): { icon: ReactElement; title: string; description: string } => {
  if (favourite) {
    return {
      icon: <EmptyFavouriteIcon />,
      title: "Favorites is Empty",
      description: "Click the Star Button on any website to add a Favorites.",
    };
  } else if (archive) {
    return {
      icon: <EmptyArchiveIcon />,
      title: "Archive is empty",
      description: "You can find your archive websites here",
    };
  } else if (trash) {
    return {
      icon: <EmptyTrashIcon />,
      title: "Trash is Empty",
      description: "You can find your trash websites here",
    };
  } else {
    return {
      icon: <EmptyFormListIcon />,
      title: "No Website Found",
      description:
        "All websites that you added will be visible here. Add new website by clicking 'New Website' button on top of this page",
    };
  }
};

interface IProps {
  isFavorite: boolean;
  isArchived: boolean;
  inTrash: boolean;
}

const WebsiteEmptyPage: React.FC<IProps> = ({ isFavorite, isArchived, inTrash }) => {
  const { icon, title, description } = getEmptyPageContent(isFavorite, isArchived, inTrash);
  return <GlobalEmptyPage icon={icon} title={title} description={description} />;
};

export default WebsiteEmptyPage;
