import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  AdminTemplateI,
  CloneAdminTemplateI,
  CreateAdminTemplateI,
  DeleteAdminTemplateI,
  FetchTemplatesPayloadI,
  InTrashAdminTemplateI,
  RenameAdminTemplateI,
  UpdateAdminTemplateI,
  UpdateApproveStatusI,
  UpdatePublishStatusI,
} from "../interface/website-template.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class AdminTemplateService {
  fetchWebSiteTemplate(params: FetchTemplatesPayloadI) {
    return httpRequest.get<WithPagination<AdminTemplateI>>(websiteBuilderAPI + "admin/website/list", params);
  }

  createWebsiteTemplate(payload: CreateAdminTemplateI) {
    return httpRequest.post<AdminTemplateI>(websiteBuilderAPI + "admin/website/create", payload);
  }

  renameTemplate(payload: RenameAdminTemplateI) {
    return httpRequest.put(websiteBuilderAPI + "admin/website/setting/update/" + payload.websiteId, payload);
  }

  updateWebSiteTemplate(payload: UpdateAdminTemplateI) {
    return httpRequest.put<AdminTemplateI>(websiteBuilderAPI + "admin/website/update/" + payload.id, payload);
  }

  deleteWebSiteTemplate(payload: DeleteAdminTemplateI) {
    return httpRequest.delete<AdminTemplateI>(websiteBuilderAPI + "admin/website/delete", "", payload);
  }

  trashWebSiteTemplate(payload: InTrashAdminTemplateI) {
    return httpRequest.put<InTrashAdminTemplateI>(websiteBuilderAPI + "admin/website/trash", payload);
  }

  updatePublishTemplateStatus(payload: UpdatePublishStatusI) {
    return httpRequest.put<InTrashAdminTemplateI>(websiteBuilderAPI + "admin/website/publish", payload);
  }

  updateTemplateApproveStatusByAdmin(payload: UpdateApproveStatusI) {
    return httpRequest.put<InTrashAdminTemplateI>(websiteBuilderAPI + "admin/website/approve", payload);
  }

  cloneTemplate(payload: CloneAdminTemplateI) {
    return httpRequest.post<AdminTemplateI>(websiteBuilderAPI + "admin/website/clone", payload);
  }
}

const adminTemplateService = new AdminTemplateService();
export default adminTemplateService;
