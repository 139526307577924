import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, styled, Typography } from "@mui/material";
import AddNewStepModalContent from "./AddNewStepModalContent";
import SelectTemplateModal from "./template/SelectTemplateModal";
import BasicModal from "../../../common/Modal/BasicModal";
import CoreButton from "../../../common/Button/CoreButton";
import AddPageIcon from "../../../common/Icons/AddPageIcon";
import LoadingButton from "../../../common/Button/LoadingButton";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import { addStepPage } from "../../../state/features/funnelStepPages/funnelStepPagesSlice";
import { selectSelectedFunnelStep } from "../../../state/features/funnelStep/funnelStepSelector";
import { selectFunnelStepPagesState } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";

const StyledInheritButton = styled(CoreButton)(({ theme }) => ({
  borderColor: theme.palette.other.outlinedBorderDarker,
}));

const EmptyStepPage: React.FC = () => {
  const dispatch = useDispatch();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const { id: stepId } = useSelector(selectSelectedFunnelStep);
  const { addPagePayload, isAdding } = useSelector(selectFunnelStepPagesState);

  const { value: openFunnelModal, setTrue: onOpenFunnelModal, setFalse: onCloseFunnelModal } = useBoolean(false);
  const { value: openWebsiteModal, setTrue: onOpenWebsiteModal, setFalse: onCloseWebsiteModal } = useBoolean(false);
  const { value: openTemplate, setTrue: onOpenTemplate, setFalse: onCloseTemplate } = useBoolean(false);

  const onPageCreateFromScratch = () => {
    dispatch(
      addStepPage({
        funnelId: Number(funnelId),
        stepId: stepId,
        pageType: "ORIGINAL",
        pageCreateType: "FROM_BLANK",
      })
    );
  };

  const isLoading = addPagePayload !== null && addPagePayload.pageCreateType === "FROM_BLANK" && isAdding;

  return (
    <>
      <Box p={4}>
        <Stack alignItems={"center"} spacing={2} sx={{ width: 300, margin: "auto" }}>
          <AddPageIcon sx={{ fontSize: 100 }} />
          <Typography variant={"h5"}>Start by adding a page</Typography>
          <LoadingButton
            fullWidth
            variant={"outlined"}
            color={"secondary"}
            isLoading={isLoading}
            onClick={onPageCreateFromScratch}
          >
            Design from scratch
          </LoadingButton>
          <StyledInheritButton fullWidth variant={"outlined"} color={"inherit"} onClick={onOpenWebsiteModal}>
            Select from existing funnels
          </StyledInheritButton>
          <StyledInheritButton fullWidth variant={"outlined"} color={"inherit"} onClick={onOpenFunnelModal}>
            Select from existing funnels
          </StyledInheritButton>
          {/*<StyledInheritButton fullWidth variant={"outlined"} color={"inherit"} onClick={onOpenTemplate}>*/}
          {/*  use template*/}
          {/*</StyledInheritButton>*/}
        </Stack>
      </Box>

      <BasicModalWithHeader
        width={500}
        title={"Add Page From Existing Funnel"}
        open={openFunnelModal}
        onClose={onCloseFunnelModal}
      >
        <AddNewStepModalContent cloneType={"FUNNEL"} pageType={"ORIGINAL"} onClose={onCloseFunnelModal} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        width={500}
        title={"Add Page From Existing Website"}
        open={openWebsiteModal}
        onClose={onCloseWebsiteModal}
      >
        <AddNewStepModalContent cloneType={"WEBSITE"} pageType={"ORIGINAL"} onClose={onCloseWebsiteModal} />
      </BasicModalWithHeader>

      <BasicModal open={openTemplate} onClose={onCloseTemplate}>
        <SelectTemplateModal />
      </BasicModal>
    </>
  );
};

export default EmptyStepPage;
