import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Accordion, AccordionDetails, AccordionSummary, Chip, Stack, Typography } from "@mui/material";
import CoreButton from "../../../../common/Button/CoreButton";
import { getTemplateTags, toggleSelectTemplateTag } from "../../../../state/features/template/templateSlice";
import { selectSelectedTagIds, selectTemplateTag } from "../../../../state/features/template/templateSelector";

const WebsitesTags: React.FC = () => {
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(5);
  const [expanded, setExpanded] = useState(false);
  const { data: tagList, isSuccess } = useSelector(selectTemplateTag);
  const selectedTagIds = useSelector(selectSelectedTagIds);

  const handleSeeMore = () => {
    setLimit((prevState) => prevState + 5);
  };

  useEffect(() => {
    if (!isSuccess) dispatch(getTemplateTags());
  }, [dispatch, isSuccess]);

  const handleSelectTag = (tagId: number) => {
    dispatch(toggleSelectTemplateTag(tagId));
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        sx={{ background: "transparent", boxShadow: "none", "&:before": { content: "none" } }}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon />}
          sx={{
            p: 0,
            minHeight: "36px !important",
            "& .MuiAccordionSummary-content.Mui-expanded": { margin: "12px 0" },
          }}
        >
          <Typography variant={"body2SemiBold"} color={"text.secondary"}>
            Tags
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Stack direction={"row"} flexWrap={"wrap"} sx={{ gap: "8px" }}>
            {tagList.map((tagInfo, index) => {
              const isChecked = selectedTagIds.indexOf(tagInfo.id) !== -1;
              console.log("render 333::: ", tagInfo.id);
              return (
                <Chip
                  key={tagInfo.id}
                  size={"small"}
                  variant={"filled"}
                  color={isChecked ? "primary" : "default"}
                  label={tagInfo.name}
                  onClick={() => handleSelectTag(tagInfo.id)}
                  sx={{fontWeight:300}}
                />
              );
            })}
            {tagList.length > limit && (
              <CoreButton
                onClick={handleSeeMore}
                size={"small"}
                color={"secondary"}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ ml: "10px" }}
              >
                See More
              </CoreButton>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default WebsitesTags;
