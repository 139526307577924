import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import TagsListItem from "./TagsListItem";
import { CustomList } from "../../../SitesList/SitesListStyles";
import CommonSidebarAccordion from "../CommonSidebarAccordion";
import AddOrEditTagsName from "../modals/AddOrEditTagsName";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import FolderListSkeleton from "../../../../common/LoadingView/FolderListSkeleton";
import useBoolean from "../../../../hooks/useBoolean";
import { fetchTag } from "../../../../state/features/funnel-tag/funnelTagSlice";
import { selectFunnelTagState } from "../../../../state/features/funnel-tag/funnelTagSelector";
import { selectPermissionState } from "../../../../state/features/permission/permissionSelector";

const TagsList: React.FC = () => {
  const dispatch = useDispatch();

  const { isAdmin } = useSelector(selectPermissionState);
  const { data, isLoading } = useSelector(selectFunnelTagState);
  const { value: openNewTagModal, setTrue: onOpenNewTagModal, setFalse: onCloseNewTagModal } = useBoolean(false);

  let content = <></>;
  if (isLoading) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        <FolderListSkeleton count={10} />
      </CustomList>
    );
  } else if (data.length > 0) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        {data.map((tag) => (
          <TagsListItem tag={tag} key={tag.id} isAdmin={isAdmin} />
        ))}
      </CustomList>
    );
  } else {
    content = (
      <Typography variant={"caption"} fontStyle={"italic"}>
        No tags exist.
      </Typography>
    );
  }

  useEffect(() => {
    dispatch(fetchTag());
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onOpenNewTagModal();
  };

  return (
    <>
      <CommonSidebarAccordion title={"Tags"} tooltipText={"Add New Tag"} onClick={handleClick} isAdmin={isAdmin}>
        {content}
      </CommonSidebarAccordion>
      <BasicModalWithHeader title={"Add New Tag"} open={openNewTagModal} onClose={onCloseNewTagModal}>
        <AddOrEditTagsName onClose={onCloseNewTagModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default TagsList;
