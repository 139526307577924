import funnelApiSlice from "../../funnelApiSlice";
import { SuccessResponseIn, WithPagination } from "../../../interface/response.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  IAddRedirectUrlPayload,
  IDeleteRedirectUrlPayload,
  IGetRedirectUrlPayload,
  AddEditRedirectUrlRequestType,
  RedirectUrlInterface,
} from "./redirectUrl.interface";

export const redirectUrlApiSlice = funnelApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRedirectUrlList: builder.query<SuccessResponseIn<WithPagination<RedirectUrlInterface>>, IGetRedirectUrlPayload>({
      query: (params) => ({
        url: "page/redirect/url/list",
        method: "GET",
        params: params,
      }),
      providesTags: ["REDIRECT_URL"],
    }),

    createRedirectUrl: builder.mutation<SuccessResponseIn<RedirectUrlInterface>, IAddRedirectUrlPayload>({
      query: (body) => ({
        url: "page/redirect/url/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["REDIRECT_URL"],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: createdPageState } = await queryFulfilled;

          if (createdPageState.success) showSuccessToaster(createdPageState.message);
          else showErrorToaster(createdPageState.message);
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
    }),

    updateRedirectUrl: builder.mutation<SuccessResponseIn<RedirectUrlInterface>, AddEditRedirectUrlRequestType>({
      query: (body) => ({
        url: "page/redirect/url/update",
        method: "PUT",
        body,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPostState } = await queryFulfilled;

          if (updatedPostState.success) showSuccessToaster(updatedPostState.message);
          else showErrorToaster(updatedPostState.message);
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
      invalidatesTags: ["REDIRECT_URL"],
    }),

    deleteRedirectUrl: builder.mutation<SuccessResponseIn<IDeleteRedirectUrlPayload>, IDeleteRedirectUrlPayload>({
      query: (body) => ({
        url: "page/redirect/url/delete",
        method: "DELETE",
        body,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: clonedPostState } = await queryFulfilled;

          if (clonedPostState.success) showSuccessToaster(clonedPostState.message);
          else showErrorToaster(clonedPostState.message);
        } catch (err: any) {
          const { message } = err.error?.data;
          showErrorToaster(message as string);
        }
      },
      invalidatesTags: ["REDIRECT_URL"],
    }),
  }),
});

export const {
  useGetRedirectUrlListQuery,
  useCreateRedirectUrlMutation,
  useUpdateRedirectUrlMutation,
  useDeleteRedirectUrlMutation,
} = redirectUrlApiSlice;
