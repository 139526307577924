import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import FunnelTags from "./FunnelTags";
import BrowseCategory from "./BrowseCategory";
import TypesOfFunnels from "./TypesOfFunnels";

const TemplateLibrarySidebar: React.FC = () => {
  return (
    <Box width={200} pr={2} pt={3}>
      <Stack spacing={1}>
        <BrowseCategory />
        <Divider light />
        <TypesOfFunnels />
        <Divider light />
        <FunnelTags />
        <Divider light />
      </Stack>
    </Box>
  );
};

export default TemplateLibrarySidebar;
