import {
  alpha,
  Box,
  Chip,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  styled,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

export const WebsiteListWrapper = styled(Box)(() => ({
  width: "100%",
  height: "calc(100vh - 116px)",
  background: "linear-gradient(0deg, rgba(148, 157, 178, 0.12), rgba(148, 157, 178, 0.12)), #FFFFFF",
}));

export const WebsiteListWrapperAdmin = styled(Paper)(({ theme }) => ({
  width: "100%",
  height: "calc(100vh - 190px)",
  marginTop: theme.spacing(2),
}));

export const SiteListHeaderWrp = styled(Stack)(({ theme }) => ({
  justifyContent: "space-between",
  marginBottom: theme.spacing(1.5),
  flexDirection: "row",
  // [theme.breakpoints.down("lg")]: { flexDirection: "column", gap: theme.spacing(1.5) },
}));

export const WebsiteListBody = styled(Stack)(({ theme }) => ({
  height: "calc(100% - 62px)",
  gap: "16px",
  flexDirection: "row",
  [theme.breakpoints.down("lg")]: { flexDirection: "column" },
}));
export const WebsiteListBodyAdmin = styled(Stack)(({ theme }) => ({
  height: "calc(100% - 82px)",
  flexDirection: "row",
  [theme.breakpoints.down("lg")]: { flexDirection: "column", height: "calc(100% - 170px)" },
}));

//left sidebar
export const LeftSideBar = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.background.paper,
  transition: "all 0.3s ease",
  "& .iSidebarTitleBar": {
    width: "100%",
    justifyContent: "space-between",
    transition: "all 0.3s ease",
    "& .iSidebarTitle": {
      width: "max-content",
      marginLeft: theme.spacing(1.25),
      opacity: 1,
      transitionDelay: "0.2s",
    },
  },
  "&.collapseLeftSide": {
    width: 0,
    "& .iSidebarTitleBar": {
      width: "56px",
      justifyContent: "center",
      background: theme.palette.common.white,
      borderRadius: "4px",
      "& .iSidebarTitle": {
        width: 0,
        opacity: 0,
        pointerEvents: "none",
        margin: 0,
        transitionDelay: "0s",
      },
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "56px",
      background: "transparent",
    },
  },
  [theme.breakpoints.up("lg")]: {
    width: "268px",
  },
  [theme.breakpoints.down("lg")]: {
    "& .iSidebarTitleBar": {
      justifyContent: "flex-end",
    }
  },
}));

export const SidebarListWrapper = styled(Box)(({ theme }) => ({
  width: "268px",
  height: "calc(100vh - 236px)",
  opacity: 1,
  top: "80px",
  left: 0,
  transition: "all 0.2s ease",
  overflowY: "auto",
  zIndex: 99,
  "&.admin-template-sidebar-list": {
    height: "calc(100vh - 320px)",
  },
  "&::-webkit-scrollbar": { display: "none" },
  "& > *": {
    transition: "all 0.2s ease",
    transitionDelay: "0.2s",
    opacity: "1",
  },
  "&.slw-hide-sidebar": {
    width: 0,
    opacity: 0,
    "& > *": {
      transitionDelay: 0,
      opacity: 0,
    },
  },
  "&.slw-show-sidebar-tab": {
    left: "-100%",
  },
  [theme.breakpoints.down("lg")]: {
    height: "calc(100% - 55px) ",
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    position: "fixed",
    boxShadow: theme.shadows[3],
    "&.admin-template-sidebar-list": {
      height: "calc(100vh - 80px)",
    },
  },
}));

export const SideTopBarTab = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(2),
  display: "none",
  [theme.breakpoints.down("lg")]: { display: "flex" },
}));

export const CustomList = styled(List)(({ theme }) => ({
  width: "100%",
  maxHeight: 380,
  height: "auto",
  overflowY: "auto",
  padding: 0,
  marginTop: "8px",
  paddingRight: "6px",
  transition: "0.3s",
  "& .MuiListItem-root": {
    width: "100%",
    borderRadius: "4px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.other.secondaryHover,
    },
    "&.active": {
      background: theme.palette.other.secondaryHover,
    },
    "& .MuiListItemIcon-root": {
      minWidth: "32px",
    },
  },
}));

export const SideBarListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
  "& .ib-sidebar-more-icon": {
    visibility: "hidden",
    pointerEvents: "none",
    marginRight: theme.spacing(-1.5),
  },
  "&:hover": {
    "& .ib-sidebar-more-icon": { visibility: "visible", pointerEvents: "all" },
  },
}));

export const SidebarListItem = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "4px",
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  "&:hover, &.Mui-selected": {
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
  },
  "&.Mui-selected": {
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
    color: theme.palette.secondary.main,
    "& .MuiListItemIcon-root ": {
      color: theme.palette.secondary.main,
    },
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  "& .MuiListItemIcon-root ": {
    minWidth: "29px",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export const ChangeColorText = styled(Typography)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  padding: theme.spacing(1, 2),
  fontWeight: 300,
}));

export const ColorItemBox = styled(Box)(({ theme }) => ({
  gap: "10px",
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateRows: "repeat(2, 1fr)",
  padding: "8px 16px",
  cursor: "pointer",
  "& .ibColorItem": {
    width: "32px",
    height: "32px",
    borderRadius: "4px",
    display: "inline-flex",
    background: theme.palette.other.outlinedBorder,
  },
}));

// right side website
export const ListsWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  flex: "1 1 100%",
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
}));

export const HeaderBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "16px",
  // marginBottom: theme.spacing(1),
  // borderBottom: `1px solid ${theme.palette.action.hover}`,
  paddingBottom: theme.spacing(2),
}));

// list
export const SingleWebsiteListWrp = styled(TableRow)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  transition: "all 0.2s ease",
  "& .rrmsl-list-action-icons": {
    visibility: "hidden",
    pointerEvents: "none",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "auto",
    gap: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      "& .MuiIconButton-root:not(.rrmsl-list-more-icon)": { display: "none" },
    },
  },
  "&:hover, &.active,&.Mui-selected:hover": {
    backgroundColor: theme.palette.other.secondaryHover,
    "& .rrmsl-list-action-icons": {
      visibility: "visible",
      pointerEvents: "all",
    },
  },
}));

// folder list chip tab
export const FolderChipListTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "max-content",
  alignItems: "center",
  "& .MuiTab-root": {
    minHeight: "max-content",
  },
  "& .MuiTabs-flexContainer": { gap: "8px" },
  "&:hover": {
    "& .MuiTabScrollButton-root": {
      display: "inline-flex",
      zIndex: 99,
    },
  },
  "& .MuiTabScrollButton-root": {
    display: "none",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    background: theme.palette.text.secondary,
    color: theme.palette.common.white,
    position: "absolute",
    left: "-10px",
    "&:last-child": {
      right: "-10px",
      left: "auto",
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

interface StyledChipProps {
  chipColor: string;
}
export const StyledChip = styled(Chip, {
  shouldForwardProp(propName: PropertyKey) {
    return propName !== "chipColor";
  },
})<StyledChipProps>(({ theme, chipColor }) => ({
  height: "24px",
  paddingLeft: theme.spacing(0.25),
  backgroundColor: alpha(chipColor, 0.2),
  color: chipColor,
  transition: "all 0.3s ease",
  textTransform: "initial",
  "& .MuiChip-icon": {
    color: chipColor,
  },
  "& .MuiChip-deleteIcon": {
    position: "absolute",
    right: 0,
    transform: "translateX(20px)",
    pointerEvents: "none",
    color: theme.palette.other.darkNavy,
    "&:hover": {
      color: theme.palette.other.darkNavy,
    },
  },
  "&:hover": {
    paddingRight: theme.spacing(2),
    "& .MuiChip-deleteIcon": {
      transform: "translateX(0)",
      transformOrigin: "right",
      pointerEvents: "all",
    },
  },
}));
