import httpRequest from "./api/httpRequest";
import { FunnelTemplateSettingI, UpdateFunnelTemplateSettingI } from "../interface/funnelTemplateSetting.interface";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Funnel API URL not found in env!");

class FunnelTemplateSettingService {
  getFunnelTemplateSetting(params: string) {
    return httpRequest.get<FunnelTemplateSettingI>(funnelBuilderAPI + "admin/funnel/setting/" + params);
  }

  updateFunnelTemplateSetting(payload: UpdateFunnelTemplateSettingI) {
    return httpRequest.put<FunnelTemplateSettingI>(
      funnelBuilderAPI + "admin/funnel/setting/update/" + payload.funnelId,
      payload
    );
  }
}

const funnelTemplateSettingService = new FunnelTemplateSettingService();
export default funnelTemplateSettingService;
