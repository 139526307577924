import React from "react";
import AdminPrivateLayout from "../Layouts/AdminPrivateLayout";
import { Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import AdminFunnelTemplates from "./AdminFunnelTemplates";
import WebsiteFunnelSwitchingTabs from "../WebsiteFunnelSwitchingTabs";

const FunnelAdminIndex = () => {
  const [value, setValue] = React.useState(0);

  React.useLayoutEffect(() => {
    const tab = new URLSearchParams(window.location.search).get("tab");
    if (tab === "section") setValue(1);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AdminPrivateLayout>
      <>
        <Paper elevation={0}>
          <Stack direction={"row-reverse"} alignItems={"center"} justifyContent={"space-between"} px={2}>
            <Typography variant={"h5"} color={"secondary.main"}>
              Funnel Admin Panel
            </Typography>
            <WebsiteFunnelSwitchingTabs
              firstPath={"/wbuilder/admin"}
              secondPath={"/wbuilder/funnels/admin"}
              firstTitle={"Websites"}
              secondTitle={"Funnels"}
            />
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <Typography variant={"body2"}>Templates:</Typography>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor={"secondary"}
                indicatorColor={"secondary"}
                sx={{
                  "& .MuiTab-root": {
                    fontSize: 18,
                  },
                }}
              >
                <Tab label='Pages' />
                {/*<Tab label='Sections' />*/}
              </Tabs>
            </Stack>
          </Stack>
        </Paper>
        {value === 0 && <AdminFunnelTemplates />}
        {/*{value === 1 && <Sections />}*/}
      </>
    </AdminPrivateLayout>
  );
};

export default FunnelAdminIndex;
