import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import EditPageButtonGroup from "./EditPageButtonGroup";
import { PageImageBox, PageWrapperBox } from "./FunnelPageStyles";
import { selectFunnelStepPageOriginal } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";

const OriginalPage = () => {
  const original = useSelector(selectFunnelStepPageOriginal);

  return (
    <PageWrapperBox textAlign={"center"}>
      <Typography variant={"subtitle2"} fontWeight={600} color={"text.secondary"}>
        Original
      </Typography>
      {/*<Typography variant={"body2"}>{original?.name}</Typography>*/}

      <PageImageBox variant={"elevation"} elevation={4}>
        {original?.thumbnail && <img src={original.thumbnail} alt={""} />}
      </PageImageBox>

      <EditPageButtonGroup />
    </PageWrapperBox>
  );
};

export default OriginalPage;
