import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import { AdminWebsiteSettingI } from "../../../interface/adminWebsiteSetting.interface";
import adminWebsiteSettingService from "../../../services/adminWebsiteSetting.service";
import {
  getAdminWebsiteSettingFailed,
  getAdminWebsiteSettingSuccess,
  resetAdminWebsiteSetting,
  updateAdminWebsiteSettingSuccess,
} from "./adminWebsiteSettingSlice";

function* adminWebsiteSettingWatcher() {
  yield takeEvery("adminWebsiteSetting/getAdminWebsiteSetting", getWebsiteSettingSaga);
  yield takeEvery("adminWebsiteSetting/updateAdminWebsiteSetting", updateWebsiteSettingSaga);
}

function* getWebsiteSettingSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(adminWebsiteSettingService.getAdminWebsiteSetting, action.payload);

    if (response.success) {
      yield put(getAdminWebsiteSettingSuccess(response.data));
    } else {
      yield put(getAdminWebsiteSettingFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getAdminWebsiteSettingFailed("Network or server error"));
    console.error(err);
  }
}

function* updateWebsiteSettingSaga(action: PayloadAction<AdminWebsiteSettingI>) {
  try {
    const response = yield* call(adminWebsiteSettingService.updateAdminWebsiteSetting, action.payload);

    if (response.success) {
      yield put(updateAdminWebsiteSettingSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetAdminWebsiteSetting());
      showErrorToaster(response.message);
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(resetAdminWebsiteSetting());
    }
  }
}

export default function* adminWebsiteSettingSaga() {
  yield all([adminWebsiteSettingWatcher()]);
}
