import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../state/store";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { IconButton, Stack } from "@mui/material";
import { Delete, DeleteForever, RestoreFromTrash } from "@mui/icons-material";
import CoreButton from "../../../common/Button/CoreButton";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import useBoolean from "../../../hooks/useBoolean";
import { selectFunnelState } from "../../../state/features/funnel/funnelSelector";
import {
  deleteFunnelForever,
  resetDeleteFunnelForeverSuccess,
  toggleArchiveStatus,
  toggleInTrashStatus,
} from "../../../state/features/funnel/funnelSlice";

const FunnelListBulkActions = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { selectedFunnelIds, isDeleting, isDeleteSuccess, filters } = useSelector(selectFunnelState);
  const { inTrash, isArchived } = filters;
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  useEffect(() => {
    if (isDeleteSuccess) {
      onCloseModal();
      dispatch(resetDeleteFunnelForeverSuccess());
    }
  }, []);

  // handle delete move in trash
  const deleteFunnelHandler = () => {
    dispatch(toggleInTrashStatus({ ids: selectedFunnelIds, inTrash: true }));
  };

  // handle restore from in trash
  const handleRestoreFunnel = () => {
    dispatch(toggleInTrashStatus({ ids: selectedFunnelIds, inTrash: false }));
  };

  const onConfirmDeleteForever = () => {
    dispatch(deleteFunnelForever({ ids: selectedFunnelIds }));
  };

  // handle restore from archived
  const toggleArchiveFunnel = () => {
    dispatch(
      toggleArchiveStatus({
        ids: selectedFunnelIds,
        isArchived: !isArchived,
      })
    );
  };

  // decide what to render
  let element: JSX.Element | null = null;
  if (selectedFunnelIds.length > 0 && inTrash) {
    element = (
      <>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <CoreButton
            variant={"contained"}
            color={"inherit"}
            startIcon={<RestoreFromTrash />}
            onClick={handleRestoreFunnel}
            sx={{ maxWidth: "100%" }}
          >
            Restore
          </CoreButton>
          <CoreButton
            variant={"contained"}
            color={"error"}
            startIcon={<DeleteForever />}
            onClick={onOpenModal}
            sx={{ minWidth: "120px", maxWidth: "100%" }}
          >
            Delete Forever
          </CoreButton>
        </Stack>

        <DeleteWarningModal
          title={"Delete Forever!!"}
          warningSubtitle={"Are you sure, you want to delete this funnels?"}
          warningContent={"After delete, this operation cannot be undone."}
          buttonText={"Yes, Delete Forever"}
          cancelButtonText={"Cancel"}
          open={openModal}
          onClose={onCloseModal}
          onConfirm={onConfirmDeleteForever}
          isLoading={isDeleting}
        />
      </>
    );
  } else if (selectedFunnelIds.length > 0) {
    element = (
      <>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Stack direction='row' spacing={0.25} alignItems='center'>
            <CustomTooltip title={!isArchived ? "Archive" : "Unarchive"} arrow placement='top'>
              <IconButton onClick={toggleArchiveFunnel}>
                {!isArchived ? <ArchiveIcon color='action' /> : <UnarchiveIcon color='action' />}
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title='Delete' arrow placement='top'>
              <IconButton onClick={deleteFunnelHandler}>
                <Delete color='action' />
              </IconButton>
            </CustomTooltip>
          </Stack>
        </Stack>
      </>
    );
  }

  return element;
};

export default FunnelListBulkActions;
