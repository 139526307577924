import React from "react";
import { format } from "date-fns";
import { TableCell, TableRow } from "@mui/material";
import DomainI from "../../../interface/domain.interface";
import DomainActions from "./DomainActions";

interface IProps {
  domainInfo: DomainI;
}

const DomainListItem: React.FC<IProps> = ({ domainInfo }) => {
  const { domain, updatedAt } = domainInfo || {};

  const formattedUpdatedAt = updatedAt ? format(new Date(updatedAt), "MMM dd, yyyy hh:mm aa") : "-";
  return (
    <>
      <TableRow>
        <TableCell>{domain}</TableCell>
        <TableCell>{formattedUpdatedAt}</TableCell>
        <TableCell align={"right"}>
          <DomainActions domainInfo={domainInfo} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default DomainListItem;
