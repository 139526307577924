import httpRequest from "./api/httpRequest";
import WebsiteSettings from "../state/features/website-settings/website-setting.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class WebSiteSettingService {
  getWebSiteSetting(params: string) {
    return httpRequest.get<WebsiteSettings>(websiteBuilderAPI + "website/setting/" + params);
  }

  updateWebSiteSetting(payload: WebsiteSettings) {
    return httpRequest.put<WebsiteSettings>(websiteBuilderAPI + "website/setting/update/" + payload.websiteId, payload);
  }
}

const websiteSettingService = new WebSiteSettingService();
export default websiteSettingService;
