import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableCell } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import { AppDispatch } from "../../../../state/store";
import useBoolean from "../../../../hooks/useBoolean";
import IconButton from "../../../../common/IconButton";
import RenameSectionTemplate from "../RenameSectionTemplate";
import CustomTooltip from "../../../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import { selectPermissionState } from "../../../../state/features/permission/permissionSelector";
import { selectSectionLibraryState } from "../../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";
import { PUBLISH_STATUS, APPROVE_STATUS, SectionLibraryI } from "../../../../interface/websiteSectionLibrary.interface";

import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../../../common/PopoverMenu/CustomPopoverMenu";
import {
  cloneSectionLibrary,
  toggleSectionLibraryApproveStatus,
  toggleSectionLibraryPublishStatus,
  deleteSectionLibrary,
} from "../../../../state/features/adminSectionLibrary/adminSectionLibrarySlice";

interface Props {
  section: SectionLibraryI;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionCell: React.FC<Props> = ({ section, setIsActive }) => {
  const { sectionId, approveStatus, status } = section;
  const isPublished = status === "PUBLISHED";
  const isApproved = approveStatus === "APPROVE";

  // hooks
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { isAdmin, isSuperAdmin } = useSelector(selectPermissionState);
  const { isDeleting, isDeleteSuccess } = useSelector(selectSectionLibraryState);
  const { setFalse: closeDeleteModal, setTrue: openDeleteModal, value: isDeleteModalOpen } = useBoolean();
  const { value: openRenameModal, setTrue: onOpenRenameModal, setFalse: onCloseRenameModal } = useBoolean(false);

  // functions
  const handleCloneWebsite = () => {
    dispatch(cloneSectionLibrary({ sectionId }));
  };

  const onGoWebsiteEdit = () => {
    history.push("/wbuilder/admin/site/" + sectionId);
  };

  const onSectionDelete = () => {
    dispatch(
      deleteSectionLibrary({
        sectionId,
      })
    );
  };

  const publishUnpublishedHandler = (status: PUBLISH_STATUS) => {
    if (status === "PUBLISHED") {
      dispatch(toggleSectionLibraryPublishStatus({ id: sectionId, status: "UNPUBLISHED" }));
    } else {
      dispatch(toggleSectionLibraryPublishStatus({ id: sectionId, status: "PUBLISHED" }));
    }
  };

  const templateApproveStatusByAdminHandler = (status: APPROVE_STATUS) => {
    if (status === "APPROVE") {
      dispatch(toggleSectionLibraryApproveStatus({ id: sectionId, approveStatus: "NOT_APPROVE" }));
    } else {
      dispatch(toggleSectionLibraryApproveStatus({ id: sectionId, approveStatus: "APPROVE" }));
    }
  };

  useEffect(() => {
    if (isDeleting === false && isDeleteSuccess) {
      closeDeleteModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting, isDeleteSuccess]);

  return (
    <React.Fragment>
      <TableCell align={"right"}>
        <CustomPopoverMenu minWidth='160px' twoColumns={!!isAdmin} callbackOnClose={() => setIsActive(false)}>
          <CustomPopoverMenuButton>
            <CustomTooltip title='More Options' arrow placement='top'>
              <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={() => setIsActive(true)}>
                <MoreVertIcon fontSize={"small"} />
              </IconButton>
            </CustomTooltip>
          </CustomPopoverMenuButton>

          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Rename'
              icon={<DriveFileRenameOutlineIcon fontSize={"small"} />}
              onClick={onOpenRenameModal}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Edit Section'
              icon={<EditIcon fontSize={"small"} />}
              onClick={onGoWebsiteEdit}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Clone'
              icon={<FileCopyIcon fontSize={"small"} />}
              onClick={handleCloneWebsite}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          {isSuperAdmin ? (
            <CustomPopoverMenuItem
              title={isApproved ? "Unapproved" : "Approved"}
              icon={isApproved ? <UnpublishedIcon /> : <PublishIcon />}
              onClick={() => templateApproveStatusByAdminHandler(approveStatus)}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          <CustomPopoverMenuItem
            title='Delete'
            icon={<DeleteIcon fontSize={"small"} />}
            onClick={openDeleteModal}
            onClickHide={true}
          />

          {isAdmin ? (
            <CustomPopoverMenuItem
              title={isPublished ? "Unpublished" : "Publish"}
              icon={isPublished ? <UnpublishedIcon /> : <PublishIcon />}
              onClick={() => publishUnpublishedHandler(status)}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}
        </CustomPopoverMenu>
      </TableCell>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={onSectionDelete}
      />

      <BasicModalWithHeader title='Rename Section' open={openRenameModal} onClose={onCloseRenameModal}>
        <RenameSectionTemplate onClose={onCloseRenameModal} section={section} />
      </BasicModalWithHeader>
    </React.Fragment>
  );
};

export default ActionCell;
