import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import FunnelTypeService from "../../../services/funnel-type.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  createTypeFailed,
  createTypeSuccess,
  deleteTypeFailed,
  deleteTypeSuccess,
  fetchTypeFailed,
  fetchTypeSuccess,
  updateTypeFailed,
  updateTypeSuccess,
} from "./funnelTypeSlice";
import { CreateTypeI, UpdateTypeI } from "../../../interface/type.interface";

function* funnelTypeWatcher() {
  yield takeEvery("funnelType/fetchType", fetchTypeSaga);
  yield takeEvery("funnelType/fetchAllUserType", fetchAllUserTypeSaga);
  yield takeEvery("funnelType/createType", createTypeSaga);
  yield takeEvery("funnelType/updateType", updateTypeSaga);
  yield takeEvery("funnelType/deleteType", deleteTypeSaga);
}

function* fetchTypeSaga() {
  try {
    const response = yield* call(FunnelTypeService.fetchType);

    if (response.success) {
      yield put(fetchTypeSuccess(response.data));
    } else {
      yield put(fetchTypeFailed());
    }
  } catch (err) {
    yield put(fetchTypeFailed());
  }
}

function* fetchAllUserTypeSaga() {
  try {
    const response = yield* call(FunnelTypeService.fetchAllUserType);

    if (response.success) {
      yield put(fetchTypeSuccess(response.data));
    } else {
      yield put(fetchTypeFailed());
    }
  } catch (err) {
    yield put(fetchTypeFailed());
  }
}

function* createTypeSaga(action: PayloadAction<CreateTypeI>) {
  try {
    const response = yield* call(FunnelTypeService.createType, action.payload);

    if (response.success) {
      yield put(createTypeSuccess(response.data));
      showSuccessToaster("Type created");
    } else {
      yield put(createTypeFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createTypeFailed());
  }
}

function* updateTypeSaga(action: PayloadAction<UpdateTypeI>) {
  try {
    const response = yield* call(FunnelTypeService.updateType, action.payload);

    if (response.success) {
      yield put(updateTypeSuccess(response.data));
      showSuccessToaster("Type Updated");
    } else {
      yield put(updateTypeFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(updateTypeFailed());
  }
}

function* deleteTypeSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(FunnelTypeService.deleteType, action.payload);

    if (response.success) {
      yield put(deleteTypeSuccess(action.payload));
      showSuccessToaster("Type deleted");
    } else {
      yield put(deleteTypeFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteTypeFailed());
  }
}

export default function* funnelTypeSaga() {
  yield all([funnelTypeWatcher()]);
}
