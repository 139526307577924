import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteForever, RestoreFromTrash } from "@mui/icons-material";
import { Box, Checkbox, Stack, TableCell, Typography } from "@mui/material";
import useBoolean from "../../hooks/useBoolean";
import IconButton from "../../common/IconButton";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import { SingleWebsiteListWrp } from "../SitesList/SitesListStyles";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import { AdminTemplateI } from "../../interface/website-template.interface";
import {
  deleteTemplates,
  toggleSingleTemplate,
  trashTemplates,
} from "../../state/features/adminTemplates/adminTemplateSlice";
import { selectAdminTemplateState } from "../../state/features/adminTemplates/adminTemplateSelector";

interface IProps {
  template: AdminTemplateI;
}

const AdminSingleWebsiteInTrash: React.FC<IProps> = ({ template }: IProps) => {
  const dispatch = useDispatch();
  const { selectedTemplateIds, isDeleting } = useSelector(selectAdminTemplateState);
  const { setFalse: closeDeleteModal, setTrue: openDeleteModal, value: isDeleteModalOpen } = useBoolean();

  const { id, name } = template || {};
  const isActive = false;

  const trashWebsiteHandler = () => {
    dispatch(trashTemplates({ websiteIds: [id], inTrash: false }));
  };

  const deleteWebsiteHandler = () => {
    dispatch(deleteTemplates({ websiteIds: [id] }));
  };

  const isChecked = selectedTemplateIds.includes(id);
  return (
    <SingleWebsiteListWrp
      selected={selectedTemplateIds.includes(id) || isActive}
      className={selectedTemplateIds.includes(id) || isActive ? "active" : ""}
    >
      <TableCell>
        <Stack spacing={1} direction={"row"} alignItems={"center"}>
          <Checkbox
            edge='start'
            color='secondary'
            size={"small"}
            tabIndex={-1}
            checked={isChecked}
            onChange={() => dispatch(toggleSingleTemplate(id))}
            sx={{
              "& .PrivateSwitchBase-input": {
                pointerEvents: "auto !important",
              },
            }}
          />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: `100%` }}>
              <Typography variant='body1Medium' color='text.primary'>
                {name}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack className={"rrmsl-list-action-icons"}>
          <CustomTooltip title='Restore' arrow placement='top'>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={trashWebsiteHandler}>
              <RestoreFromTrash fontSize={"small"} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title='Delete Forever' arrow placement='top'>
            <IconButton variant={"outlined"} color={"error"} size='small' onClick={openDeleteModal}>
              <DeleteForever fontSize={"small"} />
            </IconButton>
          </CustomTooltip>
        </Stack>
      </TableCell>

      <DeleteWarningModal
        title={"Delete Forever!!"}
        isLoading={isDeleting}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteWebsiteHandler}
      />
    </SingleWebsiteListWrp>
  );
};

export default AdminSingleWebsiteInTrash;
