import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import TypesListItem from "./TypesListItem";
import AddOrEditTypesName from "../modals/AddOrEditTypesName";
import CommonSidebarAccordion from "../CommonSidebarAccordion";
import { CustomList } from "../../../SitesList/SitesListStyles";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import FolderListSkeleton from "../../../../common/LoadingView/FolderListSkeleton";
import useBoolean from "../../../../hooks/useBoolean";
import { fetchType } from "../../../../state/features/type/typeSlice";
import { selectTypeState } from "../../../../state/features/type/typeSelector";
import { selectPermissionState } from "../../../../state/features/permission/permissionSelector";

const TypesList: React.FC = () => {
  const dispatch = useDispatch();

  const { isAdmin } = useSelector(selectPermissionState);
  const { data, isLoading } = useSelector(selectTypeState);
  const { value: openNewTypeModal, setTrue: onOpenNewTypeModal, setFalse: onCloseNewTypeModal } = useBoolean(false);

  let content = <></>;
  if (isLoading) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        <FolderListSkeleton count={10} />
      </CustomList>
    );
  } else if (data.length > 0) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        {data.map((type) => (
          <TypesListItem type={type} key={type.id} isAdmin={isAdmin} />
        ))}
      </CustomList>
    );
  } else {
    content = (
      <Typography variant={"caption"} fontStyle={"italic"}>
        No types exist.
      </Typography>
    );
  }

  useEffect(() => {
    dispatch(fetchType());
  }, [dispatch]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    onOpenNewTypeModal();
  };

  return (
    <>
      <CommonSidebarAccordion title={"Types"} tooltipText={"Add New Type"} onClick={handleClick} isAdmin={isAdmin}>
        {content}
      </CommonSidebarAccordion>
      <BasicModalWithHeader title={"Add New Type"} open={openNewTypeModal} onClose={onCloseNewTypeModal}>
        <AddOrEditTypesName onClose={onCloseNewTypeModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default TypesList;
