import React, { useState } from "react";
import { Box, Collapse, Stack } from "@mui/material";
import TemplateGallery from "./Gallery/TemplateGallery";
import TemplateLibraryHeader from "./TemplateLibraryHeader";
import TemplateLibrarySidebar from "./Sidebar/TemplateLibrarySidebar";

const FunnelTemplateLibrary: React.FC = () => {
  const [showFilters, setShowFilters] = useState(true);

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "80vh", overflowY: "auto" }} p={3}>
      <Box sx={{ width: "90%", mx: "auto" }}>
        <TemplateLibraryHeader onClick={handleShowFilters} />

        <Stack direction={"row"}>
          {/* ## Template Sidebar ## */}
          <Collapse in={showFilters} orientation={"horizontal"}>
            <TemplateLibrarySidebar />
          </Collapse>
          {/* ## Template Main Gallery ## */}
          <TemplateGallery />
        </Stack>
      </Box>
    </Box>
  );
};

export default FunnelTemplateLibrary;
