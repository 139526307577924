import React, { useState } from "react";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AddOrEditFolderName from "./TopBar/AddOrEditFolderName";
import CoreButton from "../../common/Button/CoreButton";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";

const CreateFolder = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <Box my={1}>
      <CoreButton fullWidth variant='contained' color='secondary' startIcon={<Add />} onClick={openModal}>
        Create New Folder
      </CoreButton>

      <BasicModalWithHeader
        title='Create New Folder'
        icon={<CreateNewFolderIcon />}
        open={isModalOpen}
        onClose={closeModal}
      >
        <AddOrEditFolderName onClose={closeModal} isEditMode={false} />
      </BasicModalWithHeader>
    </Box>
  );
};

export default CreateFolder;
