import React from "react";
import { Divider, Stack } from "@mui/material";

import VariantPage from "./VariantPage";
import OriginalPage from "./OriginalPage";

const TestingArea: React.FC = () => {
  return (
    <Stack direction={"row"} p={4} justifyContent={"center"} sx={{ px: { xs: 2, lg: 8 } }}>
      <OriginalPage />
      <Divider light sx={{ flex: 1, minWidth: 30, alignSelf: "center" }} />
      <VariantPage />
    </Stack>
  );
};

export default TestingArea;
