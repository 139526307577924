import { useForm } from "react-hook-form";
import { Info } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Grid, InputAdornment, Stack } from "@mui/material";

import { AppDispatch } from "../../../state/store";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import CoreTextField from "../../../common/TextField/CoreTextField";
import {
  registerPatternFunnelStep,
  removeSpecialCharacters,
  replaceSpaceToHyphen,
} from "../../../helpers/constant/registerPattern";
import {
  addFunnelTemplateStep,
  resetAddFunnelTemplateStep,
  resetUpdateFunnelTemplateStep,
  updateFunnelTemplateStep,
} from "../../../state/features/funnelTemplateStep/funnelTemplateStepSlice";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import generateFunnelPreviewUrl from "../../../helpers/utils/generateFunnelPreviewUrl";
import { selectFunnelInfoData } from "../../../state/features/funnelInfo/funnelInfoSelector";
import { selectFunnelTemplateStepState } from "../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";
import {
  CreateFunnelTemplateStepPayloadI,
  FunnelTemplateStepI,
  UpdateFunnelTemplateStepPayloadI,
} from "../../../interface/funnelTemplateStep.interface";

interface StepModalInterface {
  onClose: () => void;
  isEdit?: boolean;
  stepInfo?: FunnelTemplateStepI;
}

const AddOrEditStepContent: React.FC<StepModalInterface> = ({ onClose, isEdit = false, stepInfo }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const {
    data: funnelSteps = [],
    isAdding,
    isAddSuccess,
    isUpdateSuccess,
    isUpdating,
  } = useSelector(selectFunnelTemplateStepState);
  const { subDomain, domainInfo } = useSelector(selectFunnelInfoData);

  const [isPathAlreadyEdited, setIsPathAlreadyEdited] = useState(isEdit);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FunnelTemplateStepI>({
    mode: "onSubmit",
  });

  // auto path created feature
  const fieldName = !isPathAlreadyEdited && watch("name");

  useEffect(() => {
    if (!isPathAlreadyEdited) {
      let path = fieldName ? fieldName.toLowerCase().trim() : "";
      path = removeSpecialCharacters(path);
      path = path.trimStart();
      path = replaceSpaceToHyphen(path);
      if (!path.startsWith("/")) path = "/" + path;
      setValue("path", path);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName]);

  useEffect(() => {
    if (stepInfo) {
      setValue("name", stepInfo.name);
      setValue("path", stepInfo.path);
      setValue("stepId", stepInfo.stepId);
      setValue("pageId", stepInfo.pageId);
      setValue("funnelId", stepInfo.funnelId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepInfo]);

  useEffect(() => {
    if (isAddSuccess || isUpdateSuccess) {
      dispatch(resetAddFunnelTemplateStep());
      dispatch(resetUpdateFunnelTemplateStep());
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddSuccess, isUpdateSuccess]);

  const onSubmit = (data: CreateFunnelTemplateStepPayloadI | UpdateFunnelTemplateStepPayloadI) => {
    // update step
    if ("stepId" in data && stepInfo && isEdit) {
      data.funnelId = funnelId;
      data.stepId = stepInfo.stepId;
      dispatch(updateFunnelTemplateStep(data));
    }
    // create step
    else {
      const payload = { ...data } as CreateFunnelTemplateStepPayloadI;
      payload.index = funnelSteps.length;
      payload.funnelId = funnelId;
      dispatch(addFunnelTemplateStep(payload));
    }
  };

  const loader = isAdding || isUpdating;
  const prefixUrl = generateFunnelPreviewUrl(subDomain, "", domainInfo);
  return (
    <Box>
      <Grid container component={"form"} spacing={1} p={3}>
        <Grid item xs={12}>
          <FormControl fullWidth color={"secondary"} error={!!(errors.name && errors.name.message)} required>
            <CoreTextField
              autoFocus={!isEdit}
              type='text'
              size='small'
              color='secondary'
              label={"Step Name"}
              placeholder='Enter step name here'
              error={!!(errors.name && errors.name.message)}
              helperText={errors.name && errors.name.message}
              {...register("name", registerPatternFunnelStep.name)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl color={"secondary"} fullWidth>
            <CoreTextField
              type='text'
              color='secondary'
              placeholder='Slug'
              label={
                <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                  <InputLabel component={"span"} required sx={{ mb: 0 }}>
                    Slug
                  </InputLabel>
                  <CustomTooltip
                    title={"The slug should be something like /about, /contact."}
                    arrow
                    placement={"right-end"}
                  >
                    <Info color={"action"} sx={{ fontSize: 16 }} />
                  </CustomTooltip>
                </Stack>
              }
              InputProps={{
                startAdornment: <InputAdornment position='start'>{prefixUrl}</InputAdornment>,
              }}
              error={!!(errors.path && errors.path.message)}
              helperText={errors.path && errors.path.message}
              {...register("path", registerPatternFunnelStep.path)}
              onChange={() => {
                if (!isPathAlreadyEdited) setIsPathAlreadyEdited(true);
              }}
              sx={{ "& input": { paddingLeft: "0 !important" }, "& .MuiInputAdornment-root": { mr: 0 } }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} pt={2}>
            <CoreButton type={"button"} color={"inherit"} onClick={onClose}>
              Cancel
            </CoreButton>
            <LoadingButton
              type={"submit"}
              isLoading={loader}
              variant={"contained"}
              color={"secondary"}
              onClick={handleSubmit(onSubmit)}
            >
              {isEdit ? "Update" : "Add"} step {loader && "..."}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddOrEditStepContent;
