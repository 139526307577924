import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../state/store";
import GlobalTablePagination from "../../../common/Pagination/GlobalTablePagination";
import removeFalsyObjectProperty from "../../../helpers/utils/removeFalsyObjectProperty";
import { FetchSectionLibraryPayloadI } from "../../../interface/websiteSectionLibrary.interface";
import { fetchSectionLibraries } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySlice";
import { selectSectionLibraryState } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";
import AdminSectionLibraryTable from "./AdminSectionLibraryTable";

const AdminSectionLibraryData: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { limit, filters, count, currentPage } = useSelector(selectSectionLibraryState);
  const { searchText, type } = filters;

  const onChangePage = (newPage: number) => {
    const payload = removeFalsyObjectProperty<FetchSectionLibraryPayloadI>({
      currentPage: newPage,
      limit,
      searchText,
      type,
    });
    payload.type = type ?? ""
    dispatch(fetchSectionLibraries(payload));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    const payload = removeFalsyObjectProperty<FetchSectionLibraryPayloadI>({
      currentPage: newPage,
      limit: perPage,
      type,
      searchText,
    });
    payload.type = type ?? ""
    dispatch(fetchSectionLibraries(payload));
  };

  return (
    <React.Fragment>
      <AdminSectionLibraryTable />

      <GlobalTablePagination
        count={count}
        page={currentPage}
        rowsPerPage={limit}
        onChangePage={onChangePage}
        onChangePerPage={onChangePerPage}
      />
    </React.Fragment>
  );
};

export default AdminSectionLibraryData;
