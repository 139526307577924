import { all, call, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getFunnelInfoFailed,
  getFunnelInfoSuccess,
  resetUpdateFunnelInfo,
  toggleActiveStatusFunnelSuccess,
  updateFunnelInfoSuccess,
} from "./funnelInfoSlice";
import { IUpdateFunnelInfo } from "./funnelInfoInterfaces";
import FunnelService from "../../../services/funnel.service";
import { IActiveStatusPayload } from "../funnel/funnel.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* funnelInfoWatcher() {
  yield takeLatest("funnelInfo/getFunnelInfo", getFunnelInfoSaga);
  yield takeLatest("funnelInfo/updateFunnelInfo", updateFunnelInfoSaga);
  yield takeLatest("funnelInfo/toggleActiveStatusFunnel", toggleActiveStatusFunnelSaga);
}

function* getFunnelInfoSaga(action: PayloadAction<{ id: number }>) {
  try {
    const response = yield* call(FunnelService.getFunnelInfo, action.payload);

    if (response.success) {
      yield put(getFunnelInfoSuccess(response.data));
    } else {
      yield put(getFunnelInfoFailed(response.message));
    }
  } catch (err) {
    yield put(getFunnelInfoFailed("Network or server error"));
  }
}

function* updateFunnelInfoSaga(action: PayloadAction<IUpdateFunnelInfo>) {
  try {
    const response = yield* call(FunnelService.updateFunnelInfo, action.payload);

    if (response.success) {
      yield put(updateFunnelInfoSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetUpdateFunnelInfo());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getFunnelInfoFailed("Network or server error"));
  }
}

function* toggleActiveStatusFunnelSaga(action: PayloadAction<IActiveStatusPayload>) {
  try {
    const response = yield* call(FunnelService.toggleActiveStatus, action.payload);

    yield put(toggleActiveStatusFunnelSuccess());
    if (response.success) {
      showSuccessToaster(response.message);
    } else {
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getFunnelInfoFailed("Network or server error"));
  }
}

export default function* funnelInfoSaga() {
  yield all([funnelInfoWatcher()]);
}
