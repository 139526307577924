import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { StyledSelect } from "../../../common/Select/StyledSelect";
import {AppDispatch} from "../../../state/store";
import {selectFunnelFilters} from "../../../state/features/funnel/funnelSelector";
import {addFunnelOrderByFilter} from "../../../state/features/funnel/funnelSlice";
import {FunnelOrderByList} from "../../../helpers/constant/funnelConstant";

const FunnelListFilterStatus = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { orderBy = "" } = useSelector(selectFunnelFilters);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(addFunnelOrderByFilter(event.target.value as string));
  };

  return (
      <StyledSelect size='small' color='secondary' value={orderBy} onChange={handleChange} sx={{ width: "200px" }}>
        {FunnelOrderByList.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.title}
            </MenuItem>
        ))}
      </StyledSelect>
  );
};
export default FunnelListFilterStatus;
