import React from "react";
import { useSelector } from "react-redux";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import RouteLink from "../../../common/Link/RouteLink";
import { selectAdminWebsiteSettingState } from "../../../state/features/adminWebsiteSetting/adminWebsiteSettingSelector";

const AdminWebPageHeading: React.FC = () => {
  const { data: pageSettings, isLoading } = useSelector(selectAdminWebsiteSettingState);
  const { name } = pageSettings || {};

  return (
    <Box sx={{ width: "max-content" }}>
      <RouteLink to={`/wbuilder/admin`}>
        <Stack direction={"row"} alignItems={"center"} spacing={1} p={2}>
          <IconButton size='small'>
            <KeyboardBackspaceRoundedIcon color={"secondary"} />
          </IconButton>
          <Typography variant={"h5"} color={"text.primary"}>
            {isLoading ? <Skeleton variant={"text"} width={200} /> : name}
          </Typography>
        </Stack>
      </RouteLink>
    </Box>
  );
};

export default AdminWebPageHeading;
