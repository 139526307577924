import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import TableLoaderSkeleton from "../../../common/LoadingView/TableLoaderSkeleton";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import { addFunnelReportsSearchText, getFunnelReports } from "../../../state/features/funnelReports/funnelReportsSlice";
import { selectFunnelReportsState } from "../../../state/features/funnelReports/funnelReportsSelector";
import { IFunnelStepReport } from "../../../state/features/funnelReports/funnelReports.interface";
import NoContentFoundIcon from "../../../common/Icons/NoContentFoundIcon";

const filterStepReports = (steps: IFunnelStepReport[] = [], searchText: string = "") => {
  return steps.filter((step) => step.name.toLowerCase().includes(searchText.toLowerCase()));
};

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Step name</TableCell>
        <TableCell align={"right"}>all page views</TableCell>
        <TableCell align={"right"}>unique page views</TableCell>
        {/*<TableCell align={"right"}>all opt ins</TableCell>*/}
        {/*<TableCell align={"right"} sx={{ pr: 2 }}>*/}
        {/*  unique opt ins*/}
        {/*</TableCell>*/}
      </TableRow>
    </TableHead>
  );
};

const StepReportsTable = () => {
  const dispatch = useDispatch();
  const { funnelId } = useParams<IFunnelDetailsParams>();

  const {
    data: funnelSteps = [],
    isLoading,
    filters: { searchText },
  } = useSelector(selectFunnelReportsState);

  useEffect(() => {
    dispatch(getFunnelReports({ funnelId: Number(funnelId) }));

    return () => {
      dispatch(addFunnelReportsSearchText(""));
    };
  }, []);

  // decide what to render
  let content = <></>;
  if (isLoading) {
    content = <TableLoaderSkeleton columns={4} numberOfRows={10} />;
  } else if (funnelSteps?.length === 0) {
    content = <GlobalEmptyPage icon={<NoContentFoundIcon/>} title={"No Reports Found!"} />;
  } else {
    content = (
      <TableContainer sx={{ height: "calc(100% - 95px)", overflowY: "auto" }}>
        <Table stickyHeader>
          <TableHeader />
          <TableBody>
            {filterStepReports(funnelSteps, searchText).map(
              ({ id, name, totalPageView, totalUniquePageView, totalOptIn, totalUniqueOptIn }) => (
                <TableRow key={id}>
                  <TableCell>{name}</TableCell>
                  <TableCell align={"right"}>{totalPageView}</TableCell>
                  <TableCell align={"right"}>{totalUniquePageView}</TableCell>
                  {/*<TableCell align={"right"}>{totalOptIn}</TableCell>*/}
                  {/*<TableCell align={"right"}>{totalUniqueOptIn}</TableCell>*/}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Paper elevation={0} sx={{ m: 3, p: 1, mt: 0, pb: 3 }}>
      {content}
    </Paper>
  );
};

export default StepReportsTable;
