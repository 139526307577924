import React, { useState } from "react";
import { format, isDate } from "date-fns";
import { useSelector } from "react-redux";
import { Box, Chip, Stack, TableCell, Typography } from "@mui/material";
import RouteLink from "../../../common/Link/RouteLink";
import ActionCell from "./SectionLibraryCells/ActionCell";
import { SingleWebsiteListWrp } from "../../SitesList/SitesListStyles";
import { SectionLibraryI } from "../../../interface/websiteSectionLibrary.interface";
import { selectPermissionState } from "../../../state/features/permission/permissionSelector";
import { selectAdminTemplateState } from "../../../state/features/adminTemplates/adminTemplateSelector";

interface IProps {
  template: SectionLibraryI;
}

const AdminSingleSection: React.FC<IProps> = ({ template }: IProps) => {
  const { sectionId, name, createdAt, approveStatus, status, type } = template || {};

  const [isActive, setIsActive] = useState(false);
  const { isAdmin } = useSelector(selectPermissionState);
  const { selectedTemplateIds } = useSelector(selectAdminTemplateState);

  const formattedCreatedAt =
    createdAt && isDate(new Date(createdAt)) ? format(new Date(createdAt), "MMM dd, yyyy") : "-";

  const isPublished = status === "PUBLISHED";
  const isApproved = approveStatus === "APPROVE";

  return (
    <SingleWebsiteListWrp
      selected={selectedTemplateIds.includes(sectionId) || isActive}
      className={selectedTemplateIds.includes(sectionId) || isActive ? "active" : ""}
    >
      <TableCell>
        <Stack direction={"row"} alignItems={"center"} ml={1}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: `100%` }}>
              {isAdmin ? (
                <RouteLink to={`/wbuilder/admin/section/${sectionId}`}>
                  <Typography variant='body1Medium' color='text.primary'>
                    {name}
                  </Typography>
                </RouteLink>
              ) : (
                <RouteLink to={`/wbuilder/admin/section/${sectionId}?superadmin=true`}>
                  <Typography variant='body1Medium' color='text.primary'>
                    {name}
                  </Typography>
                </RouteLink>
              )}
            </Stack>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction={"row"} gap={0.5} flexWrap={"wrap"}>
          <Chip variant={"outlined"} color={"info"} size={"small"} label={type} />
        </Stack>
      </TableCell>
      <TableCell>{formattedCreatedAt}</TableCell>
      <TableCell>
        {isPublished ? (
          <Chip color={"success"} size={"small"} label={"Published"} />
        ) : (
          <Chip color={"warning"} size={"small"} label={"Draft"} />
        )}
      </TableCell>
      <TableCell>
        {isApproved ? (
          <Chip color={"success"} size={"small"} label={"Approved"} />
        ) : (
          <Chip color={"error"} size={"small"} label={"Not Approved"} />
        )}
      </TableCell>
      <ActionCell section={template} isActive={isActive} setIsActive={setIsActive} />
    </SingleWebsiteListWrp>
  );
};

export default AdminSingleSection;
