import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FunnelInfoInterface, IUpdateFunnelInfo } from "./funnelInfoInterfaces";
import { IActiveStatusPayload } from "../funnel/funnel.interface";

const initialState = {
  data: {} as FunnelInfoInterface,
  isLoading: true,
  isSuccess: false,
  isError: false,
  error: "",

  isUpdating: false,
  isUpdateSuccess: false,
};

const funnelInfoSlice = createSlice({
  name: "funnelInfo",
  initialState,
  reducers: {
    getFunnelInfo(state, action: PayloadAction<{ id: number; loadingDisabled?: boolean }>) {
      if (!action.payload.loadingDisabled) {
        state.isLoading = true;
      }
    },
    getFunnelInfoSuccess(state, action: PayloadAction<FunnelInfoInterface>) {
      state.data = action.payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = "";
    },
    getFunnelInfoFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateFunnelInfo(state, action: PayloadAction<IUpdateFunnelInfo>) {
      state.isUpdating = true;
    },
    updateFunnelInfoSuccess(state, action: PayloadAction<IUpdateFunnelInfo>) {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.data = { ...state.data, ...action.payload };
    },
    resetUpdateFunnelInfo(state) {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    toggleActiveStatusFunnel: (state, action: PayloadAction<IActiveStatusPayload>) => {
      state.isUpdating = true;
      state.data = { ...state.data, activeStatus: action.payload.activeStatus };
    },
    toggleActiveStatusFunnelSuccess: (state) => {
      state.isUpdating = false;
    },
  },
});

export const {
  getFunnelInfo,
  getFunnelInfoSuccess,
  getFunnelInfoFailed,

  updateFunnelInfo,
  updateFunnelInfoSuccess,
  resetUpdateFunnelInfo,

  toggleActiveStatusFunnel,
  toggleActiveStatusFunnelSuccess,
} = funnelInfoSlice.actions;
export default funnelInfoSlice.reducer;
