import { put, call } from "typed-redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, all } from "redux-saga/effects";
import WebsiteSettings from "./website-setting.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  getWebsiteSettingFailed,
  getWebsiteSettingSuccess,
  resetUpdateWebsiteSetting,
  updateWebsiteSettingSuccess,
} from "./websiteSettingSlice";
import websiteSettingService from "../../../services/websiteSettings";

function* websiteSettingWatcher() {
  yield takeEvery("webSiteSetting/getWebsiteSetting", getWebsiteSettingSaga);
  yield takeEvery("webSiteSetting/updateWebsiteSetting", updateWebsiteSettingSaga);
}

function* getWebsiteSettingSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(websiteSettingService.getWebSiteSetting, action.payload);

    if (response.success) {
      yield put(getWebsiteSettingSuccess(response.data));
    } else {
      yield put(getWebsiteSettingFailed(response.message));
    }
  } catch (err) {
    yield put(getWebsiteSettingFailed("Network or server error"));
  }
}

function* updateWebsiteSettingSaga(action: PayloadAction<WebsiteSettings>) {
  try {
    const response = yield* call(websiteSettingService.updateWebSiteSetting, action.payload);

    if (response.success) {
      yield put(updateWebsiteSettingSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetUpdateWebsiteSetting());
      showErrorToaster(response.message);
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(resetUpdateWebsiteSetting());
    }
  }
}

export default function* websiteSettingSaga() {
  yield all([websiteSettingWatcher()]);
}
