import React from "react";
import { Box, Divider, Stack } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CoreButton from "../../../../common/Button/CoreButton";
import LoadingButton from "../../../../common/Button/LoadingButton";
import { ITemplate } from "../../../../state/features/template/template.interface";

interface IProps {
  onClose?: () => void;
  template: ITemplate;
  useTemplate: () => void;
  isLoading: boolean;
}

const EachTemplateModal: React.FC<IProps> = ({ onClose, template, useTemplate, isLoading }) => {
  return (
    <Box sx={{ height: "95vh" }}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
        <CoreButton variant={"outlined"} color={"secondary"} startIcon={<ArrowBackIcon />} onClick={onClose}>
          Back
        </CoreButton>
        <LoadingButton
          isLoading={isLoading}
          variant={"contained"}
          color={"secondary"}
          endIcon={<ArrowForwardIcon />}
          onClick={useTemplate}
        >
          Continue
        </LoadingButton>
      </Stack>
      <Divider light />
      <Box sx={{ width: "80%", height: "calc(100% - 100px)", margin: "auto", p: 3, pb: 0 }}>
        <iframe
          src={
            process.env.REACT_APP_WEBSITE_EDITOR_URL +
            `/mf/admin/preview/${btoa(
              JSON.stringify({
                websiteId: template.id,
                pageId: template.defaultPageId,
              })
            )}`
          }
          title={"Radiant smile"}
          height={"100%"}
          width={"100%"}
          style={{ border: "none" }}
        />
      </Box>
    </Box>
  );
};

export default EachTemplateModal;
