import React, { useState } from "react";
import { useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, FormControl, Grid, Paper, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { InputAdornmentWithLeftBorder } from "../../../common/TextField/InputAdornmentWithLeftBorder";
import { selectDomainCredentialState } from "../../../state/features/domain/domainSelector";
import copyFromClipBoard from "../../../helpers/utils/copyFromClipBoard";
import { showSuccessToaster } from "../../../helpers/utils/toaster";

interface Props {
  domain: string;
}
const CustomDomainSnippets: React.FC<Props> = ({ domain }) => {
  const { data } = useSelector(selectDomainCredentialState);
  const { cname } = data || {};
  const [copyId, setCopyId] = useState(0);

  const handleCopy = (c_name: string, inputId = 0) => {
    copyFromClipBoard(c_name);
    showSuccessToaster("Successfully copied");

    setCopyId(inputId);
    setTimeout(() => {
      setCopyId(0);
    }, 2000);
  };

  let firstRowType = "";
  let firstRowName = "";
  let firstRowContent = "";

  let secondRowType = "";
  let secondRowName = "";
  let secondRowContent = "";

  if (domain) {
    const domainArray = domain?.split(".");
    if (domainArray?.length === 2) {
      firstRowType = "A";
      firstRowName = "@";
      firstRowContent = "35.225.228.20";

      secondRowType = "CNAME";
      secondRowName = "www";
      secondRowContent = domain;
    }
    if (domainArray?.length === 3) {
      firstRowType = "A";
      firstRowName = domainArray[0];
      firstRowContent = "35.225.228.20";

      secondRowType = "CNAME";
      secondRowName = "www" + "." + domainArray[0];
      secondRowContent = domain;
    }
  }

  // const type = domainArray?.length === 2 ? "CNAME & A" : "CNAME";

  return (
    <Paper elevation={0}>
      <Box>
        <Typography variant={"body2"} mb={2}>
          You'll need to add a CNAME & A record to your DNS Settings. In order to make these changes you'll need to
          login to the account where you purchased your domain. After logging in, navigate to the domain DNS Settings.
        </Typography>
        <Typography variant={"body2"} mb={2}>
          Need Help? Reach out to the company you purchased your domain from. Let them know you need help adding a CNAME
          & A record and give them the info below.
        </Typography>
        <Grid container spacing={2} my={2}>
          <Grid item xs={3} sx={{ pt: "0 !important" }}>
            <InputLabel sx={{ mb: -1.5 }}>Record</InputLabel>
          </Grid>
          <Grid item xs={4} sx={{ pt: "0 !important" }}>
            <InputLabel sx={{ mb: -1.5 }}>Host</InputLabel>
          </Grid>
          <Grid item xs={5} sx={{ pt: "0 !important" }}>
            <InputLabel sx={{ mb: -1.5 }}>Required Value</InputLabel>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <CoreTextField disabled type='text' size='small' color='secondary' placeholder={firstRowType} />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <CoreTextField
                type='text'
                size='small'
                color='secondary'
                placeholder='@'
                value={firstRowName}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentWithLeftBorder
                      position='end'
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleCopy(firstRowName, 4)}
                    >
                      {copyId === 4 ? (
                        <CheckCircleOutlineIcon fontSize={"small"} />
                      ) : (
                        <ContentCopyIcon fontSize={"small"} />
                      )}
                    </InputAdornmentWithLeftBorder>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <CoreTextField
                type='text'
                size='small'
                color='secondary'
                value={firstRowContent}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentWithLeftBorder
                      position='end'
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleCopy(firstRowContent as string, 3)}
                    >
                      {copyId === 3 ? (
                        <CheckCircleOutlineIcon fontSize={"small"} />
                      ) : (
                        <ContentCopyIcon fontSize={"small"} />
                      )}
                    </InputAdornmentWithLeftBorder>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <CoreTextField disabled type='text' size='small' color='secondary' placeholder={secondRowType} />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <CoreTextField
                type='text'
                size='small'
                color='secondary'
                placeholder='www'
                value={secondRowName}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentWithLeftBorder
                      position='end'
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleCopy(secondRowName, 2)}
                    >
                      {copyId === 2 ? (
                        <CheckCircleOutlineIcon fontSize={"small"} />
                      ) : (
                        <ContentCopyIcon fontSize={"small"} />
                      )}
                    </InputAdornmentWithLeftBorder>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <CoreTextField
                type='text'
                size='small'
                color='secondary'
                placeholder={"mydomain.com"}
                value={secondRowContent}
                InputProps={{
                  endAdornment: (
                    <InputAdornmentWithLeftBorder
                      position='end'
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleCopy(secondRowContent as string, 1)}
                    >
                      {copyId === 1 ? (
                        <CheckCircleOutlineIcon fontSize={"small"} />
                      ) : (
                        <ContentCopyIcon fontSize={"small"} />
                      )}
                    </InputAdornmentWithLeftBorder>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default CustomDomainSnippets;
