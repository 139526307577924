import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack } from "@mui/material";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { SectionLibraryI } from "../../../interface/websiteSectionLibrary.interface";
import { registerPatternTemplateSettings } from "../../../helpers/constant/registerPattern";
import {
  updateSectionLibrary,
  resetUpdateSectionLibrarySuccess,
} from "../../../state/features/adminSectionLibrary/adminSectionLibrarySlice";
import { selectSectionLibraryState } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";

interface Props {
  onClose: () => void;
  section: SectionLibraryI;
}

const RenameSectionTemplate: React.FC<Props> = ({ section, onClose }) => {
  const dispatch = useDispatch();
  const { isUpdating, isUpdateSuccess } = useSelector(selectSectionLibraryState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SectionLibraryI>();

  useEffect(() => {
    setValue("name", section.name);
    setValue("type", section.type);
    setValue("sectionId", section.sectionId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(resetUpdateSectionLibrarySuccess());
      onClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUpdating]);

  const onSubmit = (data: SectionLibraryI) => {
    dispatch(updateSectionLibrary({ sectionId: data.sectionId, name: data.name, type: data.type }));
  };

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
        <CoreTextField
          label='Section Name'
          required
          sx={{ mt: 0 }}
          type='text'
          size='small'
          color='secondary'
          placeholder='Enter name here'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternTemplateSettings.name)}
        />
      </FormControl>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton isLoading={isUpdating} loadingText={"Renaming..."} type='submit' size='large' color='success'>
          Rename
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default RenameSectionTemplate;
