import React from "react";
import { useSelector } from "react-redux";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FunnelSettings from "../FunnelList/FunnelSettings";
import { selectFunnelInfoData } from "../../state/features/funnelInfo/funnelInfoSelector";

interface IProps {
  onBackToFunnel: () => void;
}

const FunnelSettingsPage: React.FC<IProps> = ({ onBackToFunnel }) => {
  const { name } = useSelector(selectFunnelInfoData);

  return (
    <Box sx={{ bgcolor: "other.outlinedBorder", height: "100%" }}>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
        <IconButton size={"small"} onClick={onBackToFunnel}>
          <ArrowBackIcon />
        </IconButton>
        <Stack sx={{ mr: "auto", ml: 1 }}>
          <Typography variant={"h6"}>Settings</Typography>
          <Typography variant={"caption"} color={"text.secondary"}>
            {name}
          </Typography>
        </Stack>
      </Stack>

      <Paper elevation={0}>
        <FunnelSettings />
      </Paper>
    </Box>
  );
};

export default FunnelSettingsPage;
