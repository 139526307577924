import React from "react";
import { styled } from "@mui/material";
import WebIcon from "@mui/icons-material/Web";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../../state/store";
import CoreButton from "../../../common/Button/CoreButton";
import { resetTemplateFilter } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySlice";
import { selectSectionLibraryState } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";

export const AllSitesButton = styled(CoreButton)(({ theme }) => ({
  justifyContent: "flex-start",
  "&.MuiButton-textSizeMedium": {
    fontSize: "15px",
    padding: theme.spacing(1, 2.5),
  },
  "&.active": {
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
    color: theme.palette.secondary.main,
  },
}));

const AdminAllSectionsButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    filters: { type },
  } = useSelector(selectSectionLibraryState);

  const handleSelectAll = () => {
    dispatch(resetTemplateFilter());
  };

  const isActive = !type;

  return (
    <AllSitesButton
      fullWidth
      variant={"text"}
      color={"inherit"}
      startIcon={<WebIcon fontSize={"small"} />}
      onClick={handleSelectAll}
      className={isActive ? "active" : ""}
    >
      All Sections 
    </AllSitesButton>
  );
};

export default AdminAllSectionsButton;
