import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { CustomList } from "../../SitesList/SitesListStyles";
import SectionLibraryType from "../AdminLeftSidebar/Category/SectionLibraryType";
import { SECTION_TYPES } from "../../../helpers/constant/sectionLibraryConstant";
import { selectPermissionState } from "../../../state/features/permission/permissionSelector";
import { selectSectionLibraryState } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";

const SectionTypeList: React.FC = () => {
  const { isAdmin } = useSelector(selectPermissionState);
  const {
    filters: { type },
  } = useSelector(selectSectionLibraryState);

  let content = <></>;
  if (SECTION_TYPES.length > 0) {
    content = (
      <CustomList className='pb_scroll_bar' id={"wb_folder_scroll_bar"} sx={{ m: 0, p: 0 }}>
        {SECTION_TYPES.map((sectionType) => (
          <SectionLibraryType
            category={sectionType}
            key={sectionType.id}
            selected={type === sectionType.id}
            isAdmin={isAdmin}
          />
        ))}
      </CustomList>
    );
  } else {
    content = (
      <Typography variant={"caption"} fontStyle={"italic"}>
        No categories found.
      </Typography>
    );
  }

  return (
    <React.Fragment>
      <Typography
        variant={"subtitle2"}
        color={"text.secondary"}
        sx={{ bgcolor: "other.gray", p: 1, position: "sticky", top: 0, zIndex: 99 }}
      >
        Types
      </Typography>
      {content}
    </React.Fragment>
  );
};

export default SectionTypeList;
