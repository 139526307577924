import React from "react";
import Divider from "@mui/material/Divider";
import { IconButton, Stack, Typography } from "@mui/material";

import SectionTypeList from "./SectionTypeList";
// import DraftAndTrash from "../FunnelAdminLeftSidebar/DraftAndTrash";
import AdminAllSectionsButton from "./AdminAllSectionsButton";
import ShowSideBarIcon from "../../../common/Icons/ShowSideBarIcon";
import HideSideBarIcon from "../../../common/Icons/HideSideBarIcon";
import { LeftSideBar, SidebarListWrapper, SideTopBarTab } from "../../SitesList/SitesListStyles";

interface Props {
  isHideLeftSide: boolean;
  isShowLeftSideTab: boolean;
  toggleLeftSide?: React.MouseEventHandler<HTMLButtonElement>;
}

const AdminWebsiteSectionLeftSide: React.FC<Props> = ({ toggleLeftSide, isHideLeftSide, isShowLeftSideTab }) => {
  return (
    <LeftSideBar className={isHideLeftSide ? "collapseLeftSide" : ""}>
      <Stack direction='row' alignItems='center' className={"iSidebarTitleBar"}>
        <Typography variant='body1' color='text.secondary' className={"iSidebarTitle"}>
          My Sections
        </Typography>
        <IconButton size={"large"} onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
          {isHideLeftSide || isShowLeftSideTab ? <ShowSideBarIcon /> : <HideSideBarIcon />}
        </IconButton>
      </Stack>
      <SidebarListWrapper
        px={1}
        className={`${isHideLeftSide ? "slw-hide-sidebar" : ""} ${
          isShowLeftSideTab ? "slw-show-sidebar-tab" : ""
        } admin-template-sidebar-list`}
      >
        <SideTopBarTab>
          <Typography variant='body1' color='text.secondary'>
            My Sections
          </Typography>
          <IconButton size='large' onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
            <HideSideBarIcon />
          </IconButton>
        </SideTopBarTab>
        <AdminAllSectionsButton />
        <Stack spacing={1} my={1}>
          <SectionTypeList />
        </Stack>
        <Divider light />
        {/* <DraftAndTrash /> */}
      </SidebarListWrapper>
    </LeftSideBar>
  );
};

export default AdminWebsiteSectionLeftSide;
