import React, { useState } from "react";
import { format, isDate } from "date-fns";
import { useHistory } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { Box, Checkbox, Chip, Stack, TableCell, Typography } from "@mui/material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RenameTemplate from "./RenameTemplate";
import useBoolean from "../../hooks/useBoolean";
import IconButton from "../../common/IconButton";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import RouteLink from "../../common/Link/RouteLink";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import { SingleWebsiteListWrp } from "../SitesList/SitesListStyles";
import { goToPreviewPage } from "../../helpers/utils/goToPreviewPage";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import { cloneTemplate } from "../../state/features/adminTemplates/adminTemplateSlice";
import { selectPermissionState } from "../../state/features/permission/permissionSelector";
import { AdminTemplateI, PUBLISH_STATUS, STATUS } from "../../interface/website-template.interface";
import { selectAdminTemplateState } from "../../state/features/adminTemplates/adminTemplateSelector";
import {
  toggleApproveStatusByAdmin,
  togglePublishStatus,
  toggleSingleTemplate,
  trashTemplates,
} from "../../state/features/adminTemplates/adminTemplateSlice";

interface IProps {
  template: AdminTemplateI;
}

const AdminSingleWebsite: React.FC<IProps> = ({ template }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, name, totalPage, createdAt = "", status, publishStatus, defaultPageId } = template || {};

  const [isActive, setIsActive] = useState(false);
  const { setFalse: closeDeleteModal, setTrue: openDeleteModal, value: isDeleteModalOpen } = useBoolean();
  const { value: openRenameModal, setTrue: onOpenRenameModal, setFalse: onCloseRenameModal } = useBoolean(false);
  const { isSuperAdmin, isAdmin } = useSelector(selectPermissionState);
  const { selectedTemplateIds, isDeleting } = useSelector(selectAdminTemplateState);

  const trashWebsiteHandler = () => {
    dispatch(trashTemplates({ websiteIds: [id], inTrash: true }));
  };

  const handleCloneWebsite = () => {
    dispatch(cloneTemplate({ websiteId: id }));
  };

  const onGoWebsitePreview = () => {
    goToPreviewPage(defaultPageId, id, true);
  };

  const onGoWebsiteEdit = () => {
    history.push("/wbuilder/admin/site/" + id);
  };

  const formattedCreatedAt = isDate(new Date(createdAt)) ? format(new Date(createdAt), "MMM dd, yyyy") : "-";
  const isChecked = selectedTemplateIds.includes(id);

  const isPublished = publishStatus === "PUBLISHED";
  const isApproved = status === "APPROVE";

  const publishUnpublishedHandler = (publishedStatus: PUBLISH_STATUS) => {
    if (publishedStatus === "PUBLISHED") {
      dispatch(togglePublishStatus({ websiteId: id, status: "DRAFT" }));
    } else {
      dispatch(togglePublishStatus({ websiteId: id, status: "PUBLISHED" }));
    }
  };

  const templateApproveStatusByAdminHandler = (status: STATUS) => {
    if (status === "APPROVE") {
      dispatch(toggleApproveStatusByAdmin({ websiteId: id, status: "NOT_APPROVE" }));
    } else {
      dispatch(toggleApproveStatusByAdmin({ websiteId: id, status: "APPROVE" }));
    }
  };

  return (
    <SingleWebsiteListWrp
      selected={selectedTemplateIds.includes(id) || isActive}
      className={selectedTemplateIds.includes(id) || isActive ? "active" : ""}
    >
      <TableCell>
        <Stack direction={"row"} alignItems={"center"} ml={1}>
          <Checkbox
            edge='start'
            color='secondary'
            size={"small"}
            tabIndex={-1}
            checked={isChecked}
            onChange={() => dispatch(toggleSingleTemplate(id))}
            sx={{
              "& .PrivateSwitchBase-input": {
                pointerEvents: "auto !important",
              },
            }}
          />
          <Box
            sx={{
              width: "100%",
              ml: 1,
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: `100%` }}>
              {isAdmin ? (
                <RouteLink to={`/wbuilder/admin/site/${id}`}>
                  <Typography variant='body1Medium' color='text.primary'>
                    {name}
                  </Typography>
                </RouteLink>
              ) : (
                <Typography variant='body1Medium' color='text.primary'>
                  {name}
                </Typography>
              )}
            </Stack>

            <Typography variant='caption' color='text.secondary' fontWeight={300}>
              {totalPage} Pages
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>{formattedCreatedAt}</TableCell>
      <TableCell>
        {isPublished ? (
          <Chip color={"success"} size={"small"} label={"Published"} />
        ) : (
          <Chip color={"warning"} size={"small"} label={"Draft"} />
        )}
      </TableCell>
      <TableCell>
        {isApproved ? (
          <Chip color={"success"} size={"small"} label={"Approved"} />
        ) : (
          <Chip color={"error"} size={"small"} label={"Not Approved"} />
        )}
      </TableCell>
      <TableCell align={"right"}>
        <CustomPopoverMenu
          twoColumns={isAdmin ? true : false}
          position={"bottom"}
          callbackOnClose={() => setIsActive(false)}
        >
          <CustomPopoverMenuButton>
            <CustomTooltip title='More Options' arrow placement='top'>
              <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={() => setIsActive(true)}>
                <MoreVertIcon fontSize={"small"} />
              </IconButton>
            </CustomTooltip>
          </CustomPopoverMenuButton>
          <CustomPopoverMenuItem
            title='Preview'
            icon={<VisibilityIcon fontSize={"small"} />}
            onClick={onGoWebsitePreview}
            onClickHide={true}
          />
          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Rename'
              icon={<DriveFileRenameOutlineIcon fontSize={"small"} />}
              onClick={onOpenRenameModal}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}
          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Edit Website'
              icon={<EditIcon fontSize={"small"} />}
              onClick={onGoWebsiteEdit}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Clone'
              icon={<FileCopyIcon fontSize={"small"} />}
              onClick={handleCloneWebsite}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          <CustomPopoverMenuItem
            title='Delete'
            icon={<DeleteIcon fontSize={"small"} />}
            onClick={openDeleteModal}
            onClickHide={true}
          />

          {isAdmin ? (
            <CustomPopoverMenuItem
              title={isPublished ? "Unpublished" : "Publish"}
              icon={isPublished ? <UnpublishedIcon /> : <PublishIcon />}
              onClick={() => publishUnpublishedHandler(publishStatus)}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          {isSuperAdmin ? (
            <CustomPopoverMenuItem
              title={isApproved ? "Unapproved" : "Approved"}
              icon={isApproved ? <UnpublishedIcon /> : <PublishIcon />}
              onClick={() => templateApproveStatusByAdminHandler(status)}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}
        </CustomPopoverMenu>
      </TableCell>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={trashWebsiteHandler}
      />
      <BasicModalWithHeader title='Rename Template' open={openRenameModal} onClose={onCloseRenameModal}>
        <RenameTemplate onClose={onCloseRenameModal} template={{ id: template.id, name: template.name }} />
      </BasicModalWithHeader>
    </SingleWebsiteListWrp>
  );
};

export default AdminSingleWebsite;
