import React from "react";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Box, Stack, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import useBoolean from "../../../hooks/useBoolean";
import { SiteListHeaderWrp } from "../FunnelListStyles";
import CoreButton from "../../../common/Button/CoreButton";
import CreateNewFunnelModal from "../CreateNewFunnelModal";
import WebsiteFunnelSwitchingTabs from "../../WebsiteFunnelSwitchingTabs";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import FunnelTemplateLibrary from "../funnelTemplateLibrary/FunnelTemplateLibrary";
import { resetTemplateLibraryFilterState } from "../../../state/features/funnel-template-library/funnelTemplateLibrarySlice";

const FunnelListHeader = () => {
  const dispatch = useDispatch();
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);
  const { value: openTemplateModal, setTrue: onOpenTemplateModal, setFalse: onCloseTemplateModal } = useBoolean(false);

  return (
    <React.Fragment>
      <SiteListHeaderWrp>
        <Stack direction={"row"} alignItems={"center"} gap={2.5}>
          <Box width={268}>
            <Typography variant={"h5"} color={"text.primary"}>
              My Funnels
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              Manage all your funnels in one spot.
            </Typography>
          </Box>

          <WebsiteFunnelSwitchingTabs
            firstPath={"/wbuilder/sites"}
            secondPath={"/wbuilder/funnels"}
            firstTitle={"Websites"}
            secondTitle={"Funnels"}
          />
        </Stack>

        <Stack direction={"row"} spacing={1} alignSelf={"flex-end"}>
          <CoreButton
            color={"secondary"}
            variant={"outlined"}
            size={"large"}
            startIcon={<SettingsIcon />}
            href={"/wbuilder/settings"}
          >
            Settings
          </CoreButton>
          <CoreButton
            color='secondary'
            variant='contained'
            size='large'
            startIcon={<AddIcon />}
            onClick={onOpenModal}
            sx={{ width: 200 }}
          >
            Create Funnel
          </CoreButton>
        </Stack>
      </SiteListHeaderWrp>

      <BasicModalWithHeader width={850} title='Create New Funnel' open={openModal} onClose={onCloseModal}>
        <CreateNewFunnelModal onClose={onCloseModal} onOpenTemplateModal={onOpenTemplateModal} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        width={"98%"}
        title={"Template Library (Funnel)"}
        disableOutSideClick={true}
        open={openTemplateModal}
        onClose={() => {
          onCloseTemplateModal();
          dispatch(resetTemplateLibraryFilterState());
        }}
      >
        <FunnelTemplateLibrary />
      </BasicModalWithHeader>
    </React.Fragment>
  );
};

export default FunnelListHeader;
