import React from "react";
import { useSelector } from "react-redux";
import { Box, CircularProgress, Grid, Paper, Stack, styled, Typography } from "@mui/material";
import AdminSingleWebPageCard from "./AdminSingleWebPageCard";
import { selectAdminTemplatePageState } from "../../../state/features/adminTemplatePage/adminTemplatePageSelector";
import AdminAddNewPageAction from "./AdminAddNewPageAction";
import AdminPageCreationEmptyPage from "./AdminPageCreationEmptyPage";

const CardPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  "&:hover": {
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.other.secondaryHover,
  },
}));

const PagesWrapper = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 350px)",
  overflowY: "auto",
  padding: theme.spacing(2),
  paddingTop: 0,
}));

const AdminWebsiteAllPages: React.FC = () => {
  const { isLoading, data } = useSelector(selectAdminTemplatePageState);

  let content = <></>;
  if (isLoading) {
    content = (
      <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }}>
        <CircularProgress color={"secondary"} size={25} sx={{ position: "relative" }} />
        <Typography color={"secondary.main"}>Loading Pages...</Typography>
      </Stack>
    );
  } else if (data.length > 0) {
    content = (
      <Grid container spacing={3}>
        {data.map((templatePage) => {
          return (
            <Grid item xs={6} md={4} lg={3} xxl={2} key={templatePage.pageId}>
              <CardPaper variant={"outlined"}>
                <AdminSingleWebPageCard pageInfo={templatePage} />
              </CardPaper>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    content = <AdminPageCreationEmptyPage />;
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack alignItems={"flex-end"} p={2} pb={0}>
          {data?.length > 0 && <AdminAddNewPageAction />}
        </Stack>
        <PagesWrapper>{content}</PagesWrapper>
      </Stack>
    </>
  );
};

export default AdminWebsiteAllPages;
