import apiSlice from "../../apiSlice";
import { IWebsite } from "./website.interface";
import { SuccessResponseIn, WithPagination } from "../../../interface/response.interface";

export const websitePageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWebsiteList: builder.query<
      SuccessResponseIn<WithPagination<IWebsite>>,
      { currentPage: number; limit: number; searchText: string }
    >({
      query: (params) => ({
        url: "website/list",
        method: "GET",
        params: params,
      }),
    }),
  }),
});

export const { useGetWebsiteListQuery } = websitePageApiSlice;
