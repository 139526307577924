import React from "react";
import { Add } from "@mui/icons-material";
import useBoolean from "../../hooks/useBoolean";
import AddOrEditPageInfo from "./AddOrEditPageInfo";
import CoreButton from "../../common/Button/CoreButton";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";

const AddNewPageAction = () => {
  const { value: openPageModal, setFalse: onClosePageModal, setTrue: onOpenPageModal } = useBoolean(false);

  return (
    <>
      <CoreButton
        variant='contained'
        color='secondary'
        size={"large"}
        startIcon={<Add />}
        onClick={onOpenPageModal}
        sx={{ minWidth: "max-content" }}
      >
        Add New Page
      </CoreButton>

      <BasicModalWithHeader
        open={openPageModal}
        onClose={onClosePageModal}
        title='Add New Page for Website'
        width={500}
      >
        <AddOrEditPageInfo onClose={onClosePageModal} isEdit={false} />
      </BasicModalWithHeader>
    </>
  );
};

export default AddNewPageAction;
