import React from "react";
import { Box } from "@mui/material";
import StepReportsTable from "./StepReportsTable";
import StepReportsHeader from "./StepReportsHeader";

interface IProps {
  onBackToFunnel: () => void;
}

const ReportPage: React.FC<IProps> = ({ onBackToFunnel }) => {
  return (
    <Box sx={{ bgcolor: "other.outlinedBorder", height: "100%", pb:1 }}>
      <StepReportsHeader onBackToFunnel={onBackToFunnel} />
      <StepReportsTable />
    </Box>
  );
};

export default ReportPage;
