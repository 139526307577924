import { PermissionI } from "../state/features/permission/permissionSlice";
import httpRequest from "./api/httpRequest";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Website Builder API URL not found in env!");

class PermissionService {
  authenticateUser() {
    return httpRequest.get<PermissionI>(websiteBuilderAPI + "admin/website/has/permission");
  }
}

const permissionService = new PermissionService();
export default permissionService;
