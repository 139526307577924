import React, { useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WebsiteTemplateCard from "./WebsiteTemplateCard";
import CoreButton from "../../../../common/Button/CoreButton";
import { EmptyFormListIcon } from "../../../../common/Icons/EmptyDataIcons";
import GlobalEmptyPage from "../../../../common/GlobalEmptyPage/GlobalEmptyPage";
import TemplateListSkeleton from "../../../../common/LoadingView/TemplateListSkeleton";
import { selectTemplateState } from "../../../../state/features/template/templateSelector";
import { getMoreTemplates, getTemplates } from "../../../../state/features/template/templateSlice";

const TemplateGallery: React.FC = () => {
  const dispatch = useDispatch();

  const {
    data: templateList,
    isLoading,
    currentPage,
    limit,
    count,
    isMoreFetching,
    filters,
  } = useSelector(selectTemplateState);
  const { searchText, selectedCategoryId, selectedTypeIds, selectedTagIds } = filters || {};

  useEffect(() => {
    dispatch(
      getTemplates({
        currentPage: 1,
        limit: limit,
        categoryId: selectedCategoryId as number,
        typeIds: selectedTypeIds,
        tagIds: selectedTagIds,
        searchText: searchText,
      })
    );
  }, [dispatch, limit, searchText, selectedCategoryId, selectedTypeIds, selectedTagIds]);

  const handleShowMoreList = () => {
    dispatch(
      getMoreTemplates({
        currentPage: currentPage + 1,
        limit: limit,
        categoryId: selectedCategoryId as number,
        typeIds: selectedTypeIds,
        tagIds: selectedTagIds,
        searchText: searchText,
      })
    );
  };

  // decide what to render
  let content: React.ReactNode | null = null;
  if (isLoading) {
    content = <TemplateListSkeleton count={10} />;
  } else if (templateList?.length === 0) {
    content = (
      <Box sx={{ maxHeight: 500 }}>
        <GlobalEmptyPage icon={<EmptyFormListIcon />} title={"No Template Found"} />
      </Box>
    );
  } else if (templateList.length > 0) {
    content = (
      <Grid container spacing={3} rowSpacing={5}>
        {templateList.map((template) => {
          return (
            <Grid key={template.id as number} item xs={12} sm={6} md={4} xl={3}>
              <WebsiteTemplateCard template={template} />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  const hideShowMoreButton = isMoreFetching || isLoading || templateList?.length <= count;
  return (
    <Box width={150} sx={{ flex: 1, my: 3 }}>
      {content}

      {isMoreFetching && <TemplateListSkeleton count={10} />}
      <Stack direction={"row"} justifyContent={"center"}>
        {!hideShowMoreButton && (
          <CoreButton endIcon={<KeyboardArrowDownIcon />} onClick={handleShowMoreList}>
            See More
          </CoreButton>
        )}
      </Stack>
    </Box>
  );
};

export default TemplateGallery;
