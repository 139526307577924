import { combineReducers } from "@reduxjs/toolkit";
import apiSlice from "./apiSlice";
import funnelApiSlice from "./funnelApiSlice";
import tagSlice from "./features/tag/tagSlice";
import typeSlice from "./features/type/typeSlice";
import folderSlice from "./features/folder/folderSlice";
import categorySlice from "./features/category/categorySlice";
import templateSlice from "./features/template/templateSlice";
import websiteSlice from "./features/website/websiteSlice";
import websiteSettingSlice from "./features/website-settings/websiteSettingSlice";
import websiteReportsSlice from "./features/websiteReports/websiteReportsSlice";
import adminTemplateSlice from "./features/adminTemplates/adminTemplateSlice";
import adminWebsiteSettingSlice from "./features/adminWebsiteSetting/adminWebsiteSettingSlice";
import permissionSlice from "./features/permission/permissionSlice";
import adminTemplatePageSlice from "./features/adminTemplatePage/adminTemplatePageSlice";
import domainSlice from "./features/domain/domainSlice";
import adminSectionLibrarySlice from "./features/adminSectionLibrary/adminSectionLibrarySlice";
import redirectSlice from "./features/urlRirects/redirectSlice";
import funnelSlice from "./features/funnel/funnelSlice";
import funnelStepSlice from "./features/funnelStep/funnelStepSlice";
import funnelInfoSlice from "./features/funnelInfo/funnelInfoSlice";
import funnelStepPagesSlice from "./features/funnelStepPages/funnelStepPagesSlice";
import funnelTemplateSlice from "./features/funnelTemplate/funnelTemplateSlice";
import funnelReportsSlice from "./features/funnelReports/funnelReportsSlice";
import funnelTemplateStepSlice from "./features/funnelTemplateStep/funnelTemplateStepSlice";
import funnelTemplateSettingSlice from "./features/funnelTemplateSetting/funnelTemplateSettingSlice";
import funnelCategorySlice from "./features/funnel-category/funnelCategorySlice";
import funnelTagSlice from "./features/funnel-tag/funnelTagSlice";
import funnelTypeSlice from "./features/funnel-type/funnelTypeSlice";
import funnelTemplateLibrarySlice from "./features/funnel-template-library/funnelTemplateLibrarySlice";

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [funnelApiSlice.reducerPath]: funnelApiSlice.reducer,
  folder: folderSlice,
  template: templateSlice,
  website: websiteSlice,
  webSiteSetting: websiteSettingSlice,
  websiteReports: websiteReportsSlice,
  category: categorySlice,
  tag: tagSlice,
  type: typeSlice,
  adminTemplate: adminTemplateSlice,
  adminWebsiteSetting: adminWebsiteSettingSlice,
  permission: permissionSlice,
  adminTemplatePage: adminTemplatePageSlice,
  domain: domainSlice,
  adminSectionLibrary: adminSectionLibrarySlice,
  urlRedirect: redirectSlice,
  funnel: funnelSlice,
  funnelStep: funnelStepSlice,
  funnelInfo: funnelInfoSlice,
  funnelStepPages: funnelStepPagesSlice,
  funnelTemplate: funnelTemplateSlice,
  funnelReports: funnelReportsSlice,
  funnelTemplateStep: funnelTemplateStepSlice,
  funnelTemplateSetting: funnelTemplateSettingSlice,
  funnelCategory: funnelCategorySlice,
  funnelTag: funnelTagSlice,
  funnelType: funnelTypeSlice,
  funnelTemplateLibrary: funnelTemplateLibrarySlice,
});

export default rootReducer;
