import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CoreButton from "../../common/Button/CoreButton";
import { selectFolderId } from "../../state/features/website/websiteSlice";
import { selectWebsiteFilters } from "../../state/features/website/websiteSelector";

export const AllSitesButton = styled(CoreButton)(({ theme }) => ({
  justifyContent: "flex-start",
  "&.MuiButton-textSizeMedium": {
    fontSize: "15px",
    padding: theme.spacing(1, 2.5),
  },
  "&.active": {
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
    color: theme.palette.secondary.main,
  },
}));

const FolderAllSites = () => {
  const dispatch = useDispatch();
  const { folderId, isFavorite, isArchived, inTrash } = useSelector(selectWebsiteFilters);

  const handleSelectAll = () => {
    dispatch(selectFolderId(null));
  };

  const isActive = !isFavorite && !isArchived && !inTrash && folderId === null;

  return (
    <AllSitesButton
      fullWidth
      variant={"text"}
      color={"inherit"}
      startIcon={<FolderIcon color={isActive ? "secondary" : "action"} fontSize={"small"} />}
      onClick={handleSelectAll}
      className={isActive ? "active" : ""}
    >
      All Sites
    </AllSitesButton>
  );
};

export default FolderAllSites;
