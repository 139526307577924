import { styled } from "@mui/material";
import CoreButton from "./CoreButton";

interface ButtonForIconProps {
  textColor?: string;
}

const ButtonForIcon = styled(CoreButton)<ButtonForIconProps>(
  ({ theme, textColor, backgroundColor = theme.palette.other.dividerFillColor }) => ({
    padding: "8px 8px !important",
    backgroundColor: backgroundColor,
    color: textColor,
    "&:hover": { backgroundColor: backgroundColor },
    "&.MuiButton-root:focus": { backgroundColor: `${backgroundColor} !important` },
    "& .MuiButton-startIcon": {
      marginRight: "0px",
      marginLeft: "0px",
    },
    "&.reset": {
      "&:hover": {
        "& .MuiButton-startIcon": {
          transform: "rotate(360deg)",
          transition: "0.4s ease-in-out",
        },
      },
    },
  })
);

export default ButtonForIcon;
