import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import {
  IAddWebsitePayload,
  IAddWebsitesToFoldersPayload,
  IArchiveStatusPayload,
  ICloneWebsitePayload,
  IDeleteForeverPayload,
  IFavoriteStatusPayload,
  IGetWebsitesPayload,
  IRemoveFolderFromWebsitePayload,
  IRenamedWebsitePayload,
  IWebsite,
  IWebsiteRemovePayload,
} from "./website.interface";
import { websiteOrderByList } from "../../../helpers/constant/websiteConstant";

const initialState = {
  data: [] as IWebsite[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  currentPage: 1,
  limit: 10,
  nextPage: 1,
  totalPages: 1,

  renderId: "",
  selectedWebsiteIds: [] as number[],

  isAdding: false,
  isAddSuccess: false,
  addedWebsiteData: {} as IWebsite,
  isNewWebsiteInsideFolder: false,
  newWebsiteFolderId: 0,

  isRenaming: false,
  isRenameSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  filters: {
    folderId: null as number | null,
    isFavorite: false,
    isArchived: false,
    inTrash: false,
    searchText: "",
    orderBy: websiteOrderByList[2].value,
  },
};

const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    getWebsites: (state, action: PayloadAction<IGetWebsitesPayload>) => {
      if (!state.renderId) state.isLoading = true;
    },
    getWebsitesSuccess: (state, action: PayloadAction<WithPagination<IWebsite>>) => {
      const { content, count, limit, currentPage } = action.payload;
      state.data = content;
      state.count = count;
      state.limit = +limit;
      state.currentPage = currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getWebsitesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    forceWebsiteRender: (state) => {
      state.renderId = nanoid(5);
    },

    addNewWebsiteInsideFolder: (state, action) => {
      state.isNewWebsiteInsideFolder = action.payload.status;
      state.newWebsiteFolderId = action.payload.folderId;
    },
    addWebsite: (state, _action: PayloadAction<IAddWebsitePayload>) => {
      state.isAdding = true;
    },
    addWebsiteSuccess: (state, action: PayloadAction<IWebsite>) => {
      state.addedWebsiteData = action.payload;
      state.isAdding = false;
      state.isAddSuccess = true;
    },
    addWebsiteFailed: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },
    resetAddWebsiteSuccess: (state) => {
      state.isAddSuccess = false;
      state.addedWebsiteData = {} as IWebsite;
    },

    renamedWebsite: (state, action: PayloadAction<IRenamedWebsitePayload>) => {
      state.isRenaming = true;
    },
    renamedWebsiteSuccess: (state, action: PayloadAction<IRenamedWebsitePayload>) => {
      state.isRenaming = false;
      state.isRenameSuccess = true;
      state.data = state.data.map((website) => {
        if (website.id === action.payload.websiteId) return { ...website, name: action.payload.name };
        return website;
      });
    },
    renamedWebsiteFailed: (state) => {
      state.isRenaming = false;
      state.isRenameSuccess = false;
    },
    resetRenamedWebsiteSuccess: (state) => {
      state.isRenameSuccess = false;
    },

    deleteWebsite: (state, action: PayloadAction<IWebsiteRemovePayload>) => {
      state.isDeleting = true;
    },
    deleteWebsiteSuccess: (state, action: PayloadAction<IWebsiteRemovePayload>) => {
      if (action.payload) {
        state.data = state.data.filter((website) => !action.payload.websiteIds.includes(website.id as number));
      }
      state.isDeleting = false;
      state.selectedWebsiteIds = [];
      state.renderId = nanoid(5);
    },
    deleteWebsiteFailed: (state) => {
      state.isDeleting = false;
    },

    deleteWebsiteForever: (state, action: PayloadAction<IDeleteForeverPayload>) => {
      state.isDeleting = true;
    },
    deleteWebsiteForeverSuccess: (state, action: PayloadAction<IDeleteForeverPayload>) => {
      if (action.payload) {
        state.data = state.data.filter((website) => !action.payload.websiteIds.includes(website.id as number));
      }
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.selectedWebsiteIds = [];
      state.renderId = nanoid(5);
    },
    deleteWebsiteForeverFailed: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },
    resetDeleteWebsiteForeverSuccess: (state) => {
      state.isDeleteSuccess = false;
    },

    cloneWebsite: (state, action: PayloadAction<ICloneWebsitePayload>) => {
      state.isUpdating = true;
    },
    cloneWebsiteSuccess: (state) => {
      state.isUpdating = false;
      state.renderId = nanoid(5);
    },
    cloneWebsiteFailed: (state) => {
      state.isUpdating = false;
    },

    toggleFavStatus: (state, action: PayloadAction<IFavoriteStatusPayload>) => {
      state.isUpdating = true;
    },
    toggleFavStatusSuccess: (state, action: PayloadAction<IFavoriteStatusPayload>) => {
      state.data = state.data.map((website) => {
        if (website.id === action.payload.websiteId)
          return {
            ...website,
            isFavorite: action.payload.isFavorite,
          };
        return website;
      });
      state.isUpdating = false;
      state.selectedWebsiteIds = [];
      if (state.filters.isFavorite) state.renderId = nanoid(5);
    },
    toggleFavStatusFailed: (state) => {
      state.isUpdating = false;
    },

    toggleArchiveStatus: (state, action: PayloadAction<IArchiveStatusPayload>) => {
      state.isUpdating = true;
    },
    toggleArchiveStatusSuccess: (state, action: PayloadAction<IArchiveStatusPayload>) => {
      state.data = state.data.filter((website) => !action.payload.websiteIds.includes(website.id as number));
      state.isUpdating = false;
      state.selectedWebsiteIds = [];
      state.renderId = nanoid(5);
    },
    toggleArchiveStatusFailed: (state) => {
      state.isUpdating = false;
    },

    // website selection actions
    selectedAllWebsite: (state) => {
      state.selectedWebsiteIds = state.data.map((data) => Number(data.id));
    },
    deselectAllWebsite: (state) => {
      state.selectedWebsiteIds = [];
    },
    toggleSelectedSingleWebsite: (state, action) => {
      const currentFormIndex = state.selectedWebsiteIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentFormIndex !== -1) {
        state.selectedWebsiteIds.splice(currentFormIndex, 1);
      } else {
        state.selectedWebsiteIds.push(action.payload);
      }
    },

    addWebsitesToFolders: (state, action: PayloadAction<IAddWebsitesToFoldersPayload>) => {
      state.isUpdating = true;
    },

    addWebsitesToFoldersSuccess: (state, action: PayloadAction<IAddWebsitesToFoldersPayload>) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.renderId = nanoid(5);
      state.selectedWebsiteIds = [];
    },
    addWebsitesToFoldersFailed: (state) => {
      state.isUpdating = false;
    },

    resetWebsiteUpdateSuccess: (state) => {
      state.isUpdateSuccess = false;
    },

    removeFolderFromWebsite: (state, action: PayloadAction<IRemoveFolderFromWebsitePayload>) => {
      state.isUpdating = true;
    },

    removeFolderFromWebsiteSuccess: (state, action: PayloadAction<IRemoveFolderFromWebsitePayload>) => {
      state.isUpdating = false;
      const { folderId, websiteId } = action.payload;
      if (folderId === state.filters.folderId) {
        state.data = state.data.filter((website) => website.id !== websiteId);
      } else {
        state.data = state.data.map((website) => {
          if (website.id === websiteId) {
            return {
              ...website,
              folder: website.folder?.filter((singleFolder) => singleFolder.folderId !== folderId) || [],
            };
          }
          return website;
        });
      }
    },

    selectFolderId: (state, action) => {
      state.filters.folderId = action.payload;
      state.filters.isFavorite = false;
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.selectedWebsiteIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectFavoriteFolder: (state) => {
      state.filters.folderId = null;
      state.filters.isFavorite = true;
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.selectedWebsiteIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectArchivedFolder: (state) => {
      state.filters.folderId = null;
      state.filters.isFavorite = false;
      state.filters.isArchived = true;
      state.filters.inTrash = false;
      state.selectedWebsiteIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectTrashFolder: (state) => {
      state.filters.folderId = null;
      state.filters.isFavorite = false;
      state.filters.isArchived = false;
      state.filters.inTrash = true;
      state.selectedWebsiteIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },

    addWebsiteSearchTextFilter: (state, action: PayloadAction<string>) => {
      state.filters.searchText = action.payload;
      state.currentPage = 1;
    },
    addWebsiteOrderByFilter: (state, action: PayloadAction<string>) => {
      state.filters.orderBy = action.payload;
      state.currentPage = 1;
    },

    resetWebsiteFilter: (state) => {
      state.filters.isFavorite = false;
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.filters.searchText = "";
      state.filters.orderBy = websiteOrderByList[2].value;
    },
    resetOnlyWebsiteFilter: (state) => {
      state.filters.searchText = "";
      state.filters.orderBy = websiteOrderByList[2].value;
    },
    showErrorToasterRedux: (state) => {},
  },
});

export const {
  getWebsites,
  getWebsitesFailed,
  getWebsitesSuccess,
  forceWebsiteRender,

  addNewWebsiteInsideFolder,
  addWebsite,
  addWebsiteFailed,
  addWebsiteSuccess,
  resetAddWebsiteSuccess,

  renamedWebsite,
  renamedWebsiteFailed,
  renamedWebsiteSuccess,
  resetRenamedWebsiteSuccess,

  deleteWebsite,
  deleteWebsiteFailed,
  deleteWebsiteSuccess,

  deleteWebsiteForever,
  deleteWebsiteForeverFailed,
  deleteWebsiteForeverSuccess,
  resetDeleteWebsiteForeverSuccess,

  cloneWebsite,
  cloneWebsiteFailed,
  cloneWebsiteSuccess,

  toggleFavStatus,
  toggleFavStatusSuccess,
  toggleFavStatusFailed,

  toggleArchiveStatus,
  toggleArchiveStatusSuccess,
  toggleArchiveStatusFailed,

  selectedAllWebsite,
  deselectAllWebsite,
  toggleSelectedSingleWebsite,

  addWebsitesToFolders,
  addWebsitesToFoldersSuccess,
  addWebsitesToFoldersFailed,

  removeFolderFromWebsite,
  removeFolderFromWebsiteSuccess,

  addWebsiteSearchTextFilter,
  addWebsiteOrderByFilter,

  selectFolderId,
  selectFavoriteFolder,
  selectArchivedFolder,
  selectTrashFolder,

  resetWebsiteUpdateSuccess,
  resetWebsiteFilter,
  resetOnlyWebsiteFilter,

  showErrorToasterRedux,
} = websiteSlice.actions;

export default websiteSlice.reducer;
