import React from "react";
import { useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { PageImageBox, PageWrapperBox } from "./FunnelPageStyles";
import AbTestingIcon from "../../../common/Icons/AbTestingIcon";
import { selectFunnelStepPageVariant } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";

const VariantPage = () => {
  const variant = useSelector(selectFunnelStepPageVariant);
  const noVariantFound = !variant?.id;

  const thumbnail = variant?.thumbnail;

  let content;
  if (noVariantFound) {
    content = <Stack justifyContent={"center"} alignItems={"center"} spacing={2} p={2} sx={{ height: "100%" }}></Stack>;
  } else {
    content = thumbnail ? <img src={thumbnail} alt={"variant thumbnail"} /> : <></>;
  }

  return (
    <PageWrapperBox textAlign={"center"}>
      <Typography variant={"subtitle2"} fontWeight={600} color={"text.secondary"}>
        Variant
      </Typography>

      <PageImageBox variant={noVariantFound ? "outlined" : "elevation"} elevation={4}>
        {content}
      </PageImageBox>

      {/*{!noVariantFound && <VariantPageActions />}*/}
    </PageWrapperBox>
  );
};

export default VariantPage;
