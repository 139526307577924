import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../state/store";
import removeFalsyObjectProperty from "../helpers/utils/removeFalsyObjectProperty";
import {
  fetchSectionLibraries,
  resetSectionLibraryState,
} from "../state/features/adminSectionLibrary/adminSectionLibrarySlice";
import { selectSectionLibraryState } from "../state/features/adminSectionLibrary/adminSectionLibrarySelector";

export const useSectionLibrary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading, currentPage, limit, count, renderId, filters } = useSelector(selectSectionLibraryState);
  const { searchText, type } = filters;

  useEffect(() => {
    return () => {
      dispatch(resetSectionLibraryState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = removeFalsyObjectProperty({
      currentPage: renderId ? currentPage : 1,
      limit,
      searchText,
      type: type ?? "",
    });
    payload.type = type ?? "";
    dispatch(fetchSectionLibraries(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, type, renderId]);

  return { data, isLoading, count, currentPage, limit, type, searchText };
};
