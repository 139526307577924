import { IWebsiteDomainInfo } from "../../state/features/website/website.interface";

export const goToPreviewPage = (pageId: string, websiteId: string, isAdmin = false, domainInfo?: IWebsiteDomainInfo | null, subDomain?: string | null) => {
  let url = process.env.REACT_APP_WEBSITE_EDITOR_URL;

  if (isAdmin) {
    url += "/mf/admin";
  }
  if (domainInfo) {
    window.open("https://" + domainInfo.domain, "_blank");
    return;
  }
  if (subDomain) {
    window.open("https://" + subDomain, "_blank");
    return;
  }
  url += `/preview/${btoa(JSON.stringify({ pageId, websiteId }))}`;

  window.open(url, "_blank");
};
