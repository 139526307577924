import { SuccessResponseIn } from "../../../interface/response.interface";
import { FunnelStepInterface } from "./funnelStep.interface";
import funnelApiSlice from "../../funnelApiSlice";

export const funnelStepApiSlice = funnelApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllFunnelStep: builder.query<SuccessResponseIn<FunnelStepInterface[]>, { funnelId: number }>({
      query: (params) => ({
        url: "funnel/step/all/" + params.funnelId,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllFunnelStepQuery } = funnelStepApiSlice;
