import apiSlice from "../../apiSlice";
import { SuccessResponseIn } from "../../../interface/response.interface";

export const domainApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDomains: builder.query<SuccessResponseIn<{ id: number; domain: string }[]>, {}>({
      query: () => ({
        url: "website/domain/all",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllDomainsQuery } = domainApiSlice;
