import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import FunnelTemplateStepService from "../../../services/funnelTemplateStep";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  fetchFunnelTemplateStepsSuccess,
  resetFetchFunnelTemplateSteps,
  addFunnelTemplateStepSuccess,
  resetAddFunnelTemplateStep,
  updateFunnelTemplateStepSuccess,
  resetUpdateFunnelTemplateStep,
  resetDeleteFunnelTemplateStep,
  deleteFunnelTemplateStepSuccess,
  cloneFunnelTemplateStepSuccess,
  resetCloneFunnelTemplateStep,
  
  reorderFunnelTemplateStepSuccess,
  resetReorderFunnelTemplateStepSuccess
} from "./funnelTemplateStepSlice";
import {
  CreateFunnelTemplateStepPayloadI,
  UpdateFunnelTemplateStepPayloadI,
  DeleteFunnelTemplateStepPayloadI,
  CloneFunnelTemplateStepPayloadI,
  ReorderFunnelTemplateStepPayloadI
} from "../../../interface/funnelTemplateStep.interface";

function* funnelTemplateSettingWatcher() {
  yield takeEvery("funnelTemplateStep/addFunnelTemplateStep", addFunnelTemplateStepSaga);
  yield takeEvery("funnelTemplateStep/cloneFunnelTemplateStep", cloneFunnelTemplateStepSaga);
  yield takeEvery("funnelTemplateStep/fetchFunnelTemplateSteps", fetchFunnelTemplateStepsSaga);
  yield takeEvery("funnelTemplateStep/updateFunnelTemplateStep", updateFunnelTemplateStepSaga);
  yield takeEvery("funnelTemplateStep/deleteFunnelTemplateStep", deleteFunnelTemplateStepSaga);
  yield takeEvery("funnelTemplateStep/reorderFunnelTemplateStep", reorderFunnelTemplateStepSaga);
}

function* fetchFunnelTemplateStepsSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(FunnelTemplateStepService.fetchFunnelTemplateSteps, action.payload);

    if (response.success) {
      yield put(fetchFunnelTemplateStepsSuccess(response.data));
    } else {
      yield put(resetFetchFunnelTemplateSteps());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetFetchFunnelTemplateSteps());
    console.error(err);
  }
}

function* addFunnelTemplateStepSaga(action: PayloadAction<CreateFunnelTemplateStepPayloadI>) {
  try {
    const response = yield* call(FunnelTemplateStepService.addFunnelTemplateStep, action.payload);

    if (response.success) {
      yield put(addFunnelTemplateStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetAddFunnelTemplateStep());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetAddFunnelTemplateStep());
    console.error(err);
  }
}

function* updateFunnelTemplateStepSaga(action: PayloadAction<UpdateFunnelTemplateStepPayloadI>) {
  try {
    const response = yield* call(FunnelTemplateStepService.updateFunnelTemplateStep, action.payload);

    if (response.success) {
      yield put(updateFunnelTemplateStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetUpdateFunnelTemplateStep());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetUpdateFunnelTemplateStep());
    console.error(err);
  }
}

function* deleteFunnelTemplateStepSaga(action: PayloadAction<DeleteFunnelTemplateStepPayloadI>) {
  try {
    const response = yield* call(FunnelTemplateStepService.deleteFunnelTemplateStep, action.payload);

    if (response.success) {
      yield put(deleteFunnelTemplateStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetDeleteFunnelTemplateStep());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetDeleteFunnelTemplateStep());
    console.error(err);
  }
}

function* cloneFunnelTemplateStepSaga(action: PayloadAction<CloneFunnelTemplateStepPayloadI>) {
  try {
    const response = yield* call(FunnelTemplateStepService.cloneFunnelTemplateStep, action.payload);

    if (response.success) {
      yield put(cloneFunnelTemplateStepSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetCloneFunnelTemplateStep());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetCloneFunnelTemplateStep());
    console.error(err);
  }
}

function* reorderFunnelTemplateStepSaga(action: PayloadAction<ReorderFunnelTemplateStepPayloadI>) {
  try {
    const response = yield* call(FunnelTemplateStepService.reorderFunnelTemplateStep, action.payload);

    if (response.success) {
      yield put(reorderFunnelTemplateStepSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(resetReorderFunnelTemplateStepSuccess(action.payload));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetCloneFunnelTemplateStep());
    console.error(err);
  }
}

export default function* funnelTemplateStepSaga() {
  yield all([funnelTemplateSettingWatcher()]);
}
