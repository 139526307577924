import { all, call, put } from "typed-redux-saga";
import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  addFolderFailed,
  addFolderSuccess,
  deleteFolderFailed,
  deleteFolderSuccess,
  getFoldersFailed,
  getFoldersSuccess,
  getMoreFoldersFailed,
  getMoreFoldersSuccess,
  updateFolderFailed,
  updateFolderSuccess,
} from "./folderSlice";
import FolderService from "../../../services/folder.service";
import { forceWebsiteRender } from "../website/websiteSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import { FolderInterface, IDeleteFolderPayload, IGetFoldersPayload } from "./folder.Interface";

function* formFolderWatcher() {
  yield takeEvery("folder/getFolders", getFoldersSaga);
  yield takeEvery("folder/getMoreFolders", getMoreFoldersSaga);
  yield takeEvery("folder/addFolder", addFolderSaga);
  yield takeEvery("folder/updateFolder", updateFolderSaga);
  yield takeEvery("folder/deleteFolder", deleteFolderSaga);
}

function* getFoldersSaga(action: PayloadAction<IGetFoldersPayload>) {
  try {
    const response = yield* call(FolderService.getFolders, action.payload);

    if (response.success) {
      yield put(getFoldersSuccess(response.data));
    } else {
      yield put(getFoldersFailed(response.message));
    }
  } catch (err) {
    if (err instanceof Error) {
      yield put(getFoldersFailed(err.message));
    }
  }
}

function* getMoreFoldersSaga(action: PayloadAction<IGetFoldersPayload>) {
  try {
    const response = yield* call(FolderService.getFolders, action.payload);

    if (response.success) {
      yield put(getMoreFoldersSuccess(response.data));
    } else {
      yield put(getMoreFoldersFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* addFolderSaga(action: PayloadAction<FolderInterface>) {
  try {
    const response = yield* call(FolderService.createFolder, action.payload);

    if (response.success) {
      yield put(addFolderSuccess(response.data as FolderInterface));
      showSuccessToaster(response.message);
    } else {
      yield put(addFolderFailed(response.message as string));
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(addFolderFailed("Error happen!"));
  }
}

function* updateFolderSaga(action: PayloadAction<FolderInterface>) {
  try {
    const response = yield* call(FolderService.updateFolder, action.payload);

    if (response.success) {
      yield put(updateFolderSuccess(action.payload as FolderInterface));
      showSuccessToaster(response.message);
      yield put(forceWebsiteRender());
    } else {
      yield put(updateFolderFailed(response.message as string));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deleteFolderSaga(action: PayloadAction<IDeleteFolderPayload>) {
  try {
    const response = yield* call(FolderService.deleteFolder, action.payload);

    if (response.success) {
      yield put(deleteFolderSuccess(action.payload.id));
      showSuccessToaster("Folder deleted successfully!");
      yield put(forceWebsiteRender());
    } else {
      yield put(deleteFolderFailed(response.message as string));
      showErrorToaster("Folder deleted failed!");
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* formFolderSaga() {
  yield all([formFolderWatcher()]);
}
