import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, Grid, InputAdornment, Paper, Stack } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DomainI from "../../interface/domain.interface";
import LoadingButton from "../../common/Button/LoadingButton";
import FileUploadWithPreview from "../../common/FileUpload/FileUploadWithPreview";
import { registerPatternWebsite } from "../../helpers/constant/registerPattern";
import IWebsiteSettings from "../../state/features/website-settings/website-setting.interface";
import { selectWebsiteSettingState } from "../../state/features/website-settings/websiteSettingSelector";
import {
  resetUpdateWebsiteSetting,
  updateWebsiteSetting,
} from "../../state/features/website-settings/websiteSettingSlice";
import { useGetAllDomainsQuery } from "../../state/features/domain/domainApiSlice";
import { useGetAllWebsitePagesQuery } from "../../state/features/website-pages/websitePageApiSlice";
import CoreButton from "../../common/Button/CoreButton";
import copyFromClipBoard from "../../helpers/utils/copyFromClipBoard";
import { showSuccessToaster } from "../../helpers/utils/toaster";

const WebsitePageSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { websiteId } = useParams<{ websiteId: string }>();
  const [copied, setCopied] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<IWebsiteSettings>();

  const { data: websiteDetails, isUpdating, isUpdateSuccess } = useSelector(selectWebsiteSettingState);
  const { data: domainState } = useGetAllDomainsQuery({});
  const { data: domainList = [] } = domainState || {};

  const { data: websitePageState, refetch } = useGetAllWebsitePagesQuery({ websiteId: websiteId });
  const { data: websitePageList = [] } = websitePageState || {};

  useEffect(() => {
    if (websiteDetails?.websiteId) {
      reset({ ...websiteDetails, domainId: websiteDetails?.domainId || "" });
    }
  }, [websiteDetails?.websiteId]);

  useEffect(() => {
    if (isUpdateSuccess) {
      dispatch(resetUpdateWebsiteSetting());
      refetch();
    }
  }, [isUpdateSuccess]);

  const onSubmitHandler = (values: IWebsiteSettings) => {
    const updatedValues: IWebsiteSettings = {
      websiteId: websiteId,
      name: values.name,
      defaultPageId: values.defaultPageId,
      domainId: values.domainId,
      faviconURL: values.faviconURL,
      logo: values.logo,
      headingTrackingCode: values.headingTrackingCode,
      bodyTrackingCode: values.bodyTrackingCode,
      pixel: values.pixel,
      analytics: values.analytics,
    };
    updatedValues.domainInfo = domainList.find((domainInfo) => domainInfo.id === Number(values.domainId));

    dispatch(updateWebsiteSetting(updatedValues));
  };

  const { subDomain, domainInfo } = websiteDetails || {};
  const sitemapUrl = `${domainInfo ? domainInfo.domain : subDomain}/sitemap.xml`;
  const handleCopy = () => {
    copyFromClipBoard(sitemapUrl);
    showSuccessToaster("Successfully copied sitemap");

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onSubmitHandler)} sx={{ p: 3 }}>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            required
            label='Website Name'
            color='secondary'
            error={!!(errors.name && errors.name.message)}
            helperText={errors.name && errors.name.message}
            {...register("name", registerPatternWebsite.name)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name='defaultPageId'
            defaultValue={""}
            rules={registerPatternWebsite.defaultPageId}
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
              return (
                <FormControl fullWidth required error={invalid}>
                  <InputLabel color='secondary' id='demo-simple-select-label'>
                    Default Home Page
                  </InputLabel>
                  <Select color='secondary' label='Domain' fullWidth value={value} onChange={onChange}>
                    <MenuItem disabled value=''>
                      Choose your Home Page
                    </MenuItem>
                    {websitePageList?.map((pageInfo) => (
                      <MenuItem key={pageInfo.pageId} value={pageInfo.pageId}>
                        {pageInfo.path}
                      </MenuItem>
                    ))}
                  </Select>
                  {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name='domainId'
            defaultValue={""}
            render={({ field: { value, onChange } }) => {
              return (
                <FormControl fullWidth>
                  <InputLabel color='secondary' id='demo-simple-select-label'>
                    Domain
                  </InputLabel>
                  <Select
                    color='secondary'
                    label='Domain'
                    fullWidth
                    displayEmpty
                    value={value}
                    onChange={onChange}
                    MenuProps={{ style: { maxHeight: 250 } }}
                  >
                    <MenuItem value=''>None</MenuItem>
                    {domainList?.map((domainInfo) => (
                      <MenuItem key={domainInfo.id} value={domainInfo.id}>
                        {domainInfo.domain}
                      </MenuItem>
                    ))}
                  </Select>
                  <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                    <FormHelperText>
                      This is the domain you've connected to the Website -{" "}
                      <Link to={"/wbuilder/settings"}>Add/Edit Domains</Link>
                    </FormHelperText>
                  </Stack>
                </FormControl>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <InputLabel error={!!(errors.logo && errors.logo.message)}>Logo</InputLabel>
          <Paper variant={"outlined"} sx={{ borderColor: "other.outlinedBorderDarker", pl: 2 }}>
            <Controller
              control={control}
              name='logo'
              // rules={registerPatternWebsite.logo}
              render={({ field: { value, onChange } }) => {
                return (
                  <FileUploadWithPreview
                    fileUrl={value}
                    onChange={(fileUrl) => {
                      onChange(fileUrl);
                    }}
                    maxFileSizeMB={2}
                    infoText={`Click here to upload your logo`}
                    maxFileInfoMessage={`Max size of 2MB`}
                    isAdmin={false}
                  />
                );
              }}
            />
          </Paper>
          <FormHelperText>{errors.logo && errors.logo.message}</FormHelperText>
        </Grid>

        <Grid item xs={12} xl={6}>
          <InputLabel error={!!(errors.faviconURL && errors.faviconURL.message)}>Favicon</InputLabel>
          <Paper variant={"outlined"} sx={{ borderColor: "other.outlinedBorderDarker", pl: 2 }}>
            <Controller
              control={control}
              name='faviconURL'
              rules={registerPatternWebsite.faviconURL}
              render={({ field: { value, onChange } }) => {
                return (
                  <FileUploadWithPreview
                    fileUrl={value}
                    onChange={(fileUrl) => {
                      onChange(fileUrl);
                    }}
                    maxFileSizeMB={0.2}
                    infoText={`Click here to upload your favicon`}
                    maxFileInfoMessage={`File format must be .png or .ico. We recommend using .ico for best results.
Max size of 200kb`}
                    isAdmin={false}
                    specialImageFileRegex={/image\/png|image\/PNG|image\/vnd.microsoft.icon|image\/x-icon/g}
                  />
                );
              }}
            />
          </Paper>
          <FormHelperText>{errors.faviconURL && errors.faviconURL.message}</FormHelperText>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            multiline
            label='Head tracking code'
            color='secondary'
            minRows={6}
            maxRows={10}
            error={!!(errors.headingTrackingCode && errors.headingTrackingCode.message)}
            helperText={errors.headingTrackingCode && errors.headingTrackingCode.message}
            {...register("headingTrackingCode", registerPatternWebsite.headingTrackingCode)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            multiline
            label='Body tracking code'
            color='secondary'
            minRows={6}
            maxRows={10}
            error={!!(errors.bodyTrackingCode && errors.bodyTrackingCode.message)}
            helperText={errors.bodyTrackingCode && errors.bodyTrackingCode.message}
            {...register("bodyTrackingCode", registerPatternWebsite.bodyTrackingCode)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            multiline
            label='Facebook Pixel'
            color='secondary'
            minRows={6}
            maxRows={10}
            error={!!(errors.pixel && errors.pixel.message)}
            helperText={errors.pixel && errors.pixel.message}
            {...register("pixel", registerPatternWebsite.pixel)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            multiline
            label='Google Analytics'
            color='secondary'
            minRows={6}
            maxRows={10}
            error={!!(errors.analytics && errors.analytics.message)}
            helperText={errors.analytics && errors.analytics.message}
            {...register("analytics", registerPatternWebsite.analytics)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel color='secondary' id='demo-simple-select-label'>
            Sitemap
          </InputLabel>
          <TextField
            fullWidth
            disabled
            color={"secondary"}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ mr: 0 }} position='start'>
                  <CoreButton
                    variant={"contained"}
                    size={"small"}
                    sx={{ width: 100 }}
                    startIcon={copied ? <CheckCircleOutlineIcon /> : <ContentCopyIcon />}
                    onClick={handleCopy}
                  >
                    {copied ? "Copied" : "Copy"}
                  </CoreButton>
                </InputAdornment>
              ),
            }}
            value={sitemapUrl}
          />
        </Grid>
      </Grid>
      <LoadingButton
        isLoading={isUpdating}
        loadingText={"Saving..."}
        type='submit'
        variant='contained'
        color='secondary'
      >
        Save
      </LoadingButton>
    </Box>
  );
};

export default WebsitePageSettings;
