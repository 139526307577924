import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "../helpers/Cookie";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Invoice API URL not found in env!");

const baseQueryMiddleware = fetchBaseQuery({
  baseUrl: funnelBuilderAPI,
  prepareHeaders: async (headers /*, { getState, endpoint }*/) => {
    const token = getCookie(process.env.REACT_APP_ACCESS_TOKEN as string);
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const funnelApiSlice = createApi({
  reducerPath: "funnelApi",
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQueryMiddleware(args, api, extraOptions);
    const { error } = result || {};
    if (error) {
      console.log("Error::: ", error);
    }

    return result;
  },
  tagTypes: ["SPLIT_TEST_SETTINGS", "SPLIT_TEST_REPORTS", "REDIRECT_URL"],
  endpoints: () => ({}),
});

export default funnelApiSlice;
