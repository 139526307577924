import { Components, Theme, outlinedInputClasses } from "@mui/material";
import { lightModePalette } from "./helpers/constant/themeConstant";

const propertyInObj = <T extends object>(color: string | number, obj?: T): string => {
  const key = color as keyof T;
  if (obj) {
    return color in obj ? String(obj[key]) : "";
  }
  return "";
};

export const components: Components<Theme> = {
  MuiTextField: {
    defaultProps: {
      hiddenLabel: true,
    },
    styleOverrides: {
      root: {
        borderRadius: "8px",
        "& label.Mui-focused": {},
        input: {
          padding: "10px 13px",
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
      disableAnimation: true,
      size: "small",
    },
    styleOverrides: {
      root: {
        position: "relative",
        transform: "unset",
        fontWeight: 500,
        // fontSize: "14px",
        // lineHeight: "16px",
        letterSpacing: "-0.15px",
        marginBottom: "8px",
        "&.Mui-focused": {
          color: lightModePalette.text?.secondary,
        },
      },
      sizeSmall: {
        fontSize: "14px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& legend": {
          display: "none",
        },
      },
      input: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "-0.25px",
        padding: "10px 13px",
      },
      inputMultiline: {
        padding: 0,
      },
      notchedOutline: {
        top: 0,
        borderColor: propertyInObj("inputOutlinedEnabledBorder", lightModePalette.other),
      },
      multiline: {
        padding: "10px 13px",
        [`&.${outlinedInputClasses.sizeSmall}`]: {
          padding: "8px 13px",
        },
      },
      sizeSmall: {
        input: {
          padding: "8px 13px",
        },
      },
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      input: {
        height: "unset",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "-0.25px",
      },
      adornedStart: {
        paddingLeft: 0,
      },
      adornedEnd: {
        paddingRight: 0,
      },
      sizeSmall: {
        input: {
          padding: "8px 13px",
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      input: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "-0.25px",
      },
      underline: {
        marginTop: "0 !important",
      },
      sizeSmall: {
        input: {
          padding: "8px 13px",
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: "6px 14px 0 0",
        fontSize: "12px",
        fontWeight: 400,
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        backgroundColor: propertyInObj("graySolid", lightModePalette.other),
        borderTop: `1px solid ${propertyInObj("outlinedBorder", lightModePalette.other)}`,
        borderBottom: `1px solid ${propertyInObj("outlinedBorder", lightModePalette.other)}`,
        textTransform: "uppercase",
        color: propertyInObj("tertiary", lightModePalette.text),
        fontSize: "12px",
      },
      root: {
        borderBottom: `1px solid ${propertyInObj("outlinedBorder", lightModePalette.other)}`,
        padding: "8px 12px",
        "&:first-of-type": {
          borderLeft: `1px solid ${propertyInObj("outlinedBorder", lightModePalette.other)}`,
        },
        "&:last-of-type": {
          borderRight: `1px solid ${propertyInObj("outlinedBorder", lightModePalette.other)}`,
        },
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      disableGutters: true,
      elevation: 0,
    },
    styleOverrides: {
      root: {
        // borderBottom: `1px solid ${alpha(propertyInObj("primary", lightModePalette.text), 0.06)}`,
        "&:before": {
          content: "none",
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        gap: 8,
        padding: "0 8px",
      },
      content: {
        // margin: "8px 0",
        alignItems: "center",
        gap: 8,
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: "8px",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      rounded: {
        "& ul": {
          color: "unset !important",
        },
      },
    },
  },
};
