import React, { useState } from "react";
import { format, isDate } from "date-fns";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Popover, Stack, TableCell, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchivedIcon from "@mui/icons-material/Unarchive";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderNameChipList from "./FolderNameChipList";
import AddToFolderWebsite from "./AddToFolderWebsite";
import RenameWebsite from "./RenameWebsite";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import IconButton from "../../common/IconButton";
import RouteLink from "../../common/Link/RouteLink";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import { SingleWebsiteListWrp } from "./SitesListStyles";
import { IWebsite } from "../../state/features/website/website.interface";
import { selectWebsiteState } from "../../state/features/website/websiteSelector";
import {
  deleteWebsite,
  toggleArchiveStatus,
  toggleFavStatus,
  toggleSelectedSingleWebsite,
} from "../../state/features/website/websiteSlice";
import useBoolean from "../../hooks/useBoolean";
import { goToPreviewWebsite } from "../../helpers/utils/goToPreviewWebsite";
import CloneWebsiteModal from "./CloneWebsiteModal";
import BasicModal from "../../common/Modal/BasicModal";

interface IProps {
  websiteInfo: IWebsite;
}

const SingleWebsite: React.FC<IProps> = ({ websiteInfo }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    id,
    name,
    folder,
    totalPage,
    isFavorite,
    isArchived,
    createdAt = "",
    defaultPageInfo,
    domainInfo,
    subDomain,
  } = websiteInfo || {};

  const { value: openRenameModal, setTrue: onOpenRenameModal, setFalse: onCloseRenameModal } = useBoolean(false);
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    value: openNameForCloneModal,
    setTrue: onOpenNameForCloneModal,
    setFalse: onCloseNameForCloneModal,
  } = useBoolean(false);

  const { selectedWebsiteIds } = useSelector(selectWebsiteState);

  const toggleFavoriteHandler = () => {
    dispatch(toggleFavStatus({ websiteId: id as number, isFavorite: !isFavorite }));
  };

  const archiveHandler = () => {
    dispatch(toggleArchiveStatus({ websiteIds: [id as number], isArchived: !isArchived }));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteWebsiteHandler = () => {
    dispatch(deleteWebsite({ websiteIds: [id as number], inTrash: true }));
  };



  const onGoWebsitePreview = () => {
    goToPreviewWebsite(id, defaultPageInfo, domainInfo, subDomain);
  };

  const onGoWebsiteEdit = () => {
    history.push("/wbuilder/site/" + id);
  };

  const open = Boolean(anchorEl);
  const formattedCreatedAt = isDate(new Date(createdAt)) ? format(new Date(createdAt), "MMM dd, yyyy") : "-";
  const isChecked = selectedWebsiteIds.includes(id as number);
  // const isPreviewShow = defaultPageInfo || domainInfo;
  // @ts-ignore
  return (
    <SingleWebsiteListWrp
      selected={selectedWebsiteIds.includes(id as number) || isActive}
      className={selectedWebsiteIds.includes(id as number) || isActive ? "active" : ""}
    >
      <TableCell>
        <Stack direction={"row"} alignItems={"center"} ml={1}>
          <Checkbox
            edge='start'
            color='secondary'
            size={"small"}
            tabIndex={-1}
            checked={isChecked}
            onChange={() => dispatch(toggleSelectedSingleWebsite(id as number))}
            sx={{
              "& .PrivateSwitchBase-input": {
                pointerEvents: "auto !important",
              },
            }}
          />
          <Checkbox
            checked={!!isFavorite}
            size={"small"}
            icon={<StarIcon color='action' />}
            checkedIcon={<StarIcon color='warning' />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            onClick={toggleFavoriteHandler}
          />
          <Box
            sx={{
              width: "100%",
              ml: 1,
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: `100%` }}>
              <RouteLink to={`/wbuilder/site/${id}`}>
                <Typography variant='body1Medium' color='text.primary' noWrap>
                  {name}
                </Typography>
              </RouteLink>
              <FolderNameChipList websiteId={id as number} websiteFolderList={folder || []} />
            </Stack>

            <Typography variant='caption' color='text.secondary' fontWeight={300}>
              {totalPage} Pages | Created on: {formattedCreatedAt}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack className={"rrmsl-list-action-icons"}>
          {/*<Chip color={"success"} size={"small"} icon={<ModeStandbyIcon />} label={"Live"} />*/}

          <RouteLink to={`/wbuilder/site/${id}`}>
            <CustomTooltip title='Edit' arrow placement='top'>
              <IconButton variant={"outlined"} color={"secondary"} size='small'>
                <EditIcon fontSize={"small"} />
              </IconButton>
            </CustomTooltip>
          </RouteLink>

            <CustomTooltip title='Preview' arrow placement='top'>
              <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={onGoWebsitePreview}>
                <VisibilityIcon fontSize={"small"} />
              </IconButton>
            </CustomTooltip>

          <CustomTooltip title='Delete' arrow placement='top'>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={deleteWebsiteHandler}>
              <DeleteIcon fontSize={"small"} />
            </IconButton>
          </CustomTooltip>

          <CustomPopoverMenu twoColumns={true} callbackOnClose={() => setIsActive(false)}>
            <CustomPopoverMenuButton>
              <CustomTooltip title='More Options' arrow placement='top'>
                <IconButton
                  variant={"outlined"}
                  color={"secondary"}
                  size='small'
                  className={"rrmsl-list-more-icon"}
                  onClick={() => setIsActive(true)}
                >
                  <MoreVertIcon fontSize={"small"} />
                </IconButton>
              </CustomTooltip>
            </CustomPopoverMenuButton>
              <CustomPopoverMenuItem
                title='Preview'
                icon={<VisibilityIcon fontSize={"small"} />}
                onClick={onGoWebsitePreview}
                onClickHide={true}
              />

            <CustomPopoverMenuItem
              title='Edit Website'
              icon={<EditIcon fontSize={"small"} />}
              onClick={onGoWebsiteEdit}
              onClickHide={true}
            />

            {/*<CustomPopoverMenuItem*/}
            {/*  title='Website Settings'*/}
            {/*  icon={<SettingsIcon fontSize={"small"} />}*/}
            {/*  onClick={() => console.log("clicked")}*/}
            {/*  onClickHide={true}*/}
            {/*/>*/}
            <CustomPopoverMenuItem
              title={!isArchived ? "Archive" : "Unarchive"}
              icon={!isArchived ? <ArchiveIcon fontSize={"small"} /> : <UnarchivedIcon fontSize={"small"} />}
              onClick={archiveHandler}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='Rename'
              icon={<DriveFileRenameOutlineIcon fontSize={"small"} />}
              onClick={onOpenRenameModal}
            />
            <CustomPopoverMenuItem
              title='Delete'
              icon={<DeleteIcon fontSize={"small"} />}
              onClick={deleteWebsiteHandler}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='Clone'
              icon={<FileCopyIcon fontSize={"small"} />}
              onClick={onOpenNameForCloneModal}
              onClickHide={true}
            />
            <CustomPopoverMenuItem
              title='Add to folder'
              icon={<CreateNewFolderIcon fontSize={"small"} />}
              onClick={handleClick}
            />
          </CustomPopoverMenu>
        </Stack>
      </TableCell>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AddToFolderWebsite
          websiteIds={[id as number]}
          folderIds={(websiteInfo.folder || []).map((s) => s.folderId)}
          onClose={handleClose}
        />
      </Popover>

      <BasicModalWithHeader title='Rename Website' open={openRenameModal} onClose={onCloseRenameModal} width={500}>
        <RenameWebsite onClose={onCloseRenameModal} websiteInfo={websiteInfo} />
      </BasicModalWithHeader>

      <BasicModal
        width={500}
        open={openNameForCloneModal}
        onClose={onCloseNameForCloneModal}
      >
        <CloneWebsiteModal websiteId={id as number} clone={true} onClose={onCloseNameForCloneModal} />
      </BasicModal>
    </SingleWebsiteListWrp>
  );
};

export default SingleWebsite;
