import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IGetTemplatePayload,
  IGetTemplates,
  ITemplate,
  ITemplateCategory,
  ITemplateTag,
  ITemplateType,
} from "./template.interface";
import { WithPagination } from "../../../interface/response.interface";

const initialState = {
  data: [] as ITemplate[],
  isLoading: false,
  isError: false,
  error: "",
  count: 0,
  currentPage: 1,
  limit: 50,
  isMoreFetching: false,

  category: {
    data: [] as ITemplateCategory[],
    isLoading: false,
    isSuccess: false,
  },
  type: {
    data: [] as ITemplateType[],
    isLoading: false,
    isSuccess: false,
  },
  tag: {
    data: [] as ITemplateTag[],
    isLoading: false,
    isSuccess: false,
  },

  filters: {
    searchText: "",
    selectedCategoryId: null as null | number,
    selectedTypeIds: [] as number[],
    selectedTagIds: [] as number[],
  },
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    getTemplates: (state, action: PayloadAction<IGetTemplatePayload>) => {
      state.isLoading = true;
    },
    getTemplatesSuccess: (state, action: PayloadAction<WithPagination<ITemplate>>) => {
      state.data = action.payload.content;
      state.currentPage = +action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = action.payload.count;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getTemplatesFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    getMoreTemplates: (state, action: PayloadAction<IGetTemplates>) => {
      state.isMoreFetching = true;
    },
    getMoreTemplatesSuccess: (state, action: PayloadAction<WithPagination<ITemplate>>) => {
      state.data = [...state.data, ...action.payload.content];
      state.currentPage = +action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = action.payload.count;
      state.isMoreFetching = false;
    },
    getMoreTemplatesFailed: (state, action) => {
      state.isError = true;
      state.error = action.payload;
      state.isMoreFetching = false;
    },

    getTemplateCategories: (state) => {
      state.category.isLoading = true;
    },
    getTemplateCategoriesSuccess: (state, action: PayloadAction<ITemplateCategory[]>) => {
      state.category.data = action.payload;
      state.category.isLoading = false;
      state.category.isSuccess = true;
    },
    getTemplateCategoriesFailed: (state) => {
      state.category.data = [];
      state.category.isLoading = false;
      state.category.isSuccess = false;
    },

    getTemplateTypes: (state) => {
      state.type.isLoading = true;
    },
    getTemplateTypesSuccess: (state, action: PayloadAction<ITemplateType[]>) => {
      state.type.data = action.payload;
      state.type.isLoading = false;
      state.type.isSuccess = true;
    },
    getTemplateTypesFailed: (state) => {
      state.type.data = [];
      state.type.isLoading = false;
      state.type.isSuccess = false;
    },

    getTemplateTags: (state) => {
      state.tag.isLoading = true;
    },
    getTemplateTagsSuccess: (state, action: PayloadAction<ITemplateTag[]>) => {
      state.tag.data = action.payload;
      state.tag.isLoading = false;
      state.tag.isSuccess = true;
    },
    getTemplateTagsFailed: (state) => {
      state.tag.data = [];
      state.tag.isLoading = false;
      state.tag.isSuccess = false;
    },

    addTemplateSearchText: (state, action) => {
      state.filters.searchText = action.payload;
    },
    addTemplateCategory: (state, action) => {
      state.filters.selectedCategoryId = action.payload;
    },
    toggleSelectTemplateType: (state, action) => {
      const currentIndex = state.filters.selectedTypeIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.filters.selectedTypeIds.splice(currentIndex, 1);
      } else {
        state.filters.selectedTypeIds.push(action.payload);
      }
    },
    toggleSelectTemplateTag: (state, action) => {
      const currentIndex = state.filters.selectedTagIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentIndex !== -1) {
        state.filters.selectedTagIds.splice(currentIndex, 1);
      } else {
        state.filters.selectedTagIds.push(action.payload);
      }
    },
  },
});

export const {
  getTemplates,
  getTemplatesFailed,
  getTemplatesSuccess,

  getMoreTemplates,
  getMoreTemplatesFailed,
  getMoreTemplatesSuccess,

  getTemplateCategories,
  getTemplateCategoriesFailed,
  getTemplateCategoriesSuccess,

  getTemplateTypes,
  getTemplateTypesFailed,
  getTemplateTypesSuccess,

  getTemplateTags,
  getTemplateTagsFailed,
  getTemplateTagsSuccess,

  addTemplateSearchText,
  addTemplateCategory,
  toggleSelectTemplateType,
  toggleSelectTemplateTag,
} = templateSlice.actions;

export default templateSlice.reducer;
