import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import PauseIcon from "@mui/icons-material/Pause";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack, styled, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PlayArrow, Settings } from "@mui/icons-material";
import TimelineIcon from "@mui/icons-material/Timeline";
import HrefLink from "../../../common/Link/HrefLink";
import RouteLink from "../../../common/Link/RouteLink";
import CoreButton from "../../../common/Button/CoreButton";
import { FUNNEL_PAGE_ID } from "../../../helpers/constant/coreConstants";
import { selectFunnelInfoData } from "../../../state/features/funnelInfo/funnelInfoSelector";
import { toggleActiveStatusFunnel } from "../../../state/features/funnelInfo/funnelInfoSlice";
import generateFunnelPreviewUrl from "../../../helpers/utils/generateFunnelPreviewUrl";

const WhiteOutlinedButton = styled(CoreButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.other.darkNavy,
  "&:focus, &:hover": {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.other.darkNavy} !important`,
  },
  "&.pause-button": {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

interface BuilderTopbarInterface {
  setSelectedScreen: React.Dispatch<React.SetStateAction<string>>;
}

const BuilderTopBar: React.FC<BuilderTopbarInterface> = ({ setSelectedScreen }) => {
  const dispatch = useDispatch();
  const { id, name, activeStatus, subDomain, domainInfo, defaultStepInfo } = useSelector(selectFunnelInfoData);

  const toggleActiveStatus = () => {
    dispatch(toggleActiveStatusFunnel({ id: id as number, activeStatus: !activeStatus }));
  };

  const path = defaultStepInfo?.path ?? "";
  const previewUrl = generateFunnelPreviewUrl(subDomain, path, domainInfo);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ position: "sticky", top: 0, bgcolor: "other.darkNavy", zIndex: 11, p: 1 }}
    >
      <RouteLink to={"/wbuilder/funnels"}>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <IconButton size={"small"}>
            <ArrowBackIcon sx={{ color: "common.white" }} />
          </IconButton>
          <Typography variant={"h6"} color={"common.white"}>
            {name}
          </Typography>
        </Stack>
      </RouteLink>

      <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ ml: "auto",mr:1.5 }}>
        <HrefLink href={previewUrl} target={"_blank"}>
          <WhiteOutlinedButton size={"small"} startIcon={<VisibilityIcon />}>
            Preview Funnel
          </WhiteOutlinedButton>
        </HrefLink>

        <WhiteOutlinedButton
          size={"small"}
          startIcon={<TimelineIcon />}
          onClick={() => setSelectedScreen(FUNNEL_PAGE_ID.STATS)}
        >
          Stats
        </WhiteOutlinedButton>
        <WhiteOutlinedButton
          size={"small"}
          startIcon={<Settings />}
          onClick={() => setSelectedScreen(FUNNEL_PAGE_ID.SETTINGS)}
        >
          Settings
        </WhiteOutlinedButton>

        <CoreButton
          size={"small"}
          variant={"contained"}
          color={!activeStatus ? "success" : "error"}
          startIcon={!activeStatus ? <PlayArrow /> : <PauseIcon />}
          onClick={toggleActiveStatus}
        >
          {!activeStatus ? "Activate " : "Deactivate "} Funnel
        </CoreButton>
      </Stack>
    </Stack>
  );
};

export default BuilderTopBar;
