import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export default function HideSideBarIcon(props: SvgIconProps, fill = "#006DF5") {
  return (
    <SvgIcon {...props}>
      <path
        d='M18.6625 22H5.3375C3.5 22 2 20.5 2 18.6625V5.3375C2 3.5 3.5 2 5.3375 2H18.675C20.5 2 22 3.5 22 5.3375V18.675C22 20.5 20.5 22 18.6625 22ZM5.3375 3.6625C4.425 3.6625 3.675 4.4125 3.675 5.325V18.6625C3.675 19.575 4.425 20.325 5.3375 20.325H18.675C19.5875 20.325 20.3375 19.575 20.3375 18.6625V5.3375C20.3375 4.425 19.5875 3.675 18.675 3.675H5.3375V3.6625ZM8.6625 22C8.1625 22 7.825 21.6625 7.825 21.1625V2.8375C7.8375 2.3375 8.1625 2 8.6625 2C9.1625 2 9.5 2.3375 9.5 2.8375V21.175C9.5 21.6625 9.1625 22 8.6625 22ZM13.6625 12.8375C13.4125 12.8375 13.25 12.75 13.075 12.5875C12.75 12.25 12.75 11.75 13.0875 11.4125L15.5875 8.9125C15.925 8.575 16.425 8.575 16.75 8.9125C17.0875 9.25 17.0875 9.75 16.75 10.075L14.25 12.575C14.0875 12.75 13.9125 12.8375 13.6625 12.8375ZM16.1625 15.3375C15.9125 15.3375 15.75 15.25 15.575 15.0875L13.075 12.5875C12.75 12.25 12.75 11.75 13.0875 11.4125C13.425 11.075 13.925 11.075 14.25 11.4125L16.75 13.9125C17.0875 14.25 17.0875 14.75 16.75 15.075C16.5875 15.25 16.4125 15.3375 16.1625 15.3375Z'
        fill={fill}
      />
    </SvgIcon>
  );
}
