import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { alpha, Box, Paper, Stack, styled, Typography, useTheme } from "@mui/material";

import EachTemplateModal from "./EachTemplateModal";
import useBoolean from "../../../../hooks/useBoolean";
import CloneFunnelModal from "../../CloneFunnelModal";
import BasicModal from "../../../../common/Modal/BasicModal";
import CoreButton from "../../../../common/Button/CoreButton";
import LoadingButton from "../../../../common/Button/LoadingButton";
import { FunnelTemplateI } from "../../../../interface/funnel-template.interface";

const CardDescription = styled(Typography)<{ clamp: string }>`
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ clamp }) => `-webkit-line-clamp: ${clamp};`}
`;

interface CardImageWrapperProps {
  coverImage: string;
}

const CardImageWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "coverImage",
})<CardImageWrapperProps>(({ theme, coverImage }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  cursor: "pointer",
  position: "relative",
  transition: "all 0.3s ease",
  width: "100%",
  height: "100%",
  backgroundImage: `url(${
    coverImage ||
    "https://forms-a5940fe4.s3.amazonaws.com/user/%2F1_form_1701841231932_image_2023_12_04T12_14_02_078Z.png"
  })`,
  backgroundSize: "cover",
  backgroundRepeat: "repeat",
  backgroundPosition: "top center",
  "& .wb_template_overlay_actions": {
    width: "100%",
    height: "100%",
    padding: theme.spacing(2),
    background: alpha(theme.palette.common.black, 0.7),
    zIndex: 1,
    opacity: 0,
    pointerEvents: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      "& .wb_short-desc": {
        transform: "translateY(-10px)",
        transition: "all 0.4s ease",
        height: "80%",
      },
    },
  },
  "&:hover": {
    "& .wb_template_overlay_actions": {
      opacity: 1,
      pointerEvents: "all",
      transition: "all 0.3s ease",
    },
  },
}));

interface TemplateCardInterface {
  template: FunnelTemplateI;
}

const FunnelTemplateCard: React.FC<TemplateCardInterface> = ({ template }) => {
  const theme = useTheme();

  const { id, name, description, thumbnail } = template || {};
  const [isLoading, setLoading] = useState(false);

  const {
    value: openTemplateNameModal,
    setTrue: onOpenTemplateNameModal,
    setFalse: onCloseTemplateNameModal,
  } = useBoolean(false);

  const { value: previewTemplate, setTrue: onPreviewTemplate, setFalse: onClosePreviewTemplate } = useBoolean(false);

  const handleTemplateSelect = () => {
    setLoading(true);
    onOpenTemplateNameModal();
  };

  const demoTemplate = "https://olk9ai.s3.amazonaws.com/123/file/webpage-1703670851452.png";

  return (
    <>
      <Box sx={{ height: "100%", minHeight: { xs: 300, lg: 400 } }}>
        <CardImageWrapper variant={"outlined"} coverImage={thumbnail ?? demoTemplate}>
          <Stack justifyContent={"space-between"} className={"wb_template_overlay_actions"}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1}>
              <LoadingButton
                variant={"contained"}
                size={"small"}
                color={"secondary"}
                isLoading={isLoading}
                loadingText={"Processing..."}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTemplateSelect();
                }}
              >
                Select
              </LoadingButton>
              <CoreButton
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                sx={{ borderColor: "common.white", padding: "4px !important" }}
                onClick={(e) => {
                  e.stopPropagation();
                  onPreviewTemplate();
                }}
              >
                <VisibilityIcon sx={{ color: theme.palette.common.white }} />
              </CoreButton>
            </Stack>
            <Stack justifyContent={"flex-end"} onClick={onOpenTemplateNameModal} className={"wb_short-desc"}>
              <CardDescription variant={"caption"} noWrap color={"common.white"} clamp={"3"}>
                {description}
              </CardDescription>
            </Stack>
          </Stack>
        </CardImageWrapper>
        <Typography variant={"body2"} color={"text.primary"} fontWeight={400}>
          {name}
        </Typography>
      </Box>

      <BasicModal
        width={500}
        open={openTemplateNameModal}
        onClose={() => {
          onCloseTemplateNameModal();
          setLoading(false);
        }}
      >
        <CloneFunnelModal
          funnelId={id}
          onClose={() => {
            onCloseTemplateNameModal();
            setLoading(false);
          }}
        />
      </BasicModal>

      <BasicModal
        width={"98%"}
        open={previewTemplate}
        onClose={onClosePreviewTemplate}
        hideCloseIcon={true}
        disableOutSideClick={true}
      >
        <EachTemplateModal
          onClose={onClosePreviewTemplate}
          template={template}
          useTemplate={handleTemplateSelect}
          isLoading={isLoading}
        />
      </BasicModal>
    </>
  );
};

export default FunnelTemplateCard;
