import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, InputAdornment, Stack, Typography } from "@mui/material";
import { Delete, DeleteForever, Restore, Search } from "@mui/icons-material";

import { AppDispatch } from "../../state/store";
import useBoolean from "../../hooks/useBoolean";
import CoreButton from "../../common/Button/CoreButton";
import { HeaderBoxWrapper } from "../SitesList/SitesListStyles";
import CoreTextField from "../../common/TextField/CoreTextField";
import { debounceHandler } from "../../helpers/utils/debounceHandler";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import {
  deleteTemplates,
  resetDeleteTemplates,
  templateSearchTextFilter,
  trashTemplates,
} from "../../state/features/funnelTemplate/funnelTemplateSlice";
import { selectFunnelTemplateState } from "../../state/features/funnelTemplate/funnelTemplateSelector";

const AdminFunnelListFilterHeader: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedTemplateIds, isDeleting, isDeleteSuccess, filters } = useSelector(selectFunnelTemplateState);
  const { inTrash } = filters;

  const { setFalse: closeDeleteModal, setTrue: openDeleteModal, value: isDeleteModalOpen } = useBoolean();

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(resetDeleteTemplates());
      closeDeleteModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteSuccess]);

  const trashWebsiteHandler = () => {
    dispatch(trashTemplates({ funnelIds: selectedTemplateIds, inTrash: true }));
  };

  const restoreWebsiteHandler = () => {
    dispatch(trashTemplates({ funnelIds: selectedTemplateIds, inTrash: false }));
  };

  const deleteWebsiteHandler = () => {
    dispatch(deleteTemplates({ funnelIds: selectedTemplateIds }));
  };

  return (
    <HeaderBoxWrapper>
      <>
        <Box sx={{ flex: 1 }}>
          {selectedTemplateIds.length === 0 ? (
            <CoreTextField
              fullWidth
              size={"small"}
              type={"search"}
              color={"secondary"}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ mr: 0 }} position='start'>
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder={"Search Funnel by name"}
              onChange={debounceHandler((event) => {
                dispatch(templateSearchTextFilter(event.target.value));
              }, 500)}
            />
          ) : (
            <Stack direction='row' spacing={1} alignItems='center' justifyContent={"space-between"}>
              <Typography variant={"body2"}>
                <b>{selectedTemplateIds.length}</b> {selectedTemplateIds.length === 1 ? "Template" : "Templates"}{" "}
                Selected
              </Typography>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CoreButton
                  variant={"outlined"}
                  color={"error"}
                  startIcon={inTrash ? <DeleteForever /> : <Delete />}
                  onClick={inTrash ? openDeleteModal : trashWebsiteHandler}
                >
                  Delete {inTrash && " Forever"}
                </CoreButton>
                {inTrash && (
                  <CoreButton
                    variant={"outlined"}
                    color={"secondary"}
                    startIcon={<Restore />}
                    onClick={restoreWebsiteHandler}
                  >
                    Restore
                  </CoreButton>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </>

      <DeleteWarningModal
        title={"Delete Forever!!"}
        isLoading={isDeleting}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteWebsiteHandler}
      />
    </HeaderBoxWrapper>
  );
};

export default AdminFunnelListFilterHeader;
