import { RedirectType } from "../../state/features/redirectUrl/redirectUrl.interface";

interface UrlRedirectTypeInterface {
  label: string;
  value: RedirectType;
}
export const REDIRECT_TYPE_LIST: UrlRedirectTypeInterface[] = [
  {
    label: "Url",
    value: "URL",
  },
  {
    label: "Website",
    value: "WEBSITE",
  },
  {
    label: "Funnel",
    value: "FUNNEL",
  },
  {
    label: "All",
    value: "ALL",
  },
];
