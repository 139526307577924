import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";
import { WEBSITE_REPORT_DATE_RANGE_TYPE } from "../constant/websiteConstant";

export const getDatesByDuration = (durationType: string) => {
  const currentDate = new Date();

  if (durationType === WEBSITE_REPORT_DATE_RANGE_TYPE.THIS_WEEK) {
    return [startOfWeek(currentDate, { weekStartsOn: 1 }), endOfWeek(currentDate, { weekStartsOn: 1 })];
  } else if (durationType === WEBSITE_REPORT_DATE_RANGE_TYPE.THIS_MONTH) {
    return [startOfMonth(currentDate), endOfMonth(currentDate)];
  } else if (durationType === WEBSITE_REPORT_DATE_RANGE_TYPE.ALL_TIME) {
    return [new Date("2023-12-01"), currentDate];
  }
  return [currentDate, currentDate];
};
