import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import tagService from "../../../services/tag.service";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  createTagFailed,
  createTagSuccess,
  deleteTagFailed,
  deleteTagSuccess,
  fetchTagFailed,
  fetchTagSuccess,
  updateTagFailed,
  updateTagSuccess,
} from "./tagSlice";
import { CreateTagI, UpdateTagI } from "../../../interface/tag.interface";

function* tagWatcher() {
  yield takeEvery("tag/fetchTag", fetchTagSaga);
  yield takeEvery("tag/createTag", createTagSaga);
  yield takeEvery("tag/updateTag", updateTagSaga);
  yield takeEvery("tag/deleteTag", deleteTagSaga);
}

function* fetchTagSaga() {
  try {
    const response = yield* call(tagService.fetchTag);

    if (response.success) {
      yield put(fetchTagSuccess(response.data));
    } else {
      yield put(fetchTagFailed());
    }
  } catch (err) {
    yield put(fetchTagFailed());
  }
}

function* createTagSaga(action: PayloadAction<CreateTagI>) {
  try {
    const response = yield* call(tagService.createTag, action.payload);

    if (response.success) {
      yield put(createTagSuccess(response.data));
      showSuccessToaster("Tag created");
    } else {
      yield put(createTagFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(createTagFailed());
  }
}

function* updateTagSaga(action: PayloadAction<UpdateTagI>) {
  try {
    const response = yield* call(tagService.updateTag, action.payload);

    if (response.success) {
      yield put(updateTagSuccess(response.data));
      showSuccessToaster("Tag Updated");
    } else {
      yield put(updateTagFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(updateTagFailed());
  }
}

function* deleteTagSaga(action: PayloadAction<string>) {
  try {
    const response = yield* call(tagService.deleteTag, action.payload);

    if (response.success) {
      yield put(deleteTagSuccess(action.payload));
      showSuccessToaster("Tag deleted");
    } else {
      yield put(deleteTagFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteTagFailed());
  }
}

export default function* tagSaga() {
  yield all([tagWatcher()]);
}
