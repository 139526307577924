import React from "react";
import { Provider } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Routes from "./AppRoutes";
import store from "./state/store";
import { setCookie } from "./helpers/Cookie";
import ThemeWrapper from "./common/GlobalWrapper/ThemeWrapper";

interface Props {
  history?: RouteComponentProps["history"];
}


const App: React.FC<Props> = ({ history }) => {
  if (process.env.REACT_APP_PRODUCTION_MODE === "false") {
    // TODO set api access token in cookie for test
    setCookie(
      process.env.REACT_APP_ACCESS_TOKEN as string,
      // "DGM2RNXDL8KS85JD066E", // production user
      // "W6GSG6PQ6K5ZCQ1UQT5E", // user
      "5W67ZI3Q80O1RC50YK8I", // admin
      // "8568X4DH49KY5WQH1S6F", // super admin
      process.env.REACT_APP_ACCESS_TOKEN_VALIDITY as string
    );
  }

  return (
    <Provider store={store}>
      <ThemeWrapper>
        <Routes history={history} />
      </ThemeWrapper>
    </Provider>
  );
};

export default App;
