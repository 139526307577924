import httpRequest from "./api/httpRequest";
import {
  FolderInterface,
  IDeleteFolderPayload,
  IGetFoldersPayload,
  IGetFoldersResponse,
} from "../state/features/folder/folder.Interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class FolderService {
  getFolders(params: IGetFoldersPayload) {
    return httpRequest.get<IGetFoldersResponse>(websiteBuilderAPI + "website/folder/list", params);
  }

  createFolder(params: FolderInterface) {
    return httpRequest.post<FolderInterface>(websiteBuilderAPI + "website/folder/create", params);
  }

  updateFolder(params: FolderInterface) {
    return httpRequest.put<FolderInterface>(websiteBuilderAPI + "website/folder/update", params);
  }

  deleteFolder(params: IDeleteFolderPayload) {
    return httpRequest.delete<number>(websiteBuilderAPI + "website/folder/delete", params);
  }
}

const folderService = new FolderService();
export default folderService;
