import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import FunnelResetFilter from "./SiteListfilterHeader/FunnelResetFilter";
import FunnelListBulkActions from "./SiteListfilterHeader/FunnelListBulkActions";
import FunnelListFilterSearch from "./SiteListfilterHeader/FunnelListFilterSearch";
import FunnelListFilterStatus from "./SiteListfilterHeader/FunnelListFilterStatus";
import { selectFunnelSelectedIds } from "../../state/features/funnel/funnelSelector";
import { HeaderBoxWrapper } from "./FunnelListStyles";

const FunnelListFilterHeader: React.FC = () => {
  const selectedFunnelIds = useSelector(selectFunnelSelectedIds);

  return (
    <HeaderBoxWrapper>
      <>
        <Box sx={{ flex: 1 }}>
          {selectedFunnelIds?.length === 0 ? <FunnelListFilterSearch /> : <FunnelListBulkActions />}
        </Box>
        <FunnelListFilterStatus />
        <FunnelResetFilter />
      </>
    </HeaderBoxWrapper>
  );
};

export default FunnelListFilterHeader;
