import { styled } from "@mui/material";
import CoreButton from "./CoreButton";

export const LightCoreButton = styled(CoreButton)(({ theme }) => ({
  backgroundColor: theme.palette.other.secondaryHover,
  "&.MuiButtonBase-root:focus": {
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
  },
  "&.MuiButton-textError": {
    backgroundColor: theme.palette.error.light,
    "&:focus": {
      backgroundColor: `${theme.palette.error.light} !important`,
    },
  },
}));
