import React from "react";
import { Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddOrEditDomain from "./AddOrEditDomain";
import useBoolean from "../../../hooks/useBoolean";
import CoreButton from "../../../common/Button/CoreButton";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";

const DomainHeader = () => {
  const {
    value: openAddDomainModal,
    setTrue: onOpenAddDomainModal,
    setFalse: onCloseAddDomainModal,
  } = useBoolean(false);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1} pb={2}>
        <CoreButton variant={"contained"} color={"secondary"} startIcon={<AddIcon />} onClick={onOpenAddDomainModal}>
          Add New Domain
        </CoreButton>
      </Stack>

      <BasicModalWithHeader title='Add New Domain' open={openAddDomainModal} onClose={onCloseAddDomainModal}>
        <AddOrEditDomain onClose={onCloseAddDomainModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default DomainHeader;
