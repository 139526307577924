import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FunnelTemplateSettingI,
  UpdateFunnelTemplateSettingI,
} from "../../../interface/funnelTemplateSetting.interface";

const initialState = {
  data: {} as FunnelTemplateSettingI,
  isLoading: false,
  isError: false,
  error: "",

  isUpdating: false,
  isUpdated: false,
  isRenaming: false,
  isRenamed: false,
};

const funnelTemplateSetting = createSlice({
  name: "funnelTemplateSetting",
  initialState,
  reducers: {
    getFunnelTemplateSetting(state, action: PayloadAction<string>) {
      state.isLoading = true;
    },

    getFunnelTemplateSettingSuccess(state, action: PayloadAction<FunnelTemplateSettingI>) {
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.data = action.payload;
    },

    resetFunnelTemplateSetting(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    updateFunnelTemplateSetting(state, action: PayloadAction<UpdateFunnelTemplateSettingI>) {
      state.isUpdating = true;
    },
    updateFunnelTemplateSettingSuccess(state, action: PayloadAction<FunnelTemplateSettingI>) {
      state.isUpdating = false;
      state.isUpdated = true;
      state.data = action.payload;
    },
    resetUpdateSettingStatus(state) {
      state.isUpdating = false;
      state.isUpdated = false;
    },
  },
});

export const {
  getFunnelTemplateSetting,
  getFunnelTemplateSettingSuccess,
  resetFunnelTemplateSetting,
  updateFunnelTemplateSetting,
  updateFunnelTemplateSettingSuccess,
  resetUpdateSettingStatus,
} = funnelTemplateSetting.actions;

export default funnelTemplateSetting.reducer;
