import React from "react";
import { Alert, Avatar, Stack, styled, Typography } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import LoadingButton from "../Button/LoadingButton";
import CoreButton from "../Button/CoreButton";
import BasicModal from "./BasicModal";

const IconAvatar = styled(Avatar)(({ theme }) => ({
  width: "100px",
  height: "90px",
  backgroundColor: theme.palette.error.light,
}));

interface Props {
  warningSubtitle?: string;
  warningContent?: string;
  buttonText?: string;
  cancelButtonText?: string;
  title?: string;
  open?: boolean;
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onConfirm?: () => void;
  isLoading?: boolean;
}

const DeleteWarningModal: React.FC<Props> = ({
  title = "Delete",
  warningSubtitle = "Are you sure, want to delete?",
  warningContent = "This operation cannot be undone.",
  buttonText = "Yes, Delete",
  cancelButtonText = "Cancel",
  open = false,
  onClose,
  onConfirm,
  isLoading = false,
}) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <>
        <Stack alignItems='center' spacing={2} sx={{ p: 2 }}>
          <IconAvatar variant='rounded'>
            <WarningRoundedIcon sx={{ fontSize: "70px" }} color='error' />
          </IconAvatar>
          <Typography variant='h6'>{title}</Typography>
          <Typography variant='body2'>{warningSubtitle}</Typography>
          <Alert severity='error'>{warningContent}</Alert>
        </Stack>

        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={2} sx={{ p: 2 }}>
          <CoreButton variant={"text"} sx={{ color: "text.primary" }} onClick={onClose}>
            {cancelButtonText}
          </CoreButton>
          <LoadingButton isLoading={isLoading} variant={"contained"} color={"error"} onClick={onConfirm}>
            {buttonText}
          </LoadingButton>
        </Stack>
      </>
    </BasicModal>
  );
};

export default DeleteWarningModal;
