import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import EditPageButtonGroup from "./EditPageButtonGroup";
import { PageImageBox, PageWrapperBox } from "./FunnelPageStyles";
import { selectFunnelTemplateStepState } from "../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";

const OriginalPage: React.FC = () => {
  const { selectedFunnelStep } = useSelector(selectFunnelTemplateStepState);

  return (
    <PageWrapperBox textAlign={"center"}>
      <Typography variant={"subtitle2"} fontWeight={600} color={"text.secondary"}>
        Original
      </Typography>

      <PageImageBox variant={"outlined"}>
        {selectedFunnelStep?.thumbnail && <img src={selectedFunnelStep.thumbnail} alt={""} />}
      </PageImageBox>

      <EditPageButtonGroup />
    </PageWrapperBox>
  );
};

export default OriginalPage;
