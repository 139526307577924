import React, { useEffect } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FormHelperText, Grid, Paper, Stack, styled } from "@mui/material";
import AdminPAgeSettingSkeleton from "./AdminPAgeSettingSkeleton";
import LoadingButton from "../../../common/Button/LoadingButton";
import FileUploadWithPreview from "../../../common/FileUpload/FileUploadWithPreview";
import { registerPatternTemplateSettings } from "../../../helpers/constant/registerPattern";
import { UpdateAdminWebsiteSettingI } from "../../../interface/adminWebsiteSetting.interface";
import { selectTagState } from "../../../state/features/tag/tagSelector";
import { selectTypeState } from "../../../state/features/type/typeSelector";
import { selectCategoryState } from "../../../state/features/category/categorySelector";
import {
  resetAdminWebsiteSetting,
  updateAdminWebsiteSetting,
} from "../../../state/features/adminWebsiteSetting/adminWebsiteSettingSlice";
import { selectAdminWebsiteSettingState } from "../../../state/features/adminWebsiteSetting/adminWebsiteSettingSelector";
import { selectAdminTemplatePageState } from "../../../state/features/adminTemplatePage/adminTemplatePageSelector";
import { fetchTemplatePages } from "../../../state/features/adminTemplatePage/adminTemplatePageSlice";
import CoreTextField from "../../../common/TextField/CoreTextField";

const MultiSelect = styled(Select)(({ theme }) => ({
  "& .st-select__menu": {
    zIndex: 9,
  },
  "& .st-select__control": {
    borderColor: theme.palette.other.outlinedBorderDarker,
  },
  "& .st-select__input-container": {
    height: 30,
    "& input": {
      height: "100% !important",
    },
  },
}));

interface ReactSelectValue {
  label: string;
  value: string;
}

const AdminWebsitePageSettings: React.FC = () => {
  const dispatch = useDispatch();

  const { data: tagList, isLoading: tagsIsLoading } = useSelector(selectTagState);
  const { data: typeList, isLoading: typesIsLoading } = useSelector(selectTypeState);
  const { data: categoryList, isLoading: categoriesIsLoading } = useSelector(selectCategoryState);
  const { data: pageList, isLoading: pageIsLoading } = useSelector(selectAdminTemplatePageState);
  const { data, isLoading, isUpdating, isUpdated } = useSelector(selectAdminWebsiteSettingState);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<UpdateAdminWebsiteSettingI>();

  const tagOptions = tagList.map<ReactSelectValue>((tag) => ({ value: tag.id, label: tag.name }));
  const typeOptions = typeList.map<ReactSelectValue>((type) => ({ value: type.id, label: type.name }));
  const categoryOptions = categoryList.map<ReactSelectValue>((category) => ({
    value: category.id,
    label: category.name,
  }));
  const pageOptions = pageList.map<ReactSelectValue>((page) => ({
    value: page.pageId,
    label: page.name,
  }));

  watch(["tagIds", "typeIds", "categoryId", "thumbnail", "name"]);

  useEffect(() => {
    if (data?.name && isLoading === false) {
      reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isUpdated) {
      dispatch(resetAdminWebsiteSetting());
      dispatch(fetchTemplatePages({ websiteId: data.websiteId, currentPage: 1, limit: 100 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const onSubmitHandler = (value: UpdateAdminWebsiteSettingI) => {
    dispatch(updateAdminWebsiteSetting(value));
  };

  let content = <></>;

  if (isLoading || tagsIsLoading || typesIsLoading || categoriesIsLoading) {
    content = <AdminPAgeSettingSkeleton />;
  } else {
    content = (
      <Paper
        component='form'
        noValidate
        onSubmit={handleSubmit(onSubmitHandler)}
        elevation={0}
        sx={{ p: 3, maxWidth: "90%", margin: "auto" }}
      >
        <Grid container spacing={2} columnSpacing={3} mb={3}>
          <Grid item xs={12} md={12}>
            <Box>
              <CoreTextField
                required
                fullWidth
                label='Website Name'
                color='secondary'
                error={!!(errors.name && errors.name.message)}
                helperText={errors.name && errors.name.message}
                {...register("name", registerPatternTemplateSettings.name)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Controller
                name='defaultPageId'
                control={control}
                rules={registerPatternTemplateSettings.defaultPageId}
                render={({ field: { value }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth error={invalid} required>
                      <InputLabel color='secondary'>Default Home Page</InputLabel>
                      <MultiSelect
                        isLoading={pageIsLoading}
                        classNamePrefix={"st-select"}
                        options={pageOptions}
                        value={pageOptions.find((option) => option.value === value)}
                        onChange={(newOption) => {
                          const option = newOption as ReactSelectValue;
                          setValue("defaultPageId", option.value);
                        }}
                      />
                      {invalid && error?.message && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Controller
                name='categoryId'
                control={control}
                rules={registerPatternTemplateSettings.categoryId}
                render={({ field: { value }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth error={invalid} required>
                      <InputLabel color='secondary'>Category</InputLabel>
                      <MultiSelect
                        isLoading={categoriesIsLoading}
                        classNamePrefix={"st-select"}
                        options={categoryOptions}
                        value={categoryOptions.find((category) => category.value === value)}
                        onChange={(newOption) => {
                          const option = newOption as ReactSelectValue;
                          setValue("categoryId", option.value);
                        }}
                      />
                      {invalid && error?.message && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Controller
                name='typeIds'
                control={control}
                render={({ field: { value } }) => {
                  return (
                    <FormControl fullWidth>
                      <InputLabel color='secondary'>Types</InputLabel>
                      <MultiSelect
                        isLoading={typesIsLoading}
                        classNamePrefix={"st-select"}
                        isMulti={true}
                        options={typeOptions}
                        value={typeOptions.filter((type) => value?.includes(type.value))}
                        onChange={(newOptions) => {
                          const options = newOptions as ReactSelectValue[];
                          setValue(
                            "typeIds",
                            options.map((option) => option.value)
                          );
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name='tagIds'
              render={({ field: { value } }) => (
                <FormControl fullWidth>
                  <InputLabel color='secondary'>Tags</InputLabel>
                  <MultiSelect
                    isMulti={true}
                    options={tagOptions}
                    isLoading={tagsIsLoading}
                    classNamePrefix={"st-select"}
                    value={tagOptions.filter((tag) => value?.includes(tag.value))}
                    onChange={(newOptions) => {
                      const options = newOptions as ReactSelectValue[];
                      const tags = options.map((option) => option.value);
                      setValue("tagIds", tags);
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <CoreTextField
                fullWidth
                multiline
                label='Description'
                color='secondary'
                minRows={7}
                maxRows={10}
                error={!!(errors.description && errors.description.message)}
                helperText={errors.description && errors.description.message}
                {...register("description", registerPatternTemplateSettings.description)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>Thumbnail</InputLabel>
            <Paper variant={"outlined"} sx={{ borderColor: "other.outlinedBorderDarker", pl: 2 }}>
              <Controller
                control={control}
                name='thumbnail'
                render={({ field: { value } }) => {
                  return (
                    <FileUploadWithPreview
                      fileUrl={value}
                      onChange={(fileUrl) => {
                        setValue("thumbnail", fileUrl);
                      }}
                    />
                  );
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        <Stack alignItems={"flex-end"}>
          <LoadingButton type='submit' isLoading={isUpdating} variant='contained' color='secondary'>
            Save
          </LoadingButton>
        </Stack>
      </Paper>
    );
  }

  return content;
};

export default AdminWebsitePageSettings;
