import React, { useEffect, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { AppDispatch } from "../../state/store";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import { AdminPageI } from "../../interface/adminPage.interface";
import CoreTextField from "../../common/TextField/CoreTextField";
import {
  registerPatternPage,
  removeSpecialCharacters,
  replaceSpaceToHyphen,
} from "../../helpers/constant/registerPattern";
import {
  createTemplatePage,
  resetCreateTemplatePage,
  resetUpdateTemplatePage,
  updateTemplatePage,
} from "../../state/features/adminTemplatePage/adminTemplatePageSlice";
import { selectAdminTemplatePageState } from "../../state/features/adminTemplatePage/adminTemplatePageSelector";
import { generateEditorUrl } from "../../helpers/utils/generateEditorUrl";

interface IProps {
  onClose: () => void;
  pageInfo?: AdminPageI;
  isEdit?: boolean;
}

const AddOrEditTemplatePageInfo: React.FC<IProps> = ({ onClose, pageInfo, isEdit = false }) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { websiteId } = useParams<{ websiteId: string }>();

  const { isUpdating, isCreating, isUpdateSuccess, isCreateSuccess, createdPageId } =
    useSelector(selectAdminTemplatePageState);
  const [isPathAlreadyEdited, setIsPathAlreadyEdited] = useState(isEdit);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<AdminPageI>();

  // auto path created feature
  const fieldName = !isPathAlreadyEdited && watch("name");

  useEffect(() => {
    if (!isPathAlreadyEdited) {
      let path = fieldName ? fieldName.toLowerCase().trim() : "";
      path = removeSpecialCharacters(path);
      path = path.trimStart();
      path = replaceSpaceToHyphen(path);
      setValue("path", path);
    }
  }, [fieldName]);

  useEffect(() => {
    if (pageInfo && pageInfo.pageId && isEdit) {
      setValue("name", pageInfo.name ?? "");
      setValue("path", pageInfo.path ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      if (createdPageId && isCreateSuccess) {
        history.push(generateEditorUrl(createdPageId, websiteId, true));
      }

      dispatch(resetCreateTemplatePage());
      dispatch(resetUpdateTemplatePage());

      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess, isUpdateSuccess]);

  const onFormSubmit: SubmitHandler<AdminPageI> = (values) => {
    if (isEdit) {
      if (pageInfo) {
        values.pageId = pageInfo.pageId;
        dispatch(updateTemplatePage(values));
      }
    } else {
      values.websiteId = websiteId;
      dispatch(createTemplatePage(values));
    }
  };

  const isLoading = isCreating || isUpdating;
  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <Stack gap={2} p={3}>
        <CoreTextField
          required
          type='text'
          size='medium'
          color='secondary'
          label='Name for Page'
          placeholder='Name for Page'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternPage.name)}
        />
        <CoreTextField
          required
          type='text'
          size='medium'
          color='secondary'
          label='Path'
          placeholder='Path'
          error={!!(errors.path && errors.path.message)}
          helperText={errors.path && errors.path.message}
          {...register("path", registerPatternPage.path)}
          onChange={() => {
            if (!isPathAlreadyEdited) setIsPathAlreadyEdited(true);
          }}
        />
      </Stack>
      <Divider light />
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1} px={3} py={2}>
        <CoreButton color={"inherit"} size={"small"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          type='submit'
          size={"small"}
          variant='contained'
          color='secondary'
          isLoading={isLoading}
          loadingText={isEdit ? "Updating..." : "Creating..."}
        >
          {isEdit ? "Update" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditTemplatePageInfo;
