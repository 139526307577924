import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Paper, Slider, Stack, Typography } from "@mui/material";
import CoreButton from "../../../../common/Button/CoreButton";
import LoadingButton from "../../../../common/Button/LoadingButton";
import {
  useGetSplitTestSettingsQuery,
  useUpdateSplitTestSettingsMutation,
} from "../../../../state/features/funnelStepPages/funnelStepPagesApiSlice";
import { selectFunnelStepPagesState } from "../../../../state/features/funnelStepPages/funnelStepPagesSelector";
import { resetDeleteStepPageSuccess } from "../../../../state/features/funnelStepPages/funnelStepPagesSlice";
import { selectSelectedFunnelStep } from "../../../../state/features/funnelStep/funnelStepSelector";

type IButtonNames = "START" | "END" | "ADJUST" | "SAVE" | "CANCEL";
const TestProcessingCard = () => {
  const dispatch = useDispatch();
  const [clickBtn, setClickBtn] = useState<IButtonNames>("START");
  const [sliderValue, setSliderValue] = useState<number>(50);

  const { id: stepId } = useSelector(selectSelectedFunnelStep);
  const { variant, isDeleteSuccess } = useSelector(selectFunnelStepPagesState);

  const { data: splitTestSettingsState, isLoading } = useGetSplitTestSettingsQuery(
    { stepId: stepId },
    { refetchOnMountOrArgChange: true }
  );
  const { data: splitTestSettings } = splitTestSettingsState || {};

  const [updateSplitTestSettings, { isLoading: isUpdating }] = useUpdateSplitTestSettingsMutation();

  useEffect(() => {
    setClickBtn(clickBtn !== "START" ? "START" : "END");
    if (splitTestSettings && splitTestSettings?.stepId) {
      if (typeof splitTestSettings.originalSplitPercentage !== "number") setSliderValue(50);
      else setSliderValue(splitTestSettings.originalSplitPercentage);
    }
  }, [splitTestSettings]);

  useEffect(() => {
    if (isDeleteSuccess) {
      onConfirmAndStartTest(false, 50, "END", true);
      dispatch(resetDeleteStepPageSuccess());
    }
  }, [isDeleteSuccess]);

  const handleChange = (_event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  const onConfirmAndStartTest = (start: boolean, percentage: number, btnName: IButtonNames, hideToaster: boolean = false) => {
    setClickBtn(btnName);

    if (btnName === "CANCEL") {
      setSliderValue(percentage);
      return;
    }

    setSliderValue(percentage);
    updateSplitTestSettings({
      stepId,
      start: start,
      originalSplitPercentage: percentage,
      variantSplitPercentage: 100 - percentage,
      hideToaster: hideToaster
    });
  };

  const showTestComponent = variant?.id;

  let content;
  if (isLoading) {
    content = <></>;
  } else if (!splitTestSettings || !splitTestSettings?.start) {
    content = (
      <>
        <LoadingButton
          size={"small"}
          color={"secondary"}
          variant={"contained"}
          isLoading={clickBtn === "START" && isUpdating}
          onClick={() => onConfirmAndStartTest(true, sliderValue, "START")}
        >
          Confirm & Start test
        </LoadingButton>
      </>
    );
  } else if (splitTestSettings.originalSplitPercentage !== sliderValue && clickBtn !== "ADJUST") {
    content = (
      <>
        <LoadingButton
          isLoading={clickBtn === "SAVE" && isUpdating}
          color={"secondary"}
          size={"small"}
          variant={"contained"}
          sx={{ minWidth: "max-content" }}
          onClick={() => onConfirmAndStartTest(true, sliderValue, "SAVE")}
        >
          Save Changes
        </LoadingButton>
        <CoreButton
          size={"small"}
          color={"inherit"}
          variant={"outlined"}
          sx={{ minWidth: "max-content" }}
          onClick={() => onConfirmAndStartTest(true, splitTestSettings.originalSplitPercentage, "CANCEL")}
        >
          Cancel
        </CoreButton>
      </>
    );
  } else {
    content = (
      <>
        <CoreButton
          disabled={sliderValue === 50}
          size={"small"}
          color={"inherit"}
          variant={"contained"}
          sx={{ minWidth: "max-content" }}
          onClick={() => onConfirmAndStartTest(true, 50, "ADJUST")}
        >
          Adjust split
        </CoreButton>
        <LoadingButton
          isLoading={clickBtn === "END" && isUpdating}
          size={"small"}
          color={"error"}
          variant={"outlined"}
          sx={{ minWidth: "max-content" }}
          onClick={() => onConfirmAndStartTest(false, sliderValue, "END")}
        >
          End split test
        </LoadingButton>
      </>
    );
  }

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} sx={{ flex: 1, minWidth: 60, maxWidth: "50%" }}>
        <Divider light sx={{ flex: 1, minWidth: 30 }} />
        {showTestComponent && (
          <Paper variant={"outlined"} sx={{ height: 220, width: 320, p: 2 }}>
            <Stack spacing={3} alignItems={"center"} justifyContent={"center"} sx={{ height: "100%", mx: "auto" }}>
              <Typography variant={"body2"}>Adjust traffic split</Typography>
              <Box sx={{ width: "80%" }}>
                <Slider color={"secondary"} value={sliderValue} onChange={handleChange} />
                <Stack direction={"row"} justifyContent={"space-between"} mt={-2}>
                  <Typography variant={"caption"}>{sliderValue}%</Typography>
                  <Typography variant={"caption"}>{100 - sliderValue}%</Typography>
                </Stack>
              </Box>
              <Stack sx={{ flexDirection: { md: "column", xl: "row" }, gap: 1 }}>{content}</Stack>
            </Stack>
          </Paper>
        )}
        <Divider light sx={{ flex: 1, minWidth: 30 }} />
      </Stack>
    </>
  );
};

export default TestProcessingCard;
