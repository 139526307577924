import React, { useState } from "react";
import { format, isDate } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, Popover, Stack, TableCell, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import { RestoreFromTrash } from "@mui/icons-material";
import AddToFolderWebsite from "./AddToFolderWebsite";
import IconButton from "../../common/IconButton";
import RouteLink from "../../common/Link/RouteLink";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import { SingleWebsiteListWrp } from "./SitesListStyles";
import { IWebsite } from "../../state/features/website/website.interface";
import { selectWebsiteState } from "../../state/features/website/websiteSelector";
import {
  deleteWebsite,
  deleteWebsiteForever,
  toggleFavStatus,
  toggleSelectedSingleWebsite,
} from "../../state/features/website/websiteSlice";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import useBoolean from "../../hooks/useBoolean";

interface IProps {
  websiteInfo: IWebsite;
}

const SingleWebsiteInTrash: React.FC<IProps> = ({ websiteInfo }: IProps) => {
  const dispatch = useDispatch();

  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);
  const { id, name, totalPage, isFavorite, createdAt = "" } = websiteInfo || {};
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { selectedWebsiteIds, isDeleting } = useSelector(selectWebsiteState);

  const toggleFavoriteHandler = () => {
    dispatch(toggleFavStatus({ websiteId: id as number, isFavorite: !isFavorite }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRestore = () => {
    dispatch(deleteWebsite({ websiteIds: [id as number], inTrash: false }));
  };

  const onConfirmDeleteForever = () => {
    dispatch(deleteWebsiteForever({ websiteIds: [id as number] }));
  };

  const open = Boolean(anchorEl);
  const formattedCreatedAt = isDate(new Date(createdAt)) ? format(new Date(createdAt), "MMM dd, yyyy") : "-";
  const isChecked = selectedWebsiteIds.includes(id as number);
  return (
    <SingleWebsiteListWrp
      selected={selectedWebsiteIds.includes(id as number)}
      className={selectedWebsiteIds.includes(id as number) ? "active" : ""}
    >
      <TableCell>
        <Stack spacing={1} direction={"row"} alignItems={"center"}>
          <Checkbox
            edge='start'
            color='secondary'
            size={"small"}
            tabIndex={-1}
            checked={isChecked}
            onChange={() => dispatch(toggleSelectedSingleWebsite(id as number))}
            sx={{
              "& .PrivateSwitchBase-input": {
                pointerEvents: "auto !important",
              },
            }}
          />
          <Checkbox
            checked={isFavorite}
            size={"small"}
            icon={<StarIcon color='action' />}
            checkedIcon={<StarIcon color='warning' />}
            inputProps={{ "aria-label": "Checkbox demo" }}
            onClick={toggleFavoriteHandler}
          />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center' sx={{ width: `100%` }}>
              <RouteLink to={`/wbuilder/site/${id}`}>
                <Typography variant='body1Medium' color='text.primary'>
                  {name}
                </Typography>
              </RouteLink>
            </Stack>

            <Typography variant='caption' color='text.secondary' fontWeight={300}>
              {totalPage} Pages | Created on: {formattedCreatedAt}
            </Typography>
          </Box>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack className={"rrmsl-list-action-icons"}>
          <CustomTooltip title='Restore' arrow placement='top'>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={handleRestore}>
              <RestoreFromTrash fontSize={"small"} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title='Delete' arrow placement='top'>
            <IconButton variant={"outlined"} color={"error"} size='small' onClick={onOpenModal}>
              <DeleteIcon fontSize={"small"} />
            </IconButton>
          </CustomTooltip>
        </Stack>
      </TableCell>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AddToFolderWebsite
          websiteIds={[id as number]}
          folderIds={(websiteInfo.folder || []).map((s) => s.folderId)}
          onClose={handleClose}
        />
      </Popover>

      <DeleteWarningModal
        title={"Delete Forever!!"}
        warningSubtitle={"Are you sure, you want to delete this website?"}
        warningContent={"After delete, this operation cannot be undone."}
        buttonText={"Yes, Delete Forever"}
        cancelButtonText={"Cancel"}
        open={openModal}
        onClose={onCloseModal}
        onConfirm={onConfirmDeleteForever}
        isLoading={isDeleting}
      />
    </SingleWebsiteListWrp>
  );
};

export default SingleWebsiteInTrash;
