import React from "react";
import { Box, Tab, Tabs } from "@mui/material";

interface Props {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}

const AdminWebsitePageTabs: React.FC<Props> = ({ value, setValue }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box px={2} py={0}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='basic tabs example'
        textColor={"secondary"}
        indicatorColor={"secondary"}
      >
        <Tab label='Pages' />
        <Tab label='Settings' />
      </Tabs>
    </Box>
  );
};

export default AdminWebsitePageTabs;
