import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, IconButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import useBoolean from "../../../../hooks/useBoolean";
import TypeI from "../../../../interface/type.interface";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../../../common/PopoverMenu/CustomPopoverMenu";
import AddOrEditTypesName from "../modals/AddOrEditTypesName";
import { deleteType } from "../../../../state/features/funnel-type/funnelTypeSlice";
import DeleteWarningModal from "../../../../common/Modal/DeleteWarningModal";
import { selectFunnelTypeState } from "../../../../state/features/funnel-type/funnelTypeSelector";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import { toggleType } from "../../../../state/features/funnelTemplate/funnelTemplateSlice";
import { SideBarListItem, StyledListItemButton } from "../../../SitesList/SitesListStyles";
import { selectFunnelFilterState } from "../../../../state/features/funnelTemplate/funnelTemplateSelector";

interface IProps {
  type: TypeI;
  selected?: boolean;
  isAdmin: boolean;
}

const TypesListItem: React.FC<IProps> = ({ type, selected, isAdmin }) => {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  const { isDeleting } = useSelector(selectFunnelTypeState);
  const { value: openEditModal, setTrue: onOpenEditModal, setFalse: onCloseEditModal } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const { typeIds } = useSelector(selectFunnelFilterState);

  const isIdIncludesInSelectedTypes = typeIds.includes(type.id);

  const deleteTypeHandler = () => {
    dispatch(deleteType(type.id));
  };

  return (
    <>
      <SideBarListItem
        disablePadding
        sx={{ pr: 2 }}
        className={selected || isActive || isIdIncludesInSelectedTypes ? "active" : ""}
      >
        <StyledListItemButton
          onClick={() => {
            dispatch(toggleType(type.id));
          }}
        >
          <ListItemIcon>
            <Checkbox edge='start' tabIndex={-1} size={"small"} checked={isIdIncludesInSelectedTypes} />
          </ListItemIcon>
          <ListItemText primary={<Typography variant={"body2"}>{type.name}</Typography>} className='text-truncate' />
        </StyledListItemButton>

        <CustomPopoverMenu callbackOnClose={() => setIsActive(false)} minWidth={"max-content"}>
          <CustomPopoverMenuButton>
            <IconButton size={"small"} className={"ib-sidebar-more-icon"} onClick={() => setIsActive(true)}>
              <MoreVertIcon color={"action"} fontSize={"small"} />
            </IconButton>
          </CustomPopoverMenuButton>

          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Edit'
              icon={<EditIcon color={"action"} fontSize={"small"} />}
              onClick={onOpenEditModal}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          <CustomPopoverMenuItem
            title='Delete'
            icon={<DeleteIcon color={"action"} fontSize={"small"} />}
            onClick={onOpenDeleteModal}
            onClickHide={true}
          />
        </CustomPopoverMenu>
      </SideBarListItem>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={deleteTypeHandler}
      />

      <BasicModalWithHeader title={"Edit Type"} open={openEditModal} onClose={onCloseEditModal}>
        <AddOrEditTypesName type={type} onClose={onCloseEditModal} isEditMode={true} />
      </BasicModalWithHeader>
    </>
  );
};

export default TypesListItem;
