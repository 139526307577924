import React from "react";
import { alpha, Box, Divider, Paper, Radio, Stack, styled, Typography } from "@mui/material";

interface CardWrapperInterface {
  selected?: boolean;
}

const CardWrapper = styled(Paper)<CardWrapperInterface>(({ theme, selected }) => ({
  width: "50%",
  cursor: "pointer",
  borderRadius: "8px",
  border: "2px solid",
  borderColor: selected ? theme.palette.secondary.main : "transparent",
  background: selected ? theme.palette.other.secondaryHover : theme.palette.background.paper,
  transition: "all 0.3s ease",
  position: "relative",
  overflow:'hidden',
  "&:hover": {
    borderColor: theme.palette.secondary.main,
  },
  "&.card-disabled": {
    pointerEvents: "none",
    "& .create-card-content": {
      opacity: 0.3,
      pointerEvents: "none",
      background: alpha(theme.palette.common.black,0.05),
    },
    "& .disabled-overlay-box": {
      position: "absolute",
      width: "100%",
      height: "100%",
      top:'35%',
      left:'34%',
      zIndex:999,
    },
  },
}));

interface OptionCardInterface {
  checked: boolean;
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const CreateWebsiteOptionCard: React.FC<OptionCardInterface> = ({
  checked,
  disabled,
  onClick,
  title,
  subtitle,
  children,
}) => {
  return (
    <CardWrapper elevation={1} selected={checked} onClick={onClick} className={disabled ? "card-disabled" : ""}>
      {disabled && (
        <Box className={"disabled-overlay-box"}>
          <Typography variant={"subtitle1"} color={"other.darkNavy"}>Coming soon...</Typography>
        </Box>
      )}
      <Box className={"create-card-content"} >
        <Stack
          direction={"row"}
          alignItems={"baseline"}
          justifyContent={"space-between"}
          px={3}
          py={2}
          sx={{ position: "relative" }}
        >
          <Box>
            <Typography variant={"subtitle1"} color={checked ? "secondary.main" : "text.primary"} fontWeight={500}>
              {title}
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"} fontSize={12} fontWeight={400}>
              {subtitle}
            </Typography>
          </Box>
          <Radio
            size={"small"}
            color={"secondary"}
            checked={checked}
            sx={{ position: "absolute", top: 10, right: 10 }}
          />
        </Stack>
        <Divider light />
        <Box p={3} pb={4}>
          {children}
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default CreateWebsiteOptionCard;
