import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Divider, FormControl, MenuItem, Select, Stack } from "@mui/material";

import { AppDispatch } from "../../../state/store";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import InputWithLabel from "../../../common/TextField/InputWithLabel";
import { SECTION_TYPES } from "../../../helpers/constant/sectionLibraryConstant";
import { CreateSectionLibraryI } from "../../../interface/websiteSectionLibrary.interface";
import {
  resetCreateStatus,
  createSectionLibrary,
} from "../../../state/features/adminSectionLibrary/adminSectionLibrarySlice";
import { selectSectionLibraryState } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";

interface IProps {
  onClose: () => void;
}

const CreateSectionModalAdmin: React.FC<IProps> = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { isCreating, isCreated, createdSectionLibraryId } = useSelector(selectSectionLibraryState);
  const { handleSubmit, control } = useForm<CreateSectionLibraryI>({ defaultValues: { name: "", type: "header" } });

  useEffect(() => {
    if (isCreated) {
      history.push("/wbuilder/admin/section/" + createdSectionLibraryId);
      dispatch(resetCreateStatus());
    }
  }, [dispatch, isCreated, history, createdSectionLibraryId]);

  const onSubmitHandler = (value: CreateSectionLibraryI) => {
    dispatch(createSectionLibrary(value));
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Stack spacing={2} p={3}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: "Section name is required!",
            maxLength: {
              value: 50,
              message: "section name must have less than 50 characters!",
            },
            pattern: {
              value: /^(?!\s*$).+/,
              message: "section name is empty",
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => {
            return (
              <InputWithLabel
                required
                color={"secondary"}
                fullWidth={true}
                label={"Section Name"}
                error={invalid}
                helperText={error?.message}
                {...field}
              />
            );
          }}
        />
        <Controller
          name='type'
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select color={"secondary"} value={value} onChange={onChange}>
                  {SECTION_TYPES.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />
      </Stack>

      <Divider light />
      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} p={3}>
        <CoreButton color={"inherit"} size={"small"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          type='submit'
          variant={"contained"}
          color={"secondary"}
          size={"small"}
          isLoading={isCreating}
          sx={{ minWidth: 88 }}
        >
          Create
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CreateSectionModalAdmin;
