import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, ListItemButton, ListItemIcon, styled, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import {
  selectTrashFolder,
  selectArchivedFolder,
  selectFavoriteFolder,
} from "../../state/features/website/websiteSlice";
import { selectWebsiteFilters } from "../../state/features/website/websiteSelector";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "4px",
  marginBottom: theme.spacing(0.5),
  "& .MuiListItemIcon-root": {
    color: theme.palette.action.active
  },
  "&:hover":{
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
  },
  "&.Mui-selected":{
    backgroundColor: `${theme.palette.other.secondaryHover} !important`,
    "& .MuiListItemIcon-root": {
      color: theme.palette.secondary.main
    }
  }
}));

const SpecialFolderList = () => {
  const dispatch = useDispatch();
  const { isFavorite, isArchived, inTrash } = useSelector(selectWebsiteFilters);

  return (
    <List>
      <StyledListItemButton selected={isFavorite} onClick={() => dispatch(selectFavoriteFolder())}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <StarIcon color={"warning"} />
        </ListItemIcon>
        <Typography variant='buttonMedium' color={isFavorite ? "secondary.main" : "text.secondary"}>
          Favorites
        </Typography>
      </StyledListItemButton>

      <StyledListItemButton selected={isArchived} onClick={() => dispatch(selectArchivedFolder())}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <ArchiveIcon />
        </ListItemIcon>
        <Typography variant='buttonMedium' color={isArchived ? "secondary.main" : "text.secondary"}>
          Archive
        </Typography>
      </StyledListItemButton>

      <StyledListItemButton selected={inTrash} onClick={() => dispatch(selectTrashFolder())}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <DeleteIcon/>
        </ListItemIcon>
        <Typography variant='buttonMedium' color={inTrash ? "secondary.main" : "text.secondary"}>
          Trash
        </Typography>
      </StyledListItemButton>
    </List>
  );
};

export default SpecialFolderList;
