import React from "react";
import { styled } from "@mui/material";

import WebsiteEmptyPage from "../../SitesList/WebsiteEmptyPage";
import AdminSectionLibraryData from "./AdminSectionLibraryData";
import { useSectionLibrary } from "../../../hooks/useSectionLibrary";
import WebsiteListSkeleton from "../../../common/LoadingView/WebsiteListSkeleton";

const Container = styled("div")(() => ({
  height: "calc(100% - 50px)",
  overflow: "hidden",
}));

const AdminSectionLibraryLists: React.FC = () => {
  const { data, isLoading } = useSectionLibrary();

  // decide what to render
  let content = <></>;
  if (isLoading) {
    content = <WebsiteListSkeleton count={10} />;
  } else if (data.length === 0) {
    content = <WebsiteEmptyPage inTrash={false} isArchived={false} isFavorite={false} />;
  } else if (data.length > 0) {
    content = <AdminSectionLibraryData />;
  }

  return <Container>{content}</Container>;
};

export default AdminSectionLibraryLists;
