import httpRequest from "./api/httpRequest";
import { AdminWebsiteSettingI, UpdateAdminWebsiteSettingI } from "../interface/adminWebsiteSetting.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class AdminWebsiteSettingService {
  getAdminWebsiteSetting(params: string) {
    return httpRequest.get<AdminWebsiteSettingI>(websiteBuilderAPI + "admin/website/setting/" + params);
  }

  updateAdminWebsiteSetting(payload: UpdateAdminWebsiteSettingI) {
    return httpRequest.put<AdminWebsiteSettingI>(
      websiteBuilderAPI + "admin/website/setting/update/" + payload.websiteId,
      payload
    );
  }
}

const adminWebsiteSettingService = new AdminWebsiteSettingService();
export default adminWebsiteSettingService;
