import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";
import { Box, Divider, FormControl, Stack } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreTextField from "../../common/TextField/CoreTextField";
import { IWebsite } from "../../state/features/website/website.interface";
import { registerPatternWebsite } from "../../helpers/constant/registerPattern";
import { selectWebsiteState } from "../../state/features/website/websiteSelector";
import { renamedWebsite, resetRenamedWebsiteSuccess } from "../../state/features/website/websiteSlice";

interface Props {
  onClose: () => void;
  websiteInfo: IWebsite;
}

const RenameWebsite: React.FC<Props> = ({ onClose, websiteInfo }) => {
  const dispatch = useDispatch();
  const { isRenaming, isRenameSuccess } = useSelector(selectWebsiteState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IWebsite>();

  useEffect(() => {
    if (websiteInfo) {
      setValue("name", websiteInfo.name);
    }
  }, [websiteInfo]);

  useEffect(() => {
    if (isRenameSuccess) {
      dispatch(resetRenamedWebsiteSuccess());
      onClose();
    }
  }, [isRenameSuccess]);

  const onSubmit: SubmitHandler<IWebsite> = (data) => {
    dispatch(renamedWebsite({ websiteId: websiteInfo.id as number, name: data.name }));
  };

  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box p={3}>
        <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
          <CoreTextField
            type='text'
            size='small'
            color='secondary'
            label={"Website Name"}
            placeholder='Enter name here'
            error={!!(errors.name && errors.name.message)}
            helperText={errors.name && errors.name.message}
            {...register("name", registerPatternWebsite.name)}
            required
          />
        </FormControl>
      </Box>
      <Divider light />
      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} p={3}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton isLoading={isRenaming} loadingText={"Renaming..."} type='submit' size='large' color='success'>
          Rename
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default RenameWebsite;
