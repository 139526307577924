import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Stack, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoadingButton from "../../../common/Button/LoadingButton";
import CoreButton from "../../../common/Button/CoreButton";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import { useGetWebsiteListQuery } from "../../../state/features/website/websitApiSlice";
import {
  AddStepPageRequestType,
  PageCloneType,
  PageType,
} from "../../../state/features/funnelStepPages/funnelStepPagesInterfaces";
import { useGetAllWebsitePagesQuery } from "../../../state/features/website-pages/websitePageApiSlice";
import { useGetFunnelListQuery } from "../../../state/features/funnel/funnelListApiSlice";
import { addStepPage } from "../../../state/features/funnelStepPages/funnelStepPagesSlice";
import { useGetAllFunnelStepQuery } from "../../../state/features/funnelStep/funnelStepApiSlice";
import { selectSelectedFunnelStep } from "../../../state/features/funnelStep/funnelStepSelector";
import { selectFunnelStepPagesState } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";
import StyledReactSelect from "../../../common/Select/StyledReactSelect";

interface IProps {
  pageType: PageType;
  cloneType: PageCloneType;
  onClose: () => void;
}

const AddNewStepModalContent: React.FC<IProps> = ({ cloneType, onClose, pageType = "ORIGINAL" }) => {
  const muiTheme = useTheme();
  const dispatch = useDispatch();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const { id: stepId } = useSelector(selectSelectedFunnelStep);
  const { isAdding } = useSelector(selectFunnelStepPagesState);

  const { handleSubmit, control, watch } = useForm();

  const websiteInfo = watch("websiteInfo");
  const funnelInfo = watch("funnelInfo");

  const [websiteSearchText, setWebsiteSearchText] = useState("");
  const { data: websiteState, isLoading: websiteLoading } = useGetWebsiteListQuery(
    {
      currentPage: 1,
      limit: 10,
      searchText: websiteSearchText,
    },
    { skip: cloneType !== "WEBSITE" }
  );

  const { data: websitePageState } = useGetAllWebsitePagesQuery(
    { websiteId: websiteInfo?.value },
    { skip: !websiteInfo?.value }
  );

  const { data: websitePageList = [] } = websitePageState || {};
  let { content = [] } = websiteState?.data || {};
  const websiteList: { value: number; label: string }[] = content.map(({ id, name }) => ({
    value: Number(id),
    label: name,
  }));

  const [funnelSearchText, setFunnelSearchText] = useState("");
  const { data: funnelState, isLoading: funnelLoading } = useGetFunnelListQuery(
    {
      currentPage: 1,
      limit: 10,
      searchText: funnelSearchText,
    },
    { skip: cloneType !== "FUNNEL" }
  );
  let { content: funnelContent = [] } = funnelState?.data || {};
  const funnelList: { value: number; label: string }[] = funnelContent.map(({ id, name }) => ({
    value: Number(id),
    label: name,
  }));

  const { data: funnelStepState } = useGetAllFunnelStepQuery(
    { funnelId: funnelInfo?.value },
    { skip: !funnelInfo?.value }
  );
  const { data: funnelStepList = [] } = funnelStepState || {};

  const doSearchWebsite = (value: string) => {
    setWebsiteSearchText(value);
  };

  // debounce implement for search input change for website
  const handleWebsiteSearch = React.useMemo(() => {
    return debounceHandler(doSearchWebsite, 500);
  }, []);

  const doSearchFunnel = (value: string) => {
    setFunnelSearchText(value);
  };

  // debounce implement for search input change for website
  const handleFunnelSearch = React.useMemo(() => {
    return debounceHandler(doSearchFunnel, 500);
  }, []);

  const onSubmitHandler = (values: any) => {
    console.log("values:: ", values);

    if (cloneType === "FUNNEL") {
      const payload: AddStepPageRequestType = {
        pageType: pageType,
        funnelId: Number(funnelId),
        stepId: stepId,
        pageCreateType: "USE_EXIST_PAGE",
        cloneType: cloneType,
        targetFunnelId: Number(values.funnelInfo.value),
        targetFunnelStepId: Number(values.stepId),
      };

      dispatch(addStepPage(payload));
    } else if (cloneType === "WEBSITE") {
      const payload: AddStepPageRequestType = {
        pageType: pageType,
        funnelId: Number(funnelId),
        stepId: stepId,
        pageCreateType: "USE_EXIST_PAGE",
        cloneType: cloneType,
        targetWebsiteId: Number(values.websiteInfo.value),
        targetPageId: Number(values.pageId),
      };

      dispatch(addStepPage(payload));
    }
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onSubmitHandler)} sx={{ p: 3 }}>
      <Grid container spacing={2}>
        {cloneType === "FUNNEL" && (
          <>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='funnelInfo'
                defaultValue={""}
                rules={{ required: "need to choose funnel" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel color='secondary'>Select Funnel</InputLabel>
                      <StyledReactSelect
                        isLoading={funnelLoading}
                        classNamePrefix={"st-select"}
                        options={funnelList}
                        value={value}
                        onChange={(newOption) => {
                          onChange(newOption);
                        }}
                        onInputChange={(newValue) => {
                          return handleFunnelSearch(newValue);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: invalid ? muiTheme.palette.error.main : muiTheme.palette.secondary.main,
                            ...(invalid ? { borderColor: muiTheme.palette.error.main } : {}),
                          },
                        })}
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: invalid ? muiTheme.palette.error.main : base.borderColor,
                          }),
                        }}
                      />
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='stepId'
                defaultValue={""}
                rules={{ required: "need to select a step" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel>Select Step</InputLabel>
                      <Select
                        fullWidth
                        displayEmpty
                        color='secondary'
                        label='Select Step'
                        value={value}
                        onChange={onChange}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value=''>None</MenuItem>
                        {funnelStepList.map((stepInfo: any) => (
                          <MenuItem key={stepInfo.id} value={stepInfo.id}>
                            {stepInfo.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </>
        )}

        {cloneType === "WEBSITE" && (
          <>
            <Grid item xs={12}>
              <Controller
                control={control}
                name='websiteInfo'
                defaultValue={""}
                rules={{ required: "need to choose website" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel color='secondary'>Select Website</InputLabel>
                      <StyledReactSelect
                        // menuIsOpen={true}
                        isLoading={websiteLoading}
                        classNamePrefix={"st-select"}
                        options={websiteList}
                        value={value}
                        onChange={(newOption) => {
                          onChange(newOption);
                        }}
                        onInputChange={(newValue) => {
                          return handleWebsiteSearch(newValue);
                        }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary: invalid ? muiTheme.palette.error.main : muiTheme.palette.secondary.main,
                            ...(invalid ? { borderColor: muiTheme.palette.error.main } : {}),
                          },
                        })}
                      />
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name='pageId'
                defaultValue={""}
                rules={{ required: "need to select website page" }}
                render={({ field: { value, onChange }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth required color={"secondary"} error={invalid}>
                      <InputLabel>Select Page</InputLabel>
                      <Select
                        fullWidth
                        displayEmpty
                        color='secondary'
                        label='Select Website'
                        value={value}
                        onChange={onChange}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value=''>None</MenuItem>
                        {websitePageList.map((pageInfo: any) => (
                          <MenuItem key={pageInfo.pageId} value={pageInfo.pageId}>
                            {pageInfo.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} spacing={2} pt={2}>
            <CoreButton color={"inherit"} onClick={onClose}>
              Cancel
            </CoreButton>
            <LoadingButton isLoading={isAdding} type={"submit"} variant={"contained"} color={"secondary"}>
              Add Page
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddNewStepModalContent;
