import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";
import {
  addFunnelFailed,
  addFunnelSuccess,
  cloneFunnelFailed,
  cloneFunnelSuccess,
  deleteFunnelForeverSuccess,
  getFunnelListFailed,
  getFunnelListSuccess,
  resetDeleteFunnelForeverSuccess,
  toggleActiveStatusFailed,
  toggleActiveStatusSuccess,
  toggleArchiveStatusFailed,
  toggleArchiveStatusSuccess,
  toggleInTrashStatusFailed,
  toggleInTrashStatusSuccess,
} from "./funnelSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  IActiveStatusPayload,
  ArchiveStatusPayload,
  GetFunnelsPayload,
  IAddFunnelPayload,
  ICloneFunnelPayload,
  IDeleteForeverPayload,
  InTrashStatusPayload,
  IAddFunnelFromTemplateLibraryPayload,
} from "./funnel.interface";
import FunnelService from "../../../services/funnel.service";

function* funnelWatcher() {
  yield takeEvery("funnel/getFunnelList", getFunnelListSaga);
  yield takeEvery("funnel/addFunnel", addFunnelSaga);
  yield takeEvery("funnel/cloneFunnel", cloneFunnelSaga);
  yield takeEvery("funnel/deleteFunnelForever", deleteFunnelForeverSaga);
  yield takeEvery("funnel/toggleActiveStatus", toggleActiveStatusSaga);
  yield takeEvery("funnel/toggleArchiveStatus", toggleArchiveStatusSaga);
  yield takeEvery("funnel/toggleInTrashStatus", toggleInTrashStatusSaga);
  yield takeEvery("funnel/addFunnelFromTemplateLibrary", addFunnelFromTemplateLibrarySaga);
}

function* getFunnelListSaga(action: PayloadAction<GetFunnelsPayload>) {
  try {
    const response = yield* call(FunnelService.getFunnelList, action.payload);

    if (response.success) {
      yield put(getFunnelListSuccess(response.data));
    } else {
      yield put(getFunnelListFailed(response.message));
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(getFunnelListFailed("Network or server error"));
  }
}

function* addFunnelSaga(action: PayloadAction<IAddFunnelPayload>) {
  try {
    const response = yield* call(FunnelService.addFunnel, action.payload);

    if (response.success) {
      yield put(addFunnelSuccess(response.data));
    } else {
      yield put(addFunnelFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(addFunnelFailed());
  }
}

function* deleteFunnelForeverSaga(action: PayloadAction<IDeleteForeverPayload>) {
  try {
    const response = yield* call(FunnelService.deleteFunnelForever, action.payload);

    if (response.success) {
      yield put(deleteFunnelForeverSuccess(action.payload));
      showSuccessToaster("Funnel deleted forever successfully!");
    } else {
      yield put(resetDeleteFunnelForeverSuccess());
      showErrorToaster("Failed funnel deleted forever");
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(resetDeleteFunnelForeverSuccess());
  }
}

function* cloneFunnelSaga(action: PayloadAction<ICloneFunnelPayload>) {
  try {
    const response = yield* call(FunnelService.cloneFunnel, action.payload);

    if (response.success) {
      yield put(cloneFunnelSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(cloneFunnelFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(cloneFunnelFailed());
  }
}
function* toggleInTrashStatusSaga(action: PayloadAction<InTrashStatusPayload>) {
  try {
    const response = yield* call(FunnelService.toggleInTrashStatus, action.payload);

    if (response.success) {
      yield put(toggleInTrashStatusSuccess(action.payload));
      showSuccessToaster(action.payload.inTrash ? "Funnel successfully move to trash" : "Funnel successfully restore");
    } else {
      yield put(toggleInTrashStatusFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(toggleInTrashStatusFailed());
  }
}

function* toggleActiveStatusSaga(action: PayloadAction<IActiveStatusPayload>) {
  try {
    const response = yield* call(FunnelService.toggleActiveStatus, action.payload);

    if (response.success) {
      yield put(toggleActiveStatusSuccess());
      showSuccessToaster(action.payload.activeStatus ? "Funnel is now active" : "Funnel is now inactive!");
    } else {
      yield put(toggleActiveStatusFailed(action.payload));
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(toggleActiveStatusFailed(action.payload));
  }
}

function* toggleArchiveStatusSaga(action: PayloadAction<ArchiveStatusPayload>) {
  try {
    const response = yield* call(FunnelService.updateArchiveStatus, action.payload);

    if (response.success) {
      yield put(toggleArchiveStatusSuccess(action.payload));
      showSuccessToaster(
        action.payload.isArchived ? "Funnel archived successfully!" : "Funnel unarchived successfully!"
      );
    } else {
      yield put(toggleArchiveStatusFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(getFunnelListFailed("Network or server error"));
  }
}

function* addFunnelFromTemplateLibrarySaga(action: PayloadAction<IAddFunnelFromTemplateLibraryPayload>) {
  try {
    const response = yield* call(FunnelService.addFunnelFromTemplateLibrary, action.payload);

    if (response.success) {
      yield put(addFunnelSuccess(response.data));
    } else {
      yield put(addFunnelFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    showErrorToaster("Network or server error");
    yield put(addFunnelFailed());
  }
}

export default function* funnelSaga() {
  yield all([funnelWatcher()]);
}
