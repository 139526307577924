import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOrEditDomain from "./AddOrEditDomain";
import useBoolean from "../../../hooks/useBoolean";
import IconButton from "../../../common/IconButton";
import DomainI from "../../../interface/domain.interface";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import { deleteDomain, showDomainCredential } from "../../../state/features/domain/domainSlice";
import { selectDomainState } from "../../../state/features/domain/domainSelector";

interface IProps {
  domainInfo: DomainI;
}

const DomainActions: React.FC<IProps> = ({ domainInfo }) => {
  const dispatch = useDispatch();
  const { isDeleting } = useSelector(selectDomainState);

  const {
    value: openEditDomainModal,
    setTrue: onOpenEditDomainModal,
    setFalse: onCloseEditDomainModal,
  } = useBoolean(false);

  const {
    value: openDeleteDomainModal,
    setTrue: onOpenDeleteDomainModal,
    setFalse: onCloseDeleteDomainModal,
  } = useBoolean(false);

  const onConfirmDelete = () => {
    dispatch(deleteDomain({ id: domainInfo.id }));
  };

  const handleViewCredential = () => {
    onOpenEditDomainModal();
    dispatch(showDomainCredential());
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1}>
        <CustomTooltip title={"View Domain Records"} arrow placement={"top"}>
          <IconButton variant={"outlined"} size={"small"} color={"secondary"} onClick={handleViewCredential}>
            <VisibilityIcon fontSize={"small"} />
          </IconButton>
        </CustomTooltip>
        <CustomTooltip title={"Delete"} arrow placement={"top"}>
          <IconButton variant={"outlined"} size={"small"} color={"error"} onClick={onOpenDeleteDomainModal}>
            <DeleteIcon fontSize={"small"} />
          </IconButton>
        </CustomTooltip>
      </Stack>

      <BasicModalWithHeader title={"Domain Records"} open={openEditDomainModal} onClose={onCloseEditDomainModal}>
        <AddOrEditDomain onClose={onCloseEditDomainModal} domainInfo={domainInfo} isEdit={true} />
      </BasicModalWithHeader>

      <DeleteWarningModal
        open={openDeleteDomainModal}
        onClose={onCloseDeleteDomainModal}
        onConfirm={onConfirmDelete}
        isLoading={isDeleting}
      />
    </>
  );
};

export default DomainActions;
