import React from "react";
import WebsiteListFilterHeader from "./WebsiteListFilterHeader";
import WebsiteListTable from "./WebsiteListTable";
import { ListsWrapper } from "./SitesListStyles";

const WebsiteLists: React.FC = () => {
  return (
    <ListsWrapper p={2}>
      <WebsiteListFilterHeader />
      <WebsiteListTable />
    </ListsWrapper>
  );
};

export default WebsiteLists;
