import { RootState } from "../../store";

export const selectTemplateState = (state: RootState) => state.template;
export const selectTemplateFilters = (state: RootState) => state.template.filters;
export const selectSelectedCategoryIds = (state: RootState) => state.template.filters.selectedCategoryId;
export const selectSelectedTypeIds = (state: RootState) => state.template.filters.selectedTypeIds;
export const selectSelectedTagIds = (state: RootState) => state.template.filters.selectedTagIds;
export const selectTemplateCategory = (state: RootState) => state.template.category;
export const selectTemplateType = (state: RootState) => state.template.type;
export const selectTemplateTag = (state: RootState) => state.template.tag;
