import { Add } from "@mui/icons-material";
import React, { useCallback, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";

import useBoolean from "../../../hooks/useBoolean";
import AdminSectionLibrary from "./AdminSectionLibrary";
import CoreButton from "../../../common/Button/CoreButton";
import CreateSectionModalAdmin from "./CreateSectionModalAdmin";
import AdminWebsiteSectionLeftSide from "./AdminWebsiteSectionLeftSide";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { WebsiteListBodyAdmin, WebsiteListWrapperAdmin } from "../../SitesList/SitesListStyles";

const Sections: React.FC = () => {
  const [isHideLeftSide, setIsHideLeftSide] = useState(false);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  const {
    value: isOpenCreateSectionModal,
    setTrue: onOpenCreateSectionModal,
    setFalse: onCloseCreateSectionModal,
  } = useBoolean(false);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;

    if (width > 1200) {
      setIsHideLeftSide(!isHideLeftSide);
    } else {
      setIsHideLeftSide(false);
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  }, [isHideLeftSide, isShowLeftSideTab]);

  return (
    <>
      <WebsiteListWrapperAdmin variant={"outlined"}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
          <Box sx={{ flex: 1 }}>
            <Typography variant={"h6"} color={"text.secondary"}>
              Sections
            </Typography>
            <Typography variant={"body2"} color={"text.secondary"}>
              All of your website Sections will be here.
            </Typography>
          </Box>

          <CoreButton
            color={"secondary"}
            size={"large"}
            variant='contained'
            startIcon={<Add />}
            onClick={onOpenCreateSectionModal}
          >
            New Section
          </CoreButton>
        </Stack>

        <Divider light />

        <WebsiteListBodyAdmin>
          <AdminWebsiteSectionLeftSide
            toggleLeftSide={toggleLeftSidePanel}
            isHideLeftSide={isHideLeftSide}
            isShowLeftSideTab={isShowLeftSideTab}
          />
          <Divider orientation='vertical' flexItem light />
          <AdminSectionLibrary />
        </WebsiteListBodyAdmin>
      </WebsiteListWrapperAdmin>

      <BasicModalWithHeader
        title='Create New Section Template'
        open={isOpenCreateSectionModal}
        onClose={onCloseCreateSectionModal}
        width={500}
      >
        <CreateSectionModalAdmin onClose={onCloseCreateSectionModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default Sections;
