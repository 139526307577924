import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import { FunnelTemplateI } from "../interface/funnel-template.interface";
import { FetchFunnelTemplateLibrariesPayloadI } from "../interface/funnel-template-library";

const funnelBuilderAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelBuilderAPI) throw Error("Invoice API URL not found in env!");

class FunnelTemplateService {
  fetchFunnelTemplate(params: FetchFunnelTemplateLibrariesPayloadI) {
    return httpRequest.get<WithPagination<FunnelTemplateI>>(funnelBuilderAPI + "funnel/template/list", params);
  }
}

const funnelTemplateService = new FunnelTemplateService();
export default funnelTemplateService;
