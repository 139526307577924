import React from "react";
import Divider from "@mui/material/Divider";
import { IconButton, Stack, Typography } from "@mui/material";
import TagsList from "./Tags/TagsList";
import TypesList from "./Types/TypesList";
import DraftAndTrash from "./DraftAndTrash";
import SectionLibraryTypeList from "./Category/SectionLibraryTypeList";
import AdminAllSitesButton from "./AdminAllSitesButton";
import ShowSideBarIcon from "../../../common/Icons/ShowSideBarIcon";
import HideSideBarIcon from "../../../common/Icons/HideSideBarIcon";
import { LeftSideBar, SidebarListWrapper, SideTopBarTab } from "../../SitesList/SitesListStyles";

interface Props {
  toggleLeftSide?: React.MouseEventHandler<HTMLButtonElement>;
  isHideLeftSide: boolean;
  isShowLeftSideTab: boolean;
}

const AdminWebsiteListLeftSide: React.FC<Props> = ({ toggleLeftSide, isHideLeftSide, isShowLeftSideTab }) => {
  return (
    <LeftSideBar className={isHideLeftSide ? "collapseLeftSide" : ""}>
      <Stack direction='row' alignItems='center' className={"iSidebarTitleBar"}>
        <Typography variant='body1' color='text.secondary' className={"iSidebarTitle"}>
          My Templates
        </Typography>
        <IconButton size={"large"} onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
          {isHideLeftSide || isShowLeftSideTab ? <ShowSideBarIcon /> : <HideSideBarIcon />}
        </IconButton>
      </Stack>
      <SidebarListWrapper
        px={1}
        className={`${isHideLeftSide ? "slw-hide-sidebar" : ""} ${
          isShowLeftSideTab ? "slw-show-sidebar-tab" : ""
        } admin-template-sidebar-list`}
      >
        <SideTopBarTab>
          <Typography variant='body1' color='text.secondary'>
            My Templates
          </Typography>
          <IconButton size='large' onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
            <HideSideBarIcon />
          </IconButton>
        </SideTopBarTab>
        <AdminAllSitesButton />
        <Stack spacing={1} my={1}>
          <SectionLibraryTypeList />
          <TypesList />
          <TagsList />
        </Stack>
        <Divider light />
        <DraftAndTrash />
      </SidebarListWrapper>
    </LeftSideBar>
  );
};

export default AdminWebsiteListLeftSide;
