import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { List, ListItemButton, ListItemIcon, styled, Typography } from "@mui/material";
import { selectTrashFolder } from "../../../state/features/adminTemplates/adminTemplateSlice";
import { selectAdminFilterState } from "../../../state/features/adminTemplates/adminTemplateSelector";

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: "4px",
  marginBottom: theme.spacing(0.5),
}));

const DraftAndTrash: React.FC = () => {
  const dispatch = useDispatch();
  const { inTrash } = useSelector(selectAdminFilterState);

  return (
    <List>
      <StyledListItemButton
        selected={inTrash}
        onClick={() => {
          dispatch(selectTrashFolder());
        }}
      >
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <DeleteIcon color={"action"} />
        </ListItemIcon>
        <Typography variant='buttonMedium'>Trash</Typography>
      </StyledListItemButton>
    </List>
  );
};

export default DraftAndTrash;
