import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import { FilterAltTwoTone, Search } from "@mui/icons-material";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { LightCoreButton } from "../../../common/Button/LightCoreButton";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { templateSearchTextFilter } from "../../../state/features/funnel-template-library/funnelTemplateLibrarySlice";
import { selectFunnelTemplateLibraryFilterState } from "../../../state/features/funnel-template-library/funnelTemplateLibrarySelector";

interface TemplateHeaderInterface {
  onClick?: () => void;
}

const TemplateLibraryHeader: React.FC<TemplateHeaderInterface> = ({ onClick }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { searchText } = useSelector(selectFunnelTemplateLibraryFilterState);
  const handleSearch = debounceHandler((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(templateSearchTextFilter(event.target.value));
  }, 500);

  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
      <Box width={200}>
        <LightCoreButton
          variant={"outlined"}
          size={"small"}
          color={"secondary"}
          endIcon={<FilterAltTwoTone />}
          onClick={onClick}
        >
          Filters
        </LightCoreButton>
      </Box>
      <Typography variant={"subtitle1"} fontWeight={500} sx={{ mr: "auto" }}>
        Funnels
      </Typography>
      <CoreTextField
        type={"search"}
        size={"small"}
        color={"secondary"}
        placeholder={"Search Template"}
        sx={{ background: theme.palette.background.paper, width: 300 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
        }}
        defaultValue={searchText}
        onChange={handleSearch}
      />
    </Stack>
  );
};

export default TemplateLibraryHeader;
