import React from "react";
import FunnelListFilterHeader from "./FunnelListFilterHeader";
import FunnelListTable from "./FunnelListTable";
import { ListsWrapper } from "./FunnelListStyles";

const FunnelLists: React.FC = () => {
  return (
    <ListsWrapper p={2}>
      <FunnelListFilterHeader />
      <FunnelListTable />
    </ListsWrapper>
  );
};

export default FunnelLists;
