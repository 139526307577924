import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import Divider from "@mui/material/Divider";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../state/store";
import AdminWebPageHeading from "./AdminWebPageHeading";
import AdminWebsitePageTabs from "./AdminWebsitePageTabs";
import AdminWebsiteAllPages from "./AdminWebsiteAllPages";
import AdminWebsitePageSettings from "./AdminWebsitePageSettings";
import AdminPrivateLayout from "../../Layouts/AdminPrivateLayout";
import { fetchTag } from "../../../state/features/tag/tagSlice";
import { fetchType } from "../../../state/features/type/typeSlice";
import { fetchCategory } from "../../../state/features/category/categorySlice";
import { getAdminWebsiteSetting } from "../../../state/features/adminWebsiteSetting/adminWebsiteSettingSlice";
import { fetchTemplatePages } from "../../../state/features/adminTemplatePage/adminTemplatePageSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const AdminWebPages: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { websiteId } = useParams<{ websiteId: string }>();

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (websiteId) {
      dispatch(fetchType());
      dispatch(fetchTag());
      dispatch(fetchCategory());
      dispatch(getAdminWebsiteSetting(websiteId));
      dispatch(fetchTemplatePages({ websiteId: websiteId, currentPage: 1, limit: 100 }));
    }
  }, [dispatch, websiteId]);

  return (
    <AdminPrivateLayout>
      <Box sx={{ background: "#FFFFFF" }}>
        <AdminWebPageHeading />
        <AdminWebsitePageTabs value={value} setValue={setValue} />
        <Divider light />
        <TabPanel index={0} value={value}>
          <AdminWebsiteAllPages />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <AdminWebsitePageSettings />
        </TabPanel>
      </Box>
    </AdminPrivateLayout>
  );
};

export default AdminWebPages;
