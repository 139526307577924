import React from "react";
import { Box } from "@mui/material";
import NoDataFoundPage from "../../../../common/GlobalEmptyPage/NoDataFoundPage";

const SelectTemplateModal = () => {
  return (
    <Box sx={{ minWidth: "98vw", height: "94vh" }}>
      <NoDataFoundPage title={"Template is not ready yet!!"} />
    </Box>
  );
};

export default SelectTemplateModal;
