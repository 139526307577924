import React from "react";
import { Add } from "@mui/icons-material";
import AddOrEditTemplatePageInfo from "../AddOrEditTemplatePageInfo";
import useBoolean from "../../../hooks/useBoolean";
import CoreButton from "../../../common/Button/CoreButton";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";

const AdminAddNewPageAction = () => {
  const { value: openAddPageModal, setTrue: onOpenAddPageModal, setFalse: onCloseAddPageModal } = useBoolean(false);

  return (
    <>
      <CoreButton variant='contained' color='secondary' size={"large"} startIcon={<Add />} onClick={onOpenAddPageModal}>
        Add New Page
      </CoreButton>

      <BasicModalWithHeader
        open={openAddPageModal}
        onClose={onCloseAddPageModal}
        title={"New Template Page for Website"}
        width={500}
      >
        <AddOrEditTemplatePageInfo onClose={onCloseAddPageModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default AdminAddNewPageAction;
