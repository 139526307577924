import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import { websiteOrderByList } from "../../../helpers/constant/websiteConstant";
import { FunnelTemplateI, FetchFunnelTemplatesPayloadI } from "../../../interface/funnel-template.interface";

interface FunnelTemplateFilterI {
  categoryId?: string;
  searchText?: string;
  inTrash?: boolean;
  orderBy?: string;
  tagIds: string[];
  typeIds: string[];
  currentPage?: string;
  limit?: string;
}

const initialState = {
  data: [] as FunnelTemplateI[],
  currentPage: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isMoreFetching: false,
  createdFunnelId: null as null | string,

  isCreating: false,
  isCreated: false,

  filters: {
    inTrash: false,
    orderBy: websiteOrderByList[2].value,
    tagIds: [],
    typeIds: [],
  } as FunnelTemplateFilterI,
};

const funnelTemplateLibrarySlice = createSlice({
  name: "funnelTemplateLibrary",
  initialState,
  reducers: {
    fetchTemplateLibrary(state, action: PayloadAction<FetchFunnelTemplatesPayloadI>) {
      state.isLoading = true;
    },

    fetchTemplateLibrarySuccess(state, action: PayloadAction<WithPagination<FunnelTemplateI>>) {
      state.data = action.payload.content;
      state.currentPage = action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = +action.payload.count;
      state.isLoading = false;
      state.isMoreFetching = false;
    },

    resetTemplateLibraryState(state) {
      state.data = [];
      state.currentPage = 1;
      state.limit = 10;
      state.count = 0;
      state.isLoading = false;
      state.filters = {
        categoryId: "",
        searchText: "",
        inTrash: false,
        orderBy: websiteOrderByList[2].value,
        tagIds: [],
        typeIds: [],
      };
    },

    resetTemplateLibraryFilterState(state) {
      state.currentPage = 1;
      state.limit = 10;
      state.count = 0;
      state.isLoading = false;
      state.filters = {
        categoryId: "",
        searchText: "",
        inTrash: false,
        orderBy: websiteOrderByList[2].value,
        tagIds: [],
        typeIds: [],
      };
    },

    fetchMoreTemplateLibrary: (state, action: PayloadAction<FetchFunnelTemplatesPayloadI>) => {
      state.isMoreFetching = true;
    },

    toggleCategory(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        state.filters.categoryId = action.payload;
        state.filters.inTrash = false;
      } else {
        state.filters.categoryId = "";
      }
    },
    removeCategory(state) {
      state.filters.categoryId = "";
    },
    templateSearchTextFilter(state, action: PayloadAction<string>) {
      state.filters.searchText = action.payload;
    },
    toggleType(state, action: PayloadAction<string>) {
      const currentFormIndex = state.filters.typeIds.findIndex((typeId) => typeId === action.payload);
      if (currentFormIndex !== -1) {
        state.filters.typeIds.splice(currentFormIndex, 1);
      } else {
        state.filters.typeIds.push(action.payload);
        state.filters.inTrash = false;
      }
    },
    toggleTag(state, action: PayloadAction<string>) {
      const currentFormIndex = state.filters.tagIds.findIndex((tagId) => tagId === action.payload);
      if (currentFormIndex !== -1) {
        state.filters.tagIds.splice(currentFormIndex, 1);
      } else {
        state.filters.tagIds.push(action.payload);
        state.filters.inTrash = false;
      }
    },
  },
});

export const {
  fetchTemplateLibrary,
  fetchTemplateLibrarySuccess,

  resetTemplateLibraryState,
  resetTemplateLibraryFilterState,
  fetchMoreTemplateLibrary,

  removeCategory,
  templateSearchTextFilter,
  toggleCategory,
  toggleTag,
  toggleType,
} = funnelTemplateLibrarySlice.actions;

export default funnelTemplateLibrarySlice.reducer;
