import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Popper from "@mui/material/Popper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper } from "@mui/material";
import AddNewStepModalContent from "../AddNewStepModalContent";
import CoreButton from "../../../../common/Button/CoreButton";
import useBoolean from "../../../../hooks/useBoolean";
import LoadingButton from "../../../../common/Button/LoadingButton";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import { IFunnelDetailsParams } from "../../../../interface/funnelDetails.interface";
import { addStepPage } from "../../../../state/features/funnelStepPages/funnelStepPagesSlice";
import { selectSelectedFunnelStep } from "../../../../state/features/funnelStep/funnelStepSelector";
import { selectFunnelStepPagesState } from "../../../../state/features/funnelStepPages/funnelStepPagesSelector";

const AddVariantButtonGroup = () => {
  const dispatch = useDispatch();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const { id: stepId } = useSelector(selectSelectedFunnelStep);
  const { addPagePayload, isAdding } = useSelector(selectFunnelStepPagesState);

  const { value: open, setFalse: onClose, toggle: onToggle } = useBoolean(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const { value: openFunnelModal, setTrue: onOpenFunnelModal, setFalse: onCloseFunnelModal } = useBoolean(false);
  const { value: openWebsiteModal, setTrue: onOpenWebsiteModal, setFalse: onCloseWebsiteModal } = useBoolean(false);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    onClose();
  };

  const onPageCreateFromScratch = () => {
    dispatch(
      addStepPage({
        funnelId: Number(funnelId),
        stepId: stepId,
        pageType: "VARIANT",
        pageCreateType: "FROM_BLANK",
      })
    );
  };

  const onDuplicationFromOriginal = () => {
    dispatch(
      addStepPage({
        funnelId: Number(funnelId),
        stepId: stepId,
        pageType: "VARIANT",
        pageCreateType: "DUPLICATE_ORIGINAL_PAGE",
      })
    );
  };

  const isLoading = addPagePayload !== null && isAdding;

  return (
    <div>
      <ButtonGroup variant={"contained"} color={"secondary"} size={"small"} ref={anchorRef} sx={{ boxShadow: "none" }}>
        <LoadingButton size={"small"} color={"secondary"} isLoading={isLoading} onClick={onToggle}>
          Add Variant
        </LoadingButton>
        <CoreButton disabled={isLoading} onClick={onToggle}>
          <ArrowDropDownIcon />
        </CoreButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 9,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "right top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  <MenuItem sx={{ fontSize: 14 }} onClick={onPageCreateFromScratch}>
                    Design from scratch
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 14 }} onClick={onDuplicationFromOriginal}>
                    Duplicate Original Page
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 14 }} onClick={onOpenWebsiteModal}>
                    Select from existing funnels
                  </MenuItem>
                  <MenuItem sx={{ fontSize: 14 }} onClick={onOpenFunnelModal}>
                    Select from existing funnels
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <BasicModalWithHeader
        width={500}
        title={"Add Page From Existing Funnel"}
        open={openFunnelModal}
        onClose={onCloseFunnelModal}
      >
        <AddNewStepModalContent cloneType={"FUNNEL"} pageType={"VARIANT"} onClose={onCloseFunnelModal} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        width={500}
        title={"Add Page From Existing Website"}
        open={openWebsiteModal}
        onClose={onCloseWebsiteModal}
      >
        <AddNewStepModalContent cloneType={"WEBSITE"} pageType={"VARIANT"} onClose={onCloseWebsiteModal} />
      </BasicModalWithHeader>
    </div>
  );
};

export default AddVariantButtonGroup;
