import { call, put } from "typed-redux-saga";
import { takeEvery, all } from "redux-saga/effects";
import permissionService from "../../../services/permission.service";
import { authenticateUserFailed, authenticateUserSuccess } from "./permissionSlice";

function* permissionWatcher() {
  yield takeEvery("permission/authenticateUser", authenticateUserSaga);
}

function* authenticateUserSaga() {
  try {
    const response = yield* call(permissionService.authenticateUser);

    if (response.success) {
      yield put(authenticateUserSuccess(response.data));
    } else {
      yield put(authenticateUserFailed());
    }
  } catch (err) {
    yield put(authenticateUserFailed());
  }
}

export default function* permissionSaga() {
  yield all([permissionWatcher()]);
}
