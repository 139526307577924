import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Divider,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EmojiEvents } from "@mui/icons-material";
import LoadingButton from "../../../common/Button/LoadingButton";
import TableLoaderSkeleton from "../../../common/LoadingView/TableLoaderSkeleton";
import {
  useDeclareWinPageMutation,
  useGetSplitTestReportQuery,
  useGetSplitTestSettingsQuery,
} from "../../../state/features/funnelStepPages/funnelStepPagesApiSlice";
import { selectSelectedFunnelStep } from "../../../state/features/funnelStep/funnelStepSelector";
import {
  PageType,
  SplitTestPageReportInterface,
} from "../../../state/features/funnelStepPages/funnelStepPagesInterfaces";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";

const PageReportRow: React.FC<{
  pageInfo?: SplitTestPageReportInterface;
  onDeclareWin: () => void;
  isUpdating: boolean;
}> = ({ pageInfo, onDeclareWin, isUpdating }) => {
  const {
    pageType = "-",
    totalPageView = 0,
    totalUniquePageView = 0,
    totalOptIn = 0,
    totalUniqueOptIn = 0,
  } = pageInfo || {};
  const [clickPageType, setClickPageType] = useState("");

  const handleClick = () => {
    onDeclareWin();
    setClickPageType(pageInfo?.pageType ?? "");
  };
  return (
    <TableRow>
      <TableCell>{pageType}</TableCell>
      <TableCell align={"center"}>{totalPageView}</TableCell>
      <TableCell align={"center"}>{totalUniquePageView}</TableCell>
      {/*<TableCell align={"center"}>{totalOptIn}</TableCell>*/}
      {/*<TableCell align={"center"}>{totalUniqueOptIn}</TableCell>*/}

      <TableCell align={"center"}>
        <LoadingButton
          isLoading={!!(clickPageType && isUpdating)}
          size={"small"}
          color={"secondary"}
          variant={"outlined"}
          onClick={handleClick}
          startIcon={<EmojiEvents />}
        >
          Declare Winner
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};

const SplitTestHistory = () => {
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const { id: stepId } = useSelector(selectSelectedFunnelStep);

  const [declareWinPage, { isLoading: isUpdating }] = useDeclareWinPageMutation();

  const { data: splitTestSettingsState } = useGetSplitTestSettingsQuery(
    { stepId: stepId },
    { refetchOnMountOrArgChange: true }
  );
  const { data: splitTestSettings } = splitTestSettingsState || {};

  const { data: splitTestPagesState, isLoading } = useGetSplitTestReportQuery(
    { stepId: stepId },
    { refetchOnMountOrArgChange: true, skip: !splitTestSettings?.start }
  );
  const { data: splitTestPages = [] } = splitTestPagesState || {};

  const onDeclareWin = (pageType?: PageType, wonPageId?: number, lostPageId?: number) => {
    if (!pageType || !wonPageId || !lostPageId) return;

    const payload = {
      funnelId: Number(funnelId),
      stepId: stepId,
      wonPageId: wonPageId,
      lostPageId: lostPageId,
    };

    declareWinPage(payload);
  };

  let content = <></>;
  if (isLoading) {
    content = (
      <Stack spacing={2} p={2}>
        <Skeleton variant={"text"} height={30} width={300} />
        <TableLoaderSkeleton columns={4} numberOfRows={3} />
      </Stack>
    );
  } else if (splitTestPages?.length > 0 && splitTestSettings?.start) {
    const original = splitTestPages?.find((pageInfo) => {
      if (pageInfo.pageType === "ORIGINAL") return true;
    });

    const variant = splitTestPages?.find((pageInfo) => {
      if (pageInfo.pageType === "VARIANT") return true;
    });

    content = (
      <>
        <Divider light />
        <Stack spacing={2} p={2}>
          <Typography variant={"subtitle1"}>Split Test History</Typography>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Page</TableCell>
                  <TableCell align={"center"}>all page views</TableCell>
                  <TableCell align={"center"}>unique page views</TableCell>
                  {/*<TableCell align={"center"}>all opt ins</TableCell>*/}
                  {/*<TableCell align={"center"}>unique opt ins</TableCell>*/}
                  <TableCell align={"center"}>actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <PageReportRow
                  pageInfo={original}
                  isUpdating={isUpdating}
                  onDeclareWin={() => onDeclareWin(original?.pageType, original?.id, variant?.id)}
                />
                <PageReportRow
                  pageInfo={variant}
                  isUpdating={isUpdating}
                  onDeclareWin={() => onDeclareWin(variant?.pageType, variant?.id, original?.id)}
                />
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </>
    );
  }
  return content;
};

export default SplitTestHistory;
