import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import adminPageService from "../../../services/adminPage";
import {
  FetchAdminPagesParamsI,
  CreateAdminPagePayloadI,
  DeleteAdminPagePayloadI,
  UpdateAdminPagePayloadI,
  CloneAdminPagePayloadI,
} from "../../../interface/adminPage.interface";
import {
  cloneTemplatePageSuccess,
  createTemplatePageSuccess,
  deleteTemplatePageFailed,
  deleteTemplatePageSuccess,
  fetchTemplatePagesFailed,
  fetchTemplatePagesSuccess,
  resetCloneTemplatePage,
  resetCreateTemplatePage,
  resetUpdateTemplatePage,
  updateTemplatePageSuccess,
} from "./adminTemplatePageSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* websiteSettingWatcher() {
  yield takeEvery("adminTemplatePage/fetchTemplatePages", fetchTemplatePagesSaga);
  yield takeEvery("adminTemplatePage/createTemplatePage", createTemplatePageSaga);
  yield takeEvery("adminTemplatePage/updateTemplatePage", updateTemplatePageSaga);
  yield takeEvery("adminTemplatePage/deleteTemplatePage", deleteTemplatePageSaga);
  yield takeEvery("adminTemplatePage/cloneTemplatePage", cloneTemplatePageSaga);
}

function* fetchTemplatePagesSaga(action: PayloadAction<FetchAdminPagesParamsI>) {
  try {
    const response = yield* call(adminPageService.fetchTemplatePages, action.payload);

    if (response.success) {
      yield put(fetchTemplatePagesSuccess(response.data));
    } else {
      yield put(fetchTemplatePagesFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(fetchTemplatePagesFailed());
    console.error(err);
  }
}

function* createTemplatePageSaga(action: PayloadAction<CreateAdminPagePayloadI>) {
  try {
    const response = yield* call(adminPageService.createTemplatePage, action.payload);

    if (response.success) {
      yield put(createTemplatePageSuccess(response.data));
    } else {
      yield put(resetCreateTemplatePage());
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.error(err);
  }
}

function* updateTemplatePageSaga(action: PayloadAction<UpdateAdminPagePayloadI>) {
  try {
    const response = yield* call(adminPageService.updateTemplatePage, action.payload);

    if (response.success) {
      yield put(updateTemplatePageSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetUpdateTemplatePage());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetUpdateTemplatePage());
    console.error(err);
  }
}

function* deleteTemplatePageSaga(action: PayloadAction<DeleteAdminPagePayloadI>) {
  try {
    const response = yield* call(adminPageService.deleteTemplatePage, action.payload.pageId);

    if (response.success) {
      yield put(deleteTemplatePageSuccess({ pageId: action.payload.pageId }));
    } else {
      yield put(deleteTemplatePageFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteTemplatePageFailed());
    console.error(err);
  }
}

function* cloneTemplatePageSaga(action: PayloadAction<CloneAdminPagePayloadI>) {
  try {
    const response = yield* call(adminPageService.cloneTemplatePage, action.payload);

    if (response.success) {
      yield put(cloneTemplatePageSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(resetCloneTemplatePage());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(deleteTemplatePageFailed());
    console.error(err);
  }
}

export default function* adminTemplatePageSaga() {
  yield all([websiteSettingWatcher()]);
}
