import React, { useEffect } from "react";
import { AppDispatch } from "../../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import CoreButton from "../../../../common/Button/CoreButton";
import CategoryI from "../../../../interface/category.interface";
import LoadingButton from "../../../../common/Button/LoadingButton";
import CoreTextField from "../../../../common/TextField/CoreTextField";
import { registerPatternCategory } from "../../../../helpers/constant/registerPattern";
import {
  createCategory,
  resetCreateSuccess,
  resetUpdateSuccess,
  updateCategory,
} from "../../../../state/features/category/categorySlice";
import { selectCategoryState } from "../../../../state/features/category/categorySelector";

interface Props {
  onClose: () => void;
  isEditMode?: boolean;
  category?: CategoryI;
}

const AddOrEditCategoryName: React.FC<Props> = ({ onClose, isEditMode, category }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isCreating, isUpdating, isCreatedSuccessfully, isUpdatedSuccessfully } = useSelector(selectCategoryState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CategoryI>();

  const onSubmit: SubmitHandler<CategoryI> = (data) => {
    if (isEditMode) {
      dispatch(updateCategory(data));
    } else {
      dispatch(createCategory(data));
    }
  };

  useEffect(() => {
    if (category) {
      setValue("id", category.id);
      setValue("name", category.name);
      setValue("description", category.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    if (isCreatedSuccessfully || isUpdatedSuccessfully) {
      dispatch(resetCreateSuccess());
      dispatch(resetUpdateSuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedSuccessfully, isUpdatedSuccessfully]);

  const isLoading = isCreating || isUpdating;
  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack gap={2}>
        <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
          <CoreTextField
            type='text'
            size='small'
            color='secondary'
            label={"Enter a category name here"}
            placeholder='Enter Category name here'
            error={!!(errors.name && errors.name.message)}
            helperText={errors.name && errors.name.message}
            {...register("name", registerPatternCategory.name)}
            required
          />
        </FormControl>

        <FormControl fullWidth error={!!(errors.description && errors.description.message)} required>
          <CoreTextField
            multiline
            rows={7}
            type='text'
            size='small'
            color='secondary'
            label={"Enter a category description here"}
            placeholder='Enter Category description here'
            error={!!(errors.description && errors.description.message)}
            helperText={errors.description && errors.description.message}
            {...register("description", registerPatternCategory.description)}
            required
          />
        </FormControl>
      </Stack>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isLoading}
          loadingText={isEditMode ? "Changing..." : "Creating..."}
          type='submit'
          size='large'
          color='success'
        >
          {isEditMode ? "Change" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditCategoryName;
