import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../state/store";
import { Search } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import { selectFunnelFilters } from "../../../state/features/funnel/funnelSelector";
import { addFunnelSearchTextFilter } from "../../../state/features/funnel/funnelSlice";

const FunnelListFilterSearch: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { searchText = "" } = useSelector(selectFunnelFilters);
    const [search, setSearch] = useState(searchText);

    useEffect(() => {
        if (searchText === "") {
            setSearch("");
        }
    }, [searchText]);

    const doSearch = (value: string) => {
        dispatch(addFunnelSearchTextFilter(value));
    };

    // debounce implement for search input change
    const handleSearch = React.useMemo(() => {
        return debounceHandler(doSearch, 500);
    }, []);

    return (
        <CoreTextField
            fullWidth
            size={"small"}
            type={"search"}
            color={"secondary"}
            placeholder={"Search funnel by name"}
            InputProps={{
                startAdornment: (
                    <InputAdornment sx={{ mr: 0 }} position='start'>
                        <Search />
                    </InputAdornment>
                ),
            }}
            value={search}
            onChange={(e) => {
                setSearch(e.target.value);
                return handleSearch(e.target.value);
            }}
        />
    );
};

export default FunnelListFilterSearch;
