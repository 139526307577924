import httpRequest from "./api/httpRequest";
import { IFunnelStepReport, IGetFunnelReportPayload } from "../state/features/funnelReports/funnelReports.interface";

const funnelAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelAPI) throw Error("Funnel API URL not found in env!");

class Service {
  getFunnelReports(params: IGetFunnelReportPayload) {
    return httpRequest.get<IFunnelStepReport[]>(funnelAPI + "funnel/step/report-stats/" + params.funnelId);
  }
}

const FunnelReportsService = new Service();
export default FunnelReportsService;
