export const SECTION_TYPES = [
  { id: "header", name: "Header" },
  { id: "form", name: "Form" },
  { id: "footer", name: "Footer" },
  { id: "content", name: "Content" },
  { id: "section", name: "Section" },
  { id: "template", name: "Template" },
  { id: "layout", name: "Layout" },
  { id: "block", name: "Block" },
  { id: "widget", name: "Widget" },
  { id: "element", name: "Element" },
];