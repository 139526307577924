import { IWebsiteDomainInfo, IWebsitePageInfo } from "../../state/features/website/website.interface";
import { goToPreviewPage } from "./goToPreviewPage";

export const goToPreviewWebsite = (
  websiteId?: number,
  defaultPageInfo?: IWebsitePageInfo | null,
  domainInfo?: IWebsiteDomainInfo | null,
  subDomain?: string | null
) => {
  if (!websiteId) {
    return;
  }

  if (domainInfo) {
    window.open("https://" + domainInfo.domain, "_blank");
    return;
  }

  if (subDomain) {
    window.open("https://" + subDomain, "_blank");
    return;
  }

  if (defaultPageInfo) {
    goToPreviewPage(defaultPageInfo.pageId.toString(), websiteId.toString());
  }
};
