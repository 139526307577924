import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../state/store";
import { IconButton, Popover, Stack } from "@mui/material";
import { CreateNewFolder, Delete, DeleteForever, RestoreFromTrash } from "@mui/icons-material";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchivedIcon from "@mui/icons-material/Unarchive";
import AddToFolderWebsite from "../AddToFolderWebsite";
import useBoolean from "../../../hooks/useBoolean";
import CoreButton from "../../../common/Button/CoreButton";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import {
  deleteWebsite,
  toggleArchiveStatus,
  deleteWebsiteForever,
  resetDeleteWebsiteForeverSuccess,
} from "../../../state/features/website/websiteSlice";
import { selectedWebsitesFolderIds, selectWebsiteState } from "../../../state/features/website/websiteSelector";

const WebsiteListBulkActions = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { selectedWebsiteIds, isDeleting, isDeleteSuccess, filters } = useSelector(selectWebsiteState);
  const { inTrash, isArchived } = filters;
  const selectedWebsiteFolderIds = useSelector(selectedWebsitesFolderIds);
  const { value: openModal, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    if (isDeleteSuccess) {
      onCloseModal();
      dispatch(resetDeleteWebsiteForeverSuccess());
    }
  }, []);

  // handle delete move in trash
  const deleteWebsiteHandler = () => {
    dispatch(deleteWebsite({ websiteIds: selectedWebsiteIds, inTrash: true }));
  };

  // handle restore from in trash
  const handleRestoreWebsite = () => {
    dispatch(deleteWebsite({ websiteIds: selectedWebsiteIds, inTrash: false }));
  };

  const onConfirmDeleteForever = () => {
    dispatch(deleteWebsiteForever({ websiteIds: selectedWebsiteIds }));
  };

  // handle restore from archived
  const toggleArchiveWebsite = () => {
    dispatch(
      toggleArchiveStatus({
        websiteIds: selectedWebsiteIds,
        isArchived: !isArchived,
      })
    );
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // decide what to render
  let element: JSX.Element | null = null;
  if (selectedWebsiteIds.length > 0 && inTrash) {
    element = (
      <>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <CoreButton
            variant={"contained"}
            color={"inherit"}
            startIcon={<RestoreFromTrash />}
            onClick={handleRestoreWebsite}
            sx={{ maxWidth: "100%" }}
          >
            Restore
          </CoreButton>
          <CoreButton
            variant={"contained"}
            color={"error"}
            startIcon={<DeleteForever />}
            onClick={onOpenModal}
            sx={{ minWidth: "120px", maxWidth: "100%" }}
          >
            Delete Forever
          </CoreButton>
        </Stack>

        <DeleteWarningModal
          title={"Delete Forever!!"}
          warningSubtitle={"Are you sure, you want to delete this websites?"}
          warningContent={"After delete, this operation cannot be undone."}
          buttonText={"Yes, Delete Forever"}
          cancelButtonText={"Cancel"}
          open={openModal}
          onClose={onCloseModal}
          onConfirm={onConfirmDeleteForever}
          isLoading={isDeleting}
        />
      </>
    );
  } else if (selectedWebsiteIds.length > 0) {
    element = (
      <>
        <Stack direction='row' spacing={1} alignItems='center'>
          <CoreButton variant='outlined' color='secondary' startIcon={<CreateNewFolder />} onClick={handleClick}>
            Add to folder
          </CoreButton>

          <Stack direction='row' spacing={0.25} alignItems='center'>
            <CustomTooltip title={!isArchived ? "Archive" : "Unarchive"} arrow placement='top'>
              <IconButton onClick={toggleArchiveWebsite}>
                {!isArchived ? <ArchiveIcon color='action' /> : <UnarchivedIcon color='action' />}
              </IconButton>
            </CustomTooltip>

            <CustomTooltip title='Delete' arrow placement='top'>
              <IconButton onClick={deleteWebsiteHandler}>
                <Delete color='action' />
              </IconButton>
            </CustomTooltip>
          </Stack>
        </Stack>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <AddToFolderWebsite
            websiteIds={selectedWebsiteIds}
            folderIds={selectedWebsiteFolderIds}
            onClose={handleClose}
          />
        </Popover>
      </>
    );
  }

  return element;
};

export default WebsiteListBulkActions;
