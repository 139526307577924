import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Divider, Paper, Stack } from "@mui/material";

import BuilderTopBar from "./BuilderTopBar";
import { AppDispatch } from "../../../state/store";
import BuilderMainContent from "./BuilderMainContent";
import FunnelSteps from "./templateFunnelSteps/FunnelSteps";
import AdminFunnelSettingsPage from "../AdminFunnelSettingsPage";
import { FUNNEL_PAGE_ID } from "../../../helpers/constant/coreConstants";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import {
  fetchFunnelTemplateSteps,
  resetFetchFunnelTemplateSteps,
} from "../../../state/features/funnelTemplateStep/funnelTemplateStepSlice";
import { fetchTag } from "../../../state/features/funnel-tag/funnelTagSlice";
import { fetchType } from "../../../state/features/funnel-type/funnelTypeSlice";
import { fetchCategory } from "../../../state/features/funnel-category/funnelCategorySlice";
import { getFunnelTemplateSetting } from "../../../state/features/funnelTemplateSetting/funnelTemplateSettingSlice";

const AdminFunnelBuilderIndex: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const [selectedScreen, setSelectedScreen] = useState(FUNNEL_PAGE_ID.FUNNEL);

  useEffect(() => {
    dispatch(getFunnelTemplateSetting(funnelId));
    dispatch(fetchFunnelTemplateSteps(funnelId));
    dispatch(fetchCategory());
    dispatch(fetchType());
    dispatch(fetchTag());

    return () => {
      dispatch(resetFetchFunnelTemplateSteps());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBackToFunnel = () => {
    setSelectedScreen(FUNNEL_PAGE_ID.FUNNEL);
  };

  return (
    <Box>
      {selectedScreen === FUNNEL_PAGE_ID.FUNNEL && (
        <>
          <BuilderTopBar setSelectedScreen={setSelectedScreen} />
          <Divider light />
          <Paper elevation={0} sx={{ height: "calc(100vh - 51px)", background: "transparent" }}>
            <Stack direction={"row"} sx={{ height: "100%" }}>
              <FunnelSteps />
              <BuilderMainContent />
            </Stack>
          </Paper>
        </>
      )}
      {selectedScreen === FUNNEL_PAGE_ID.SETTINGS && <AdminFunnelSettingsPage onBackToFunnel={onBackToFunnel} />}
    </Box>
  );
};

export default AdminFunnelBuilderIndex;
