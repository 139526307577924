import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, ListItemIcon, ListItemText } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CreateWebsiteModal from "./TopBar/CreateWebsiteModal";
import AddOrEditFolderName from "./TopBar/AddOrEditFolderName";
import TemplateLibrary from "./templateLibrary/TemplateLibrary";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../common/PopoverMenu/CustomPopoverMenu";
import DeleteWarningModal from "../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../common/Modal/BasicModalWithHeader";
import { ChangeColorText, ColorItemBox, SideBarListItem, StyledListItemButton } from "./SitesListStyles";
import { FOLDER_COLORS, FOLDER_DEFAULT_COLOR } from "../../helpers/constant/folderConstant";
import useBoolean from "../../hooks/useBoolean";
import { FolderInterface } from "../../state/features/folder/folder.Interface";
import { selectFolderState } from "../../state/features/folder/folderSelector";
import { deleteFolder, resetDeleteFolderSuccess, updateFolder } from "../../state/features/folder/folderSlice";
import { addNewWebsiteInsideFolder, selectFolderId } from "../../state/features/website/websiteSlice";

interface IProps {
  folder: FolderInterface;
  selected: boolean;
}

const FolderListItem: React.FC<IProps> = ({ folder, selected }) => {
  const dispatch = useDispatch();

  const { id, name, color } = folder;
  const { value: openEditModal, setTrue: onOpenEditModal, setFalse: onCloseEditModal } = useBoolean(false);
  const { value: openWebsiteModal, setTrue: onOpenWebsiteModal, setFalse: onCloseWebsiteModal } = useBoolean(false);
  const { value: openTemplateModal, setTrue: onOpenTemplateModal, setFalse: onCloseTemplateModal } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const { isDeleting, isDeleteSuccess } = useSelector(selectFolderState);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(resetDeleteFolderSuccess());
      onCloseDeleteModal();
    }
  }, [isDeleteSuccess]);

  const changeFolderColorHandler = (color: string) => {
    dispatch(updateFolder({ ...folder, color }));
  };

  const deleteFolderHandler = () => {
    dispatch(deleteFolder({ id: id as number }));
  };

  const selectFolderHandler = () => {
    dispatch(selectFolderId(id as number));
  };

  const handleOpenAddNewWebsiteModal = () => {
    onOpenWebsiteModal();
    dispatch(addNewWebsiteInsideFolder({ status: true, folderId: id as number }));
  };

  return (
    <>
      <SideBarListItem disablePadding sx={{ pr: 2 }} className={selected || isActive ? "active" : ""}>
        <StyledListItemButton onClick={selectFolderHandler}>
          <ListItemIcon>
            <FolderIcon fontSize={"small"} sx={{ color: color || FOLDER_DEFAULT_COLOR }} />
          </ListItemIcon>
          <ListItemText primary={name} className='text-truncate' sx={{ fontWeight: 300 }} />
        </StyledListItemButton>

        <CustomPopoverMenu callbackOnClose={() => setIsActive(false)}>
          <CustomPopoverMenuButton>
            <IconButton size={"small"} className={"wb-sidebar-more-icon"} onClick={() => setIsActive(true)}>
              <MoreVertIcon color={"action"} fontSize={"small"} />
            </IconButton>
          </CustomPopoverMenuButton>

          <CustomPopoverMenuItem
            title='Add New Website'
            icon={<AddIcon color={"action"} />}
            onClickHide={true}
            onClick={handleOpenAddNewWebsiteModal}
          />
          <CustomPopoverMenuItem
            title='Change Name'
            icon={<EditIcon color={"action"} fontSize={"small"} />}
            onClick={onOpenEditModal}
            onClickHide={true}
          />
          <CustomPopoverMenuItem
            title='Delete Folder'
            icon={<DeleteIcon color={"action"} fontSize={"small"} />}
            onClick={onOpenDeleteModal}
            onClickHide={true}
          />

          <ChangeColorText variant='body1' color='text.secondary'>
            <ColorLensIcon color={"action"} fontSize={"small"} /> Change Color
          </ChangeColorText>

          <ColorItemBox>
            {FOLDER_COLORS.map((color, i) => (
              <div
                key={i}
                className='ibColorItem'
                style={{ backgroundColor: color }}
                onClick={() => changeFolderColorHandler(color)}
              ></div>
            ))}
          </ColorItemBox>
        </CustomPopoverMenu>
      </SideBarListItem>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={deleteFolderHandler}
      />
      <BasicModalWithHeader
        title={"Change Folder Name"}
        icon={<EditIcon />}
        open={openEditModal}
        onClose={onCloseEditModal}
      >
        <AddOrEditFolderName onClose={onCloseEditModal} folder={folder} isEditMode={true} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        title='Create a New Website'
        open={openWebsiteModal}
        onClose={onCloseWebsiteModal}
        width={850}
      >
        <CreateWebsiteModal onClose={onCloseWebsiteModal} onOpenTemplateModal={onOpenTemplateModal} />
      </BasicModalWithHeader>

      <BasicModalWithHeader
        width={"98%"}
        title={"Template Library"}
        disableOutSideClick={true}
        open={openTemplateModal}
        onClose={onCloseTemplateModal}
      >
        <TemplateLibrary />
      </BasicModalWithHeader>
    </>
  );
};

export default React.memo(FolderListItem);
