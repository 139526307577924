import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import { Box, InputAdornment } from "@mui/material";
import { AppDispatch } from "../../../state/store";
import { selectAdminTemplateState } from "../../../state/features/adminTemplates/adminTemplateSelector";
import useBoolean from "../../../hooks/useBoolean";
import {
  deleteTemplates,
  resetDeleteTemplates,
  templateSearchTextFilter,
  trashTemplates,
} from "../../../state/features/adminTemplates/adminTemplateSlice";
import { HeaderBoxWrapper } from "../../SitesList/SitesListStyles";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { debounceHandler } from "../../../helpers/utils/debounceHandler";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";

const AdminSectionListFilterHeader: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { selectedTemplateIds, isDeleting, isDeleteSuccess, filters } = useSelector(selectAdminTemplateState);
  const { inTrash } = filters;

  const { setFalse: closeDeleteModal, setTrue: openDeleteModal, value: isDeleteModalOpen } = useBoolean();

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(resetDeleteTemplates());
      closeDeleteModal();
    }
  }, [isDeleteSuccess]);

  const trashWebsiteHandler = () => {
    dispatch(trashTemplates({ websiteIds: selectedTemplateIds, inTrash: true }));
  };

  const restoreWebsiteHandler = () => {
    dispatch(trashTemplates({ websiteIds: selectedTemplateIds, inTrash: false }));
  };

  const deleteWebsiteHandler = () => {
    dispatch(deleteTemplates({ websiteIds: selectedTemplateIds }));
  };

  return (
    <HeaderBoxWrapper>
      <>
        <Box sx={{ flex: 1 }}>
          <CoreTextField
            fullWidth
            size={"small"}
            type={"search"}
            color={"secondary"}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0 }} position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder={"Search Section by name"}
            onChange={debounceHandler((event) => {
              dispatch(templateSearchTextFilter(event.target.value));
            }, 500)}
          />
        </Box>
      </>

      <DeleteWarningModal
        title={"Delete Forever!!"}
        isLoading={isDeleting}
        open={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={deleteWebsiteHandler}
      />
    </HeaderBoxWrapper>
  );
};

export default AdminSectionListFilterHeader;
