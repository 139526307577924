import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFunnelStepReport, IGetFunnelReportPayload } from "./funnelReports.interface";

const initialState = {
  data: [] as IFunnelStepReport[],
  isLoading: false,
  isError: false,
  error: "",

  filters: {
    searchText: "",
  },
};

const funnelReportsSlice = createSlice({
  name: "funnelReports",
  initialState,
  reducers: {
    getFunnelReports(state, action: PayloadAction<IGetFunnelReportPayload>) {
      state.isLoading = true;
    },

    getFunnelReportsSuccess(state, action: PayloadAction<IFunnelStepReport[]>) {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },

    getFunnelReportsFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    addFunnelReportsSearchText(state, action: PayloadAction<string>) {
      state.filters.searchText = action.payload;
    },
  },
});

export const { getFunnelReports, getFunnelReportsFailed, getFunnelReportsSuccess, addFunnelReportsSearchText } =
  funnelReportsSlice.actions;

export default funnelReportsSlice.reducer;
