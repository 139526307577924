import { ListItemText, Menu, styled, MenuProps, PopoverOrigin } from "@mui/material";

interface StyledMenuProps extends MenuProps {
  twoColumns?: boolean;
}

export const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop: string) => prop !== "twoColumns",
})<StyledMenuProps>(({ theme, twoColumns }) => ({
  maxWidth: "100%",
  "& .MuiMenu-list": {
    display: twoColumns ? "grid" : "block",
    gridTemplateColumns: "1fr 50%",
    "& .MuiMenuItem-root": {
      padding: theme.spacing(1, 2),
      "&:hover": {
        backgroundColor: theme.palette.other.secondaryHover,
      },
    },
  },
}));

export const CustomListItemText = styled(ListItemText)(() => ({
  paddingLeft: "0 !important",
}));

interface IPositions {
  left: {
    anchor: PopoverOrigin;
    transform: PopoverOrigin;
  };
  right: {
    anchor: PopoverOrigin;
    transform: PopoverOrigin;
  };
  bottom: {
    anchor: PopoverOrigin;
    transform: PopoverOrigin;
  };
}

export const positions: IPositions = {
  left: {
    anchor: {
      vertical: "bottom",
      horizontal: "left",
    },
    transform: {
      vertical: "top",
      horizontal: "right",
    },
  },
  right: {
    anchor: {
      vertical: "bottom",
      horizontal: "right",
    },
    transform: {
      vertical: "top",
      horizontal: "left",
    },
  },
  bottom: {
    anchor: {
      vertical: "bottom",
      horizontal: "center",
    },
    transform: {
      vertical: "top",
      horizontal: "center",
    },
  },
};
