import React from "react";
import { Box, Divider, Grow, IconButton, Modal, ModalProps, Paper, Stack, styled, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

const PaperStyle = styled(Paper)(({ theme }) => ({
  width: "98%",
  backgroundColor: theme.palette.background.paper,
  border: 0,
  boxShadow: "0",
  borderRadius: "4px",
  paddingBottom: "4px",
  outline: 'none',
  pointerEvents:'all'
}));

interface Props extends ModalProps {
  title: string;
  subTitle?: string;
  icon?: JSX.Element;
  width?: number | string;
  disableOutSideClick?: boolean;
  childrenWrpClass?: string;
  clickPosition?: { x: number; y: number };
  onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const BasicModalWithHeader: React.FC<Props> = ({
  title = "",
  subTitle = "",
  icon,
  open,
  onClose,
  width = 700,
  disableOutSideClick = false,
  children,
  childrenWrpClass = "",
  ...rest
}) => {
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={!disableOutSideClick ? onClose : () => false}
        {...rest}
      >
        <Grow in={open}>
          <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100%", pointerEvents: "none" }}>
        <PaperStyle sx={{ maxWidth: width }}>
          <Stack direction={"row"} justifyContent='space-between' alignItems='center' sx={{ padding: "16px 24px" }}>
            <Stack direction='column' justifyContent={"center"} spacing={1}>
              <Typography
                variant='subtitle1'
                color='text.primary'
                sx={{ fontWeight: 500, display: "inline-flex", gap: "8px" }}
              >
                {icon && icon} {title}
              </Typography>
              {subTitle && (
                <Typography variant='body2' color='text.secondary'>
                  {subTitle}
                </Typography>
              )}
            </Stack>

            <IconButton color={"error"} onClick={onClose}>
              <Close fontSize={"medium"} />
            </IconButton>
          </Stack>
          <Divider />

          <Box sx={{ maxHeight: "80vh", overflowY: "auto" }} className={childrenWrpClass}>
            {children}
          </Box>
        </PaperStyle>
          </Stack>
        </Grow>
      </Modal>
    </React.Fragment>
  );
};

export default BasicModalWithHeader;
