const generateFunnelPreviewUrl = (
  subDomain: string,
  path: string,
  domainInfo?: { id: number | string; domain: string }
) => {
  if (domainInfo) {
    let addSlash = path.startsWith("/") ? "" : "/";
    if (!path) addSlash = "";
    return `https://${domainInfo.domain + addSlash + path}`;
  }

  if (subDomain) {
    return `https://${subDomain + path}`;
  }
};

export default generateFunnelPreviewUrl;
