interface PageToken {
  pageId: string;
  funnelId: string;
  stepId: string;
}

export const extractFunnelPageToken = (pageToken: string): PageToken | null => {
  try {
    let jsonObject = JSON.parse(atob(pageToken)) as PageToken;
    return jsonObject.pageId && jsonObject.funnelId ? jsonObject : null;
  } catch {
    return null;
  }
};
