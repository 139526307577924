import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Paper, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";

export const ItemAccordionHeading = styled(AccordionSummary)(({ theme }) => ({
  background: theme.palette.other.outlinedBorder,
  "& .MuiAccordionSummary-content": { justifyContent: "space-between" },
}));

interface SidebarAccordionInterface {
  title?: string;
  tooltipText: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isAdmin: boolean;
  children?: React.ReactNode;
}

const CommonSidebarAccordion: React.FC<SidebarAccordionInterface> = ({
  title,
  tooltipText,
  onClick,
  isAdmin,
  children,
}) => {
  return (
    <Paper variant={"outlined"} sx={{ borderColor: "other.outlinedBorder" }}>
      <Accordion>
        <ItemAccordionHeading expandIcon={<ExpandMoreIcon />}>
          <Typography variant={"body1"}>{title}</Typography>
          {isAdmin ? (
            <CustomTooltip title={tooltipText} arrow placement={"top"}>
              <IconButton size={"small"} sx={{ p: 0 }} onClick={onClick}>
                <AddIcon fontSize={"small"} color={"action"} />
              </IconButton>
            </CustomTooltip>
          ) : (
            <></>
          )}
        </ItemAccordionHeading>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default CommonSidebarAccordion;
