import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputLabel, Paper, Stack, Typography } from "@mui/material";

import CreateFunnelOptionCard from "./CreateFunnelOptionCard";
import CoreTextField from "../../common/TextField/CoreTextField";
import LoadingButton from "../../common/Button/LoadingButton";
import CoreButton from "../../common/Button/CoreButton";
import { selectFunnelState } from "../../state/features/funnel/funnelSelector";
import { IAddFunnelPayload } from "../../state/features/funnel/funnel.interface";
import { addFunnel, resetAddFunnelSuccess } from "../../state/features/funnel/funnelSlice";
import { CREATE_FUNNEL_FROM_BLANK, CREATE_FUNNEL_FROM_TEMPLATE } from "../../helpers/constant/coreConstants";

interface IProps {
  onClose: () => void;
  onOpenTemplateModal: () => void;
}

const makeDefaultSubdomain = (websiteName: string) => {
  return websiteName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase() + `.${process.env.REACT_APP_PUBLIC_PAGE_DOMAIN}`;
};

interface FunnelForm {
  funnelName: string;
}

const CreateNewFunnelModal: React.FC<IProps> = ({ onClose, onOpenTemplateModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<FunnelForm>({ defaultValues: { funnelName: "" } });

  const { isAdding, isAddSuccess, addedFunnelInfo } = useSelector(selectFunnelState);
  const [selectedOption, setSelectedOption] = useState(CREATE_FUNNEL_FROM_BLANK);

  const funnelName = watch("funnelName");

  useEffect(() => {
    if (isAddSuccess && addedFunnelInfo?.id) {
      history.push("/wbuilder/funnel/" + addedFunnelInfo.id);
      dispatch(resetAddFunnelSuccess());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddSuccess]);

  const handleSelectOption = (option: number) => {
    setSelectedOption(option);
  };

  const formSubmit = (data: FunnelForm) => {
    if (selectedOption === CREATE_FUNNEL_FROM_TEMPLATE) {
      onOpenTemplateModal();
      onClose();
    } else {
      const payload: IAddFunnelPayload = {
        name: funnelName,
        subDomain: makeDefaultSubdomain(funnelName),
      };
      dispatch(addFunnel(payload));
    }
  };

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <Stack direction={"row"} spacing={3} p={3} pb={0}>
        <CreateFunnelOptionCard
          title={"From Blank"}
          subtitle={"Start from scratch using our page builder."}
          checked={selectedOption === CREATE_FUNNEL_FROM_BLANK}
          onClick={() => handleSelectOption(CREATE_FUNNEL_FROM_BLANK)}
        >
          <Stack spacing={1}>
            <InputLabel>Funnel Name</InputLabel>
            <CoreTextField
              fullWidth
              autoFocus={true}
              color={"secondary"}
              {...register("funnelName", {
                required: { value: selectedOption === CREATE_FUNNEL_FROM_BLANK, message: "Funnel name is required" },
                maxLength: { value: 50, message: "Funnel name cannot be longer than 50 characters" },
              })}
              error={!!errors.funnelName}
              helperText={errors.funnelName?.message}
              sx={{ "& .MuiInputBase-root": { bgcolor: "common.white" } }}
            />
            {funnelName !== "" && (
              <Typography variant={"caption"}>https://{makeDefaultSubdomain(funnelName)}</Typography>
            )}
          </Stack>
        </CreateFunnelOptionCard>
        <CreateFunnelOptionCard
          title={"From Templates"}
          subtitle={"Save time by using one of our pre-built designs."}
          checked={selectedOption === CREATE_FUNNEL_FROM_TEMPLATE}
          onClick={() => handleSelectOption(CREATE_FUNNEL_FROM_TEMPLATE)}
        >
          <Paper variant={"outlined"} sx={{ borderRadius: "8px", height: 120, overflow: "hidden" }}>
            <img
              src={"https://crm-sites-media.s3.amazonaws.com/123/file/webpage-1703748635414.jpeg"}
              alt={"img"}
              style={{ height: "100%", width: "101%", marginLeft: "-1px", objectFit: "cover" }}
            />
          </Paper>
        </CreateFunnelOptionCard>
      </Stack>

      <Stack direction={"row"} spacing={2} justifyContent={"flex-end"} p={3}>
        <CoreButton size={"small"} color={"inherit"} type={"button"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton size={"small"} color={"secondary"} isLoading={isAdding} type={"submit"}>
          Continue
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default CreateNewFunnelModal;
