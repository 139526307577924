import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import { authenticateUser } from "../../state/features/permission/permissionSlice";
import { selectPermissionState } from "../../state/features/permission/permissionSelector";

interface IProps {
  children: React.ReactElement;
}

const AdminPrivateLayout: React.FC<IProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isAdmin, isSuperAdmin, isLoading } = useSelector(selectPermissionState);

  const [isPageReady, setIsPageReady] = useState(false);

  useEffect(() => {
    setIsPageReady(true);
    dispatch(authenticateUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasPermission = isSuperAdmin || isAdmin;
  // decide what to render
  let content = null;
  if (isLoading || !isPageReady) {
    content = (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color='secondary' />
      </Backdrop>
    );
  } else if (!isLoading && !hasPermission) {
    content = <GlobalEmptyPage title={"No Page Found !!404"} description={"you have no access on this page"} />;
  } else if (!isLoading && hasPermission) {
    content = children;
  }

  return content;
};

export default AdminPrivateLayout;
