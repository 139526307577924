import { call, put } from "typed-redux-saga";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, delay, takeEvery } from "redux-saga/effects";
import FunnelReportsService from "../../../services/funnelReports";
import { IGetFunnelReportPayload } from "./funnelReports.interface";
import { getFunnelReportsFailed, getFunnelReportsSuccess } from "./funnelReportsSlice";
import { GLOBAL_DELAY } from "../../../helpers/constant/coreConstants";

function* funnelReportsWatcher() {
  yield takeEvery("funnelReports/getFunnelReports", getFunnelReportsSaga);
}

function* getFunnelReportsSaga(action: PayloadAction<IGetFunnelReportPayload>) {
  try {
    const response = yield* call(FunnelReportsService.getFunnelReports, action.payload);
    yield delay(GLOBAL_DELAY.RESPONSE);
    if (response.success) {
      yield put(getFunnelReportsSuccess(response.data));
    } else {
      yield put(getFunnelReportsFailed(response.message));
    }
  } catch (err) {
    yield put(getFunnelReportsFailed("Network or server error"));
  }
}

export default function* funnelReportsSaga() {
  yield all([funnelReportsWatcher()]);
}
