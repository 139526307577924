import { useEffect, useRef, useState } from "react";

interface UseIframeHeightResult {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  iframeHeight: string;
}

const useIframeHeight = (src: string): UseIframeHeightResult => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState("500px");

  useEffect(() => {
    const handleIframeLoad = () => {
      try {
        const iframe = iframeRef.current;
        if (iframe && iframe.contentWindow) {
          const newHeight = iframe.contentWindow.document.body.scrollHeight + "px";
          setIframeHeight(newHeight);
        }
      } catch (e) {
        console.error(e);
        setIframeHeight("500px");
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [src]);

  return { iframeRef, iframeHeight };
};

export default useIframeHeight;
