import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ContentCopy, Delete, Edit, MoreVert } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import AddOrEditStepContent from "./AddOrEditStepContent";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../../common/PopoverMenu/CustomPopoverMenu";
import CoreButton from "../../../common/Button/CoreButton";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";
import {
  cloneFunnelStep,
  deleteFunnelStep,
  resetDeleteFunnelStepSuccess,
} from "../../../state/features/funnelStep/funnelStepSlice";
import generateFunnelPreviewUrl from "../../../helpers/utils/generateFunnelPreviewUrl";
import { selectFunnelStepState } from "../../../state/features/funnelStep/funnelStepSelector";
import { selectFunnelInfoData } from "../../../state/features/funnelInfo/funnelInfoSelector";

const StepHeader = () => {
  const dispatch = useDispatch();
  const { data: funnelStepList, isDeleting, isDeleteSuccess, selectedFunnelStep } = useSelector(selectFunnelStepState);
  const { subDomain, domainInfo } = useSelector(selectFunnelInfoData);

  const { id: stepId, name, path } = selectedFunnelStep || {};

  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const { value: editStepModal, setTrue: onOpenEditStepModal, setFalse: onCloseEditStepModal } = useBoolean(false);

  useEffect(() => {
    if (isDeleteSuccess) {
      onCloseDeleteModal();
      dispatch(resetDeleteFunnelStepSuccess());
    }
  }, [isDeleteSuccess]);

  const handleDuplicateStep = () => {
    dispatch(cloneFunnelStep({ id: stepId, index: funnelStepList.length }));
  };

  const onConfirmDelete = () => {
    dispatch(deleteFunnelStep({ id: stepId }));
  };

  const previewUrl = generateFunnelPreviewUrl(subDomain, path, domainInfo);

  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} p={2}>
      <Stack>
        <Typography variant={"h6"} color={"text.primary"}>
          {name}
        </Typography>
        {previewUrl !== undefined && (
          <a href={previewUrl} target={"_blank"} style={{ textDecoration: "none" }}>
            <Typography
              variant={"caption"}
              color={"secondary.main"}
              sx={{ display: "inline-flex", alignItems: "center", gap: 0.5, cursor: "pointer" }}
            >
              {previewUrl}
              <LaunchIcon fontSize={"small"} color={"secondary"} />
            </Typography>
          </a>
        )}
      </Stack>

      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <CoreButton
          variant={"outlined"}
          size={"small"}
          color={"secondary"}
          startIcon={<Edit />}
          onClick={onOpenEditStepModal}
        >
          Edit Step
        </CoreButton>

        <CustomPopoverMenu position={"bottom"} minWidth={"max-content"}>
          <CustomPopoverMenuButton>
            <IconButton color={"secondary"} size={"small"}>
              <MoreVert />
            </IconButton>
          </CustomPopoverMenuButton>
          <CustomPopoverMenuItem
            title='Duplicate'
            icon={<ContentCopy color={"action"} />}
            onClick={handleDuplicateStep}
            onClickHide={true}
          />
          <CustomPopoverMenuItem
            title='Delete'
            icon={<Delete color={"action"} />}
            onClick={onOpenDeleteModal}
            onClickHide={true}
          />
        </CustomPopoverMenu>
      </Stack>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDelete}
      />

      <BasicModalWithHeader title={"Edit Step"} open={editStepModal} onClose={onCloseEditStepModal}>
        <AddOrEditStepContent onClose={onCloseEditStepModal} stepInfo={selectedFunnelStep} isEdit={true} />
      </BasicModalWithHeader>
    </Stack>
  );
};

export default StepHeader;
