import { nanoid, PayloadAction } from "@reduxjs/toolkit";
import { IGetUrlRedirectPayload, IUrlRedirect } from "./redirect.interface";
import { all, put } from "typed-redux-saga";
import { getUrlRedirectFailed, getUrlRedirectSuccess } from "./redirectSlice";
import { takeEvery } from "redux-saga/effects";
import { WithPagination } from "../../../interface/response.interface";

const data: WithPagination<IUrlRedirect> = {
  count: 0, currentPage: 0, limit: 0,
  content:[
    {
      id: nanoid(),
      domain: "www.domain1.com",
      path: "/home",
      destination: "www.newPath.com",
      redirectType: '1',
      createdAt: new Date()
    },
    {
    id: nanoid(),
    domain: "www.domain1.com",
    path: "/home",
    destination: "www.newPath.com",
    redirectType: '1',
    createdAt: new Date()
  },
]
}

function* urlRedirectWatcher(){
  yield takeEvery('urlRedirect/getUrlRedirect',getUrlRedirectSaga)
}

function* getUrlRedirectSaga(action:PayloadAction<IGetUrlRedirectPayload>){
  try{
    // const response = yield* call(urlRedirectService.getUrlRedirect,action.payload)
    // console.log("here");
    // if(response.success){
    if(true){
      yield put(getUrlRedirectSuccess(data))
    }else{
      yield put(getUrlRedirectFailed("error"))
    }

  }catch (err){
    yield put(getUrlRedirectFailed("network or server error"))
  }
}

export default function* urlRedirectSaga(){
  yield all([urlRedirectWatcher()])
}