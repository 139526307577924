import React from "react";
import { Add } from "@mui/icons-material";
import useBoolean from "../../../hooks/useBoolean";
import AddOrEditStepContent from "./AddOrEditStepContent";
import CoreButton from "../../../common/Button/CoreButton";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";

interface IProps {
  size?: "small" | "large" | "medium";
}

const AddStepFunnel: React.FC<IProps> = ({ size = "small" }) => {
  const { value: addStepModal, setTrue: onOpenAddStepModal, setFalse: onCloseAddStepModal } = useBoolean(false);

  return (
    <div>
      <CoreButton
        size={size}
        variant={"contained"}
        color={"secondary"}
        startIcon={<Add />}
        onClick={onOpenAddStepModal}
        sx={{ minWidth: 126 }}
      >
        Add step
      </CoreButton>

      <BasicModalWithHeader title={"Add New Step"} open={addStepModal} onClose={onCloseAddStepModal}>
        <AddOrEditStepContent onClose={onCloseAddStepModal} />
      </BasicModalWithHeader>
    </div>
  );
};

export default AddStepFunnel;
