import { RootState } from "../../store";

export const selectWebsiteState = (state: RootState) => state.website;
export const selectWebsiteSelectedIds = (state: RootState) => state.website.selectedWebsiteIds;
export const selectWebsiteFilters = (state: RootState) => state.website.filters;

export const selectedWebsitesFolderIds = (state: RootState) => {
  const websiteIds = state.website.selectedWebsiteIds;
  const websiteList = state.website.data.filter((websiteData) => websiteIds.indexOf(websiteData.id as number) !== -1);

  const websiteFolderIds = websiteList
    .filter((websiteData) => websiteData?.folder?.length)
    .reduce((folders: { name: string; color: string; folderId: number }[], websiteData) => {
      if (websiteData.folder) {
        return [...folders, ...websiteData.folder];
      } else {
        return folders;
      }
    }, [])
    .map((folder) => folder.folderId);

  // @ts-ignore
    return [...new Set(websiteFolderIds)];
};
