import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { AppDispatch } from "../../state/store";
import SingleWebsite from "./SingleWebsite";
import SingleWebsiteInTrash from "./SingleWebsiteInTrash";
import WebsiteEmptyPage from "./WebsiteEmptyPage";
import WebsiteListSkeleton from "../../common/LoadingView/WebsiteListSkeleton";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";
import removeFalsyObjectProperty from "../../helpers/utils/removeFalsyObjectProperty";
import { selectWebsiteFilters, selectWebsiteState } from "../../state/features/website/websiteSelector";
import { deselectAllWebsite, getWebsites, selectedAllWebsite } from "../../state/features/website/websiteSlice";

const Container = styled("div")(({ theme }) => ({
  height: "calc(100% - 50px)",
  overflow: "hidden",
}));

const WebsiteListTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: websites,
    isLoading,
    currentPage,
    limit,
    count,
    renderId,
    selectedWebsiteIds,
  } = useSelector(selectWebsiteState);
  const { searchText, orderBy, folderId, isFavorite, isArchived, inTrash } = useSelector(selectWebsiteFilters);

  useEffect(() => {
    const payload = removeFalsyObjectProperty({
      folderId: folderId as number,
      currentPage,
      limit,
      searchText,
      orderBy,
      isFavorite,
      isArchived,
      inTrash,
    });
    dispatch(getWebsites(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId, searchText, orderBy, isFavorite, isArchived, inTrash, renderId, currentPage]);

  const onChangePage = (newPage: number) => {
    const payload = removeFalsyObjectProperty({
      folderId: folderId as number,
      currentPage: newPage,
      limit: limit,
      searchText,
      orderBy,
      isFavorite,
      isArchived,
      inTrash,
    });
    dispatch(getWebsites(payload));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    const payload = removeFalsyObjectProperty({
      folderId: folderId as number,
      currentPage: newPage,
      limit: perPage,
      searchText,
      orderBy,
      isFavorite,
      isArchived,
      inTrash,
    });
    dispatch(getWebsites(payload));
  };

  // decide what to render
  let content = <></>;
  if (isLoading) {
    content = <WebsiteListSkeleton count={10} />;
  } else if (websites?.length === 0) {
    content = <WebsiteEmptyPage isFavorite={isFavorite} isArchived={isArchived} inTrash={inTrash} />;
  } else if (websites?.length > 0) {
    const selectAll = websites.every((website) => selectedWebsiteIds.includes(website.id as number));
    const indeterminate = !selectAll && selectedWebsiteIds.length > 0;

    content = (
      <>
        <TableContainer sx={{ height: "calc(100% - 46px)" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    edge='start'
                    color='secondary'
                    size={"small"}
                    tabIndex={-1}
                    checked={selectAll}
                    indeterminate={indeterminate}
                    onChange={() => {
                      selectedWebsiteIds.length > 0 ? dispatch(deselectAllWebsite()) : dispatch(selectedAllWebsite());
                    }}
                    sx={{ ml: -0.5 }}
                  />
                  {selectedWebsiteIds.length > 0
                    ? `${selectedWebsiteIds.length} ${
                        selectedWebsiteIds.length === 1 ? "Website" : "Websites"
                      } selected`
                    : "Websites"}
                </TableCell>
                <TableCell align={"right"} sx={{ width: { xs: 85, md: 150 } }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            {websites.map((websiteInfo) => (
              <TableBody key={websiteInfo.id}>
                {!inTrash ? (
                  <SingleWebsite websiteInfo={websiteInfo} />
                ) : (
                  <SingleWebsiteInTrash websiteInfo={websiteInfo} />
                )}
              </TableBody>
            ))}
          </Table>
        </TableContainer>

        <GlobalTablePagination
          count={count}
          page={currentPage}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      </>
    );
  }

  return <Container>{content}</Container>;
};

export default WebsiteListTable;
