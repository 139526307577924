import React from "react";
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import AdminSingleSection from "./AdminSingleSection";
import { selectSectionLibraryState } from "../../../state/features/adminSectionLibrary/adminSectionLibrarySelector";

const AdminSectionLibraryTable: React.FC = () => {
  const { data } = useSelector(selectSectionLibraryState);

  return (
    <TableContainer sx={{ height: "calc(100% - 46px)" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Section Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Publish Status</TableCell>
            <TableCell>Approve Status</TableCell>
            <TableCell align={"right"}>actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((template) => (
            <AdminSingleSection key={template.sectionId} template={template} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminSectionLibraryTable;
