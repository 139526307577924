import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox, IconButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import useBoolean from "../../../../hooks/useBoolean";
import TagI from "../../../../interface/tag.interface";
import CustomPopoverMenu, {
  CustomPopoverMenuButton,
  CustomPopoverMenuItem,
} from "../../../../common/PopoverMenu/CustomPopoverMenu";
import AddOrEditTagsName from "../modals/AddOrEditTagsName";
import DeleteWarningModal from "../../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../../common/Modal/BasicModalWithHeader";
import { deleteTag } from "../../../../state/features/funnel-tag/funnelTagSlice";
import { toggleTag } from "../../../../state/features/funnelTemplate/funnelTemplateSlice";
import { SideBarListItem, StyledListItemButton } from "../../../SitesList/SitesListStyles";
import { selectFunnelTagState } from "../../../../state/features/funnel-tag/funnelTagSelector";
import { selectFunnelFilterState } from "../../../../state/features/funnelTemplate/funnelTemplateSelector";

interface IProps {
  tag: TagI;
  selected?: boolean;
  isAdmin: boolean;
}

const TagsListItem: React.FC<IProps> = ({ tag, selected, isAdmin }) => {
  const dispatch = useDispatch();
  const { tagIds } = useSelector(selectFunnelFilterState);
  const { isDeleting } = useSelector(selectFunnelTagState);

  const { value: openEditModal, setTrue: onOpenEditModal, setFalse: onCloseEditModal } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);
  const [isActive, setIsActive] = useState(false);
  const isIdIncludesInSelectedTags = tagIds.includes(tag.id);

  const deleteTagHandler = () => {
    dispatch(deleteTag(tag.id));
  };

  return (
    <>
      <SideBarListItem
        disablePadding
        sx={{ pr: 2 }}
        className={selected || isActive || isIdIncludesInSelectedTags ? "active" : ""}
      >
        <StyledListItemButton
          onClick={() => {
            dispatch(toggleTag(tag.id));
          }}
        >
          <ListItemIcon>
            <Checkbox edge='start' tabIndex={-1} size={"small"} checked={isIdIncludesInSelectedTags} />
          </ListItemIcon>
          <ListItemText primary={<Typography variant={"body2"}>{tag.name}</Typography>} className='text-truncate' />
        </StyledListItemButton>

        <CustomPopoverMenu callbackOnClose={() => setIsActive(false)} minWidth={"max-content"}>
          <CustomPopoverMenuButton>
            <IconButton size={"small"} className={"ib-sidebar-more-icon"} onClick={() => setIsActive(true)}>
              <MoreVertIcon color={"action"} fontSize={"small"} />
            </IconButton>
          </CustomPopoverMenuButton>
          {isAdmin ? (
            <CustomPopoverMenuItem
              title='Edit'
              icon={<EditIcon color={"action"} fontSize={"small"} />}
              onClick={onOpenEditModal}
              onClickHide={true}
            />
          ) : (
            <div></div>
          )}

          <CustomPopoverMenuItem
            title='Delete'
            icon={<DeleteIcon color={"action"} fontSize={"small"} />}
            onClick={onOpenDeleteModal}
            onClickHide={true}
          />
        </CustomPopoverMenu>
      </SideBarListItem>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={deleteTagHandler}
      />

      <BasicModalWithHeader title={"Edit Tag Name"} open={openEditModal} onClose={onCloseEditModal}>
        <AddOrEditTagsName tag={tag} onClose={onCloseEditModal} isEditMode={true} />
      </BasicModalWithHeader>
    </>
  );
};

export default TagsListItem;
