import { SuccessResponseIn, WithPagination } from "../../../interface/response.interface";
import { FunnelInterface } from "./funnel.interface";
import funnelApiSlice from "../../funnelApiSlice";

export const funnelListApiSlice = funnelApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFunnelList: builder.query<
      SuccessResponseIn<WithPagination<FunnelInterface>>,
      { currentPage: number; limit: number; searchText: string }
    >({
      query: (params) => ({
        url: "funnel/list",
        method: "GET",
        params: params,
      }),
    }),
  }),
});

export const { useGetFunnelListQuery } = funnelListApiSlice;
