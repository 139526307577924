import { takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put } from "typed-redux-saga";

import { FetchFunnelTemplateLibrariesPayloadI } from "../../../interface/funnel-template-library";

import { fetchTemplateLibrarySuccess, resetTemplateLibraryState } from "./funnelTemplateLibrarySlice";
import { showErrorToaster } from "../../../helpers/utils/toaster";
import FunnelTemplateLibraryService from "../../../services/funnelTemplateLibrary";

function* funnelTemplateLibraryWatcher() {
  yield takeEvery("funnelTemplateLibrary/fetchTemplateLibrary", fetchTemplateSaga);
  yield takeEvery("funnelTemplateLibrary/fetchMoreTemplateLibrary", fetchTemplateSaga);
}

function* fetchTemplateSaga(action: PayloadAction<FetchFunnelTemplateLibrariesPayloadI>) {
  try {
    const response = yield* call(FunnelTemplateLibraryService.fetchFunnelTemplate, action.payload);
    if (response.success) {
      yield put(fetchTemplateLibrarySuccess(response.data));
    } else {
      yield put(resetTemplateLibraryState());
      showErrorToaster(response.message);
    }
  } catch (error) {
    console.error(error);
  }
}

export default function* funnelTemplateLibrarySaga() {
  yield all([funnelTemplateLibraryWatcher()]);
}
