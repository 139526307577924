export const FOLDER_COLORS = [
  "rgba(98, 88, 255, 0.5)",
  "rgba(0, 109, 245, 0.5)",
  "rgba(237, 0, 57, 0.5)",
  "rgba(242, 153, 74, 0.5)",
  "rgba(163, 82, 194, 0.5)",
  "rgba(0, 137, 69, 0.5)",
  "rgba(255, 180, 0, 0.5)",
  "rgba(162,160,178,0.5)",
  "rgba(215, 93, 68, 0.5)",
  "rgba(81, 60, 134, 0.5)",
  "rgba(247, 215, 148,0.8)",
  "rgba(119, 139, 235,0.8)",
  "rgba(231, 127, 103,0.8)",
  "rgba(207, 106, 135,0.8)",
  "rgba(120, 111, 166,0.8)",
  "rgba(248, 165, 194,0.8)",
  "rgba(99, 205, 218,0.8)",
  "rgba(234, 134, 133,0.8)",
  "rgba(89, 98, 117,0.8)",
  "rgba(184, 233, 148,0.8)",
  "rgba(56, 173, 169,0.8)",
  "rgba(229, 80, 57,0.8)",
  "rgba(255, 82, 82,0.8)",
  "rgba(112, 111, 211,0.8)",
  "rgba(52, 172, 224,0.8)",
];

export const FOLDER_DEFAULT_COLOR = "#546376";
