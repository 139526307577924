import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, List, ListItemIcon, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import ArchiveIcon from "@mui/icons-material/Archive";
import { AppDispatch } from "../../state/store";
import ShowSideBarIcon from "../../common/Icons/ShowSideBarIcon";
import HideSideBarIcon from "../../common/Icons/HideSideBarIcon";
import { LeftSideBar, SidebarListItem, SidebarListWrapper, SideTopBarTab } from "./FunnelListStyles";
import { selectFunnelFilters } from "../../state/features/funnel/funnelSelector";
import {
  selectAllFunnelsSidebar,
  selectArchivedSidebar,
  selectTrashSidebar,
} from "../../state/features/funnel/funnelSlice";

interface Props {
  toggleLeftSide?: React.MouseEventHandler<HTMLButtonElement>;
  isHideLeftSide: boolean;
  isShowLeftSideTab: boolean;
}

const FunnelListLeftSide: React.FC<Props> = ({ toggleLeftSide, isHideLeftSide, isShowLeftSideTab }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isArchived, inTrash } = useSelector(selectFunnelFilters);

  const onSelectAllFunnels = () => {
    dispatch(selectAllFunnelsSidebar());
  };

  const onSelectArchivedSidebar = () => {
    dispatch(selectArchivedSidebar());
  };

  const onSelectTrashSidebar = () => {
    dispatch(selectTrashSidebar());
  };

  return (
    <LeftSideBar className={isHideLeftSide ? "collapseLeftSide" : ""}>
      <Stack direction='row' alignItems='center' className={"iSidebarTitleBar"}>
        <Typography variant='body1' color='text.secondary' className={"iSidebarTitle"}>
          My Funnels
        </Typography>
        <IconButton size={"large"} onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
          {isHideLeftSide || isShowLeftSideTab ? <ShowSideBarIcon /> : <HideSideBarIcon />}
        </IconButton>
      </Stack>
      <SidebarListWrapper
        px={1}
        className={`${isHideLeftSide ? "slw-hide-sidebar" : ""} ${isShowLeftSideTab ? "slw-show-sidebar-tab" : ""}`}
      >
        <SideTopBarTab>
          <Typography variant='body1' color='text.secondary'>
            My Funnels
          </Typography>
          <IconButton size='large' onClick={toggleLeftSide} sx={{ color: "secondary.main" }}>
            <HideSideBarIcon />
          </IconButton>
        </SideTopBarTab>

        <List>
          <SidebarListItem onClick={onSelectAllFunnels} selected={!isArchived && !inTrash}>
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <FolderIcon color='warning' />
            </ListItemIcon>
            <Typography variant='buttonMedium'>
              All Funnels
            </Typography>
          </SidebarListItem>

          <SidebarListItem onClick={onSelectArchivedSidebar} selected={isArchived}>
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <ArchiveIcon />
            </ListItemIcon>
            <Typography variant='buttonMedium'>
              Archive
            </Typography>
          </SidebarListItem>

          <SidebarListItem onClick={onSelectTrashSidebar} selected={inTrash}>
            <ListItemIcon sx={{ minWidth: "32px" }}>
              <DeleteIcon />
            </ListItemIcon>
            <Typography variant='buttonMedium'>
              Trash
            </Typography>
          </SidebarListItem>
        </List>
      </SidebarListWrapper>
    </LeftSideBar>
  );
};

export default FunnelListLeftSide;
