interface PageToken {
  pageId: string;
  websiteId: string;
}

export const extractPageToken = (pageToken: string): PageToken | null => {
  try {
    let jsonObject = JSON.parse(atob(pageToken)) as PageToken;
    return jsonObject.pageId && jsonObject.websiteId ? jsonObject : null;
  } catch {
    return null;
  }
};
