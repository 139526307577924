import React, { useCallback, useState } from "react";
import { Divider } from "@mui/material";
import AdminListHeader from "./AdminTopbar/AdminListHeader";
import AdminWebsiteListLeftSide from "./AdminLeftSidebar/AdminWebsiteListLeftSide";
import AdminWebsiteLists from "./AdminWebsiteLists";
import { WebsiteListBodyAdmin, WebsiteListWrapperAdmin } from "../SitesList/SitesListStyles";

const Templates = () => {
  const [isHideLeftSide, setIsHideLeftSide] = useState(false);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;

    if (width > 1200) {
      setIsHideLeftSide(!isHideLeftSide);
    } else {
      setIsHideLeftSide(false);
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  }, [isHideLeftSide, isShowLeftSideTab]);

  return (
    <WebsiteListWrapperAdmin variant={"outlined"}>
      <AdminListHeader />

      <WebsiteListBodyAdmin>
        <AdminWebsiteListLeftSide
          toggleLeftSide={toggleLeftSidePanel}
          isHideLeftSide={isHideLeftSide}
          isShowLeftSideTab={isShowLeftSideTab}
        />
        <Divider orientation={"vertical"} flexItem light />
        <AdminWebsiteLists />
      </WebsiteListBodyAdmin>
    </WebsiteListWrapperAdmin>
  );
};

export default Templates;
