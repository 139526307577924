import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Divider, styled } from "@mui/material";
import Box from "@mui/material/Box";
import StepHeader from "./StepHeader";
import StepPages from "./StepPages";
import AddStepFunnel from "./AddStepFunnel";
import useQuery from "../../../hooks/useQuery";
import CircleLoader from "../../../common/LoadingView/CircleLoader";
import NoContentFoundIcon from "../../../common/Icons/NoContentFoundIcon";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import { IFunnelDetailsParams } from "../../../interface/funnelDetails.interface";
import {
  getFunnelStepPages,
  resetAddStepPageSuccess,
} from "../../../state/features/funnelStepPages/funnelStepPagesSlice";
import { selectSelectedFunnelStep } from "../../../state/features/funnelStep/funnelStepSelector";
import { selectFunnelStepPagesState } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";
import { generateFunnelEditorUrl } from "../../../helpers/utils/generateEditorUrl";

const ContentBox = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.other.outlinedBorder}`,
  height: "100%",
  flex: 1,
  backgroundColor: theme.palette.common.white,
  overflowY: "auto",
}));

const BuilderMainContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const searchParams = useQuery();

  const { id: stepId } = useSelector(selectSelectedFunnelStep) || {};

  const { addPagePayload, isAddSuccess } = useSelector(selectFunnelStepPagesState);
  const [initialLoader, setInitialLoader] = useState(true);

  useEffect(() => {
    if (stepId) {
      searchParams.set("step", String(stepId));
      history.replace({ search: searchParams.toString() });
      dispatch(getFunnelStepPages({ funnelId: Number(funnelId), stepId }));
    }
    setInitialLoader(false);
  }, [stepId]);

  // after add page from scratch
  useEffect(() => {
    if (isAddSuccess) {
      if (addPagePayload !== null && addPagePayload.pageCreateType === "FROM_BLANK" && addPagePayload?.pageId) {
        // const funnelPageUrl = generateFunnelPreviewUrl(subDomain, path, domainInfo);
        const editorUrl = generateFunnelEditorUrl(stepId, Number(funnelId), Number(addPagePayload.pageId), false);
        window.open(editorUrl, "_blank");
      }
      dispatch(resetAddStepPageSuccess());
    }
  }, [isAddSuccess]);

  let content: null | React.ReactNode = null;
  if (initialLoader) {
    content = <CircleLoader />;
  } else if (!stepId) {
    content = (
      <GlobalEmptyPage
        icon={<NoContentFoundIcon />}
        title={"Looks like the funnel is empty!"}
        description={"Get started by adding a new step."}
      >
        <Box mt={1}>
          <AddStepFunnel size={"large"} />
        </Box>
      </GlobalEmptyPage>
    );
  } else {
    content = (
      <>
        <StepHeader />
        <Divider light />
        <StepPages />
      </>
    );
  }

  return <ContentBox>{content}</ContentBox>;
};

export default BuilderMainContent;
