import httpRequest from "./api/httpRequest";
import DomainI, {
  CreateDomainI,
  DeleteDomainI,
  DomainCredentialI,
  FetchDomainPayloadI,
  UpdateDomainI,
} from "../interface/domain.interface";
import { WithPagination } from "../interface/response.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class Service {
  fetchDomainList(params: FetchDomainPayloadI) {
    return httpRequest.get<WithPagination<DomainI>>(websiteBuilderAPI + "website/domain/list", params);
  }

  fetchDomainCredential() {
    return httpRequest.get<DomainCredentialI>(websiteBuilderAPI + "website/domain/credential");
  }

  createDomain(payload: CreateDomainI) {
    return httpRequest.post<DomainI>(websiteBuilderAPI + "website/domain/create", payload);
  }

  updateDomain(payload: UpdateDomainI) {
    return httpRequest.put<DomainI>(websiteBuilderAPI + "website/domain/update", payload);
  }

  deleteDomain(params: DeleteDomainI) {
    return httpRequest.delete<DeleteDomainI>(websiteBuilderAPI + "website/domain/delete/" + params.id);
  }
}

const DomainService = new Service();
export default DomainService;
