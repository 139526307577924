import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Stack, styled, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Settings } from "@mui/icons-material";
import HrefLink from "../../../common/Link/HrefLink";
import RouteLink from "../../../common/Link/RouteLink";
import CoreButton from "../../../common/Button/CoreButton";
import { FUNNEL_PAGE_ID } from "../../../helpers/constant/coreConstants";
// import generateFunnelPreviewUrl from "../../../helpers/utils/generateFunnelPreviewUrl";
import { selectFunnelTemplateSettingDataState } from "../../../state/features/funnelTemplateSetting/funnelTemplateSettingSelector";
import { selectFunnelTemplateStepList } from "../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";
import generateFunnelAdminPreviewUrl from "../../../helpers/utils/generateFunnelAdminPreviewUrl";

const WhiteOutlinedButton = styled(CoreButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.other.darkNavy,
  "&:focus, &:hover": {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.other.darkNavy} !important`,
  },
  "&.pause-button": {
    backgroundColor: `${theme.palette.error.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

interface BuilderTopBarInterface {
  setSelectedScreen: React.Dispatch<React.SetStateAction<string>>;
}

const BuilderTopBar: React.FC<BuilderTopBarInterface> = ({ setSelectedScreen }) => {
  const [pageId, setPageId] = useState<string>();
  const { name, defaultStepId, funnelId } = useSelector(selectFunnelTemplateSettingDataState);
  const stepList = useSelector(selectFunnelTemplateStepList);

  useEffect(() => {
    if (stepList.length > 0) {
      let defaultStepPageId = stepList.find((step) => step.stepId === defaultStepId)?.pageId;
      let pageId = defaultStepPageId ?? stepList[0].pageId;

      setPageId(pageId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepList]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ position: "sticky", top: 0, bgcolor: "other.darkNavy", zIndex: 11, p: 1 }}
    >
      <RouteLink to={"/wbuilder/funnels/admin"}>
        <Stack direction={"row"} alignItems={"center"} gap={0.5}>
          <IconButton size={"small"}>
            <ArrowBackIcon sx={{ color: "common.white" }} />
          </IconButton>
          <Typography variant={"h6"} color={"common.white"}>
            {name}
          </Typography>
        </Stack>
      </RouteLink>

      <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ ml: "auto", mr: 1.5 }}>
        {pageId && defaultStepId && (
          <HrefLink href={generateFunnelAdminPreviewUrl(pageId, defaultStepId, funnelId)} target={"_blank"}>
            <WhiteOutlinedButton size={"small"} startIcon={<VisibilityIcon />}>
              Preview Funnel
            </WhiteOutlinedButton>
          </HrefLink>
        )}
        <WhiteOutlinedButton
          size={"small"}
          startIcon={<Settings />}
          onClick={() => setSelectedScreen(FUNNEL_PAGE_ID.SETTINGS)}
        >
          Settings
        </WhiteOutlinedButton>
      </Stack>
    </Stack>
  );
};

export default BuilderTopBar;
