export const FunnelOrderByList = [
  { title: "Title [a-z]", value: "a-z" },
  { title: "Title [z-a]", value: "z-a" },
  { title: "Creation Date", value: "cre-date" },
  { title: "Last Edit", value: "last-edit" },
];

export const FUNNEL_STEP_CREATE_TYPE = {
  TEMPLATE: "TEMPLATE",
  EXISTING_FUNNEL: "EXISTING_FUNNEL",
  PAGE_BUILDER: "PAGE_BUILDER",
};

export const FUNNEL_STEP_CREATE_TYPES_OBJECT = {
  [FUNNEL_STEP_CREATE_TYPE.TEMPLATE]: {
    label: "Select a template",
    value: FUNNEL_STEP_CREATE_TYPE.TEMPLATE,
  },
  [FUNNEL_STEP_CREATE_TYPE.EXISTING_FUNNEL]: {
    label: "From existing funnels",
    value: FUNNEL_STEP_CREATE_TYPE.EXISTING_FUNNEL,
  },
  [FUNNEL_STEP_CREATE_TYPE.PAGE_BUILDER]: {
    label: "From Page Builder",
    value: FUNNEL_STEP_CREATE_TYPE.PAGE_BUILDER,
  },
};
