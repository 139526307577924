import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chip, styled } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import { FileCopyOutlined } from "@mui/icons-material";
import LaunchIcon from "@mui/icons-material/Launch";
import AddOrEditTemplatePageInfo from "../AddOrEditTemplatePageInfo";
import IconButton from "../../../common/IconButton";
import RouteLink from "../../../common/Link/RouteLink";
import CustomTooltip from "../../../common/Tooltip/CustomTooltip";
import useBoolean from "../../../hooks/useBoolean";
import { AdminPageI } from "../../../interface/adminPage.interface";
import DeleteWarningModal from "../../../common/Modal/DeleteWarningModal";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import { goToPreviewPage } from "../../../helpers/utils/goToPreviewPage";
import { generateEditorUrl } from "../../../helpers/utils/generateEditorUrl";
import {
  cloneTemplatePage,
  deleteTemplatePage,
} from "../../../state/features/adminTemplatePage/adminTemplatePageSlice";
import { selectAdminTemplatePageState } from "../../../state/features/adminTemplatePage/adminTemplatePageSelector";

const CardImg = styled(Box)<{ thumbnail: string }>(({ theme, thumbnail }) => ({
  height: "180px",
  width: "100%",
  background: "radial-gradient(circle, rgba(174,217,238,1) 0%, rgba(148,187,233,1) 100%)",
  backgroundImage: `url(${thumbnail || "https://www.messagetech.com/wp-content/themes/ml_mti/images/no-image.jpg"})`,
  backgroundPosition: "top",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  borderBottom: `1px solid ${theme.palette.other.outlinedBorder}`,
}));

interface IProps {
  pageInfo: AdminPageI;
}

const AdminSingleWebPageCard: React.FC<IProps> = ({ pageInfo }) => {
  const dispatch = useDispatch();
  const { isDeleting } = useSelector(selectAdminTemplatePageState);

  const { pageId, websiteId, name, thumbnail, isDefault } = pageInfo;
  const {
    value: openUpdatePageModal,
    setTrue: onOpenUpdatePageModal,
    setFalse: onCloseUpdatePageModal,
  } = useBoolean(false);
  const { value: openDeleteModal, setTrue: onOpenDeleteModal, setFalse: onCloseDeleteModal } = useBoolean(false);

  const clonePageHandler = () => {
    dispatch(cloneTemplatePage({ pageId, websiteId }));
  };

  const deletePageHandler = () => {
    dispatch(deleteTemplatePage({ pageId }));
  };

  return (
    <>
      <Stack spacing={1.5}>
        <RouteLink to={generateEditorUrl(pageId, websiteId, true)}>
          <CardImg thumbnail={thumbnail} />
        </RouteLink>
        <Typography
          variant={"body1SemiBold"}
          color={"text.primary"}
          px={2}
          noWrap
          textTransform={"capitalize"}
          fontWeight={400}
        >
          {name}
        </Typography>

        <Stack direction={"row"} alignItems={"center"} spacing={1} px={2} pb={2}>
          <RouteLink to={generateEditorUrl(pageId, websiteId, true)}>
            <CustomTooltip title={"Edit"} arrow placement={"top"}>
              <IconButton variant={"outlined"} color={"secondary"} size='small'>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </CustomTooltip>
          </RouteLink>

          <CustomTooltip title={"Update"} arrow placement={"top"}>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={onOpenUpdatePageModal}>
              <SettingsIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title={"Clone"} arrow placement={"top"}>
            <IconButton variant={"outlined"} color={"secondary"} size='small' onClick={clonePageHandler}>
              <FileCopyOutlined sx={{ fontSize: "16px" }} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title={"Preview"} arrow placement={"top"}>
            <IconButton
              variant={"outlined"}
              color={"secondary"}
              size='small'
              onClick={() => goToPreviewPage(pageId, websiteId, true)}
            >
              <LaunchIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </CustomTooltip>

          {isDefault !== 1 && (
            <CustomTooltip title={"Delete"} arrow placement={"top"}>
              <IconButton variant={"outlined"} color={"error"} size='small' onClick={onOpenDeleteModal}>
                <DeleteIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            </CustomTooltip>
          )}

          {isDefault === 1 && (
            <CustomTooltip title={"Default Page"} arrow placement={"top"}>
              <Chip
                label='Default'
                size='small'
                color={"primary"}
                sx={{ ml: "auto !important", height: "20px", fontSize: "10px" }}
              />
            </CustomTooltip>
          )}
        </Stack>
      </Stack>

      <BasicModalWithHeader
        open={openUpdatePageModal}
        onClose={onCloseUpdatePageModal}
        title={"Update Template Page for Website"}
        width={500}
      >
        <AddOrEditTemplatePageInfo isEdit pageInfo={pageInfo} onClose={onCloseUpdatePageModal} />
      </BasicModalWithHeader>

      <DeleteWarningModal
        isLoading={isDeleting}
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        onConfirm={deletePageHandler}
      />
    </>
  );
};

export default AdminSingleWebPageCard;
