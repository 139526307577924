import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, styled, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

import FunnelStep from "./FunnelStep";
import useQuery from "../../../../hooks/useQuery";
import FunnelStepsHeader from "./FunnelStepsHeader";
import FunnelStepSkeleton from "../../../../common/LoadingView/FunnelStepSkeleton";
import { selectFunnelTemplateStepState } from "../../../../state/features/funnelTemplateStep/funnelTemplateStepSelector";
import {
  setSelectedFunnelTemplateStep,
  reorderFunnelTemplateStep,
} from "../../../../state/features/funnelTemplateStep/funnelTemplateStepSlice";
import { useParams } from "react-router-dom";
import { IFunnelDetailsParams } from "../../../../interface/funnelDetails.interface";

const SidebarBox = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.other.outlinedBorder}`,
  padding: theme.spacing(2.5),
  width: 320,
  backgroundColor: "#fbfbfb",
  "& .collapse-sidebar-button": {
    display: "none",
  },
  [theme.breakpoints.down("lg")]: {
    position: "absolute",
    height: "100%",
    zIndex: 10,
    "& .collapse-sidebar-button": {
      display: "inline-flex",
      position: "absolute",
      right: "-24px",
      top: "45%",
      backgroundColor: theme.palette.common.white,
      padding: "8px 0",
      borderRadius: "0 8px 8px 0",
      boxShadow: "1px 1px 2px #80808026",
    },
    transition: "all 0.3s ease",
    "&.collapse-sidebar": {
      transform: "translateX(-320px)",
    },
  },
}));

const PageWrapperBox = styled(Box, {
  shouldForwardProp: (props) => props !== "isDraggingOver",
})<{ isDraggingOver: boolean }>(({ theme, isDraggingOver }) => ({
  padding: isDraggingOver ? 0 : 0,
  minHeight: "120px",
  flexGrow: 1,
}));

const FunnelSteps = () => {
  const dispatch = useDispatch();
  const { funnelId } = useParams<IFunnelDetailsParams>();
  const searchParams = useQuery();
  const step = searchParams.get("step");
  const [collapse, setCollapse] = useState(false);
  const { data, isLoading, selectedFunnelStep, isSuccess, isDeleteSuccess } =
    useSelector(selectFunnelTemplateStepState);

  // manage selected
  useEffect(() => {
    if (isSuccess) {
      if (step) {
        const stepInfo = data.find((template) => template.stepId === step);
        dispatch(setSelectedFunnelTemplateStep(stepInfo ?? data[0]));
      } else {
        dispatch(setSelectedFunnelTemplateStep(data[0]));
      }
    }

    if (isDeleteSuccess) {
      if (data.length > 0) {
        dispatch(setSelectedFunnelTemplateStep(data[0]));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isDeleteSuccess]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result || {};
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    if (source.droppableId === "stepList" && destination.droppableId === "stepList") {
      dispatch(
        reorderFunnelTemplateStep({
          id: funnelId, // here funnelId should be passed
          sourceIndex: source.index,
          targetIndex: destination.index,
        })
      );
    }
    return undefined;
  };

  let content: null | React.ReactNode = null;
  if (isLoading) {
    content = <FunnelStepSkeleton count={6} />;
  } else if (data?.length === 0) {
    content = (
      <Typography variant={"caption"} textAlign={"center"} color={"text.secondary"} fontWeight={300} sx={{ mt: 2 }}>
        No Funnel Step Found!!
      </Typography>
    );
  } else {
    content = (
      <>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='stepList' type='step'>
            {(provided, snapshot) => {
              return (
                <PageWrapperBox
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {data.map((funnelStepInfo, index) => {
                    const { stepId } = funnelStepInfo || {};

                    const draggableId = stepId.toString();
                    return (
                      <Draggable key={stepId} draggableId={draggableId} index={index}>
                        {(provided, snapshot) => {
                          return (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <FunnelStep
                                key={funnelStepInfo.stepId}
                                funnelStepInfo={funnelStepInfo}
                                selected={selectedFunnelStep.stepId === funnelStepInfo.stepId}
                                isDragging={snapshot.isDragging}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}

                  {provided.placeholder}
                </PageWrapperBox>
              );
            }}
          </Droppable>
        </DragDropContext>

        <Typography variant={"caption"} textAlign={"center"} color={"text.secondary"} fontWeight={300}>
          Funnel ends here
        </Typography>
      </>
    );
  }

  return (
    <SidebarBox className={collapse ? "collapse-sidebar" : ""}>
      <div className={"collapse-sidebar-button"} onClick={() => setCollapse((collapse) => !collapse)}>
        {collapse ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
      </div>

      <FunnelStepsHeader />
      <Box sx={{ maxHeight: "94%", overflowY: "auto", scrollbarWidth: "none" }}>
        <Stack spacing={1} mt={1}>
          {content}
        </Stack>
      </Box>
    </SidebarBox>
  );
};

export default FunnelSteps;
