import React from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import VariantPage from "./VariantPage";
import OriginalPage from "./OriginalPage";
import EmptyStepPage from "./EmptyStepPage";
import SplitTestHistory from "./SplitTestHistory";
import TestProcessingCard from "./testSnippets/TestProcessingCard";
import CircleLoader from "../../../common/LoadingView/CircleLoader";
import { selectFunnelStepPagesState } from "../../../state/features/funnelStepPages/funnelStepPagesSelector";

const TestingArea = () => {
  const { isLoading, original } = useSelector(selectFunnelStepPagesState);

  let content: null | React.ReactNode = null;
  if (isLoading) {
    content = <CircleLoader />;
  } else if (!original?.id) {
    content = <EmptyStepPage />;
  } else {
    content = (
      <>
        <Stack direction={"row"} p={4} justifyContent={"center"}>
          <OriginalPage />
          <TestProcessingCard />
          <VariantPage />
        </Stack>
        <SplitTestHistory />
      </>
    );
  }

  return content;
};

export default TestingArea;
