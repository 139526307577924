import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const AbTestingIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox='0 0 64 64'>
      <path
        d='M58.568 19.0862V21.7529C58.568 22.4602 58.2871 23.1384 57.787 23.6385C57.2869 24.1386 56.6086 24.4196 55.9014 24.4196H43.9014C43.5477 24.4196 43.2086 24.5601 42.9586 24.8101C42.7085 25.0602 42.568 25.3993 42.568 25.7529V31.2692C42.5681 31.6912 42.443 32.1037 42.2086 32.4546C41.9742 32.8055 41.6409 33.079 41.251 33.2405C40.8612 33.402 40.4322 33.4442 40.0183 33.3618C39.6044 33.2794 39.2242 33.0762 38.9259 32.7777L28.0763 21.9281C27.8782 21.73 27.721 21.4948 27.6138 21.236C27.5066 20.9772 27.4514 20.6997 27.4514 20.4196C27.4514 20.1394 27.5066 19.862 27.6138 19.6032C27.721 19.3443 27.8782 19.1091 28.0763 18.911L38.9259 8.06118C39.2242 7.76271 39.6044 7.55943 40.0183 7.47706C40.4322 7.3947 40.8613 7.43694 41.2512 7.59846C41.6411 7.75998 41.9743 8.03351 42.2087 8.38444C42.4431 8.73538 42.5681 9.14796 42.568 9.56998V15.0862C42.568 15.4399 42.7085 15.779 42.9586 16.0291C43.2086 16.2791 43.5477 16.4196 43.9014 16.4196H55.9014C56.6086 16.4196 57.2869 16.7005 57.787 17.2006C58.2871 17.7007 58.568 18.379 58.568 19.0862Z'
        fill='#E6EBFF'
      />
      <path
        d='M23.9823 30.6382C24.3962 30.7206 24.7764 30.9239 25.0747 31.2223L35.9243 42.0719C36.1224 42.27 36.2796 42.5052 36.3868 42.764C36.494 43.0229 36.5492 43.3003 36.5492 43.5804C36.5492 43.8606 36.494 44.138 36.3868 44.3969C36.2796 44.6557 36.1224 44.8909 35.9243 45.089L25.0747 55.9388C24.7763 56.2373 24.3962 56.4406 23.9823 56.523C23.5683 56.6053 23.1393 56.5631 22.7494 56.4016C22.3595 56.24 22.0263 55.9665 21.7919 55.6156C21.5575 55.2646 21.4324 54.8521 21.4326 54.43V48.9138C21.4326 48.5602 21.2921 48.221 21.042 47.971C20.792 47.7209 20.4528 47.5804 20.0992 47.5804H8.09922C7.39198 47.5804 6.7137 47.2995 6.2136 46.7994C5.71351 46.2993 5.43256 45.621 5.43256 44.9138V42.2471C5.43256 41.5399 5.71351 40.8616 6.2136 40.3615C6.7137 39.8614 7.39198 39.5804 8.09922 39.5804H20.0992C20.4528 39.5804 20.792 39.44 21.042 39.1899C21.2921 38.9399 21.4326 38.6007 21.4326 38.2471V32.7308C21.4325 32.3088 21.5576 31.8963 21.792 31.5454C22.0264 31.1945 22.3597 30.921 22.7495 30.7595C23.1394 30.5981 23.5684 30.5558 23.9823 30.6382Z'
        fill='#346FEF'
      />
    </SvgIcon>
  );
};

export default AbTestingIcon;
