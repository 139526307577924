import React from "react";
import { Box } from "@mui/material";
import UrlRedirectHeader from "./UrlRedirectHeader";
import UrlRedirectsTable from "./UrlRedirectsTable";

const UrlRedirects = () => {
  return (
    <Box sx={{ width: { xs: "100%", lg: "80%" }, margin: "auto" }}>
      <UrlRedirectHeader />
      <UrlRedirectsTable />
    </Box>
  );
};

export default UrlRedirects;
