import httpRequest from "./api/httpRequest";
import TypeI, { CreateTypeI, UpdateTypeI } from "../interface/type.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class TypeService {
  fetchType() {
    return httpRequest.get<TypeI[]>(websiteBuilderAPI + "admin/website/type/list");
  }

  createType(payload: CreateTypeI) {
    return httpRequest.post<TypeI>(websiteBuilderAPI + "admin/website/type/create", payload);
  }

  updateType(payload: UpdateTypeI) {
    return httpRequest.put<TypeI>(websiteBuilderAPI + "admin/website/type/update", payload);
  }

  deleteType(typeId: string) {
    return httpRequest.delete<TypeI>(websiteBuilderAPI + "admin/website/type/delete/" + typeId);
  }
}

const typeService = new TypeService();
export default typeService;
