import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { websiteOrderByList } from "../../../helpers/constant/websiteConstant";
import {
  AdminTemplateI,
  CloneAdminTemplateI,
  CreateAdminTemplateI,
  DeleteAdminTemplateI,
  FetchTemplatesPayloadI,
  InTrashAdminTemplateI,
  RenameAdminTemplateI,
  UpdateApproveStatusI,
  UpdatePublishStatusI,
} from "../../../interface/website-template.interface";
import { WithPagination } from "../../../interface/response.interface";

const initialState = {
  data: [] as AdminTemplateI[],
  currentPage: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  renderId: "",

  isCreating: false,
  isCreated: false,

  isDeleting: false,
  isDeleteSuccess: false,
  isCloning: false,

  isRenaming: false,
  isRenameSuccess: false,

  selectedTemplateIds: [] as string[],
  createdWebsiteId: null as null | string,

  filters: {
    categoryId: "",
    searchText: "",
    inTrash: false,
    orderBy: websiteOrderByList[2].value,
    tagIds: [] as string[],
    typeIds: [] as string[],
  },
};

const adminTemplateSlice = createSlice({
  name: "adminTemplate",
  initialState,
  reducers: {
    fetchTemplate(state, action: PayloadAction<FetchTemplatesPayloadI>) {
      if (!state.renderId) state.isLoading = true;
    },
    fetchTemplateSuccess(state, action: PayloadAction<WithPagination<AdminTemplateI>>) {
      state.data = action.payload.content;
      state.currentPage = action.payload.currentPage;
      state.limit = +action.payload.limit;
      state.count = +action.payload.count;
      state.isLoading = false;
      state.selectedTemplateIds = [];
    },
    fetchTemplateFailed(state) {
      state.data = [];
      state.currentPage = 1;
      state.limit = 10;
      state.count = 0;
      state.isLoading = false;
    },
    resetTemplateState(state) {
      state.data = [];
      state.currentPage = 1;
      state.limit = 10;
      state.count = 0;
      state.isLoading = false;
      state.selectedTemplateIds = [];
      state.renderId = "";
      state.filters = {
        categoryId: "",
        searchText: "",
        inTrash: false,
        orderBy: websiteOrderByList[2].value,
        tagIds: [] as string[],
        typeIds: [] as string[],
      };
    },
    forceTemplateRender: (state) => {
      state.renderId = nanoid(5);
    },

    createTemplate(state, action: PayloadAction<CreateAdminTemplateI>) {
      state.isCreating = true;
    },
    createTemplateSuccess(state, action: PayloadAction<AdminTemplateI>) {
      state.isCreating = false;
      state.isCreated = true;
      state.createdWebsiteId = action.payload.id;
    },
    createTemplateFailed(state) {
      state.isCreating = false;
    },

    renameTemplate(state, _action: PayloadAction<RenameAdminTemplateI>) {
      state.isRenaming = true;
    },
    renameTemplateSuccess(state, action: PayloadAction<RenameAdminTemplateI>) {
      state.isRenaming = false;
      state.data = state.data.map((template) => {
        if (template.id === action.payload.websiteId) {
          return { ...template, ...action.payload };
        }
        return template;
      });
      state.isRenameSuccess = true;
    },
    resetRenameTemplateSuccess(state) {
      state.isRenaming = false;
      state.isRenameSuccess = false;
    },

    deleteTemplates(state, action: PayloadAction<DeleteAdminTemplateI>) {
      state.isDeleting = true;
    },
    deleteTemplatesSuccess(state, action: PayloadAction<DeleteAdminTemplateI>) {
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.data = state.data.filter((template) => !action.payload.websiteIds.includes(template.id));
      state.selectedTemplateIds = [];
    },
    resetDeleteTemplates(state) {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    trashTemplates(state, action: PayloadAction<InTrashAdminTemplateI>) {
      state.isDeleting = true;
    },
    trashTemplatesSuccess(state, action: PayloadAction<InTrashAdminTemplateI>) {
      if (action.payload) {
        state.data = state.data.filter((template) => !action.payload.websiteIds.includes(template.id as string));
      }
      state.isDeleting = false;
      state.selectedTemplateIds = [];
      state.count -= 1;
    },
    trashTemplatesFailed(state) {
      state.isDeleting = true;
    },

    cloneTemplate(state, action: PayloadAction<CloneAdminTemplateI>) {
      state.isCloning = true;
    },
    cloneTemplateSuccess(state, action: PayloadAction<AdminTemplateI>) {
      state.isCloning = false;
      state.data = [action.payload, ...state.data.slice(0, 9)];
      state.count += 1;
    },
    cloneTemplateFailed(state) {
      state.isCloning = false;
    },

    togglePublishStatus(state, action: PayloadAction<UpdatePublishStatusI>) {},
    togglePublishStatusSuccess(state, action: PayloadAction<UpdatePublishStatusI>) {
      state.data = state.data.map((template) => {
        if (template.id === action.payload.websiteId) {
          return { ...template, publishStatus: action.payload.status };
        }
        return template;
      });
    },
    togglePublishStatusFailed(state) {},
    toggleApproveStatusByAdmin(state, action: PayloadAction<UpdateApproveStatusI>) {},
    toggleApproveStatusByAdminSuccess(state, action: PayloadAction<UpdateApproveStatusI>) {
      state.data = state.data.map((template) => {
        if (template.id === action.payload.websiteId) {
          return { ...template, status: action.payload.status };
        }
        return template;
      });
    },
    toggleApproveStatusByAdminFailed(state) {},

    toggleSingleTemplate(state, action: PayloadAction<string>) {
      const currentFormIndex = state.selectedTemplateIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentFormIndex !== -1) {
        state.selectedTemplateIds.splice(currentFormIndex, 1);
      } else {
        state.selectedTemplateIds.push(action.payload);
      }
    },
    selectedAllTemplate: (state) => {
      state.selectedTemplateIds = state.data.map((data) => data.id);
    },
    deselectAllTemplate: (state) => {
      state.selectedTemplateIds = [];
    },

    resetCreateStatus(state) {
      state.isCreated = false;
      state.createdWebsiteId = null;
    },

    toggleCategory(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        state.filters.categoryId = action.payload;
        state.filters.inTrash = false;
      } else {
        state.filters.categoryId = "";
      }
    },
    removeCategory(state) {
      state.filters.categoryId = "";
    },
    templateSearchTextFilter(state, action: PayloadAction<string>) {
      state.filters.searchText = action.payload;
    },
    toggleType(state, action: PayloadAction<string>) {
      const currentFormIndex = state.filters.typeIds.findIndex((typeId) => typeId === action.payload);
      if (currentFormIndex !== -1) {
        state.filters.typeIds.splice(currentFormIndex, 1);
      } else {
        state.filters.typeIds.push(action.payload);
        state.filters.inTrash = false;
      }
    },
    toggleTag(state, action: PayloadAction<string>) {
      const currentFormIndex = state.filters.tagIds.findIndex((tagId) => tagId === action.payload);
      if (currentFormIndex !== -1) {
        state.filters.tagIds.splice(currentFormIndex, 1);
      } else {
        state.filters.tagIds.push(action.payload);
        state.filters.inTrash = false;
      }
    },

    selectTrashFolder(state) {
      state.filters.inTrash = true;
      state.selectedTemplateIds = [];
      state.currentPage = 1;
      state.filters.tagIds = [];
      state.filters.typeIds = [];
      state.filters.categoryId = "";
    },

    resetTemplateFilter(state) {
      state.filters.inTrash = false;
      state.selectedTemplateIds = [];
      state.currentPage = 1;
      state.filters.tagIds = [];
      state.filters.typeIds = [];
      state.filters.categoryId = "";
    },
  },
});

export const {
  fetchTemplate,
  fetchTemplateFailed,
  fetchTemplateSuccess,
  resetTemplateState,

  createTemplate,
  createTemplateFailed,
  createTemplateSuccess,

  renameTemplate,
  renameTemplateSuccess,
  resetRenameTemplateSuccess,

  deleteTemplates,
  resetDeleteTemplates,
  deleteTemplatesSuccess,

  trashTemplates,
  trashTemplatesFailed,
  trashTemplatesSuccess,

  togglePublishStatus,
  togglePublishStatusFailed,
  togglePublishStatusSuccess,

  toggleApproveStatusByAdmin,
  toggleApproveStatusByAdminFailed,
  toggleApproveStatusByAdminSuccess,

  toggleSingleTemplate,
  deselectAllTemplate,
  selectedAllTemplate,

  cloneTemplate,
  cloneTemplateFailed,
  cloneTemplateSuccess,

  selectTrashFolder,
  resetCreateStatus,

  toggleCategory,
  removeCategory,
  toggleTag,
  toggleType,
  resetTemplateFilter,
  templateSearchTextFilter,
} = adminTemplateSlice.actions;

export default adminTemplateSlice.reducer;
