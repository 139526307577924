import React from "react";
import { Box, Grid, Paper, Skeleton, Stack } from "@mui/material";

const AdminPAgeSettingSkeleton = () => {
  return (
    <Paper elevation={0} sx={{ p: 3, maxWidth: "90%", margin: "auto" }}>
      <Grid container spacing={2} columnSpacing={3} mb={3}>
        <Grid item xs={12} md={12}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={40} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={40} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={100} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='rectangular' height={100} />
          </Box>
        </Grid>
      </Grid>
      <Stack alignItems={"flex-end"}>
        <Skeleton variant='rounded' width={100} height={40} />
      </Stack>
    </Paper>
  );
};

export default AdminPAgeSettingSkeleton;
