import { all, call, delay, put } from "typed-redux-saga";
import { takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  getMoreTemplatesFailed,
  getMoreTemplatesSuccess,
  getTemplateCategoriesFailed,
  getTemplateCategoriesSuccess,
  getTemplatesFailed,
  getTemplatesSuccess,
  getTemplateTagsFailed,
  getTemplateTagsSuccess,
  getTemplateTypesFailed,
  getTemplateTypesSuccess,
} from "./templateSlice";
import { IGetTemplatePayload } from "./template.interface";
import TemplateService from "../../../services/template.service";

function* templateWatcher() {
  yield takeLatest("template/getTemplates", getTemplatesSaga);
  yield takeLatest("template/getMoreTemplates", getMoreTemplatesSaga);
  yield takeEvery("template/getTemplateCategories", getTemplateCategoriesSaga);
  yield takeEvery("template/getTemplateTypes", getTemplateTypesSaga);
  yield takeEvery("template/getTemplateTags", getTemplateTagsSaga);
}

function* getTemplatesSaga(action: PayloadAction<IGetTemplatePayload>) {
  try {
    yield delay(1000);
    const response = yield* call(TemplateService.getTemplates, action.payload);

    if (response.success) {
      yield put(getTemplatesSuccess(response.data));
    } else {
      yield put(getTemplatesFailed(response.message));
    }
  } catch (err) {
    yield put(getTemplatesFailed("Network or server error"));
  }
}

function* getMoreTemplatesSaga(action: PayloadAction<IGetTemplatePayload>) {
  try {
    yield delay(500);
    const response = yield* call(TemplateService.getTemplates, action.payload);

    if (response.success) {
      yield put(getMoreTemplatesSuccess(response.data));
    } else {
      yield put(getMoreTemplatesFailed(response));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* getTemplateCategoriesSaga() {
  try {
    const response = yield* call(TemplateService.getTemplateCategories);

    if (response.success) {
      yield put(getTemplateCategoriesSuccess(response.data));
    } else {
      yield put(getTemplateCategoriesFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* getTemplateTypesSaga() {
  try {
    const response = yield* call(TemplateService.getTemplateTypes);

    if (response.success) {
      yield put(getTemplateTypesSuccess(response.data));
    } else {
      yield put(getTemplateTypesFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* getTemplateTagsSaga() {
  try {
    const response = yield* call(TemplateService.getTemplateTags);

    if (response.success) {
      yield put(getTemplateTagsSuccess(response.data));
    } else {
      yield put(getTemplateTagsFailed());
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* templateSaga() {
  yield all([templateWatcher()]);
}
