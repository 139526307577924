import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { StyledSelect } from "../../../common/Select/StyledSelect";
import { websiteOrderByList } from "../../../helpers/constant/websiteConstant";
import { selectWebsiteFilters } from "../../../state/features/website/websiteSelector";
import { addWebsiteOrderByFilter } from "../../../state/features/website/websiteSlice";

const WebsiteListFilterStatus = () => {
  const dispatch = useDispatch();
  const { orderBy = "" } = useSelector(selectWebsiteFilters);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    dispatch(addWebsiteOrderByFilter(event.target.value as string));
  };

  return (
    <StyledSelect size='small' color='secondary' sx={{ width: "200px" }} value={orderBy} onChange={handleChange}>
      {websiteOrderByList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.title}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default WebsiteListFilterStatus;
