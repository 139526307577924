import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DomainListItem from "./DomainListItem";
import TableLoaderSkeleton from "../../../common/LoadingView/TableLoaderSkeleton";
import GlobalTablePagination from "../../../common/Pagination/GlobalTablePagination";
import { fetchDomainList } from "../../../state/features/domain/domainSlice";
import { selectDomainState } from "../../../state/features/domain/domainSelector";
import EmptyDomainPage from "./EmptyDomainPage";

const DomainList = () => {
  const dispatch = useDispatch();
  const { data: domainList, isLoading, isReady, currentPage, limit, count } = useSelector(selectDomainState);

  useEffect(() => {
    dispatch(fetchDomainList({ currentPage: 1, limit: 10 }));
  }, [dispatch]);

  const onChangePage = (newPage: number) => {
    dispatch(fetchDomainList({ currentPage: newPage, limit: limit }));
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    dispatch(fetchDomainList({ currentPage: newPage, limit: perPage }));
  };

  // decide what to render
  let content = <></>;
  if (isLoading || !isReady) {
    content = <TableLoaderSkeleton columns={3} numberOfRows={10} />;
  } else if (domainList?.length === 0) {
    content = <EmptyDomainPage />;
  } else if (domainList?.length > 0) {
    content = (
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Domain</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell align={"right"}>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {domainList.map((domainInfo) => (
              <DomainListItem key={domainInfo.id} domainInfo={domainInfo} />
            ))}
          </TableBody>
        </Table>

        <GlobalTablePagination
          count={count}
          page={currentPage}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      </TableContainer>
    );
  }

  return content;
};

export default DomainList;
