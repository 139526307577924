import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PermissionI {
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

const initialState = {
  isAdmin: false,
  isSuperAdmin: false,

  isLoading: false,
};

const permissionSlice = createSlice({
  initialState,
  name: "permission",
  reducers: {
    authenticateUser(state) {
      state.isLoading = true;
    },
    authenticateUserSuccess(state, action: PayloadAction<PermissionI>) {
      state.isLoading = false;
      state.isAdmin = action.payload.isAdmin;
      state.isSuperAdmin = action.payload.isSuperAdmin;
    },
    authenticateUserFailed(state) {
      state.isLoading = false;
    },
  },
});

export default permissionSlice.reducer;
export const { authenticateUser, authenticateUserFailed, authenticateUserSuccess } = permissionSlice.actions;
