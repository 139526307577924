import React from "react";
import { BrowserRouter, Route, RouteComponentProps, Router, Switch } from "react-router-dom";
import ErrorPage from "./common/ErrorPage";
import SettingsPage from "./components/Settings/SettingsPage";
import SiteListPage from "./components/SitesList/SiteListPage";
import AdminIndex from "./components/WebsiteBuilderAdmin/AdminIndex";
import WebsiteIndex from "./components/SingleWebsiteProperty/WebsiteIndex";
import AdminWebPages from "./components/WebsiteBuilderAdmin/AdminWebsites/AdminWebPages";
import EditorPageUser from "./components/SingleWebsiteProperty/EditorPage/EditorPageUser";
import EditorPageAdmin from "./components/SingleWebsiteProperty/EditorPage/EditorPageAdmin";
import EditorPageSection from "./components/SingleWebsiteProperty/EditorPage/EditorPageSection";
import FunnelListPage from "./components/FunnelList/FunnelListPage";
import FunnelBuilderIndex from "./components/FunnelList/builder/FunnelBuilderIndex";
import FunnelAdminIndex from "./components/FunnelBuilderAdmin/FunnelAdminIndex";
import FunnelEditorPageUser from "./components/SingleWebsiteProperty/EditorPage/FunnelEditorPageUser";
import FunnelEditorPageAdmin from "./components/SingleWebsiteProperty/EditorPage/FunnelEditorPageAdmin";
import AdminFunnelBuilderIndex from "./components/FunnelBuilderAdmin/templatesBuilder/AdminFunnelBuilderIndex";

interface Props {
  history?: RouteComponentProps["history"];
}

interface RouterWrapperProps {
  history?: RouteComponentProps["history"];
  children: React.ReactNode;
}

const RouterWrapper = ({ history, children }: RouterWrapperProps) => {
  return history ? <Router history={history}>{children}</Router> : <BrowserRouter>{children}</BrowserRouter>;
};

const AppRouters: React.FC<Props> = ({ history }) => {
  return (
    <RouterWrapper history={history}>
      <div>
        <Switch>
          <Route path='/wbuilder/sites' exact component={SiteListPage} />
          <Route path='/wbuilder/settings' exact component={SettingsPage} />
          <Route path='/wbuilder/site/:websiteId' exact component={WebsiteIndex} />
          <Route path='/wbuilder/page/:pageId' exact component={EditorPageUser} />
          <Route path='/wbuilder/admin' exact component={AdminIndex} />
          <Route path='/wbuilder/admin/site/:websiteId' exact component={AdminWebPages} />
          <Route path='/wbuilder/admin/page/:pageId' exact component={EditorPageAdmin} />
          <Route path='/wbuilder/admin/section/:sectionId' exact component={EditorPageSection} />

          <Route path='/wbuilder/funnels' exact component={FunnelListPage} />
          <Route path='/wbuilder/funnel/:funnelId' exact component={FunnelBuilderIndex} />
          <Route path='/wbuilder/funnel/page/:token' exact component={FunnelEditorPageUser} />
          <Route path='/wbuilder/funnels/admin' exact component={FunnelAdminIndex} />
          <Route path='/wbuilder/funnels/admin/funnel/:funnelId' exact component={AdminFunnelBuilderIndex} />
          <Route path='/wbuilder/funnel/admin/page/:token' exact component={FunnelEditorPageAdmin} />
          <Route path='/*' component={ErrorPage} />
        </Switch>
      </div>
    </RouterWrapper>
  );
};

export default AppRouters;
