import httpRequest from "./api/httpRequest";
import TagI, { CreateTagI, UpdateTagI } from "../interface/tag.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class TagService {
  fetchTag() {
    return httpRequest.get<TagI[]>(websiteBuilderAPI + "admin/website/tag/list");
  }

  createTag(payload: CreateTagI) {
    return httpRequest.post<TagI>(websiteBuilderAPI + "admin/website/tag/create", payload);
  }

  updateTag(payload: UpdateTagI) {
    return httpRequest.put<TagI>(websiteBuilderAPI + "admin/website/tag/update", payload);
  }

  deleteTag(tagId: string) {
    return httpRequest.delete<TagI>(websiteBuilderAPI + "admin/website/tag/delete/" + tagId);
  }
}

const tagService = new TagService();
export default tagService;
