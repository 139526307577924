import React from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import NewWebsitePages from "./NewWebsitePages";
import PageCreationEmptyPage from "./PageCreationEmptyPage";
import { useGetAllWebsitePagesQuery } from "../../state/features/website-pages/websitePageApiSlice";

const WebsitePages: React.FC = () => {
  const { websiteId } = useParams<{ websiteId: string }>();

  const { data: websitePageState, isLoading } = useGetAllWebsitePagesQuery(
    { websiteId: websiteId },
    { skip: !websiteId }
  );
  const { data: websitePageList = [] } = websitePageState || {};

  let content = <></>;
  if (isLoading) {
    content = (
      <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"} sx={{ height: "100%" }}>
        <CircularProgress color={"secondary"} size={25} sx={{ position: "relative" }} />
        <Typography color={"secondary.main"}>Loading Pages...</Typography>
      </Stack>
    );
  } else if (websitePageList.length > 0) {
    content = <NewWebsitePages websitePageList={websitePageList} />;
  } else {
    content = <PageCreationEmptyPage />;
  }

  return (
    <Box p={3} sx={{ height: "100%" }}>
      {content}
    </Box>
  );
};

export default WebsitePages;
