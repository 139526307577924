import httpRequest from "./api/httpRequest";
import { IWebsiteReports } from "../state/features/websiteReports/websiteReports.interface";

const websiteBuilderAPI = process.env.REACT_APP_BACKEND_URL_WEBSITE_BUILDER_API;
if (!websiteBuilderAPI) throw Error("Invoice API URL not found in env!");

class Service {
  getWebsiteReports(params: string) {
    return httpRequest.get<IWebsiteReports>(websiteBuilderAPI + "website/reports/" + params);
  }
}

const WebsiteReportsService = new Service();
export default WebsiteReportsService;
