import React from "react";
import { Link, Paper, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOrEditDomain from "./AddOrEditDomain";
import BasicModalWithHeader from "../../../common/Modal/BasicModalWithHeader";
import useBoolean from "../../../hooks/useBoolean";
import CoreButton from "../../../common/Button/CoreButton";

const EmptyDomainPage = () => {
  const {
    value: openAddDomainModal,
    setTrue: onOpenAddDomainModal,
    setFalse: onCloseAddDomainModal,
  } = useBoolean(false);

  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: "other.secondaryHover", height: "60vh", position: "relative" }}>
        <Link
          href={"https://s3.us-east-1.amazonaws.com/pypepro/contact/36807830/lefEhFzVpyeLg4FKqeHvUocWikDvh068uMGUiNlp.pdf"}
          target={"_blank"}
        >
          <Typography
            variant={"caption"}
            color={"secondary.main"}
            sx={{ display: "inline-flex", alignItems: "center", gap: 0.5, position: "absolute", top: 10, right: 10 }}
          >
            Domain setup instructions.
            <InfoOutlinedIcon fontSize={"small"} />
          </Typography>
        </Link>
        <Stack alignItems={"center"} justifyContent={"center"} spacing={2} sx={{ height: "100%", p: 8 }}>
          <Typography variant={"h4"} sx={{ color: "#366aff" }}>
            You haven't added any domains yet!
          </Typography>
          <Typography sx={{ color: "other.darkNavy" }}>Click the button below to connect your first domain</Typography>
          <CoreButton
            size={"large"}
            color={"secondary"}
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={onOpenAddDomainModal}
          >
            Add new domain
          </CoreButton>
        </Stack>
      </Paper>
      <BasicModalWithHeader title='Add New Domain' open={openAddDomainModal} onClose={onCloseAddDomainModal}>
        <AddOrEditDomain onClose={onCloseAddDomainModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default EmptyDomainPage;
