import React from "react";
import { Skeleton } from "@mui/material";

const WebsiteListSkeleton = ({ count = 1 }) => {
    return (
        <>
            <Skeleton variant='rectangular' height={40} />
            {Array.from({ length: count }).map((rowNum, index) => (
                <Skeleton key={index} variant='rectangular' sx={{ mt: 1 }} height={68} />
            ))}
        </>
    );
};

export default WebsiteListSkeleton;