import React from "react";
import { useDispatch } from "react-redux";
import { ListItemText, Typography } from "@mui/material";

import { AppDispatch } from "../../../../state/store";
import CategoryI from "../../../../interface/category.interface";
import { SideBarListItem, StyledListItemButton } from "../../../SitesList/SitesListStyles";
import { toggleType, removeType } from "../../../../state/features/adminSectionLibrary/adminSectionLibrarySlice";

interface IProps {
  category: Partial<CategoryI>;
  selected?: boolean;
  isAdmin: boolean;
}

const SectionLibraryType: React.FC<IProps> = ({ category, selected, isAdmin }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <SideBarListItem disablePadding sx={{ pr: 2 }} className={selected ? "active" : ""}>
      <StyledListItemButton
        onClick={() => {
          if (selected) {
            dispatch(removeType());
          } else {
            dispatch(toggleType(category.id));
          }
        }}
      >
        <ListItemText primary={<Typography variant={"body2"}>{category.name}</Typography>} className='text-truncate' />
      </StyledListItemButton>
    </SideBarListItem>
  );
};

export default React.memo(SectionLibraryType);
