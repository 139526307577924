import React from "react";
import { useSelector } from "react-redux";
import { Box, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import LaunchIcon from "@mui/icons-material/Launch";
import { selectWebsiteSettingState } from "../../state/features/website-settings/websiteSettingSelector";
import RouteLink from "../../common/Link/RouteLink";
import { goToPreviewPage } from "../../helpers/utils/goToPreviewPage";
import { useGetAllWebsitePagesQuery } from "../../state/features/website-pages/websitePageApiSlice";
import AddNewPageAction from "./AddNewPageAction";

interface IProps {
  selectedTab?: number;
}
const WebsitePageHeading: React.FC<IProps> = ({ selectedTab }) => {
  const { data: websiteDetails, isLoading } = useSelector(selectWebsiteSettingState);
  const { name, defaultPageId, websiteId, domainInfo, subDomain } = websiteDetails || {};
  const { data: websitePageState } = useGetAllWebsitePagesQuery({ websiteId: websiteId }, { skip: !websiteId });
  const { data: websitePageList = [] } = websitePageState || {};

  const handleGotoPreview = () => {
    goToPreviewPage(defaultPageId, websiteId, false, domainInfo, subDomain);
  };

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} gap={1} p={2}>
        <RouteLink to={`/wbuilder/sites`}>
          <IconButton size='small'>
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
        </RouteLink>
        <img
          src={"https://crm-sites-media.s3.amazonaws.com/1/file/webpage-1713438506931.png"}
          alt={"site illustration"}
          style={{ width: 50, height: 50 }}
        />

        <Stack spacing={0.5}>
          <Typography variant={"h5"} color={"text.primary"}>
            {isLoading ? <Skeleton variant={"text"} width={200} /> : name}
          </Typography>

          {isLoading ? (
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <Skeleton variant={"text"} width={200} />
              <Skeleton width={30} height={30} />
            </Stack>
          ) : (
            // `https://${domainInfo?.domain}/${btoa(JSON.stringify({ defaultPageId }))}`
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                variant={"caption"}
                color={"secondary.main"}
                fontWeight={400}
                onClick={handleGotoPreview}
                sx={{ cursor: "pointer" }}
              >
                {domainInfo?.domain !== undefined ? domainInfo?.domain : subDomain}
              </Typography>
              {domainInfo?.domain !== undefined || subDomain !== undefined ? (
                <IconButton size={"small"} onClick={handleGotoPreview} sx={{ py: 0 }}>
                  <LaunchIcon fontSize={"small"} color={"secondary"} />
                </IconButton>
              ) : (
                ""
              )}
            </Stack>
          )}
        </Stack>

        <Box sx={{ ml: "auto" }}>{websitePageList?.length > 0 && selectedTab === 0 ? <AddNewPageAction /> : ""}</Box>

        {/*<Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ ml: "auto" }}>*/}
        {/*  <CoreTextField*/}
        {/*    fullWidth*/}
        {/*    size={"small"}*/}
        {/*    type={"search"}*/}
        {/*    color={"secondary"}*/}
        {/*    InputProps={{*/}
        {/*      startAdornment: (*/}
        {/*        <InputAdornment sx={{ mr: 0 }} position="start">*/}
        {/*          <Search />*/}
        {/*        </InputAdornment>*/}
        {/*      ),*/}
        {/*    }}*/}
        {/*    placeholder={"Search pages..."}*/}
        {/*  />*/}

        {/*  {websitePageList?.length > 0 && <AddNewPageAction />}*/}
        {/*</Stack>*/}
      </Stack>
    </>
  );
};

export default WebsitePageHeading;
