import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FunnelStepInterface,
  IAddFunnelStepPayload,
  ICloneFunnelStepPayload,
  IDeleteFunnelStepPayload,
  IGetFunnelStepsPayload,
  IReorderFunnelStepPayload,
  IUpdateFunnelStepPayload,
} from "./funnelStep.interface";

const initialState = {
  data: [] as FunnelStepInterface[],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: "",

  isAdding: false,
  isAddSuccess: false,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  selectedFunnelStep: {} as FunnelStepInterface,
};

const funnelStepSlice = createSlice({
  name: "funnelStep",
  initialState,
  reducers: {
    getFunnelSteps: (state, _action: PayloadAction<IGetFunnelStepsPayload>) => {
      state.isLoading = true;
    },
    getFunnelStepsSuccess: (state, action: PayloadAction<FunnelStepInterface[]>) => {
      state.data = action.payload;
      state.isSuccess = true;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    resetGetFunnelSteps: (state) => {
      state.data = [];
      state.isSuccess = false;
      state.isLoading = false;
      state.isError = true;
      state.error = "";
    },

    addFunnelStep: (state, _action: PayloadAction<IAddFunnelStepPayload>) => {
      state.isAdding = true;
    },
    addFunnelStepSuccess: (state, action: PayloadAction<FunnelStepInterface>) => {
      state.isAdding = false;
      state.isAddSuccess = true;
      state.data = [...state.data, action.payload];
      state.selectedFunnelStep = action.payload;
    },
    resetAddFunnelStepSuccess: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },

    updateFunnelStep: (state, action: PayloadAction<IUpdateFunnelStepPayload>) => {
      state.isUpdating = true;
    },
    updateFunnelStepSuccess: (state, action: PayloadAction<IUpdateFunnelStepPayload>) => {
      state.isUpdating = false;
      state.isUpdateSuccess = true;
      state.data = state.data.map((item) => {
        if (item.id === action.payload.id) {
          state.selectedFunnelStep = { ...item, ...action.payload };
          return { ...item, ...action.payload };
        }
        return item;
      });
    },
    resetUpdateFunnelStepSuccess: (state) => {
      state.isUpdating = false;
      state.isUpdateSuccess = false;
    },

    reorderFunnelStep: (state, action: PayloadAction<IReorderFunnelStepPayload>) => {
      const cloneData = [...state.data];
      const deleteStep = cloneData.splice(action.payload.sourceIndex, 1);
      cloneData.splice(action.payload.targetIndex, 0, deleteStep[0]);
      state.data = cloneData;
    },
    reorderFunnelStepSuccess: (_state) => {},
    resetReorderFunnelStepSuccess: (state, action: PayloadAction<IReorderFunnelStepPayload>) => {
      const cloneData = [...state.data];
      const deleteStep = cloneData.splice(action.payload.targetIndex, 1);
      cloneData.splice(action.payload.sourceIndex, 0, deleteStep[0]);
      state.data = cloneData;
    },

    deleteFunnelStep: (state, action: PayloadAction<IDeleteFunnelStepPayload>) => {
      state.isDeleting = true;
    },
    deleteFunnelStepSuccess: (state, action: PayloadAction<IDeleteFunnelStepPayload>) => {
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.data = state.data.filter((item) => item.id !== action.payload.id);
      if (state.selectedFunnelStep.id === action.payload.id) {
        state.selectedFunnelStep = state.data[0] ?? {};
      }
    },
    resetDeleteFunnelStepSuccess: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    cloneFunnelStep: (state, action: PayloadAction<ICloneFunnelStepPayload>) => {
      state.isUpdating = true;
    },
    cloneFunnelStepSuccess: (state, action: PayloadAction<FunnelStepInterface>) => {
      state.isUpdating = false;
      state.data = [...state.data, action.payload];
    },
    cloneFunnelStepFailed: (state) => {
      state.isUpdating = false;
    },

    setSelectedFunnelStep(state, action: PayloadAction<FunnelStepInterface>) {
      state.selectedFunnelStep = action.payload;
    },
  },
});

export const {
  getFunnelSteps,
  resetGetFunnelSteps,
  getFunnelStepsSuccess,

  addFunnelStep,
  addFunnelStepSuccess,
  resetAddFunnelStepSuccess,

  updateFunnelStep,
  updateFunnelStepSuccess,
  resetUpdateFunnelStepSuccess,

  reorderFunnelStep,
  reorderFunnelStepSuccess,
  resetReorderFunnelStepSuccess,

  deleteFunnelStep,
  deleteFunnelStepSuccess,
  resetDeleteFunnelStepSuccess,

  cloneFunnelStep,
  cloneFunnelStepFailed,
  cloneFunnelStepSuccess,

  setSelectedFunnelStep,
} = funnelStepSlice.actions;

export default funnelStepSlice.reducer;
