import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Info } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import { Box, Divider, Stack } from "@mui/material";
import CoreButton from "../../common/Button/CoreButton";
import LoadingButton from "../../common/Button/LoadingButton";
import CustomTooltip from "../../common/Tooltip/CustomTooltip";
import CoreTextField from "../../common/TextField/CoreTextField";
import { generateEditorUrl } from "../../helpers/utils/generateEditorUrl";
import {
  registerPatternPage,
  removeSpecialCharacters,
  replaceSpaceToHyphen,
} from "../../helpers/constant/registerPattern";
import IWebsitePage from "../../state/features/website-pages/websitePage.interface";
import {
  useCreateWebsitePageMutation,
  useUpdateWebSitePageMutation,
} from "../../state/features/website-pages/websitePageApiSlice";

interface IProps {
  onClose: () => void;
  pageInfo?: IWebsitePage;
  isEdit?: boolean;
}

const AddOrEditPageInfo: React.FC<IProps> = ({ onClose, pageInfo, isEdit = false }) => {
  const history = useHistory();
  const { websiteId } = useParams<{ websiteId: string }>();

  const [createWebsitePage, { data: createdPageInfo, isLoading: isCreating, isSuccess: isCreateSuccess }] =
    useCreateWebsitePageMutation();
  const [updateWebSitePage, { isLoading: isUpdating, isSuccess: isUpdateSuccess }] = useUpdateWebSitePageMutation();
  const [isPathAlreadyEdited, setIsPathAlreadyEdited] = useState(isEdit);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<IWebsitePage>();

  // auto path created feature
  const fieldName = !isPathAlreadyEdited && watch("name");

  useEffect(() => {
    if (!isPathAlreadyEdited) {
      let path = fieldName ? fieldName.toLowerCase().trim() : "";
      path = removeSpecialCharacters(path);
      path = path.trimStart();
      path = replaceSpaceToHyphen(path);
      if (!path.startsWith("/")) path = "/" + path;
      setValue("path", path);
    }
  }, [fieldName]);

  useEffect(() => {
    if (pageInfo && pageInfo.pageId && isEdit) {
      setValue("name", pageInfo.name ?? "");
      setValue("path", pageInfo.path ?? "");
      setValue("metadata.author", pageInfo.metadata?.author ?? "");
      setValue("metadata.title", pageInfo.metadata?.title ?? "");
      setValue("metadata.description", pageInfo.metadata?.description ?? "");
    }
  }, [pageInfo]);

  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      const { data } = createdPageInfo || {};
      if (isCreateSuccess && data?.pageId) {
        history.push(generateEditorUrl(data.pageId, websiteId));
      }
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateSuccess, isUpdateSuccess]);

  const onFormSubmit: SubmitHandler<IWebsitePage> = (values) => {
    if (isEdit) {
      if (pageInfo) {
        values.pageId = pageInfo.pageId;
        values.websiteId = pageInfo.websiteId;
        updateWebSitePage(values);
      }
    } else {
      values.websiteId = websiteId;
      createWebsitePage(values);
    }
  };

  const isLoading = isCreating || isUpdating;
  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <Stack gap={2} p={3}>
        <CoreTextField
          required
          autoFocus={!isEdit}
          type='text'
          size='medium'
          color='secondary'
          label='Name for Page'
          placeholder='Name for Page'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternPage.name)}
        />
        <CoreTextField
          type='text'
          size='medium'
          color='secondary'
          label={
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <InputLabel component={"span"} required sx={{ mb: 0 }}>
                Slug
              </InputLabel>
              <CustomTooltip
                title={"The slug should be something like /about, /contact."}
                arrow
                placement={"right-end"}
              >
                <Info color={"action"} sx={{ fontSize: 16 }} />
              </CustomTooltip>
            </Stack>
          }
          placeholder='Slug'
          error={!!(errors.path && errors.path.message)}
          helperText={errors.path && errors.path.message}
          {...register("path", registerPatternPage.path)}
          onChange={() => {
            if (!isPathAlreadyEdited) setIsPathAlreadyEdited(true);
          }}
        />

        <CoreTextField
          type='text'
          size='medium'
          color='secondary'
          label='Author'
          placeholder='john'
          {...register("metadata.author", registerPatternPage.author)}
          error={!!(errors.metadata?.author && errors.metadata?.author.message)}
          helperText={errors.metadata?.author && errors.metadata?.author.message}
        />

        <CoreTextField
          type='text'
          size='medium'
          color='secondary'
          label='Title Tag'
          placeholder='Home'
          {...register("metadata.title", registerPatternPage.title)}
          error={!!(errors.metadata?.title && errors.metadata?.title.message)}
          helperText={errors.metadata?.title && errors.metadata?.title.message}
        />

        <CoreTextField
          multiline
          minRows={4}
          maxRows={4}
          type='text'
          size='medium'
          color='secondary'
          label='Description'
          placeholder='Write meta description here...'
          {...register("metadata.description", registerPatternPage.description)}
          error={!!(errors.metadata?.description && errors.metadata?.description.message)}
          helperText={errors.metadata?.description && errors.metadata?.description.message}
        />
      </Stack>
      <Divider light />
      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1} px={3} py={2}>
        <CoreButton color={"inherit"} size={"small"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          type='submit'
          size={"small"}
          variant='contained'
          color='secondary'
          isLoading={isLoading}
          loadingText={isEdit ? "Updating..." : "Creating..."}
        >
          {isEdit ? "Update" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditPageInfo;
