export const websiteOrderByList = [
  { title: "Title [a-z]", value: "a-z" },
  { title: "Title [z-a]", value: "z-a" },
  { title: "Creation Date", value: "cre-date" },
  { title: "Last Edit", value: "last-edit" },
];
export const DefaultPageList = [
  { title: "Automative/Home", value: "1" },
  { title: "Automative/Appointment", value: "2" },
  { title: "Automative/Thank you", value: "3" },
  { title: "web Works/Home", value: "4" },
];

export const WEBSITE_REPORT_TAB = {
  TRAFFIC: "TRAFFIC",
  DEVICE: "DEVICE",
  PLATFORM: "PLATFORM",
  MOST_VIEW_PAGES: "MOST_VIEW_PAGES",
};

export const WEBSITE_REPORT_DATE_RANGE_TYPE = {
  THIS_WEEK: "THIS_WEEK",
  THIS_MONTH: "THIS_MONTH",
  ALL_TIME: "ALL_TIME",
  CUSTOM: "CUSTOM",
};
