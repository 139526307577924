import { all, call, delay, put } from "typed-redux-saga";
import { takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import FunnelService from "../../../services/funnel.service";
import { GLOBAL_DELAY } from "../../../helpers/constant/coreConstants";
import {
  addStepPageSuccess,
  deleteStepPageSuccess,
  getFunnelStepPages,
  getFunnelStepPagesFailed,
  getFunnelStepPagesSuccess,
  resetAddStepPageSuccess,
} from "./funnelStepPagesSlice";
import { AddStepPageRequestType, IDeleteStepPagePayload, IFunnelStepPagesRequest } from "./funnelStepPagesInterfaces";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";

function* funnelStepPagesWatcher() {
  yield takeLatest("funnelStepPages/getFunnelStepPages", getFunnelStepPagesSaga);
  yield takeLatest("funnelStepPages/addStepPage", addStepPageSaga);
  yield takeLatest("funnelStepPages/deleteStepPage", deleteStepPageSaga);
}

function* getFunnelStepPagesSaga(action: PayloadAction<IFunnelStepPagesRequest>) {
  try {
    const response = yield* call(FunnelService.getFunnelStepPages, action.payload);

    if (response.success) {
      yield put(getFunnelStepPagesSuccess(response.data));
    } else {
      yield put(getFunnelStepPagesFailed(response.message));
    }
  } catch (err) {
    yield put(getFunnelStepPagesFailed("Network or server error"));
  }
}

function* addStepPageSaga(action: PayloadAction<AddStepPageRequestType>) {
  try {
    const response = yield* call(FunnelService.addStepPage, action.payload);
    yield delay(GLOBAL_DELAY.RESPONSE);

    if (response.success) {
      yield put(addStepPageSuccess(response.data));
      showSuccessToaster(response.message);
      yield put(getFunnelStepPages({ funnelId: action.payload.funnelId, stepId: action.payload.stepId }));
    } else {
      yield put(resetAddStepPageSuccess());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetAddStepPageSuccess());
    showErrorToaster("Network or server error");
  }
}

function* deleteStepPageSaga(action: PayloadAction<IDeleteStepPagePayload>) {
  try {
    const response = yield* call(FunnelService.deleteStepPage, action.payload);
    yield delay(GLOBAL_DELAY.RESPONSE);

    if (response.success) {
      yield put(deleteStepPageSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(resetAddStepPageSuccess());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(resetAddStepPageSuccess());
    showErrorToaster("Network or server error");
  }
}

export default function* funnelStepPagesSaga() {
  yield all([funnelStepPagesWatcher()]);
}
