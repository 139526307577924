import React from "react";
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IWebsitePage from "../../state/features/website-pages/websitePage.interface";
import SingleWebPageDetails from "./SingleWebPageDetails";
import GlobalTablePagination from "../../common/Pagination/GlobalTablePagination";
import AddNewPageAction from "./AddNewPageAction";
import Stack from "@mui/material/Stack";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface newWebsitePagesInterface {
  websitePageList: IWebsitePage[];
}
const NewWebsitePages: React.FC<newWebsitePagesInterface> = ({ websitePageList }) => {
  return (
    <>
      <TableContainer sx={{ height: "95%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                title
              </TableCell>
              <TableCell>author</TableCell>
              <TableCell>date</TableCell>
              <TableCell>title tag</TableCell>
              <TableCell>meta desc.</TableCell>
              <TableCell align={"right"}>actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {websitePageList.map((page: IWebsitePage) => {
              return (
                <StyledTableRow>
                  <SingleWebPageDetails pageInfo={page} />
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <GlobalTablePagination/>
    </>
  );
};

export default NewWebsitePages;
