import React from "react";
import { styled, TextFieldProps } from "@mui/material";
import CoreTextField from "./CoreTextField";

const CoreTextFieldStyled = styled(CoreTextField)(({ theme }) => ({
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
}));

const InputWithLabel: React.FC<TextFieldProps> = (props) => {
  return (
    <CoreTextFieldStyled
      InputLabelProps={{
        shrink: true,
        style: { position: "relative", transform: "unset", color: "text.primary", fontSize: 14, top: -4 },
      }}
      {...props}
    />
  );
};

export default InputWithLabel;
