import apiSlice from "../../apiSlice";
import { SuccessResponseIn } from "../../../interface/response.interface";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import IWebsitePage, { IClonePagePayload, ICreatePagePayload, IUpdatePagePayload } from "./websitePage.interface";

export const websitePageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllWebsitePages: builder.query<SuccessResponseIn<IWebsitePage[]>, { websiteId: string }>({
      query: (params) => ({
        url: "website/page/all",
        method: "GET",
        params: params,
      }),
      providesTags: ["WEBSITE_PAGES"],
    }),
    createWebsitePage: builder.mutation<SuccessResponseIn<IWebsitePage>, ICreatePagePayload>({
      query: (body) => ({
        url: "website/page/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WEBSITE_PAGES"],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: createdPageState } = await queryFulfilled;

          if (createdPageState.success) showSuccessToaster(createdPageState.message);
          else showErrorToaster(createdPageState.message);
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
    }),
    updateWebSitePage: builder.mutation<SuccessResponseIn<IWebsitePage>, IUpdatePagePayload>({
      query: (body) => ({
        url: "website/page/update/" + body.pageId,
        method: "PUT",
        body,
      }),
      async onQueryStarted({ pageId, websiteId, ...rest }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPostState } = await queryFulfilled;

          dispatch(
            websitePageApiSlice.util.updateQueryData(
              "getAllWebsitePages",
              { websiteId: websiteId.toString() },
              (draft) => {
                draft.data = draft.data.map((pageInfo) => {
                  if (pageInfo.pageId === pageId) return { ...pageInfo, ...rest, metadata: rest.metadata, };
                  return pageInfo;
                });
                return draft;
              }
            )
          );

          if (updatedPostState.success) showSuccessToaster(updatedPostState.message);
          else showErrorToaster(updatedPostState.message);
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
    }),
    cloneWebSitePage: builder.mutation<SuccessResponseIn<IWebsitePage>, IClonePagePayload>({
      query: (body) => ({
        url: "website/page/clone",
        method: "POST",
        body,
      }),
      invalidatesTags: ["WEBSITE_PAGES"],
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data: clonedPostState } = await queryFulfilled;

          if (clonedPostState.success) showSuccessToaster(clonedPostState.message);
          else showErrorToaster(clonedPostState.message);
        } catch (e: any) {
          const { message } = e.error?.data;
          showErrorToaster(message as string);
        }
      },
    }),
    deleteWebSitePage: builder.mutation<SuccessResponseIn<IWebsitePage>, { websiteId: string; pageId: string }>({
      query: ({ pageId, ...rest }) => ({
        url: "website/page/delete/" + pageId,
        method: "DELETE",
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        // update the drafted cash
        const patchResult = dispatch(
          websitePageApiSlice.util.updateQueryData(
            "getAllWebsitePages",
            { websiteId: payload.websiteId.toString() },
            (draft) => {
              draft.data = draft.data.filter((page) => page.pageId !== payload.pageId);
              return draft;
            }
          )
        );

        try {
          const { data: clonedPostState } = await queryFulfilled;

          if (clonedPostState.success) showSuccessToaster(clonedPostState.message);
          else showErrorToaster(clonedPostState.message);
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetAllWebsitePagesQuery,
  useCreateWebsitePageMutation,
  useUpdateWebSitePageMutation,
  useCloneWebSitePageMutation,
  useDeleteWebSitePageMutation,
} = websitePageApiSlice;
