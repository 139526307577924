import httpRequest from "./api/httpRequest";
import { WithPagination } from "../interface/response.interface";
import {
  IActiveStatusPayload,
  ArchiveStatusPayload,
  FunnelInterface,
  GetFunnelsPayload,
  IAddFunnelPayload,
  ICloneFunnelPayload,
  IDeleteForeverPayload,
  InTrashStatusPayload,
  IAddFunnelFromTemplateLibraryPayload,
} from "../state/features/funnel/funnel.interface";
import { FunnelInfoInterface, IUpdateFunnelInfo } from "../state/features/funnelInfo/funnelInfoInterfaces";
import {
  AddStepPageRequestType,
  FunnelStepPageInterface,
  IDeleteStepPagePayload,
  IFunnelStepPagesRequest,
} from "../state/features/funnelStepPages/funnelStepPagesInterfaces";

const funnelAPI = process.env.REACT_APP_BACKEND_URL_FUNNEL_BUILDER_API;
if (!funnelAPI) throw Error("Funnel API URL not found in env!");

class FunnelService {
  getFunnelList(body: GetFunnelsPayload) {
    return httpRequest.get<WithPagination<FunnelInterface>>(funnelAPI + "funnel/list", body);
  }

  toggleActiveStatus(body: IActiveStatusPayload) {
    return httpRequest.put<IActiveStatusPayload>(funnelAPI + "funnel/active-status", body);
  }

  addFunnel(body: IAddFunnelPayload) {
    return httpRequest.post<FunnelInterface>(funnelAPI + "funnel/create", body);
  }

  toggleInTrashStatus(params: InTrashStatusPayload) {
    return httpRequest.put<InTrashStatusPayload>(funnelAPI + "funnel/trash", params);
  }

  deleteFunnelForever(params: IDeleteForeverPayload) {
    return httpRequest.delete<IDeleteForeverPayload>(funnelAPI + "funnel/delete", {}, params);
  }

  updateArchiveStatus(payload: ArchiveStatusPayload) {
    return httpRequest.put<ArchiveStatusPayload>(funnelAPI + "funnel/archive", payload);
  }

  cloneFunnel(payload: ICloneFunnelPayload) {
    return httpRequest.post<ICloneFunnelPayload>(funnelAPI + "funnel/clone", payload);
  }

  // funnel details and settings services
  getFunnelInfo(payload: { id: number }) {
    return httpRequest.get<FunnelInfoInterface>(funnelAPI + "funnel/details/" + payload.id);
  }
  updateFunnelInfo(payload: IUpdateFunnelInfo) {
    return httpRequest.put<FunnelInfoInterface>(funnelAPI + "funnel/update/settings", payload);
  }

  // funnel step pages services
  getFunnelStepPages(payload: IFunnelStepPagesRequest) {
    return httpRequest.get<FunnelStepPageInterface[]>(
      funnelAPI + `funnel/step/page/all/${payload.funnelId}/${payload.stepId}`
    );
  }
  addStepPage(payload: AddStepPageRequestType) {
    return httpRequest.post<FunnelStepPageInterface>(funnelAPI + "funnel/step/page/create", payload);
  }
  deleteStepPage(payload: IDeleteStepPagePayload) {
    return httpRequest.delete<IDeleteStepPagePayload>(funnelAPI + "funnel/step/page/delete/" + payload.pageId);
  }

  addFunnelFromTemplateLibrary(body: IAddFunnelFromTemplateLibraryPayload) {
    return httpRequest.post<FunnelInterface>(funnelAPI + "funnel/create/by/template", body);
  }
}

const funnelService = new FunnelService();

export default funnelService;
