import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Container, FormHelperText, Grid, Paper } from "@mui/material";

import LoadingButton from "../../common/Button/LoadingButton";
import CoreTextField from "../../common/TextField/CoreTextField";
import StyledReactSelect from "../../common/Select/StyledReactSelect";
import { registerPatternFunnel } from "../../helpers/constant/registerPattern";
import FileUploadWithPreview from "../../common/FileUpload/FileUploadWithPreview";
import { selectFunnelTagState } from "../../state/features/funnel-tag/funnelTagSelector";
import { selectFunnelTypeState } from "../../state/features/funnel-type/funnelTypeSelector";
import { selectFunnelCategoryState } from "../../state/features/funnel-category/funnelCategorySelector";
import { selectFunnelTemplateStepState } from "../../state/features/funnelTemplateStep/funnelTemplateStepSelector";
import { FunnelTemplateSettingI, UpdateFunnelTemplateSettingI } from "../../interface/funnelTemplateSetting.interface";
import { selectFunnelTemplateSettingState } from "../../state/features/funnelTemplateSetting/funnelTemplateSettingSelector";
import {
  resetUpdateSettingStatus,
  updateFunnelTemplateSetting,
} from "../../state/features/funnelTemplateSetting/funnelTemplateSettingSlice";

interface ReactSelectValue {
  label: string;
  value: string;
}

const FunnelSettings: React.FC = () => {
  const dispatch = useDispatch();
  const { funnelId } = useParams<{ funnelId: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm<FunnelTemplateSettingI>();

  const { data: tags, isLoading: tagIsLoading } = useSelector(selectFunnelTagState);
  const { data: types, isLoading: typeIsLoading } = useSelector(selectFunnelTypeState);
  const { data: steps, isLoading: stepIsLoading } = useSelector(selectFunnelTemplateStepState);
  const { data: categories, isLoading: categoryIsLoading } = useSelector(selectFunnelCategoryState);
  const { data: funnelDetails, isUpdating, isUpdated } = useSelector(selectFunnelTemplateSettingState);

  const tagOptions = tags.map<ReactSelectValue>((tag) => ({ value: tag.id, label: tag.name }));
  const typeOptions = types.map<ReactSelectValue>((type) => ({ value: type.id, label: type.name }));
  const categoryOptions = categories.map<ReactSelectValue>((category) => ({
    value: category.id,
    label: category.name,
  }));
  const stepOptions = steps.map<ReactSelectValue>((step) => ({
    value: step.stepId,
    label: step.name,
  }));

  watch(["tagIds", "typeIds", "categoryId", "thumbnail", "name"]);

  useEffect(() => {
    if (funnelDetails.funnelId) {
      reset({ ...funnelDetails });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelDetails.funnelId]);

  useEffect(() => {
    if (isUpdated) {
      dispatch(resetUpdateSettingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const onSubmitHandler = (values: UpdateFunnelTemplateSettingI) => {
    const updatedValues: UpdateFunnelTemplateSettingI = {
      ...values,
      funnelId,
    };

    dispatch(updateFunnelTemplateSetting(updatedValues));
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onSubmitHandler)} sx={{ p: 3 }}>
      <Container maxWidth='xl'>
        <Grid container spacing={2} columnSpacing={3} mb={3}>
          <Grid item xs={12} md={12}>
            <Box>
              <CoreTextField
                required
                fullWidth
                label='Funnel Name'
                color='secondary'
                error={!!(errors.name && errors.name.message)}
                helperText={errors.name && errors.name.message}
                {...register("name", registerPatternFunnel.name)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Controller
                name='defaultStepId'
                control={control}
                rules={registerPatternFunnel.defaultStepId}
                render={({ field: { value }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth error={invalid}>
                      <InputLabel color='secondary'>Default Step</InputLabel>
                      <StyledReactSelect
                        isLoading={stepIsLoading}
                        classNamePrefix={"st-select"}
                        options={stepOptions}
                        value={stepOptions.find((option) => option.value === value)}
                        onChange={(newOption) => {
                          const option = newOption as ReactSelectValue;
                          setValue("defaultStepId", option.value);
                        }}
                      />
                      {invalid && error?.message && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Controller
                name='categoryId'
                control={control}
                // rules={registerPatternFunnel.categoryId}
                render={({ field: { value }, fieldState: { invalid, error } }) => {
                  return (
                    <FormControl fullWidth error={invalid}>
                      <InputLabel color='secondary'>Category</InputLabel>
                      <StyledReactSelect
                        isLoading={categoryIsLoading}
                        classNamePrefix={"st-select"}
                        options={categoryOptions}
                        value={categoryOptions.find((category) => category.value === value)}
                        onChange={(newOption) => {
                          const option = newOption as ReactSelectValue;
                          setValue("categoryId", option.value);
                        }}
                      />
                      {invalid && error?.message && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box>
              <Controller
                name='typeIds'
                control={control}
                render={({ field: { value } }) => {
                  return (
                    <FormControl fullWidth>
                      <InputLabel color='secondary'>Types</InputLabel>
                      <StyledReactSelect
                        isLoading={typeIsLoading}
                        classNamePrefix={"st-select"}
                        isMulti={true}
                        options={typeOptions}
                        value={typeOptions.filter((type) => value?.includes(type.value))}
                        onChange={(newOptions) => {
                          const options = newOptions as ReactSelectValue[];
                          setValue(
                            "typeIds",
                            options.map((option) => option.value)
                          );
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name='tagIds'
              render={({ field: { value } }) => (
                <FormControl fullWidth>
                  <InputLabel color='secondary'>Tags</InputLabel>
                  <StyledReactSelect
                    isMulti={true}
                    options={tagOptions}
                    isLoading={tagIsLoading}
                    classNamePrefix={"st-select"}
                    value={tagOptions.filter((tag) => value?.includes(tag.value))}
                    onChange={(newOptions) => {
                      const options = newOptions as ReactSelectValue[];
                      const tags = options.map((option) => option.value);
                      setValue("tagIds", tags);
                    }}
                  />
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <CoreTextField
                fullWidth
                multiline
                label='Description'
                color='secondary'
                minRows={7}
                maxRows={10}
                error={!!(errors.description && errors.description.message)}
                helperText={errors.description && errors.description.message}
                {...register("description", registerPatternFunnel.description)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>Thumbnail</InputLabel>
            <Paper variant={"outlined"} sx={{ borderColor: "other.outlinedBorderDarker", pl: 2 }}>
              <Controller
                control={control}
                name='thumbnail'
                render={({ field: { value } }) => {
                  return (
                    <FileUploadWithPreview
                      fileUrl={value}
                      onChange={(fileUrl) => {
                        setValue("thumbnail", fileUrl);
                      }}
                    />
                  );
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        <LoadingButton
          isLoading={isUpdating}
          loadingText={"Saving..."}
          type='submit'
          variant='contained'
          color='secondary'
        >
          Save
        </LoadingButton>
      </Container>
    </Box>
  );
};

export default FunnelSettings;
