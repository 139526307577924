import React from "react";
import { Stack, Typography } from "@mui/material";
import AdminAddNewPageAction from "./AdminAddNewPageAction";
import NoPageCard from "../../SingleWebsiteProperty/NoPageCard";

const AdminPageCreationEmptyPage = () => {
  return (
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={5} sx={{ height: "100%" }}>
        <NoPageCard />
        <Stack alignItems={"center"} textAlign={"center"}>
          <Typography variant={"h3"} sx={{ color: "#316aff" }}>
            You haven't created any pages yet!
          </Typography>
          <Typography variant={"h6"} sx={{ color: "#181F48", mb: 5 }}>
            Ready to get started on your next masterpiece?
          </Typography>
        <AdminAddNewPageAction />
      </Stack>
    </Stack>
  );
};

export default AdminPageCreationEmptyPage;
