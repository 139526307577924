import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import WebsitePages from "./WebsitePages";
import WebsitePageTabs from "./WebsitePageTabs";
import WebsitePageHeading from "./WebsitePageHeading";
import WebsitePageSettings from "./WebsitePageSettings";
import { getWebsiteSetting } from "../../state/features/website-settings/websiteSettingSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const WebsiteIndex: React.FC = () => {
  const dispatch = useDispatch();
  const { websiteId } = useParams<{ websiteId: string }>();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(getWebsiteSetting(websiteId));
  }, [websiteId, dispatch]);

  return (
    <React.Fragment>
      <Box sx={{ background: "#FFFFFF" }}>
        <WebsitePageHeading selectedTab={value} />
        <Divider light />
        <Stack direction={"row"}>
          <WebsitePageTabs value={value} setValue={setValue} />
          <Box flex={1} sx={{ height: "78vh", overflowY: "auto" }}>
            {value === 0 && <WebsitePages />}
            {value === 1 && <WebsitePageSettings />}
            {/*{value === 2 && <Reports />}*/}
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default WebsiteIndex;
