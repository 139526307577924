import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { WithPagination } from "../../../interface/response.interface";
import {
  ArchiveStatusPayload,
  FunnelInterface,
  GetFunnelsPayload,
  IActiveStatusPayload,
  IAddFunnelPayload,
  ICloneFunnelPayload,
  IDeleteForeverPayload,
  InTrashStatusPayload,
  IAddFunnelFromTemplateLibraryPayload
} from "./funnel.interface";
import { FunnelOrderByList } from "../../../helpers/constant/funnelConstant";

const initialState = {
  data: [] as FunnelInterface[],
  currentPage: 1,
  limit: 10,
  count: 0,
  isLoading: false,
  isError: false,
  error: "",

  renderId: "",
  selectedFunnelIds: [] as number[],

  isAdding: false,
  isAddSuccess: false,
  addedFunnelInfo: null as FunnelInterface | null,

  isDeleting: false,
  isDeleteSuccess: false,

  isUpdating: false,
  isUpdateSuccess: false,

  filters: {
    isArchived: false,
    inTrash: false,
    searchText: "",
    orderBy: FunnelOrderByList[2].value,
  },
};

const funnelSlice = createSlice({
  name: "funnel",
  initialState,
  reducers: {
    getFunnelList: (state, action: PayloadAction<GetFunnelsPayload>) => {
      if (!state.renderId) state.isLoading = true;
    },
    getFunnelListSuccess: (state, action: PayloadAction<WithPagination<FunnelInterface>>) => {
      const { content, count, limit, currentPage } = action.payload;

      state.data = content;
      state.count = count;
      state.limit = +limit;
      state.currentPage = +currentPage;
      state.isLoading = false;
      state.isError = false;
      state.error = "";
    },
    getFunnelListFailed: (state, action) => {
      state.data = [];
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },
    forceFunnelRender: (state) => {
      state.renderId = nanoid(5);
    },

    addFunnel: (state, action: PayloadAction<IAddFunnelPayload>) => {
      state.isAdding = true;
    },
    addFunnelSuccess: (state, action: PayloadAction<FunnelInterface>) => {
      state.isAdding = false;
      state.isAddSuccess = true;
      state.addedFunnelInfo = action.payload;
    },
    addFunnelFailed: (state) => {
      state.isAdding = false;
      state.isAddSuccess = false;
    },
    resetAddFunnelSuccess: (state) => {
      state.isAddSuccess = false;
      state.addedFunnelInfo = null;
    },

    addFunnelFromTemplateLibrary: (state, action: PayloadAction<IAddFunnelFromTemplateLibraryPayload>) => {
      state.isAdding = true;
    },
    addFunnelFromTemplateLibrarySuccess: (state, action: PayloadAction<FunnelInterface>) => {
      state.isAdding = false;
      state.isAddSuccess = true;
      state.addedFunnelInfo = action.payload;
    },

    resetAddFunnelFromTemplateLibrary: (state) => {
      state.isAddSuccess = false;
      state.addedFunnelInfo = null;
    },

    deleteFunnelForever: (state, action: PayloadAction<IDeleteForeverPayload>) => {
      state.isDeleting = true;
    },
    deleteFunnelForeverSuccess: (state, action: PayloadAction<IDeleteForeverPayload>) => {
      if (action.payload) {
        state.data = state.data.filter((funnel) => !action.payload.ids.includes(funnel.id as number));
      }
      state.isDeleting = false;
      state.isDeleteSuccess = true;
      state.renderId = nanoid(5);
      state.selectedFunnelIds = [];
    },
    resetDeleteFunnelForeverSuccess: (state) => {
      state.isDeleting = false;
      state.isDeleteSuccess = false;
    },

    cloneFunnel: (state, action: PayloadAction<ICloneFunnelPayload>) => {
      state.isUpdating = true;
    },
    cloneFunnelSuccess: (state) => {
      state.isUpdating = false;
      state.renderId = nanoid(5);
    },
    cloneFunnelFailed: (state) => {
      state.isUpdating = false;
    },

    toggleActiveStatus: (state, action: PayloadAction<IActiveStatusPayload>) => {
      state.isUpdating = true;
      state.data = state.data.map((funnel) => {
        if (action.payload.id === funnel.id) {
          return { ...funnel, activeStatus: action.payload.activeStatus };
        }
        return funnel;
      });
    },
    toggleActiveStatusSuccess: (state) => {
      state.isUpdating = false;
    },
    toggleActiveStatusFailed: (state, action: PayloadAction<IActiveStatusPayload>) => {
      state.isUpdating = false;
      state.data = state.data.map((funnel) => {
        if (action.payload.id === funnel.id) {
          return { ...funnel, activeStatus: !action.payload.activeStatus };
        }
        return funnel;
      });
    },

    toggleArchiveStatus: (state, action: PayloadAction<ArchiveStatusPayload>) => {
      state.isUpdating = true;
    },
    toggleArchiveStatusSuccess: (state, action: PayloadAction<ArchiveStatusPayload>) => {
      state.data = state.data.filter((funnel) => !action.payload.ids.includes(funnel.id as number));
      state.isUpdating = false;
      state.renderId = nanoid(5);
      state.selectedFunnelIds = [];
    },
    toggleArchiveStatusFailed: (state) => {
      state.isUpdating = false;
    },

    toggleInTrashStatus: (state, action: PayloadAction<InTrashStatusPayload>) => {
      state.isUpdating = true;
    },
    toggleInTrashStatusSuccess: (state, action: PayloadAction<InTrashStatusPayload>) => {
      state.data = state.data.filter((funnel) => !action.payload.ids.includes(funnel.id as number));
      state.isUpdating = false;
      state.renderId = nanoid(5);
      state.selectedFunnelIds = [];
    },
    toggleInTrashStatusFailed: (state) => {
      state.isUpdating = false;
    },

    // funnel selection actions
    selectedAllFunnel: (state) => {
      state.selectedFunnelIds = state.data.map((data) => data.id);
    },
    deselectAllFunnel: (state) => {
      state.selectedFunnelIds = [];
    },
    toggleSelectedSingleFunnel: (state, action) => {
      const currentFormIndex = state.selectedFunnelIds.findIndex((selectedId) => selectedId === action.payload);
      if (currentFormIndex !== -1) {
        state.selectedFunnelIds.splice(currentFormIndex, 1);
      } else {
        state.selectedFunnelIds.push(action.payload);
      }
    },

    resetFunnelUpdateSuccess: (state) => {
      state.isUpdateSuccess = false;
    },

    selectArchivedSidebar: (state) => {
      state.filters.isArchived = true;
      state.filters.inTrash = false;
      state.selectedFunnelIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectTrashSidebar: (state) => {
      state.filters.isArchived = false;
      state.filters.inTrash = true;
      state.selectedFunnelIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },
    selectAllFunnelsSidebar: (state) => {
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.selectedFunnelIds = [];
      state.currentPage = 1;
      state.renderId = "";
    },

    addFunnelSearchTextFilter: (state, action: PayloadAction<string>) => {
      state.filters.searchText = action.payload;
      state.currentPage = 1;
    },
    addFunnelOrderByFilter: (state, action: PayloadAction<string>) => {
      state.filters.orderBy = action.payload;
      state.currentPage = 1;
    },

    resetFunnelFilter: (state) => {
      state.filters.isArchived = false;
      state.filters.inTrash = false;
      state.filters.searchText = "";
      state.filters.orderBy = FunnelOrderByList[2].value;
    },
    resetOnlyFunnelFilter: (state) => {
      state.filters.searchText = "";
      state.filters.orderBy = FunnelOrderByList[2].value;
    },
  },
});

export const {
  getFunnelList,
  getFunnelListFailed,
  getFunnelListSuccess,

  addFunnelFromTemplateLibrary,
  addFunnelFromTemplateLibrarySuccess,
  resetAddFunnelFromTemplateLibrary,

  addFunnel,
  addFunnelFailed,
  addFunnelSuccess,
  resetAddFunnelSuccess,
  forceFunnelRender,

  deleteFunnelForever,
  deleteFunnelForeverSuccess,
  resetDeleteFunnelForeverSuccess,

  cloneFunnel,
  cloneFunnelFailed,
  cloneFunnelSuccess,

  toggleActiveStatus,
  toggleActiveStatusSuccess,
  toggleActiveStatusFailed,

  toggleInTrashStatus,
  toggleInTrashStatusSuccess,
  toggleInTrashStatusFailed,

  toggleArchiveStatus,
  toggleArchiveStatusSuccess,
  toggleArchiveStatusFailed,

  selectedAllFunnel,
  deselectAllFunnel,
  toggleSelectedSingleFunnel,

  addFunnelSearchTextFilter,
  addFunnelOrderByFilter,

  selectAllFunnelsSidebar,
  selectArchivedSidebar,
  selectTrashSidebar,

  resetFunnelUpdateSuccess,
  resetFunnelFilter,
  resetOnlyFunnelFilter,
} = funnelSlice.actions;

export default funnelSlice.reducer;
