import React, { useState } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import EachRedirect from "./EachRedirect";
import RedirectSkeleton from "./RedirectSkeleton";
import GlobalEmptyPage from "../../../common/GlobalEmptyPage/GlobalEmptyPage";
import GlobalTablePagination from "../../../common/Pagination/GlobalTablePagination";
import { useGetRedirectUrlListQuery } from "../../../state/features/redirectUrl/redirectUrlApiSlice";

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Domain</TableCell>
        <TableCell>path</TableCell>
        <TableCell>destination</TableCell>
        <TableCell>created</TableCell>
        <TableCell align={"right"}>action</TableCell>
      </TableRow>
    </TableHead>
  );
};

const UrlRedirectsTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: redirectUrlState, isLoading } = useGetRedirectUrlListQuery({ currentPage, limit });
  const { content: redirectUrlList = [], count } = redirectUrlState?.data || {};

  const onChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const onChangePerPage = (perPage: number, newPage: number) => {
    setCurrentPage(newPage);
    setLimit(perPage);
  };

  let content = <></>;
  if (isLoading) {
    content = <RedirectSkeleton count={5} />;
  } else if (redirectUrlList?.length === 0) {
    content = (
      <TableBody>
        <TableRow>
          <TableCell colSpan={5} align={"center"}>
            <GlobalEmptyPage title={"No Url Redirects Found!"} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  } else if (redirectUrlList?.length > 0) {
    content = (
      <TableBody>
        {redirectUrlList.map((eachRedirect) => {
          return <EachRedirect key={eachRedirect.id} eachRedirect={eachRedirect} />;
        })}
      </TableBody>
    );
  }

  return (
    <Box>
      <TableContainer sx={{ maxHeight: "60vh" }}>
        <Table stickyHeader>
          <TableHeader />
          {content}
        </Table>
      </TableContainer>

      {redirectUrlList?.length > 0 && (
        <GlobalTablePagination
          count={count}
          page={currentPage}
          rowsPerPage={limit}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      )}
    </Box>
  );
};

export default UrlRedirectsTable;
