import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import CoreTextField from "../../../common/TextField/CoreTextField";
import { selectFunnelInfoData } from "../../../state/features/funnelInfo/funnelInfoSelector";
import { addFunnelReportsSearchText } from "../../../state/features/funnelReports/funnelReportsSlice";
import { selectFunnelReportsFilters } from "../../../state/features/funnelReports/funnelReportsSelector";
interface IProps {
  onBackToFunnel: () => void;
}

const StepReportsHeader: React.FC<IProps> = ({ onBackToFunnel }) => {
  const dispatch = useDispatch();
  const { name } = useSelector(selectFunnelInfoData);
  const { searchText } = useSelector(selectFunnelReportsFilters);

  const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(addFunnelReportsSearchText(event.target.value));
  };

  return (
    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} p={2}>
      <IconButton size={"small"} onClick={onBackToFunnel}>
        <ArrowBackIcon />
      </IconButton>
      <Stack sx={{ mr: "auto", ml: 1 }}>
        <Typography variant={"h6"}>Stats & Analytics</Typography>
        <Typography variant={"caption"} color={"text.secondary"}>
          {name}
        </Typography>
      </Stack>

      <CoreTextField
        size={"small"}
        type={"search"}
        color={"secondary"}
        InputProps={{
          startAdornment: (
            <InputAdornment sx={{ mr: 0 }} position='start'>
              <Search />
            </InputAdornment>
          ),
        }}
        placeholder={"Search"}
        value={searchText}
        onChange={handleSearchTextChange}
      />
    </Stack>
  );
};

export default StepReportsHeader;
