import React, { useEffect } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import FunnelTemplateCard from "./FunnelTemplateCard";
import { AppDispatch } from "../../../../state/store";
import CoreButton from "../../../../common/Button/CoreButton";
import { EmptyFormListIcon } from "../../../../common/Icons/EmptyDataIcons";
import GlobalEmptyPage from "../../../../common/GlobalEmptyPage/GlobalEmptyPage";
import TemplateListSkeleton from "../../../../common/LoadingView/TemplateListSkeleton";
import { selectFunnelTemplateLibraryState } from "../../../../state/features/funnel-template-library/funnelTemplateLibrarySelector";
import { fetchTemplateLibrary ,fetchMoreTemplateLibrary} from "../../../../state/features/funnel-template-library/funnelTemplateLibrarySlice";

const TemplateGallery: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    data: templateList,
    isLoading,
    currentPage,
    limit,
    count,
    filters,
    isMoreFetching,
  } = useSelector(selectFunnelTemplateLibraryState);
  const { searchText, tagIds, typeIds, categoryId } = filters || {};
  const typeIdsString = typeIds.reduce((acc, curr) => {
    if (acc === "") return curr;
    return acc + "," + curr;
  }, "");
  const tagIdsString = tagIds.reduce((acc, curr) => {
    if (acc === "") return curr;
    return acc + "," + curr;
  }, "");

  useEffect(() => {
    dispatch(
      fetchTemplateLibrary({
        currentPage: 1,
        limit: 30,
        categoryId,
        typeIds: typeIdsString,
        tagIds: tagIdsString,
        searchText: searchText,
      })
    );
  }, [dispatch, limit, searchText, categoryId, typeIdsString, tagIdsString]);

  const handleShowMoreList = () => {
    dispatch(
      fetchMoreTemplateLibrary({
        currentPage: currentPage + 1,
        limit: limit,
        categoryId,
        searchText: searchText,
        typeIds: typeIdsString,
        tagIds: tagIdsString,
      })
    );
  };

  // decide what to render
  let content = <></>;
  if (isLoading) {
    content = <TemplateListSkeleton count={10} />;
  } else if (templateList?.length === 0) {
    content = (
      <Box sx={{ maxHeight: 500 }}>
        <GlobalEmptyPage icon={<EmptyFormListIcon />} title={"No Template Found"} />
      </Box>
    );
  } else if (templateList.length > 0) {
    content = (
      <Grid container spacing={3} rowSpacing={5}>
        {templateList.map((template) => {
          return (
            <Grid key={template.id} item xs={12} sm={6} md={4} xl={3}>
              <FunnelTemplateCard template={template} />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  const hideShowMoreButton = isMoreFetching || isLoading || templateList?.length <= count;
  return (
    <Box width={150} sx={{ flex: 1, my: 3 }}>
      {content}

      {isMoreFetching && <TemplateListSkeleton count={10} />}
      <Stack direction={"row"} justifyContent={"center"}>
        {!hideShowMoreButton && (
          <CoreButton endIcon={<KeyboardArrowDownIcon />} onClick={handleShowMoreList}>
            See More
          </CoreButton>
        )}
      </Stack>
    </Box>
  );
};

export default TemplateGallery;
