import React, { useCallback, useState } from "react";
import { WebsiteListBody, WebsiteListWrapper } from "./SitesListStyles";
import WebsiteListHeader from "./TopBar/WebsiteListHeader";
import WebsiteListLeftSide from "./WebsiteListLeftSide";
import WebsiteLists from "./WebsiteLists";
import WebsiteFunnelSwitchingTabs from "../WebsiteFunnelSwitchingTabs";

const SiteListPage = () => {
  const [isHideLeftSide, setIsHideLeftSide] = useState(false);
  const [isShowLeftSideTab, setIsShowLeftSideTab] = useState(false);

  const toggleLeftSidePanel = useCallback(() => {
    const width = window?.innerWidth;

    if (width > 1200) {
      setIsHideLeftSide(!isHideLeftSide);
    } else {
      setIsHideLeftSide(false);
      setIsShowLeftSideTab(!isShowLeftSideTab);
    }
  }, [isHideLeftSide, isShowLeftSideTab]);

  return (
    <WebsiteListWrapper>

      <WebsiteListHeader />

      <WebsiteListBody>
        <WebsiteListLeftSide
          toggleLeftSide={toggleLeftSidePanel}
          isHideLeftSide={isHideLeftSide}
          isShowLeftSideTab={isShowLeftSideTab}
        />
        <WebsiteLists />
      </WebsiteListBody>
    </WebsiteListWrapper>
  );
};

export default SiteListPage;
