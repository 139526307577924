import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { IGetWebsiteReportPayload, IWebsiteReports } from "./websiteReports.interface";
import { getDatesByDuration } from "../../../helpers/utils/getDatesByDuration";
import { WEBSITE_REPORT_DATE_RANGE_TYPE, WEBSITE_REPORT_TAB } from "../../../helpers/constant/websiteConstant";

const [startDate, endDate] = getDatesByDuration(WEBSITE_REPORT_DATE_RANGE_TYPE.THIS_WEEK);

const initialState = {
  data: {} as IWebsiteReports,
  isLoading: false,
  isError: false,
  error: "",

  filters: {
    tab: WEBSITE_REPORT_TAB.TRAFFIC,
    startDate: format(startDate, "yyyy-MM-dd"), // "yyyy-MM-dd" format,
    endDate: format(endDate, "yyyy-MM-dd"), // "yyyy-MM-dd" format
  },
};

const websiteReportsSlice = createSlice({
  name: "websiteReports",
  initialState,
  reducers: {
    getWebsiteReports(state, action: PayloadAction<IGetWebsiteReportPayload>) {
      state.isLoading = true;
    },

    getWebsiteReportsSuccess(state, action: PayloadAction<IWebsiteReports>) {
      state.isLoading = false;
      state.isError = false;
      state.error = "";
      state.data = action.payload;
    },

    getWebsiteReportsFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = true;
      state.error = action.payload;
    },

    setWebsiteReportsDateRange: (state, action) => {
      state.filters.startDate = action.payload.startDate;
      state.filters.endDate = action.payload.endDate;
    },
    setWebsiteReportsTab: (state, action) => {
      state.filters.tab = action.payload;
    },
    resetWebsiteReportsFilters: (state) => {
      state.filters.startDate = format(startDate, "yyyy-MM-dd");
      state.filters.endDate = format(endDate, "yyyy-MM-dd");
      state.filters.tab = WEBSITE_REPORT_TAB.TRAFFIC;
    },
  },
});

export const {
  getWebsiteReports,
  getWebsiteReportsFailed,
  getWebsiteReportsSuccess,

  setWebsiteReportsDateRange,
  setWebsiteReportsTab,
  resetWebsiteReportsFilters,
} = websiteReportsSlice.actions;

export default websiteReportsSlice.reducer;
