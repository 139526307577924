export const STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  FAILED: 3,
};

export const GLOBAL_DELAY = {
  DEBOUNCE: 1000,
  RESPONSE: 1000,
};

export const CREATE_WEBSITE_FROM_BLANK = 1;
export const CREATE_WEBSITE_FROM_TEMPLATE = 2;

export const FUNNEL_PAGE_ID = {
  FUNNEL: "FUNNEL",
  STATS: "STATS",
  SETTINGS: "SETTINGS",
};

export const CREATE_FUNNEL_FROM_BLANK = 1;
export const CREATE_FUNNEL_FROM_TEMPLATE = 2;


