import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Divider, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CoreButton from "../../../common/Button/CoreButton";
import LoadingButton from "../../../common/Button/LoadingButton";
import InputWithLabel from "../../../common/TextField/InputWithLabel";
import { CreateAdminTemplateI } from "../../../interface/website-template.interface";
import { selectAdminTemplateState } from "../../../state/features/adminTemplates/adminTemplateSelector";
import { createTemplate, resetCreateStatus } from "../../../state/features/adminTemplates/adminTemplateSlice";

interface IProps {
  onClose: () => void;
}

const CreateWebsiteModalAdmin: React.FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isCreating, isCreated, createdWebsiteId } = useSelector(selectAdminTemplateState);
  const { handleSubmit, control } = useForm<CreateAdminTemplateI>({ values: { name: "" } });

  useEffect(() => {
    if (isCreated) {
      history.push("/wbuilder/admin/site/" + createdWebsiteId);
      dispatch(resetCreateStatus());
    }
  }, [dispatch, isCreated]);

  const onSubmitHandler = (value: CreateAdminTemplateI) => {
    dispatch(createTemplate(value));
  };

  return (
    <Box component='form' noValidate onSubmit={handleSubmit(onSubmitHandler)}>
      <Box p={3}>
        <Controller
          name='name'
          control={control}
          rules={{
            required: "Template name is required!",
            maxLength: {
              value: 50,
              message: "Template name must have less than 50 characters!",
            },
            pattern: {
              value: /^(?!\s*$).+/,
              message: "Template name is empty",
            },
          }}
          render={({ field, fieldState: { invalid, error } }) => {
            return (
              <InputWithLabel
                required
                color={"secondary"}
                fullWidth={true}
                label={"Website Template Name"}
                placeholder={"Name for your awesome template"}
                error={invalid}
                helperText={error?.message}
                {...field}
              />
            );
          }}
        />
      </Box>

      <Divider light />
      <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} p={3}>
        <CoreButton color={"inherit"} size={"small"} onClick={onClose}>
          Cancel
        </CoreButton>
        <LoadingButton
          type='submit'
          variant={"contained"}
          color={"secondary"}
          size={"small"}
          isLoading={isCreating}
          sx={{ minWidth: 88 }}
        >
          Create
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default CreateWebsiteModalAdmin;
