import React from "react";
import { styled, alpha } from "@mui/material";
import ReactSelect, { Props } from "react-select";

export type ReactSelectProps<T = unknown, Multi extends boolean = false> = Omit<Props<T, Multi>, "isMulti"> & {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  OptionsMaxHeight?: number;
};

const StyledReactSelect = styled(ReactSelect)<ReactSelectProps>(
  ({ theme, OptionsMaxHeight = 180, classNamePrefix, width, minWidth, maxWidth }) => {
    return {
      [`& .${classNamePrefix}__control`]: {
        minWidth: minWidth ? minWidth : "100px",
        width: width ? width : "100%",
        maxWidth: maxWidth ? maxWidth : "none",
        borderColor: '#1d29391f',
        "&:hover": {
          borderColor: theme.palette.common.black,
        },
        [`& .${classNamePrefix}__input-container`]: {
          height:30,
          "& input":{
            height:'100%'
          }
        },
        [`& .${classNamePrefix}__indicator-separator`]: {
          display:'none'
        },
        [`& .${classNamePrefix}__indicator`]: {
         "& svg":{
           fill: '#949DB2'
         }
        },
        [`&.${classNamePrefix}__control--is-focused`]: {
          borderColor: theme.palette.primary.main,
        },
        [`& .${classNamePrefix}__multi-value`]: {
          fontSize: "14px",
          [`& .${classNamePrefix}__multi-value__remove`]: {
            cursor: "pointer",
          },
        },
        [`& .${classNamePrefix}__placeholder`]: {
          fontSize: "14px",
        },
      },
      [`& .${classNamePrefix}__menu`]: {
        zIndex: 9,
        minWidth: minWidth ? minWidth : "100px",
        width: width ? width : "100%",
        maxWidth: maxWidth ? maxWidth : "none",
        marginTop: 0,
        boxShadow: theme.shadows[5],
        [`& .${classNamePrefix}__menu-list`]: {
          maxHeight: OptionsMaxHeight,
          fontSize: "14px",
          [`& .${classNamePrefix}__option`]: {
            cursor: "pointer",
            [`&.${classNamePrefix}__option--is-focused`]: {
              backgroundColor: "#6258ff1c",
            },
            [`&.${classNamePrefix}__option--is-selected`]: {
              color: "initial",
              backgroundColor: "#6258ff1c",
            },
          },
        },
      },
      "&.rounded": {
        [`& .${classNamePrefix}__control`]: {
          [`& .${classNamePrefix}__multi-value`]: {
            borderRadius: "15px",
            overflow: "hidden",
            lineHeight: "unset",
          },
        },
      },
    };
  }
) as ReactSelect;

export default StyledReactSelect;