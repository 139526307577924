import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Stack } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  createType,
  updateType,
  resetCreateSuccess,
  resetUpdateSuccess,
} from "../../../../state/features/type/typeSlice";
import { AppDispatch } from "../../../../state/store";
import TypeI from "../../../../interface/type.interface";
import CoreButton from "../../../../common/Button/CoreButton";
import LoadingButton from "../../../../common/Button/LoadingButton";
import CoreTextField from "../../../../common/TextField/CoreTextField";
import { selectTypeState } from "../../../../state/features/type/typeSelector";
import { registerPatternType } from "../../../../helpers/constant/registerPattern";

interface Props {
  onClose: () => void;
  isEditMode?: boolean;
  type?: TypeI;
  label?: string;
}

const AddOrEditTypesName: React.FC<Props> = ({ onClose, isEditMode, type, label }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isCreatedSuccessfully, isUpdatedSuccessfully, isCreating, isUpdating } = useSelector(selectTypeState);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TypeI>();

  const onSubmit: SubmitHandler<TypeI> = (data) => {
    if (isEditMode) {
      dispatch(updateType(data));
    } else {
      dispatch(createType(data));
    }
  };

  useEffect(() => {
    if (type) {
      setValue("id", type.id);
      setValue("name", type.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (isCreatedSuccessfully || isUpdatedSuccessfully) {
      dispatch(resetCreateSuccess());
      dispatch(resetUpdateSuccess());
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedSuccessfully, isUpdatedSuccessfully]);

  const isLoading = isCreating || isUpdating;
  return (
    <Box
      component='form'
      noValidate
      autoComplete='off'
      sx={{ p: 3, bgcolor: "background.paper", height: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl fullWidth error={!!(errors.name && errors.name.message)} required>
        <CoreTextField
          type='text'
          size='small'
          color='secondary'
          label={label}
          placeholder='Enter website type name here'
          error={!!(errors.name && errors.name.message)}
          helperText={errors.name && errors.name.message}
          {...register("name", registerPatternType.name)}
          required
        />
      </FormControl>

      <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={2} mt={2}>
        <CoreButton size='large' color='error' onClick={onClose} type='button'>
          Cancel
        </CoreButton>
        <LoadingButton
          isLoading={isLoading}
          loadingText={isEditMode ? "Changing..." : "Creating..."}
          type='submit'
          size='large'
          color='success'
        >
          {isEditMode ? "Change" : "Create"}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

export default AddOrEditTypesName;
