import { takeEvery, delay } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import {
  addWebsiteFailed,
  addWebsitesToFoldersFailed,
  addWebsitesToFoldersSuccess,
  addWebsiteSuccess,
  cloneWebsiteFailed,
  cloneWebsiteSuccess,
  deleteWebsiteFailed,
  deleteWebsiteForeverFailed,
  deleteWebsiteForeverSuccess,
  deleteWebsiteSuccess,
  getWebsitesFailed,
  getWebsitesSuccess,
  removeFolderFromWebsiteSuccess,
  renamedWebsiteFailed,
  renamedWebsiteSuccess,
  resetWebsiteUpdateSuccess,
  toggleArchiveStatusFailed,
  toggleArchiveStatusSuccess,
  toggleFavStatusSuccess,
} from "./websiteSlice";
import { showErrorToaster, showSuccessToaster } from "../../../helpers/utils/toaster";
import {
  IAddWebsitePayload,
  IAddWebsitesToFoldersPayload,
  IArchiveStatusPayload,
  ICloneWebsitePayload,
  IDeleteForeverPayload,
  IFavoriteStatusPayload,
  IGetWebsitesPayload,
  IRemoveFolderFromWebsitePayload,
  IRenamedWebsitePayload,
  IWebsiteRemovePayload,
} from "./website.interface";
import WebsiteService from "../../../services/website.service";

function* websiteWatcher() {
  yield takeEvery("website/getWebsites", getWebsitesSaga);
  yield takeEvery("website/addWebsite", addWebsiteSaga);
  yield takeEvery("website/renamedWebsite", renamedWebsiteSaga);
  yield takeEvery("website/deleteWebsite", deleteWebsiteSaga);
  yield takeEvery("website/deleteWebsiteForever", deleteWebsiteForeverSaga);
  yield takeEvery("website/cloneWebsite", cloneWebsiteSaga);
  yield takeEvery("website/toggleFavStatus", toggleWebsiteFavStatusSaga);
  yield takeEvery("website/toggleArchiveStatus", toggleWebsiteArchiveStatusSaga);
  yield takeEvery("website/addWebsitesToFolders", addWebsitesToFoldersSaga);
  yield takeEvery("website/removeFolderFromWebsite", removeFolderFromWebsiteSaga);
  yield takeLatest("website/showErrorToasterRedux", showErrorToasterReduxSaga);
}

function* getWebsitesSaga(action: PayloadAction<IGetWebsitesPayload>) {
  try {
    const response = yield* call(WebsiteService.getWebsites, action.payload);

    if (response.success) {
      yield put(getWebsitesSuccess(response.data));
    } else {
      yield put(getWebsitesFailed(response.message));
    }
  } catch (err) {
    yield put(getWebsitesFailed("Network or server error"));
  }
}

function* addWebsiteSaga(action: PayloadAction<IAddWebsitePayload>) {
  try {
    const response = yield* call(WebsiteService.addWebsite, action.payload);

    if (response.success) {
      yield put(addWebsiteSuccess(response.data));
      showSuccessToaster(response.message);
    } else {
      yield put(addWebsiteFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(addWebsiteFailed());
  }
}

function* renamedWebsiteSaga(action: PayloadAction<IRenamedWebsitePayload>) {
  try {
    const response = yield* call(WebsiteService.renamedWebsite, action.payload);

    if (response.success) {
      yield put(renamedWebsiteSuccess(action.payload));
      showSuccessToaster("Website renamed successfully!");
    } else {
      yield put(renamedWebsiteFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(renamedWebsiteFailed());
  }
}

function* deleteWebsiteSaga(action: PayloadAction<IWebsiteRemovePayload>) {
  try {
    const response = yield* call(WebsiteService.deleteWebsite, action.payload);

    if (response.success) {
      yield put(deleteWebsiteSuccess(action.payload));
      if (action.payload.inTrash) {
        showSuccessToaster("Website moved to trash successfully!");
      } else {
        showSuccessToaster(
          "Website" + (action.payload.websiteIds?.length > 1 ? "s" : "") + " restored from trash successfully!"
        );
      }
    } else {
      yield put(deleteWebsiteFailed());
      if (action.payload.inTrash) showErrorToaster("Failed website delete");
      else showErrorToaster("Failed website restore" + (action.payload.websiteIds?.length > 1 ? "s" : ""));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* deleteWebsiteForeverSaga(action: PayloadAction<IDeleteForeverPayload>) {
  try {
    const response = yield* call(WebsiteService.deleteWebsiteForever, action.payload);

    if (response.success) {
      yield put(deleteWebsiteForeverSuccess(action.payload));
      showSuccessToaster("Website deleted forever successfully!");
    } else {
      yield put(deleteWebsiteForeverFailed());
      showErrorToaster("Failed website deleted forever");
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* cloneWebsiteSaga(action: PayloadAction<ICloneWebsitePayload>) {
  try {
    const response = yield* call(WebsiteService.cloneWebsite, action.payload);

    if (response.success) {
      yield put(cloneWebsiteSuccess());
      showSuccessToaster(response.message);
    } else {
      yield put(cloneWebsiteFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* toggleWebsiteFavStatusSaga(action: PayloadAction<IFavoriteStatusPayload>) {
  try {
    const response = yield* call(WebsiteService.toggleWebsiteFavStatus, action.payload);

    if (response.success) {
      yield put(toggleFavStatusSuccess(action.payload));
    } else {
      yield put(getWebsitesFailed(response.message));
    }
  } catch (err) {
    yield put(getWebsitesFailed("Network or server error"));
  }
}

function* toggleWebsiteArchiveStatusSaga(action: PayloadAction<IArchiveStatusPayload>) {
  try {
    const response = yield* call(WebsiteService.toggleArchiveStatus, action.payload);

    if (response.success) {
      yield put(toggleArchiveStatusSuccess(action.payload));
      showSuccessToaster(
        action.payload.isArchived ? "Website archived successfully!" : "Website unarchived successfully!"
      );
    } else {
      yield put(toggleArchiveStatusFailed());
      showErrorToaster(response.message);
    }
  } catch (err) {
    yield put(getWebsitesFailed("Network or server error"));
  }
}

function* addWebsitesToFoldersSaga(action: PayloadAction<IAddWebsitesToFoldersPayload>) {
  try {
    const response = yield* call(WebsiteService.addWebsitesToFolders, action.payload);

    if (response.success) {
      yield put(addWebsitesToFoldersSuccess(response.data));
      showSuccessToaster("Website moved to folder successfully!");
    } else {
      yield put(addWebsitesToFoldersFailed());
      showErrorToaster("Failed add website into folder");
    }
  } catch (err) {
    yield put(addWebsitesToFoldersFailed());
    showErrorToaster("Network or server error");
  }
}

function* removeFolderFromWebsiteSaga(action: PayloadAction<IRemoveFolderFromWebsitePayload>) {
  try {
    const response = yield* call(WebsiteService.removeFolderFromWebsite, action.payload);

    if (response.success) {
      yield put(removeFolderFromWebsiteSuccess(response.data));
      showSuccessToaster("Successfully removed folder from website");
    } else {
      yield put(resetWebsiteUpdateSuccess());
      showErrorToaster("Failed removed folder from website");
    }
  } catch (err) {
    yield put(resetWebsiteUpdateSuccess());
    showSuccessToaster("Network or server error");
  }
}

function* showErrorToasterReduxSaga(action: PayloadAction<string>) {
  try {
    yield delay(100);
    showErrorToaster(action.payload);
  } catch (err) {}
}

export default function* websiteSaga() {
  yield all([websiteWatcher()]);
}
